import React, { useState, useEffect } from "react"
import { getPersColor, getPersFont, getPersLocation, getPersNote } from "../../../helpers/product"
import * as advanceAPIUtil from "../../../util/advance/advanceAPIUtil"
import { addOnChargesPersBody } from "../../../common/AdvanceBodies";

function useProductPersonalizationState({
    items,
    product,
    productSettings,
    setLoading,
    getSettingStatus
}) {
    const [personalizationCost, setPersonalizationCost] = useState({
        matchOrderQty: 0,
        price: 0,
        setupPrice: 0,
        chargeId: ''
    })
    const [personalizationArray, setPersonalizationArray] = useState(null);
    const [personalizationPerUnitPrice, setPersonalizationPerUnitPrice] = useState(null)
    const [personalizationSetupFee, setPersonalizationSetupFee] = useState(null)
    const [personalizationDropDown, setPersonalizationDropDown] = useState(false)
    const [personalizationRequired, setPersonalizationRequired] = useState(false)
    const [requiredLinesPersonalization, setRequiredLinesPersonalization] = useState('')
    const [dropDownValues, setDropDownValues] = useState([])
    const [loadPersonalizationCost, setLoadPersonalizationCost] = useState(false);
    const [advanceAddonCharge, setAdvanceAddonCharge] = useState(false);
    const [showPersonalizationCostInCart, setShowPersonalizationCostInCart] = useState(false);
    const [personalizationLabel, setPersonalizationLabel] = useState(false);
    const [showPersonalization, setShowPersonalization] = useState(false);
    const [itemsAdded, setItemsAdded] = useState([]);
    const [addAll, setAddAll] = useState(false);

    useEffect(() => {
        if (items && (itemsAdded.length > 0 || addAll)) {
            createPersonalizationRows();
        }
    }, [items, itemsAdded, addAll])

    useEffect(() => {
        setPersonalizationPerUnitPrice(product?.personalizationPerUnitPrice)
        setPersonalizationSetupFee(product?.personalizationSetupFee)
    }, [product]);

    useEffect(() => {
        if (product?.personalizationDropDown === true || product?.personalizationDropDown === false) {
            setPersonalizationDropDown(product?.personalizationDropDown)
            setRequiredLinesPersonalization(product?.requiredLinesPersonalization)
            setDropDownValues(product?.dropDownValues)
        } else if (productSettings?.personalizationDropDown === true || productSettings?.personalizationDropDown === false) {
            setPersonalizationDropDown(productSettings?.personalizationDropDown)
            setRequiredLinesPersonalization(productSettings?.requiredLinesPersonalization)
            setDropDownValues(productSettings?.dropDownValues)
        }
        if (getSettingStatus) {
            setPersonalizationRequired(getSettingStatus('personalizationRequired'));
            setShowPersonalizationCostInCart(getSettingStatus('showPersonalizationCostInCart'));
        }
        setPersonalizationLabel(!!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
            ? productSettings['showPersonalizationLabel']
            : 'Personalization')
        setShowPersonalization(getSettingStatus('showPersonalization'));
    }, [product, productSettings, getSettingStatus])

    useEffect(() => {
        if (personalizationRequired) {
            addPersonalization();
        }
    }, [personalizationRequired])

    useEffect(() => {
        if (advanceAddonCharge) {
            setPersonalizationCost((preVal) => ({
                ...preVal,
                ['matchOrderQty']: personalizationSetupFee ? 1 : 0,
                ['price']: personalizationPerUnitPrice ? personalizationPerUnitPrice : 0,
                ['setupPrice']: personalizationSetupFee ? personalizationSetupFee : 0,
                ['chargeId']: personalizationPerUnitPrice ? '' : ''
            }));
        }
    }, [advanceAddonCharge, personalizationPerUnitPrice, personalizationSetupFee]);

    const fetchPersonalizationCost = async () => {
        setLoading(true);
        setLoadPersonalizationCost(true);
        try {
            let result = await advanceAPIUtil.customAdvanceCall(addOnChargesPersBody)
            if (result?.data?.data) {
                let personalizationData = result?.data?.data.filter(
                    (val) => val.itemCode === 'PERS',
                )
                if (personalizationData) {
                    setAdvanceAddonCharge(true);
                }
            }
        } catch (error) {
            console.log('error', error)
        } finally {
            setLoading(false);
        }
    }

    const addPersonalization = (key) => {
        if (key !== undefined) {
            setItemsAdded((pv) => [...pv, key]);
        } else {
            setItemsAdded([]);
            setAddAll(true);
        }
    }

    const changePersonalizationArray = (value, type, personalization) => {
        const updatedArray = personalizationArray.map(
            (p) => {
                if (
                    p.rowId === personalization.rowId &&
                    p.rowQtyId === personalization.rowQtyId &&
                    p.sequance === personalization.sequance &&
                    p.rowQtyNo === personalization.rowQtyNo
                ) {
                    if (type === 'Font') {
                        p.font = value
                    } else if (type === 'Color') {
                        p.color = value
                    } else if (type === 'Location') {
                        p.location = value
                    } else if (type === 'Note') {
                        p.notes = value
                    } else if (type === 'displayText') {
                        p.displayText = value
                    }
                }
                return p
            },
        )
        setPersonalizationArray(updatedArray);
    }

    const createPersonalizationRows = (clear = false) => {
        if (!loadPersonalizationCost) {
            fetchPersonalizationCost()
        }
        const updatedPersonalizationArray = [];
        for (let key = 0; key < items.length; key++) {
            if (!addAll && !itemsAdded.includes(key)) continue;
            for (const row of items[key]) {
                let persQty = Number(row.quantity)
                let tempMainrows = personalizationArray?.filter(
                    (persRow) => row.id === persRow.rowId && Number(persRow.sequance) === 1,
                )
                if (Number(persQty) > 0) {
                    let itemQty = Number(persQty)
                    let pushedIds = []
                    for (let index = 0; index < itemQty; index++) {
                        if (tempMainrows && tempMainrows[index]) {
                            let tempPersRow = personalizationArray.filter(
                                (persRow) => row.id === persRow.rowId && persRow.rowQtyNo === tempMainrows[index].rowQtyNo,
                            )
                            if (pushedIds.indexOf(tempPersRow[0].rowQtyNo) === -1) {
                                pushedIds.push(tempPersRow[0].rowQtyNo)
                                tempPersRow.map((tempRow) => {
                                    updatedPersonalizationArray.push({
                                        ...tempRow,
                                        itemSize: row.size,
                                        itemColor: row.color,
                                    })
                                })
                            }
                        } else {
                            if (!!personalizationDropDown && !!requiredLinesPersonalization) {
                                !!dropDownValues && dropDownValues.length > 0 && dropDownValues.map((resData, keysData) => {
                                    updatedPersonalizationArray.push({
                                        color: getPersColor(product, productSettings),
                                        location: getPersLocation(product, productSettings),
                                        font: getPersFont(product, productSettings),
                                        notes: getPersNote(product, productSettings),
                                        displayText: '',
                                        itemCode: product.itemCode,
                                        itemColor: row.color,
                                        itemId: product.id,
                                        itemNo: product.productId,
                                        itemSize: row.size,
                                        orderId: '',
                                        rowId: row.id,
                                        rowQtyId: '',
                                        rowQtyNo: index + 1,
                                        sequance: 1 + keysData,
                                        lineName: resData.personalizationDropDownRename,
                                        isDrp: resData.isPersonalizationDropDown,
                                        drpValue: resData.personalizationDropDownValue,
                                        isEnabled: personalizationDropDown,
                                        requiredLine: requiredLinesPersonalization,
                                    })
                                })
                            } else {
                                updatedPersonalizationArray.push({
                                    color: getPersColor(product, productSettings),
                                    location: getPersLocation(product, productSettings),
                                    font: getPersFont(product, productSettings),
                                    notes: getPersNote(product, productSettings),
                                    displayText: '',
                                    itemCode: product.itemCode,
                                    itemColor: row.color,
                                    itemId: product.id,
                                    itemNo: product.productId,
                                    itemSize: row.size,
                                    orderId: '',
                                    rowId: row.id,
                                    rowQtyId: '',
                                    rowQtyNo: index + 1,
                                    sequance: 1,
                                })
                            }
                        }
                    }
                }
            }
        }
        if (clear) {
            updatedPersonalizationArray = updatedPersonalizationArray.map((row) => ({ ...row, displayText: '' }));
        }
        setPersonalizationArray(updatedPersonalizationArray);
    }

    const addPersonalizationRow = (personalization) => {
        const qtyPersRows = personalizationArray.filter((persRow) => {
            return (
                persRow.rowId === personalization.rowId &&
                persRow.rowQtyId === personalization.rowQtyId &&
                persRow.rowQtyNo === personalization.rowQtyNo
            )
        });
        const newRowData = {
            ...personalization,
            color: getPersColor(product, productSettings),
            location: getPersLocation(product, productSettings),
            font: getPersFont(product, productSettings),
            notes: getPersNote(product, productSettings),
            displayText: '',
            sequance: (Number(qtyPersRows.length) + 1),
        }
        const mainIndex = personalizationArray.indexOf(qtyPersRows[0]);
        let updatedPersonalizationArray = [...personalizationArray]
        updatedPersonalizationArray.splice(mainIndex + qtyPersRows.length, 0, newRowData);
        setPersonalizationArray(updatedPersonalizationArray);
    }

    const deletePersonalizationRow = (personalization, arrayKey) => {
        let updatedPersonalizationArray = personalizationArray.filter((persRow, key) => {
            if (
                personalization.rowQtyNo === persRow.rowQtyNo &&
                personalization.rowQtyId === persRow.rowQtyId &&
                personalization.sequance === persRow.sequance &&
                personalization.rowId === persRow.rowId
            ) {
                return false
            }
            return true
        })
        let seq = null
        updatedPersonalizationArray = updatedPersonalizationArray.filter((persRow) => {
            if (
                seq !== null &&
                persRow.sequance !== 1 &&
                persRow.rowQtyNo === personalization.rowQtyNo &&
                personalization.rowId === persRow.rowId
            ) {
                if (Number(seq) + 1 !== Number(persRow.sequance)) {
                    persRow.sequance = Number(seq) + 1
                    seq = persRow.sequance
                }
            } else {
                seq = persRow.sequance
            }
            return persRow;
        })
        setPersonalizationArray(updatedPersonalizationArray)
    }


    const deletePersonalization = (key) => {
        if (key) {
            const rowIds = items[key]?.map((row) => row.id) ?? [];
            const updatedPersonalizationArray = personalizationArray.filter((persRow) => !rowIds.includes(persRow.rowId));
            setPersonalizationArray(updatedPersonalizationArray);
            setItemsAdded(itemsAdded.filter((item) => item !== key));
        } else {
            setPersonalizationArray(null);
            setItemsAdded([]);
            setAddAll(false);
        }
    }

    return {
        personalizationCost,
        personalizationPerUnitPrice,
        personalizationSetupFee,
        personalizationDropDown,
        personalizationRequired,
        requiredLinesPersonalization,
        dropDownValues,
        personalizationArray,
        showPersonalizationCostInCart,
        showPersonalization,
        personalizationLabel,
        deletePersonalization,
        setPersonalizationArray,
        changePersonalizationArray,
        addPersonalization,
        addPersonalizationRow,
        deletePersonalizationRow,
    }
}

export default useProductPersonalizationState;