import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import PassiveRow from "./PassiveRow";
import { useAppSelector } from "../../redux/hooks";
import { selectOrder, selectParams, selectSessionId, selectStatus } from "../../redux/selectors/checkoutSelectors";

export default function DebugCard() {
  const params = useAppSelector(selectParams);
  const sessionId = useAppSelector((state) => selectSessionId(state, params));
  const status = useAppSelector((state) => selectStatus(state, params));
  const order = useAppSelector((state) => selectOrder(state, params));

  return (
    <Card>
      <CardContent>
        <Typography variant={"h6"}>Debug</Typography>
        <PassiveRow field={"User ID"} value={params?.userId} />
        <PassiveRow field={"Card ID"} value={params?.cartId} />
        <PassiveRow field={"Session ID"} value={sessionId} />
        <PassiveRow field={"Status"} value={status} />
        <PassiveRow field={"Order #"} value={order?.no} />
        <PassiveRow field={"Order ID"} value={order?.id} />
      </CardContent>
    </Card>
  );
}