import React, { useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../common/Auth';
import { USERTYPES } from '../../constant/userType';
import BreadcrumbEditSection from './BreadcrumbEditSection';

const BreadcrumbEditIcon = ({getId, fromEditAction, retrieveSettings}) => {
    const { userType } = useContext(AuthContext)
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(!open)
    }
    const handleOpen = () => {
        setOpen(!open)
    }
    return (
        <>
            {userType && userType !== USERTYPES.USER && (
                <>
                    <h6 className="animated" onClick={() => { handleOpen() }}>
                        <i className="fa fa-edit"></i>
                    </h6>
                    <BreadcrumbEditSection open={open} handleClose={handleClose} getId={getId} fromEditAction={fromEditAction} retrieveSettings={retrieveSettings} />
                </>
            )}
        </>
    )
}

export default BreadcrumbEditIcon