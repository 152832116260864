const Validate = (inputs) => {

    const errors = {};

    if (!inputs.review) {
        errors.review = "Please enter review";
    }
    if (!inputs.productStart) {
        errors.productStart = "Please give rating";
    }

    return errors;
}

export default Validate;