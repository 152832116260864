import cheerio from "cheerio"
import { uploadFile } from "./file/upload-file";

export const upload = async (data) => {
    const formData = new FormData();
    let result = null;
    formData.append('file', data, data.name);
    await Promise.resolve(uploadFile(formData)).then(x => {
        result = x.data;
    }).catch(error => {
        console.log('error', error)
    })
    return result
}

export const getNewBody = async body => {

    return new Promise(async (resolve, reject) => {
        const $ = cheerio.load(body) //surround it with one <p> as we've stripped it all off above
        var image = $("img")
        let embededPictureUrls = []
        for (let index = 0; index < image.length; index++) {
            const element = image[index]
            if (element.attribs.src.indexOf("data:image") !== -1) {
                const base64Data = new Buffer.from(element.attribs.src.replace(/^data:image\/\w+;base64,/, ""), "base64");
                const blob = new Blob([base64Data]);
                const result = await upload(blob)
                if(result?.Location) {
                    element.attribs.src = result?.Location
                    embededPictureUrls.push(result?.Location)
                }
            } else {
                embededPictureUrls.push(element.attribs.src)
            }
        }

        var exp = /((href|src)=["']|)(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        body = body.replace(exp, function () {
            return arguments[1] ?
                arguments[0] :
                "<a href=\"" + arguments[3] + "\">" + arguments[3] + "</a>"
        });
        resolve({ nbody: $("body").html() })
    })
}



