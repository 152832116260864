import React, {useEffect, useState} from "react"

export const useShopCategoryState = (props) => {
    const {selectedCategories} = props;
    const {isExpand} = props;
    const [defaultExpand, setDefaultExpand] = useState(null)
    const dataFilterStyle = {
        overflowX: "hidden"
    }

    useEffect(() => {
        if (isExpand === true) {
            setDefaultExpand(true)
            // Object.assign(dataFilterStyle, {maxHeight: 'none'});
        } else {
            setDefaultExpand(false)
        }
    }, [isExpand])

    useEffect(() => {
        if (selectedCategories.length > 0) {
            setDefaultExpand(true)
        } else {
            setDefaultExpand(false)
        }
    }, [selectedCategories])

    return {
        defaultExpand,
        selectedCategories,
        dataFilterStyle
    }
}
