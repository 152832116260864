import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Card, CardContent, Skeleton, Stack, Typography } from '@mui/material'
import PaymentMethodCard from './PaymentMethodCard'
import {
  PAYMENT_ICONS,
  PAYMENT_NAMES,
  PaymentMethodType,
} from '../../../models/PaymentMethodType'
import { AetherPaymentRequest } from '../../../models/Payment'
import PaymentSummaryCard from './PaymentSummaryCard'
import { useGetPaymentSettingsQuery } from '../../../redux/api/paymentApi'
import { getError } from '../../../helpers/checkout'

import { useAppSelector } from '../../../redux/hooks'
import {
  selectBalanceDue,
  selectFulfillments,
  selectItems,
  selectParams,
  selectPaymentRequests,
} from '../../../redux/selectors/checkoutSelectors'
import _ from 'lodash'

interface PaymentRequestContentProps {
  index: number | null
  editing: boolean
  onEdit: () => void
  errorMessage?: {
    method: PaymentMethodType
    message: string
  }
  onSubmit: ({
    index,
    data,
  }: {
    index: number | null
    data: AetherPaymentRequest
  }) => void
}

export default function PaymentRequestContent({
  index,
  editing,
  onEdit,
  errorMessage,
  onSubmit,
}: PaymentRequestContentProps) {
  const params = useAppSelector(selectParams)
  const requests = useAppSelector(selectPaymentRequests)
  const fulfillments = useAppSelector((state) =>
    selectFulfillments(state, params),
  )
  const items = useAppSelector((state) => selectItems(state, params))
  const [selectedType, setSelectedType] = useState<string | null>(null)

  const {
    data: paymentSettings,
    isLoading: settingsLoading,
    error: settingsError,
  } = useGetPaymentSettingsQuery()

  const enabledPaymentMethods = useMemo(() => paymentSettings?.filter((method) => method.enabled), [paymentSettings])

  const defaultPaymentRequest: Partial<AetherPaymentRequest> = useMemo(() => {
    const primaryFulfillment = fulfillments?.[0]
    const primaryBilling = requests?.[0]
    return _.cloneDeep({
      address: primaryBilling?.address ?? primaryFulfillment?.address,
      contact: primaryBilling?.contact ?? primaryFulfillment?.contact,
      addressSource: primaryBilling?.addressSource ?? primaryFulfillment?.addressSource,
      savedAddressId: primaryBilling?.savedAddressId ?? primaryFulfillment?.savedAddressId,
    })
  }, [fulfillments])

  const request: Partial<AetherPaymentRequest> = typeof index === 'number'
    ? requests[index]
    : defaultPaymentRequest

  useEffect(() => {
    if (request?.methodType) {
      setSelectedType(request.methodType)
    }
  }, [request])

  const handleClick = (type: PaymentMethodType) => () => {
    setSelectedType(type)
  }

  const handleSubmit = (data: AetherPaymentRequest) => {
    setSelectedType(null)
    onSubmit({ index, data })
  }

  if (!editing && request?.methodType) {
    return (
      <PaymentSummaryCard
        payment={request as AetherPaymentRequest}
        onEdit={onEdit}
      />
    )
  }

  if (settingsLoading) {
    return (
      <Stack direction={'column'} spacing={2}>
        <Skeleton variant="rounded" width={'100%'} height={'32px'} />
        <Skeleton variant="rounded" width={'100%'} height={'32px'} />
      </Stack>
    )
  }

  return (
    <Card>
      <CardContent>
        <Stack direction={'column'} spacing={2}>
          {editing && (
            <Typography variant={'body1'}>Select Payment Method</Typography>
          )}
          {enabledPaymentMethods?.map((settings) => (
            <PaymentMethodCard
              key={settings.methodType}
              icon={PAYMENT_ICONS[settings.methodType]}
              name={
                settings.labelRenameEnabled && settings.labelRenameText
                  ? settings.labelRenameText
                  : PAYMENT_NAMES[settings.methodType]
              }
              onSelect={handleClick(settings.methodType)}
              selected={selectedType === settings.methodType}
              request={request}
              methodType={settings.methodType}
              index={index}
              errorMessage={selectedType === errorMessage?.method ? errorMessage.message : undefined}
              onSubmit={handleSubmit}
            />
          ))}
          {settingsError && (
            <Alert severity="error">
              Error loading payment methods: {getError(settingsError)}
            </Alert>
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}
