import React, { useEffect, useState } from "react";
import CloseIcon from '@material-ui/icons/Close';

const ShopSearch = (props) => {
  const [temp, setTemp] = useState()
  const onHandleInput = (event) => {
    setTemp(event.target.value)
  }
  useEffect(() => {
    setTemp(props.searchQuery)
  }, [props.searchQuery])

  const removeItem = event => {
    event.preventDefault()
    props.resetSearchParams()
    setTemp("")
  }

  const searchItem = event => {
    event.preventDefault()
    props.onHandleInput(temp)
  }

  return (
    <div className="sidebar-widget">
      <div className="pro-sidebar-search mb-50 mt-25">
        <form className="pro-sidebar-search-form">
          <input type="text" value={temp} placeholder="Search here..." onChange={(e) => { onHandleInput(e) }} />
          {temp && temp != "" &&
            <CloseIcon
              className="remove-search"
              title={'Reset Background color'}
              onClick={removeItem}
            />
          }
          <button onClick={searchItem}>
            <i className="pe-7s-search" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default ShopSearch;
