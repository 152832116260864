import PropTypes from "prop-types";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Modal } from 'react-bootstrap'
import ProductModalContent from "./ProductModalContent";
import { AuthContext } from "../../common/Auth";

function ProductModal({ product, show, onHide }) {
  const [productLayoutMain, setProductLayoutMain] = useState('');
  const [productLayout, setProductLayout] = useState('');
  const {
    themeSettingsData,
  } = useContext(AuthContext)

  useEffect(() => {
    if (themeSettingsData?.length > 0) {
      themeSettingsData.map((i) => {
        if (i.settingsName === 'theme' && i.values[0].productLayout !== '') {
          setProductLayoutMain(i.values[0].productLayout)
        }
      })
    }
  }, [themeSettingsData]);

  useEffect(() => {
    if (!!product?.productLayout ) {
      setProductLayout(product.productLayout);
    } else {
        setProductLayout(productLayoutMain)
    }
  }, [product, productLayoutMain])

  return (
    <Fragment>
      <Modal
        show={show}
        onHide={onHide}
        className={`product-quickview-modal-wrapper${productLayout === 'product-condensed' ? ' productCondensedModal' : ''}`}
      >
        <Modal.Header closeButton></Modal.Header>
        <ProductModalContent product={product} />
      </Modal>
    </Fragment>
  )
}

ProductModal.propTypes = {
  onHide: PropTypes.func,
  product: PropTypes.object,
  show: PropTypes.bool,
};

export default ProductModal;
