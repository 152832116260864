import axios from 'axios';
import { X_TENANT_ID } from '../../common/ActionUrl';
const requestOptions = {
    headers: { 'X-TENANT-ID': X_TENANT_ID },
}

export const fetchBlogs = (limit = 50, offset = 0, order = "createdAt", orient = "asc", filters = []) => {
    let url = `/api/v1/front/blogs?limit=${limit}&offset=${offset}&order=${order}&orient=${orient}&isEnabled=${true}`;     
    for (let key in filters) {
        if (filters[key] && Array.isArray(filters[key])) {
            filters[key].forEach(el => {
                url += `&${key}[]=${el}`
            });
        } else if (filters[key]) {             
            url += `&${key}=${filters[key]}`;
        }
    }
    return axios.get(url, requestOptions);
};
export const fetchBlog = (urlSlug) => {
    let url = `/api/v1/front/blogs/getbyurl/` + urlSlug;
    return axios.get(url, requestOptions);
};
export const getBlogCountForeachCategory = (catIds) => {
    let url = `/api/v1/front/blogs/getcountbyids/`;
    let idString = "?"
    if (catIds && catIds.length > 0) {
        catIds.map(id => {
            idString = idString + "&ids[]=" + id
        })
    }
    return axios.get((url + idString), requestOptions);
};