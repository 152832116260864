export interface Address {
  locationName?: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  residential: boolean;
}

export interface AddressContact {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  company?: string;
  attentionTo?: string;
}

export enum SavedAddressSource {
  USER_ADDRESS = 'userAddress',
  SHIPPING_LOCATION = 'shippingLocation',
}

export type SavedAddress = Address & Partial<AddressContact> & {
  locationName: string;
  source: SavedAddressSource;
  isDefault: boolean;
  id: string;
};

export type FullAddress = Address & AddressContact;