import React from 'react'
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Stack,
  Tooltip,
} from '@mui/material'
import { SavedAddress } from '../../../models/Address'
import { getAddressString } from '../../../helpers/checkout'
import { StarOutline } from '@mui/icons-material'

export interface SavedAddressListProps {
  addresses: SavedAddress[]
  selectedAddressId: string | null
  onSelectAddress: (address: SavedAddress) => void
}

export default function SavedAddressList({
  addresses,
  selectedAddressId,
  onSelectAddress,
}: SavedAddressListProps) {
  const handleSelect = (address: SavedAddress) => () => {
    onSelectAddress(address)
  }

  return (
    <List disablePadding>
      <Divider />
      {addresses.map((address, idx) => (
        <React.Fragment key={idx}>
          <ListItem
            key={address.id}
            disablePadding
            secondaryAction={
              address.isDefault ? (
                <Tooltip title="Default">
                  <StarOutline />
                </Tooltip>
              ) : undefined
            }
          >
            <ListItemButton onClick={handleSelect(address)} dense>
              <ListItemIcon>
                <Radio
                  checked={selectedAddressId === address.id}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': address.locationName }}
                />
              </ListItemIcon>
              <Stack direction={'column'}>
                <ListItemText
                  primary={address.locationName}
                  secondary={getAddressString(address)}
                />
                {selectedAddressId === address.id &&
                  (address.firstName || address.lastName || address.email) && (
                    <ListItemText
                      secondary={`${address.firstName} ${address.lastName}${
                        address.email ? ` (${address.email})` : ''
                      }`}
                    />
                  )}
              </Stack>
            </ListItemButton>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  )
}
