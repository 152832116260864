
import PropTypes from 'prop-types';
import '../src/assets/css/icons.min.css';
import React, { useEffect, Suspense, lazy, useState, useContext } from 'react';
import axios from 'axios';
import ScrollToTop from './helpers/scroll-top';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { multilanguage, loadLanguages } from 'redux-multilanguage';
import { connect, useSelector, shallowEqual } from 'react-redux';
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic';
import { AuthProvider } from './common/Auth';
import { AuthContext } from './common/Auth';
import AuthRegRoute from './common/AuthRegRoute';
import Authentication from './common/Authentication';
import AuthRoute from './common/AuthRoute';
import DynamicComponent from './DynamicComponent';
import DynamicProductComponent from './DynamicProductComponent';
import DynamicCategoryLayout from './DynamicCategoryLayout';
import DynamicBlogComponent from './DynamicBlogComponent';
import { ConfirmProvider } from 'material-ui-confirm';
import { SETTINGS, X_TENANT_ID,THEME_URL, STORE_SETTING, CUSTOM_CSS_URL } from './common/ActionUrl';
import TemplateWrapper from './TemplateWrapper';
import ChatSupport from "./components/chat/Chat";
import Loader from './common/Loader';
import DeactiveStore from './pages/other/deactive';
import { fetchStoreDetails } from './util/products/productsAPIUtil';
import Budget from './pages/other/Budget';
import { GoogleAnalytics } from "./components/headerScripts/GoogleAnalitics";
import EmptyComponent from "./EmptyComponent";
import LanguageCurrencyChanger from './components/header/sub-components/LanguageCurrencyChanger'
import GlobalLoader from './common/GlobalLoader';
import NewCheckout from './pages/Checkout/NewCheckout';
// product pages
const Product = lazy(() => import('./pages/shop-product/Product'));
const ProductTabLeft = lazy(() => import('./pages/shop-product/ProductTabLeft'));
const ProductTabRight = lazy(() => import('./pages/shop-product/ProductTabRight'));
const ProductSticky = lazy(() => import('./pages/shop-product/ProductSticky'));
const ProductSlider = lazy(() => import('./pages/shop-product/ProductSlider'));
const ProductFixedImage = lazy(() => import('./pages/shop-product/ProductFixedImage'));

// blog pages
const BlogStandard = lazy(() => import('./pages/blog/BlogStandard'));
const BlogNoSidebar = lazy(() => import('./pages/blog/BlogNoSidebar'));
const BlogRightSidebar = lazy(() => import('./pages/blog/BlogRightSidebar'));
const BlogDetailsStandard = lazy(() => import('./pages/blog/BlogDetailsStandard'));
const StaticPage = lazy(() => import('./pages/static-pages/StaticPage'));

// other pages
const About = lazy(() => import('./pages/other/About'));
const Contact = lazy(() => import('./pages/other/Contact'));
const BannerCreateEdit = lazy(() => import('./pages/banner/Banner'));
const BannersList = lazy(() => import('./pages/banner/BannersList'));
const MyAccount = lazy(() => import('./pages/other/MyAccount'));
const Login = lazy(() => import('./pages/other/Login'));
const ForgotPassword = lazy(() => import('./pages/other/ForgotPassword'));
const SetNewPassword = lazy(() => import('./pages/other/SetNewPassword'));
const Register = lazy(() => import('./pages/other/Register'));
const Cart = lazy(() => import('./pages/other/Cart'));
const Wishlist = lazy(() => import('./pages/other/Wishlist'));
const points = lazy(() => import('./pages/other/points'));
const Compare = lazy(() => import('./pages/other/Compare'));
const Checkout = lazy(() => import('./pages/other/Checkout'));
const NotFound = lazy(() => import('./pages/other/NotFound'));
const OrdersList = lazy(() => import('./pages/orders/OrdersList'));
const OrdersDetails = lazy(() => import('./pages/orders/OrdersDetails'));
const RewardProgram = lazy(() => import('./pages/rewardprogram/RewardProgram'));
const GiftCardBalance = lazy(() => import('./pages/giftCardBalance/GiftCardBalance'));
const VoucherBalance = lazy(() => import('./pages/voucherBalance/VoucherBalance'));
const App = (props) => {
  const { user } = useContext(AuthContext);
  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require('./translations/english.json'),
          fn: require('./translations/french.json'),
          de: require('./translations/germany.json'),
        },
      })
    );
  }, []);
  const [mainGeneralSetting, setMainGeneralSetting] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mainSetting, setMainSetting] = useState(null);
  const [RegisterLink, setRegisterLink] = useState('null');
  const [storeStatus, setStoreStatus] = useState('activate');
  const [IsStoreEnable, setIsStoreEnable] = useState('');
  const [isRedirect, setIsRedirect] = useState(false);

  const getThemeIdData = async () => {
    const requestOptions = {
      headers: { 'Content-Type': 'application/json', 'X-TENANT-ID': X_TENANT_ID }
    };
    try {
      const response = await axios.get(THEME_URL, requestOptions);
      if (response.data.error) {
        return {};
      } else {
        const loginData = response.data.find((i) => i.settingsName === "login")?.values[0];
        return loginData || {};
      }
    } catch (error) {
      console.log('Error:', error);
      return {};
    }
  }

	const getThemeId = () => {
		const requestOptions = {
			headers: { 'Content-Type': 'application/json', 'X-TENANT-ID': X_TENANT_ID }
		};
		axios.get(THEME_URL, requestOptions)
			.then(res => {

				if (res.data.error) {
				} else {
					if (res.data) {
						res.data.length > 0 &&
            	res.data.map((i) => {
								if (i.settingsName == "headerMenu") {
									let aboutUs = i?.values[0]?.menuItems.find((menu) => menu.staticName == "aboutUs")
						 			let anchorLink =(aboutUs?.anchorLink && aboutUs?.anchorLink!=='') ? aboutUs?.anchorLink.charAt(0)=="/"?aboutUs.anchorLink:`/${aboutUs.anchorLink}`:'/about'
                  let contactUs = i?.values[0]?.menuItems.find((menu) => menu.staticName == "contactUs")
						 			let contactAnchorLink =(contactUs?.anchorLink && contactUs?.anchorLink!=='') ? contactUs?.anchorLink.charAt(0)=="/"?contactUs.anchorLink:`/${contactUs.anchorLink}`:'/contact'
                  setMainSetting({
                    "anchorLink" : anchorLink,
                    "contactAnchorLink": contactAnchorLink
                  })
								}
                if(i.settingsName === "login")
                {
                  setRegisterLink(i?.values[0]?.isAllowSelfRegistration)
                }
                if (i.settingsName === "theme" && i.values[0].themeName === "dark") {
                  document.body.classList.add('dark-theme');
                }
							})
					}
				}
			})
			.catch(err => {
				console.log('err', err);
			});
    localStorage.setItem('googleTranslateLoaded', "0");
	}

  useEffect(() => {
    const getCustomCss = async () => {
      try {        
        const requestOptions = {
          headers: { 'Content-Type': 'application/json', 'X-TENANT-ID': X_TENANT_ID },
        };
        let response = await axios.get(CUSTOM_CSS_URL, requestOptions);
        let customCss = response.data;
          for (let file of customCss) {
            if (!file.enabled) continue;
            let link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = file.url;
            document.head.appendChild(link);
          }
      } catch (error) {
        console.error(error);
      }
    }
    getCustomCss();
  }, [])

  useEffect(() => {
    setLoading(true)
    const requestOptions = {
      headers: { 'Content-Type': 'application/json', 'X-TENANT-ID': X_TENANT_ID },
    };
    axios
      .get(SETTINGS, requestOptions)
      .then(async (res) => {
        if (res.status === 200 && res.data.length > 0) {
         let loginSettingData = await getThemeIdData()
          const generalSettings = res.data.find((i) => i.page === 'General')?.values || {};

          const updatedState = {};
          if (generalSettings.isLandingPageEnable !== undefined) {
            updatedState.enableLandingPage = !!generalSettings.isLandingPageEnable;
          }
          if (generalSettings.landingPageRedirect) {
            updatedState.landingPage = generalSettings.landingPageRedirect;
          }
          updatedState.isPublic = loginSettingData?.isPublic !== undefined ? loginSettingData?.isPublic : generalSettings?.isPublic ?? true;
          document.documentElement.style.setProperty('--font-family', generalSettings?.defaultFont || '"Poppins", sans-serif');
          document.documentElement.style.setProperty('--themedefaultColor', generalSettings?.themedefaultColor || '#FF7F00');
          document.documentElement.style.setProperty('--themedefaulthoverColor', generalSettings?.themedefaulthoverColor || '#000');
          document.documentElement.style.setProperty('--themedefaulttextColor', generalSettings?.themedefaulttextColor || '#fff');
          document.documentElement.style.setProperty('--product-img-bg', generalSettings?.DefaultProductBackgroundColor || '');
          setMainGeneralSetting(updatedState)
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log('err', err);
      });
      getThemeId()
      getStoreStatus()
      window.addEventListener('beforeunload', () => {
        localStorage.removeItem('redux_localstorage_simple');
      });
  }, []);

  const getStoreStatus = () => {
    const requestOptions = {
      headers: { 'Content-Type': 'application/json', 'X-TENANT-ID': X_TENANT_ID },
    };
    axios
      .get(STORE_SETTING, requestOptions)
      .then((res) => {
        if (res && res?.data) {
          setStoreStatus(res?.data[0]?.status)
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.log('err', err);
      });
  }

  useEffect(() => {
    if(IsStoreEnable === "") {
      console.log("3")
      fetchStoreDetails().then(res => {
          if (res.data) {
            let storeData = res.data
            if (storeData?.enabled === true || storeData?.enabled === false) {
              setIsStoreEnable(storeData.enabled)
            }
          }
      })
    }
  }, [IsStoreEnable])

  useEffect(() => {
    if (!!mainGeneralSetting) {
      console.log("4")
      setIsRedirect(!!mainGeneralSetting?.enableLandingPage && (!!user || !!mainGeneralSetting?.isPublic));
    }
  }, [user, mainGeneralSetting]);

  return (
    <AuthProvider>
      <ConfirmProvider>
        <ToastProvider placement="top-right">
          <BreadcrumbsProvider>
            <Router>
              <ScrollToTop>
                <GlobalLoader>
                  <Suspense
                    fallback={
                      <div className="flone-preloader-wrapper">
                        <div className="flone-preloader">
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    }
                  >
                    {loading && <div className='fixedLoader'><Loader /></div>}
                    {
                      storeStatus === "deactivate" || IsStoreEnable === false ?
                      <Switch>
                        <Route exact path={process.env.PUBLIC_URL + '/'} component={DeactiveStore} />
                        <Redirect to="/" />
                      </Switch>
                      :

                    
                    <Switch>
                      <AuthRoute exact path={process.env.PUBLIC_URL + '/login'} component={Login} />
                      {(RegisterLink === true || RegisterLink === false) &&
                      <AuthRegRoute exact path={process.env.PUBLIC_URL + '/register'} component={Register} RegisterLink={RegisterLink}/>
                      }
                      <AuthRoute exact path={process.env.PUBLIC_URL + '/forgotpassword'} component={ForgotPassword} />
                      <AuthRoute exact path={process.env.PUBLIC_URL + '/setNewPassword/:id'} component={SetNewPassword} />
                      <Route exact path={process.env.PUBLIC_URL + '/notFound'} component={NotFound} />


                      <Authentication>
                        <LanguageCurrencyChanger/>
                        <Switch>
                        <Route
                          exact
                          path="/"
                          render={() => {
                              return (
                                isRedirect ?
                                <Redirect exact from="/" to={window.location.replace(mainGeneralSetting?.landingPage)} /> :

                                <Route exact path="/" component={DynamicComponent} />
                              )
                          }}
                        />

                          {/* Shop product pages */}
                          <TemplateWrapper>
                            <Route exact path={process.env.PUBLIC_URL + '/cb'}  component={EmptyComponent} />
                            <Route exact path={process.env.PUBLIC_URL + '/activate'}  component={EmptyComponent} />
                            <Route exact path={process.env.PUBLIC_URL + '/products'} component={DynamicCategoryLayout} />
                            <Route
                              exact
                              path={process.env.PUBLIC_URL + '/products/:category'}
                              component={DynamicCategoryLayout}
                            />
                            <Route
                              exact
                              path={process.env.PUBLIC_URL + '/products/:category/:childCategory'}
                              component={DynamicCategoryLayout}
                            />
                            <Route
                              exact
                              path={process.env.PUBLIC_URL + '/products/:category/:childCategory/:subChildCategory'}
                              component={DynamicCategoryLayout}
                            />
                            <Route
                              exact
                              path={process.env.PUBLIC_URL + '/products/:category/:childCategory/:subChildCategory/:subChildCategoryLevel4'}
                              component={DynamicCategoryLayout}
                            />
                            <Route
                              exact
                              path={process.env.PUBLIC_URL + '/products/:category/:childCategory/:subChildCategory/:subChildCategoryLevel4/:subChildCategoryLevel5'}
                              component={DynamicCategoryLayout}
                            />
                            <Route
                              exact
                              path={process.env.PUBLIC_URL + '/products/:category/:childCategory/:subChildCategory/:subChildCategoryLevel4/:subChildCategoryLevel5/:subChildCategoryLevel6'}
                              component={DynamicCategoryLayout}
                            />
                            <Route
                              exact
                              path={process.env.PUBLIC_URL + '/products/:category/:childCategory/:subChildCategory/:subChildCategoryLevel4/:subChildCategoryLevel5/:subChildCategoryLevel6/:subChildCategoryLevel7'}
                              component={DynamicCategoryLayout}
                            />
                            <Route
                              exact
                              path={process.env.PUBLIC_URL + '/product/:id'}
                              component={DynamicProductComponent}
                            />
                            <Route
                              exact
                              path={process.env.PUBLIC_URL + '/product-tab-left/:id'}
                              component={ProductTabLeft}
                            />
                            <Route
                              exact
                              path={process.env.PUBLIC_URL + '/product-tab-right/:id'}
                              component={ProductTabRight}
                            />
                            <Route
                              exact
                              path={process.env.PUBLIC_URL + '/product-sticky/:id'}
                              component={ProductSticky}
                            />
                            <Route
                              exact
                              path={process.env.PUBLIC_URL + '/product-slider/:id'}
                              component={ProductSlider}
                            />
                            <Route
                              exact
                              path={process.env.PUBLIC_URL + '/product-fixed-image/:id'}
                              component={ProductFixedImage}
                            />

                            {/* Blog pages */}
                            <Route exact path={process.env.PUBLIC_URL + '/blogs'} component={DynamicBlogComponent} />
                            <Route exact path={process.env.PUBLIC_URL + '/blog-standard'} component={BlogStandard} />
                            <Route exact path={process.env.PUBLIC_URL + '/blog-no-sidebar'} component={BlogNoSidebar} />
                            <Route
                              exact
                              path={process.env.PUBLIC_URL + '/blog-right-sidebar'}
                              component={BlogRightSidebar}
                            />
                            <Route exact path={process.env.PUBLIC_URL + '/blogs/:url'} component={BlogDetailsStandard} />
                            <Route exact path={process.env.PUBLIC_URL + '/page/:pageName'} component={StaticPage} />

                            {/* Other pages */}
                            <Route exact path={process.env.PUBLIC_URL + `${mainSetting?.aboutLink ? mainSetting?.aboutLink : `/about`}`} component={About} />
                            <Route exact path={process.env.PUBLIC_URL + `${mainSetting?.contactLink ? mainSetting?.contactLink : `/contact`}`} component={Contact} />
                            {/* Banner add/edit pages */}
                            <Route exact path={process.env.PUBLIC_URL + '/banner'} component={BannersList} />
                            <Route
                              exact
                              path={[process.env.PUBLIC_URL + '/banner/create', '/banner/edit/:id']}
                              component={BannerCreateEdit}
                            />
                            <Route exact path={process.env.PUBLIC_URL + '/my-account'} component={MyAccount} />
                            <Route exact path={process.env.PUBLIC_URL + '/cart'} component={Cart} />
                            <Route exact path={process.env.PUBLIC_URL + '/orders'} component={OrdersList} />
                            <Route exact path={process.env.PUBLIC_URL + '/orders/:id'} component={OrdersDetails} />
                            <Route exact path={process.env.PUBLIC_URL + '/wishlist'} component={Wishlist} />
                            <Route exact path={process.env.PUBLIC_URL + '/wallet'} component={points} />
                            <Route exact path={process.env.PUBLIC_URL + '/budget'} component={Budget} />
                            <Route exact path={process.env.PUBLIC_URL + '/compare'} component={Compare} />
                            <Route exact path={process.env.PUBLIC_URL + '/checkout'} component={Checkout} />
                            <Route exact path={process.env.PUBLIC_URL + '/newcheckout'} component={NewCheckout} />
                            <Route exact path={process.env.PUBLIC_URL + '/rewardprogram'} component={RewardProgram} />
                            <Route exact path={process.env.PUBLIC_URL + '/giftcardbalance'} component={GiftCardBalance} />
                            <Route exact path={process.env.PUBLIC_URL + '/voucherbalance'} component={VoucherBalance} />
                          </TemplateWrapper>
                        </Switch>
                        <ChatSupport/>
                        <GoogleAnalytics/>
                      </Authentication>
                    </Switch>
                    }
                  </Suspense>
                </GlobalLoader>
              </ScrollToTop>
            </Router>
          </BreadcrumbsProvider>
        </ToastProvider>
      </ConfirmProvider>
    </AuthProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

export default React.memo(connect()(multilanguage(App)));
