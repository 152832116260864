import React from "react";
import { Description, Receipt } from "@mui/icons-material";
import DirectBillForm from "../pages/Checkout/payment/POForm";

export enum PaymentMethodType {
  DIRECT_BILL = 'directBill',
  PURCHASE_ORDER = 'purchaseOrder',
  // CREDIT_CARD = 'creditCard',
  // GIFT_CARD = 'giftCard',
  // POINTS = 'points',
  // VOUCHER = 'voucher',
  // BUDGET = 'budget',
}

export const PAYMENT_NAMES = {
  [PaymentMethodType.DIRECT_BILL]: 'Direct Bill',
  [PaymentMethodType.PURCHASE_ORDER]: 'Purchase Order',
}

export const PAYMENT_ICONS: Record<PaymentMethodType, React.ReactNode> = {
  [PaymentMethodType.DIRECT_BILL]: <Description />,
  [PaymentMethodType.PURCHASE_ORDER]: <Receipt />,
}