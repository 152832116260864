import React from 'react'
import { OrderPayment } from '../../../models/Payment'
import { Button, Card, Stack, Typography } from '@mui/material'
import {
  PAYMENT_ICONS,
  PAYMENT_NAMES,
  PaymentMethodType,
} from '../../../models/PaymentMethodType'
import { useAppSelector } from '../../../redux/hooks'
import { selectCurrency } from '../../../redux/selectors/checkoutSelectors'
import PassiveRow from '../PassiveRow'
import { getAddressString } from '../../../helpers/checkout'

interface PaymentSummaryCardProps {
  payment: OrderPayment
  onEdit?: () => void
}

export default function PaymentSummaryCard({
  payment,
  onEdit,
}: PaymentSummaryCardProps) {
  const currency = useAppSelector(selectCurrency)

  return (
    <Card>
      <Stack direction={'column'} margin={2} spacing={2}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            {PAYMENT_ICONS[payment.methodType]}
            <Typography variant="body1">
              {PAYMENT_NAMES[payment.methodType]}
            </Typography>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <Typography variant="body1">
              {currency.currencySymbol}
              {payment.amount.toFixed(2)}
            </Typography>
            {onEdit && (
              <Button variant={'outlined'} onClick={onEdit}>
                Edit
              </Button>
            )}
          </Stack>
        </Stack>
        <PassiveRow
          field={'Contact'}
          values={[
            `${payment.contact.firstName} ${payment.contact.lastName}`,
            payment.contact.email,
            payment.contact.phone,
          ]}
        />
        <PassiveRow
          field={'Billing Address'}
          values={[payment.address.locationName, getAddressString(payment.address)]}
        />
        {payment.methodType === PaymentMethodType.PURCHASE_ORDER && (
          <PassiveRow field={'PO Number'} value={payment.poNumber} />
        )}
      </Stack>
    </Card>
  )
}
