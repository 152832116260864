import React, { Fragment, useState, useEffect, useRef } from 'react'
import ColorPicker from 'material-ui-color-picker'
import SecondaryTextEditor from '../../quill/SecondaryTextEditor'
import TextEditor from '../../quill/TextEditor'
import Loader from '../../common/Loader'
import axios from 'axios'
import { THEME_URL, X_TENANT_ID, USERGROUPS } from '../../common/ActionUrl'
import {
    BANNER_ITEMS,
    ALIGN,
    DIRECTION,
    ANIMATION_TIME,
    BANNER_TEXT_ALIGN,
    FONT_FAMILY,
    BANNER_IMAGE_HEIGHT,
    BANNER_COVER_AREA,
} from '../../constant/bannerItems'
import { useToasts } from 'react-toast-notifications'
import PrimaryTextEditor from '../../quill/PrimaryTextEditor'
import { makeStyles, Checkbox, FormControl, FormControlLabel, Select, MenuItem } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import JoditEditor from "jodit-react";
import TextEditorConfig from "../../helpers/TextEditor";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 600,
        backgroundColor: theme.palette.background.paper,
    },
    createUser: {
        width: '100%',
        maxWidth: 800,
    },
    textField: {
        width: '40ch',
    },
}))

const BannerForm = ({
    resetColor,
    isLoading,
    handleCheckboxInputChange,
    handleClose,
    inputs,
    handleInputChange,
    handleColorPicker,
    handleSubmit,
    errors,
    bannerId,
    fromEditAction,
    fromAction

}) => {
    const { addToast } = useToasts()
    //Its temp variable for update forcefully state
    const [temp, setTemp] = useState('')
    const [showText, setShowText] = useState(false)
    const [showDate, setShowDate] = useState(false)
    const [showPrice, setShowPrice] = useState(false)
    const [showInnerImg, setshowInnerImg] = useState(false)
    const [showVideo, setShowVideo] = useState(false)
    const [showShapeText, setShowShapeText] = useState(false)
    const [showUploadedImg, setShowUploadedImg] = useState('')
    const [showUploadedInnerImg, setShowUploadedInnerImg] = useState('')
    const [selectedTheme, setSelectedTheme] = useState('')
    const [imageSize, setImageSize] = useState('')
    const [innerImageSize, setInnerImageSize] = useState('')
    const [themeData, setThemeData] = useState('')
    const classes = useStyles()
    const editor = useRef(null)
    const [userGroups, setUserGroups] = useState([]);

    const onSubmit = () => {
        if (!showDate) {
            delete inputs.deadLineDate
        }
        handleSubmit()
    }

    const getTheme = (c) => {
        const requestOptions = {
            headers: { 'Content-Type': 'application/json', 'X-TENANT-ID': X_TENANT_ID },
        }

        axios
            .get(THEME_URL, requestOptions)
            .then((res) => {
                if (res.data.error) {
                    addToast(res.data.message, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else {
                    if (res.data) {
                        setThemeData(res.data)
                    }
                }
            })
            .catch((err) => {
                console.log('err', err)
            })
    }

    useEffect(() => {
        if (Object.keys(themeData).length > 0) {
            Object.keys(themeData).map((data, index) => {
                if (themeData[data].settingsName === 'theme') {
                    setSelectedTheme(themeData[data].values[0].themeName)
                }
            })
        }
    }, [themeData])

    const getUserGroups = () => {

        const requestOptions = {
            headers: { 'Content-Type': 'application/json', 'X-TENANT-ID': X_TENANT_ID }
        };

        axios.get(USERGROUPS, requestOptions)
            .then(res => {
                console.log('response', res)
                if (res.data.error) {
                    addToast(res.data.message, {
                        appearance: "error",
                        autoDismiss: true
                    });
                } else {
                    if (res.data) {
                        setUserGroups(res.data)
                    }
                }
            })
            .catch(err => {
                console.log('err', err);
            });
    }

    useEffect(() => {
        getTheme()
        getUserGroups()
    }, [])

    useEffect(() => {
        if ((selectedTheme === 'beach' && !fromEditAction) || fromEditAction === 'shapeTitle') {
            setShowShapeText(true)
        }
        if ((selectedTheme === 'beach' && !fromEditAction) || fromEditAction === 'text') {
            setShowText(true)
        }

        if ((selectedTheme === 'spring' && !fromEditAction) || fromEditAction === 'bgImg') {
            setShowVideo(true)
        }
        if (
            ((selectedTheme === 'fall' ||
                selectedTheme === 'winter' ||
                selectedTheme === 'fancy' ||
                selectedTheme === 'product-slider' ||
                selectedTheme === 'pictures-highlighted' ||
                selectedTheme === 'dark') &&
                !fromEditAction) ||
            fromEditAction === 'text'
        ) {
            setShowText(true)
        }
        if (
            ((selectedTheme === 'special-events' || selectedTheme === 'spring') && !fromEditAction) ||
            fromEditAction === 'innerImg'
        ) {
            setshowInnerImg(true)
        }
        if ((selectedTheme === 'deadline' && !fromEditAction) || fromEditAction === 'deadLineDate') {
            setShowDate(true)
        }
        if ((selectedTheme === 'dual-pictures' && !fromEditAction) || fromEditAction === 'price') {
            setShowPrice(true)
        }

        BANNER_ITEMS && selectedTheme &&
            BANNER_ITEMS.length &&
            Object.keys(BANNER_ITEMS).map((data, index) => {

                if (selectedTheme && BANNER_ITEMS[data].imageSize && selectedTheme === BANNER_ITEMS[data].value) {
                    setImageSize(BANNER_ITEMS[data].imageSize)
                }
                if (BANNER_ITEMS[data].innerImageSize && selectedTheme === BANNER_ITEMS[data].value) {
                    setInnerImageSize(BANNER_ITEMS[data].innerImageSize)
                }
            })
    }, [selectedTheme])

    useEffect(() => {
        if (inputs.image1 && inputs.image1.name) {
            setShowUploadedImg(URL.createObjectURL(inputs.image1))
        }
        if (inputs.popupImg && inputs.popupImg.name) {
            setShowUploadedInnerImg(URL.createObjectURL(inputs.popupImg))
        }
    }, [inputs])

    return (
        <Fragment>
            <div className="banner-form">
                <form className="banner-form-style only-banner">
                    {isLoading && <Loader />}
                    {(fromEditAction === 'bgColor' || fromAction === 'FromList' || fromAction === 'FromHome') && (
                        <div className="row mt-4">
                            <div className="col-lg-6 mb-4">
                                <label>Background Color</label>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <ColorPicker
                                            name="bgColor"
                                            defaultValue="select color"
                                            value={inputs.bgColor}
                                            onChange={(color) => {
                                                handleColorPicker('bgColor', color)
                                            }}
                                        />
                                    </div>
                                    <div className="col-lg-6 d-flex align-items-center">


                                        <p
                                            className="m-0">{inputs.bgColor != 'undefined' && inputs.bgColor}

                                        </p>
                                        {inputs.bgColor != 'undefined' &&
                                            inputs.bgColor != undefined &&
                                            inputs.bgColor != '' && (
                                                <DeleteIcon
                                                    className="text-error ml-4"
                                                    title={'Reset Background color'}
                                                    onClick={() => {
                                                        resetColor('', 'bgColor')
                                                    }}
                                                    aria-label=""
                                                />
                                            )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-4 ">
                                <label>Banner Text Align</label> &nbsp;&nbsp;
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        value={inputs.textAlign}
                                        onChange={handleInputChange}
                                        name="textAlign"
                                    >
                                        {BANNER_TEXT_ALIGN &&
                                            BANNER_TEXT_ALIGN.length &&
                                            Object.keys(BANNER_TEXT_ALIGN).map((data, index) => {
                                                return (
                                                    <MenuItem value={BANNER_TEXT_ALIGN[data].value}>
                                                        {' '}
                                                        {BANNER_TEXT_ALIGN[data].label}
                                                    </MenuItem>
                                                )
                                            })}
                                        {!BANNER_TEXT_ALIGN.length && <MenuItem value=" "> No Data</MenuItem>}
                                    </Select>
                                </FormControl>
                            </div>
                            
                            <div className="col-lg-12 mb-4">
                                <label>Banner User Group</label> &nbsp;&nbsp;
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        value={inputs.bannerUserGroup}
                                        onChange={handleInputChange}
                                        name="bannerUserGroup"
                                    >
                                        {userGroups &&
                                            userGroups.map((data, index) => {
                                                return (
                                                    <MenuItem value={data?._id}>
                                                        {data?.groupName}
                                                    </MenuItem>
                                                );
                                            })}
                                        {!userGroups.length && (
                                            <MenuItem value=" "> No Data</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    )}

                    {(fromEditAction === 'primaryText' || fromAction === 'FromList' || fromAction === 'FromHome') && (
                        <>
                            <div className="row">
                                <div className="col-lg-12">
                                    <label>Primary text*</label>
                                    {/*<PrimaryTextEditor
                                        placeholder="Start writing your primary text"
                                        onBodyChange={(bodyOfEditor) => {
                                            inputs.primaryText = bodyOfEditor
                                            errors.primaryText = ''
                                            setTemp('0')
                                        }}
                                        value={inputs.primaryText}
                                    />*/}

                                    <JoditEditor
                                        ref={editor}
                                        value={inputs.primaryText}
                                        config={TextEditorConfig}
                                        tabIndex={1}
                                        onChange={(bodyOfEditor) => {
                                            inputs.primaryText = bodyOfEditor
                                            setTemp('0');
                                        }}
                                    />

                                    {errors.primaryText && <p className="error">{errors.primaryText}</p>}
                                    <br />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 ">
                                    <label>Margin Top in PX</label>
                                    <input
                                        name="primaryTextMT"
                                        placeholder="Margin Top in PX"
                                        className="input-border material-like-input"
                                        onChange={handleInputChange}
                                        value={inputs.primaryTextMT}
                                        type="number"
                                        min="0"
                                        step="any"
                                    />
                                </div>
                                <div className="col-lg-6 ">
                                    <label>Margin Bottom in PX</label>
                                    <input
                                        name="primaryTextMB"
                                        placeholder="Margin Bottom in PX"
                                        onChange={handleInputChange}
                                        value={inputs.primaryTextMB}
                                        type="number"
                                        className="input-border material-like-input"
                                        min="0"
                                        step="any"
                                    />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-6 ">
                                    <label>Animation Delay Time</label>
                                    <br />
                                    <FormControl variant="outlined" className="w-100">
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            value={inputs.primaryTextAnimationDelay}
                                            onChange={handleInputChange}
                                            name="primaryTextAnimationDelay"
                                        >
                                            {ANIMATION_TIME &&
                                                ANIMATION_TIME.length &&
                                                Object.keys(ANIMATION_TIME).map((data, index) => {
                                                    return (
                                                        <MenuItem value={ANIMATION_TIME[data].value}>
                                                            {' '}
                                                            {ANIMATION_TIME[data].label}
                                                        </MenuItem>
                                                    )
                                                })}
                                            {!ANIMATION_TIME.length && <MenuItem value=" "> No Data</MenuItem>}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-lg-6 ">
                                    <label>Animation Direction</label> <br></br>
                                    <FormControl variant="outlined" className="w-100">
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            value={inputs.primaryTextAnimationName}
                                            onChange={handleInputChange}
                                            name="primaryTextAnimationName"
                                        >
                                            {DIRECTION &&
                                                DIRECTION.length &&
                                                Object.keys(DIRECTION).map((data, index) => {
                                                    return (
                                                        <MenuItem value={DIRECTION[data].value}>
                                                            {' '}
                                                            {DIRECTION[data].label}
                                                        </MenuItem>
                                                    )
                                                })}
                                            {!DIRECTION.length && <MenuItem value=" "> No Data</MenuItem>}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </>
                    )}

                    {(fromEditAction === 'secondaryText' || fromAction === 'FromList' || fromAction === 'FromHome') && (
                        <>
                            <div className="row mt-15 mb-15">
                                <div className="col-lg-12 mt-6">
                                    <label>Secondary text</label>

                                    <JoditEditor
                                        ref={editor}
                                        value={inputs.secondaryText}
                                        config={TextEditorConfig}
                                        tabIndex={1}
                                        onChange={(bodyOfEditor) => {
                                            inputs.secondaryText = bodyOfEditor
                                            setTemp('0');
                                        }}
                                    />
                                    {errors.secondaryText && <p className="error">{errors.secondaryText}</p>}
                                </div>
                            </div>
                            <div className="row mt-6">
                                <div className="col-lg-6 ">
                                    <label>Margin Top in PX</label>
                                    <input
                                        name="secondaryTextMT"
                                        placeholder="Margin Top in PX"
                                        onChange={handleInputChange}
                                        value={inputs.secondaryTextMT}
                                        type="number"
                                        className="input-border material-like-input"
                                        min="0"
                                        step="any"
                                    />
                                </div>
                                <div className="col-lg-6 ">
                                    <label>Margin Bottom in PX</label>
                                    <input
                                        name="secondaryTextMB"
                                        placeholder="Margin Bottom in PX"
                                        onChange={handleInputChange}
                                        value={inputs.secondaryTextMB}
                                        type="number"
                                        className="input-border material-like-input"
                                        min="0"
                                        step="any"
                                    />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-6 ">
                                    <label>Animation Delay Time</label>
                                    <br />
                                    <FormControl variant="outlined" className="w-100">
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            value={inputs.secondaryTextAnimationDelay}
                                            onChange={handleInputChange}
                                            name="secondaryTextAnimationDelay"
                                        >
                                            {ANIMATION_TIME &&
                                                ANIMATION_TIME.length &&
                                                Object.keys(ANIMATION_TIME).map((data, index) => {
                                                    return (
                                                        <MenuItem value={ANIMATION_TIME[data].value}>
                                                            {' '}
                                                            {ANIMATION_TIME[data].label}
                                                        </MenuItem>
                                                    )
                                                })}
                                            {!ANIMATION_TIME.length && <MenuItem value=" "> No Data</MenuItem>}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-lg-6 ">
                                    <label>Animation Direction</label> <br></br>
                                    <FormControl variant="outlined" className="w-100">
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            value={inputs.secondaryTextAnimationName}
                                            onChange={handleInputChange}
                                            name="secondaryTextAnimationName"
                                        >
                                            {DIRECTION &&
                                                DIRECTION.length &&
                                                Object.keys(DIRECTION).map((data, index) => {
                                                    return (
                                                        <MenuItem value={DIRECTION[data].value}>
                                                            {' '}
                                                            {DIRECTION[data].label}
                                                        </MenuItem>
                                                    )
                                                })}
                                            {!DIRECTION.length && <MenuItem value=" "> No Data</MenuItem>}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </>
                    )}

                    {showText && (
                        <>
                            <div className="row mt-15 mb-15">
                                <div className="col-lg-12 mt-6">
                                    <label>Text</label>
                                    <JoditEditor
                                        ref={editor}
                                        value={inputs.text}
                                        config={TextEditorConfig}
                                        tabIndex={1}
                                        onChange={(bodyOfEditor) => {
                                            inputs.text = bodyOfEditor
                                            setTemp('0');
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-6">
                                    <label>Text Margin Top in PX</label>
                                    <input
                                        name="textMT"
                                        placeholder="Margin Top in PX"
                                        onChange={handleInputChange}
                                        value={inputs.textMT}
                                        type="number"
                                        className="input-border material-like-input"
                                        min="0"
                                        step="any"
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <label>Text Margin Bottom in PX</label>
                                    <input
                                        name="textMB"
                                        placeholder="Margin Bottom in PX"
                                        onChange={handleInputChange}
                                        value={inputs.textMB}
                                        type="number"
                                        className="input-border material-like-input"
                                        min="0"
                                        step="any"
                                    />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-6">
                                    <label>Text Animation Delay Time</label>
                                    <br />
                                    <FormControl variant="outlined" className="w-100">
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            value={inputs.textAnimationDelay}
                                            onChange={handleInputChange}
                                            name="textAnimationDelay"
                                        >
                                            {ANIMATION_TIME &&
                                                ANIMATION_TIME.length &&
                                                Object.keys(ANIMATION_TIME).map((data, index) => {
                                                    return (
                                                        <MenuItem value={ANIMATION_TIME[data].value}>
                                                            {' '}
                                                            {ANIMATION_TIME[data].label}
                                                        </MenuItem>
                                                    )
                                                })}
                                            {!ANIMATION_TIME.length && <MenuItem value=" "> No Data</MenuItem>}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-lg-6">
                                    <label>Text Animation Direction</label> <br></br>
                                    <FormControl variant="outlined" className="w-100">
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            value={inputs.textAnimationName}
                                            onChange={handleInputChange}
                                            name="textAnimationName"
                                        >
                                            {DIRECTION &&
                                                DIRECTION.length &&
                                                Object.keys(DIRECTION).map((data, index) => {
                                                    return (
                                                        <MenuItem value={DIRECTION[data].value}>
                                                            {' '}
                                                            {DIRECTION[data].label}
                                                        </MenuItem>
                                                    )
                                                })}
                                            {!DIRECTION.length && <MenuItem value=" "> No Data</MenuItem>}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="row mt-4 mb-4">
                        {showShapeText && (
                            <div className="col-lg-12 mt-4">
                                <label>Shape text</label>
                                <input
                                    name="shapeText"
                                    placeholder="Shape Text*"
                                    className="material-like-input"
                                    onChange={handleInputChange}
                                    value={inputs.shapeText}
                                    type="text"
                                />
                            </div>
                        )}

                        {(fromEditAction === 'buttonDesign' ||
                            fromAction === 'FromList' ||
                            fromAction === 'FromHome') && (
                                <>
                                    <div className="col-lg-12">
                                        <label>Button text</label>
                                        <input
                                            name="buttonText"
                                            placeholder="Button Text*"
                                            className="input-border material-like-input"
                                            onChange={handleInputChange}
                                            value={inputs.buttonText}
                                            type="text"
                                        />
                                        {errors.buttonText && <p className="error">{errors.buttonText}</p>}
                                    </div>
                                    <div className="col-lg-12 mt-4">
                                        <label>Button Url</label>
                                        <input
                                            name="buttonUrl"
                                            placeholder="Button Url"
                                            className="input-border material-like-input"
                                            onChange={handleInputChange}
                                            value={inputs.buttonUrl}
                                            type="text"
                                        />
                                        {errors.buttonUrl && <p className="error">{errors.buttonUrl}</p>}
                                    </div>
                                    <div className="col-lg-6 mt-4">
                                        <label>Animation Delay Time</label>
                                        <br />
                                        <FormControl variant="outlined" className="w-100">
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                value={inputs.btnAnimationDelay}
                                                onChange={handleInputChange}
                                                name="btnAnimationDelay"
                                            >
                                                {ANIMATION_TIME &&
                                                    ANIMATION_TIME.length &&
                                                    Object.keys(ANIMATION_TIME).map((data, index) => {
                                                        return (
                                                            <MenuItem value={ANIMATION_TIME[data].value}>
                                                                {' '}
                                                                {ANIMATION_TIME[data].label}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                {!ANIMATION_TIME.length && <MenuItem value=" "> No Data</MenuItem>}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-lg-6 mt-4">
                                        <label>Animation Direction</label> <br></br>
                                        <FormControl variant="outlined" className="w-100">
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                value={inputs.btnAnimationName}
                                                onChange={handleInputChange}
                                                name="btnAnimationName"
                                            >
                                                {DIRECTION &&
                                                    DIRECTION.length &&
                                                    Object.keys(DIRECTION).map((data, index) => {
                                                        return (
                                                            <MenuItem value={DIRECTION[data].value}>
                                                                {' '}
                                                                {DIRECTION[data].label}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                {!DIRECTION.length && <MenuItem value=" "> No Data</MenuItem>}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-lg-6 mt-4">
                                        <label>Button Background Color</label>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <ColorPicker
                                                    name="btncolor"
                                                    defaultValue="#000"
                                                    value={inputs.btncolor}
                                                    onChange={(color) => {
                                                        handleColorPicker('btncolor', color)
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-6 d-flex align-items-center">
                                                <p className="m-0">{inputs.btncolor != 'undefined' && inputs.btncolor}</p>
                                                {inputs.btncolor != 'undefined' &&
                                                    inputs.btncolor != undefined &&
                                                    inputs.btncolor != '' && (
                                                        <DeleteIcon
                                                            className="text-error  ml-4"
                                                            title={'Reset Button color'}
                                                            onClick={() => {
                                                                resetColor('', 'btncolor')
                                                            }}
                                                            aria-label=""
                                                        />
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-4">
                                        <label>Button Hover Color</label>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <ColorPicker
                                                    name="buttonHoverColor"
                                                    defaultValue="#000"
                                                    value={inputs.buttonHoverColor}
                                                    onChange={(color) => {
                                                        handleColorPicker('buttonHoverColor', color)
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-6 d-flex align-items-center">
                                                <p className="m-0">
                                                    {inputs.buttonHoverColor != 'undefined' && inputs.buttonHoverColor}
                                                </p>
                                                {inputs.buttonHoverColor != 'undefined' &&
                                                    inputs.buttonHoverColor != undefined &&
                                                    inputs.buttonHoverColor != '' && (
                                                        <DeleteIcon
                                                            className="text-error ml-4"
                                                            title={'Reset Button Hover color'}
                                                            onClick={() => {
                                                                resetColor('', 'buttonHoverColor')
                                                            }}
                                                            aria-label=""
                                                        />
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-4">
                                        <label>Button Text Color</label>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <ColorPicker
                                                    name="buttonTextColor"
                                                    defaultValue="#000"
                                                    value={inputs.buttonTextColor}
                                                    onChange={(color) => {
                                                        handleColorPicker('buttonTextColor', color)
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-6 d-flex align-items-center">
                                                <p className="m-0">
                                                    {inputs.buttonTextColor != 'undefined' && inputs.buttonTextColor}
                                                </p>
                                                {inputs.buttonTextColor != 'undefined' &&
                                                    inputs.buttonTextColor != undefined &&
                                                    inputs.buttonTextColor != '' && (
                                                        <DeleteIcon
                                                            className="text-error ml-4"
                                                            title={'Reset Button Text color'}
                                                            onClick={() => {
                                                                resetColor('', 'buttonTextColor')
                                                            }}
                                                            aria-label=""
                                                        />
                                                    )}
                                            </div>
                                        </div>
                                    </div>

                                <div className="col-lg-6 mt-4">
                                    <label>Button Alignment</label>
                                    <br />
                                    <FormControl variant="outlined" className="w-100">
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            value={inputs.textAlign}
                                            onChange={handleInputChange}
                                            name="textAlign"
                                        >
                                            {BANNER_TEXT_ALIGN &&
                                                BANNER_TEXT_ALIGN.length &&
                                                Object.keys(BANNER_TEXT_ALIGN).map((data, index) => {
                                                    return (
                                                        <MenuItem value={BANNER_TEXT_ALIGN[data].value}>
                                                            {' '}
                                                            {BANNER_TEXT_ALIGN[data].label}
                                                        </MenuItem>
                                                    )
                                                })}
                                            {!BANNER_TEXT_ALIGN.length && <MenuItem value=" "> No Data</MenuItem>}
                                        </Select>
                                    </FormControl>
                                </div>

                                    <div className="col-lg-6 mt-4">
                                        <label>Show Button</label>

                                        <FormControlLabel
                                            className="w-75"
                                            control={
                                                <Checkbox
                                                    checked={inputs.showButton}
                                                    onChange={handleCheckboxInputChange}
                                                    name="showButton"
                                                    className="ml-2 mt-1"
                                                />
                                            }
                                        />
                                    </div>
                                </>
                            )}
                        {(showDate || fromEditAction === 'deadLineDate') && (
                            <>
                                <div className="col-sm-4 col-lg-4 mt-10">
                                    <label>Dead Line Date</label>
                                    <input
                                        name="deadLineDate"
                                        placeholder="Deadline Date"
                                        onChange={handleInputChange}
                                        value={inputs.deadLineDate}
                                        type="date"
                                    />
                                    {errors.deadLineDate && <p className="error">{errors.deadLineDate}</p>}
                                </div>
                                <div className="col-lg-6 mb-10">
                                    <label>Time Color</label>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <ColorPicker
                                                name="timeColor"
                                                defaultValue="select color"
                                                value={inputs.timeColor}
                                                onChange={(color) => {
                                                    handleColorPicker('timeColor', color)
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-2">
                                            <p>{inputs.timeColor != 'undefined' && inputs.timeColor}</p>

                                            <DeleteIcon
                                                className="text-error"
                                                title={'Reset color'}
                                                onClick={() => {
                                                    resetColor('', 'timeColor')
                                                }}
                                                aria-label=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-10">
                                    <label>Time Font Size</label>
                                    <br />
                                    <input
                                        name="timeFontSize"
                                        placeholder="Time Font Size"
                                        onChange={handleInputChange}
                                        value={inputs.timeFontSize}
                                        type="number"
                                    />
                                </div>
                                <div className="col-lg-6 mb-10">
                                    <label>Time Font</label>
                                    <br />
                                    <FormControl variant="outlined" className="w-75">
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            value={inputs.timeFont == '' ? ' ' : inputs.timeFont}
                                            onChange={handleInputChange}
                                            name="timeFont"
                                        >
                                            <MenuItem value=" ">Default</MenuItem>
                                            {FONT_FAMILY &&
                                                FONT_FAMILY.length &&
                                                Object.keys(FONT_FAMILY).map((data, index) => {
                                                    return (
                                                        <MenuItem
                                                            style={{ fontFamily: FONT_FAMILY[data].label }}
                                                            key={index}
                                                            value={FONT_FAMILY[data].value}
                                                        >
                                                            {' '}
                                                            {FONT_FAMILY[data].label}
                                                        </MenuItem>
                                                    )
                                                })}
                                            {!FONT_FAMILY.length && <MenuItem value=" "> No Font Family</MenuItem>}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-lg-4 mb-10">
                                    <label>Text Font Size</label>
                                    <br />
                                    <input
                                        name="timeTextFontSize"
                                        placeholder="Text Font Size"
                                        onChange={handleInputChange}
                                        value={inputs.timeTextFontSize}
                                        type="number"
                                    />
                                </div>
                                <div className="col-lg-6 mb-10">
                                    <label>Time Text Color</label>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <ColorPicker
                                                name="timeTextColor"
                                                defaultValue="select color"
                                                value={inputs.timeTextColor}
                                                onChange={(color) => {
                                                    handleColorPicker('timeTextColor', color)
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-2">
                                            <p>{inputs.timeTextColor != 'undefined' && inputs.timeTextColor}</p>

                                            <DeleteIcon
                                                className="text-error"
                                                title={'Reset color'}
                                                onClick={() => {
                                                    resetColor('', 'timeTextColor')
                                                }}
                                                aria-label=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-10">
                                    <label>Text Font</label>
                                    <br />
                                    <FormControl variant="outlined" className="w-75">
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            value={inputs.timeTextFont == '' ? ' ' : inputs.timeTextFont}
                                            onChange={handleInputChange}
                                            name="timeTextFont"
                                        >
                                            <MenuItem value=" ">Default</MenuItem>
                                            {FONT_FAMILY &&
                                                FONT_FAMILY.length &&
                                                Object.keys(FONT_FAMILY).map((data, index) => {
                                                    return (
                                                        <MenuItem
                                                            style={{ fontFamily: FONT_FAMILY[data].label }}
                                                            key={index}
                                                            value={FONT_FAMILY[data].value}
                                                        >
                                                            {' '}
                                                            {FONT_FAMILY[data].label}
                                                        </MenuItem>
                                                    )
                                                })}
                                            {!FONT_FAMILY.length && <MenuItem value=" "> No Font Family</MenuItem>}
                                        </Select>
                                    </FormControl>
                                </div>
                            </>
                        )}
                    </div>
                    {(showPrice || fromEditAction === 'price') && (
                        <>
                            <div className="row mt-4 mb-4">
                                <div className="col-lg-6 ">
                                    <label>Price</label>
                                    <input
                                        name="price"
                                        placeholder="Price"
                                        onChange={handleInputChange}
                                        value={inputs.price}
                                        type="number"
                                        min="0"
                                        step="any"
                                    />
                                </div>
                                <div className="col-lg-6 ">
                                    <label>Old Price</label>
                                    <input
                                        name="oldPrice"
                                        placeholder="Old Price"
                                        onChange={handleInputChange}
                                        value={inputs.oldPrice}
                                        type="number"
                                        min="0"
                                        step="any"
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    <div className={!fromEditAction ? 'row mt-4 mb-4' : ''}>
                        {(fromEditAction === 'bgImg' || fromAction === 'FromList' || fromAction === 'FromHome') && (
                            <>
                                <div className="row mt-4 w-100">
                                    <div className="col-lg-12 file-upload-wrapper">
                                        <label>Upload Banner Image</label>
                                        <br />
                                        <b>Recommended Image size : {imageSize}</b>
                                        <input name="image1" type="file" onChange={handleInputChange} />
                                        {errors.image1 && <p className="error">{errors.image1}</p>}
                                        {showUploadedImg && (
                                            <div className="sidebar-widget">
                                                <div className="sidebar-project-wrap mt-30">
                                                    <div className="single-sidebar-blog">
                                                        <div className="sidebar-blog-img">
                                                            <img src={showUploadedImg} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {bannerId && !showUploadedImg && (
                                            <div className="sidebar-widget">
                                                <div className="sidebar-project-wrap mt-30">
                                                    <div className="single-sidebar-blog">
                                                        <div className="sidebar-blog-img">
                                                            <img
                                                                src={process.env.PUBLIC_URL + inputs.image_file}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-lg-12 ">
                                        <div>Image Align</div>
                                        <FormControl variant="outlined" className="w-100">
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                value={inputs.image_file_align}
                                                onChange={handleInputChange}
                                                name="image_file_align"
                                            >
                                                {ALIGN &&
                                                    ALIGN.length &&
                                                    Object.keys(ALIGN).map((data, index) => {
                                                        return (
                                                            <MenuItem value={ALIGN[data].value}>
                                                                {' '}
                                                                {ALIGN[data].label}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                {!ALIGN.length && <MenuItem value=" "> No Data</MenuItem>}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    {showVideo && (
                                        <div className="col-lg-12 mt-4">
                                            <label>Video Link</label>
                                            <input
                                                name="videoLink"
                                                // placeholder="Video Link"
                                                className="input-border material-like-input"
                                                onChange={handleInputChange}
                                                value={inputs.videoLink}
                                                type="text"
                                            />
                                        </div>
                                    )}
                                </div>
                            </>
                        )}

                        {((selectedTheme !== 'beach' &&
                            selectedTheme !== 'contest' &&
                            selectedTheme !== 'color-background' &&
                            selectedTheme !== 'separate-menu' &&
                            selectedTheme !== 'special-events' &&
                            fromEditAction == 'bgImg') ||
                            fromAction === 'FromList' ||
                            fromAction === 'FromHome') && (
                                <>
                                    <div className="row mt-4 w-100">
                                        {/* <div className="col-lg-12 ">
                                        <div>Banner Image Height in PX</div>
                                        <FormControl variant="outlined" className="w-100">
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                value={inputs.image_file_height}
                                                onChange={handleInputChange}
                                                name="image_file_height"
                                            >
                                                {BANNER_IMAGE_HEIGHT &&
                                                    BANNER_IMAGE_HEIGHT.length &&
                                                    Object.keys(BANNER_IMAGE_HEIGHT).map((data, index) => {
                                                        return (
                                                            <MenuItem value={BANNER_IMAGE_HEIGHT[data].value}>
                                                                {' '}
                                                                {BANNER_IMAGE_HEIGHT[data].label}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                {!BANNER_IMAGE_HEIGHT.length && <MenuItem value=" "> No Data</MenuItem>}
                                            </Select>
                                        </FormControl>
                                    </div> */}
                                        {/* <div className="col-lg-12 mt-4">
                                            <div>Banner Cover Area</div>
                                            <FormControl variant="outlined" className="w-100">
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    value={inputs.bannerCoverArea}
                                                    onChange={handleInputChange}
                                                    name="bannerCoverArea"
                                                >
                                                    {BANNER_COVER_AREA &&
                                                        BANNER_COVER_AREA.length &&
                                                        Object.keys(BANNER_COVER_AREA).map((data, index) => {
                                                            return (
                                                                <MenuItem value={BANNER_COVER_AREA[data].value}>
                                                                    {' '}
                                                                    {BANNER_COVER_AREA[data].label}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    {!BANNER_COVER_AREA.length && <MenuItem value=" "> No Data</MenuItem>}
                                                </Select>
                                            </FormControl>
                                        </div> */}
                                    </div>
                                </>
                            )}
                        {(showInnerImg || fromEditAction === 'innerImg') && (
                            <>
                                <div className="row mt-4">
                                    <div className="col-lg-12">
                                        <label>Upload Banner Inner Image</label>
                                        <br />
                                        <label>Inner Image size:{innerImageSize}</label>
                                        <input name="popupImg" type="file" onChange={handleInputChange} />
                                        {showUploadedInnerImg && (
                                            <div className="sidebar-widget">
                                                <div className="sidebar-project-wrap mt-30">
                                                    <div className="single-sidebar-blog">
                                                        <div className="sidebar-blog-img">
                                                            <img src={showUploadedInnerImg} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {bannerId && !showUploadedInnerImg && inputs.popupImage && (
                                            <div className="sidebar-widget">
                                                <div className="sidebar-project-wrap mt-30">
                                                    <div className="single-sidebar-blog">
                                                        <div className="sidebar-blog-img">
                                                            <img
                                                                src={process.env.PUBLIC_URL + inputs.popupImage}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-lg-6 mt-4">
                                        <label>Inner Image Align</label> &nbsp;&nbsp;
                                        <FormControl variant="outlined" className="w-100">
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                value={inputs.popupImageAlign}
                                                onChange={handleInputChange}
                                                name="popupImageAlign"
                                            >
                                                {ALIGN &&
                                                    ALIGN.length &&
                                                    Object.keys(ALIGN).map((data, index) => {
                                                        return (
                                                            <MenuItem value={ALIGN[data].value}>
                                                                {' '}
                                                                {ALIGN[data].label}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                {!ALIGN.length && <MenuItem value=" "> No Width</MenuItem>}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="col-lg-12">
                            <button
                                type="button"
                                className="cancelButton"
                                onClick={() => {
                                    handleClose()
                                }}
                            >
                                Cancel
                            </button>
                            &nbsp;&nbsp;&nbsp;
                            <button
                                className="bannerbutton"
                                type="button"
                                onClick={() => {
                                    onSubmit()
                                }}
                            >
                                {bannerId ? 'Update' : 'Save'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    )
}

export default BannerForm
