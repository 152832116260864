import { PaymentMethodSettings } from '../../models/PaymentMethodSettings'
import { aetherApi } from './aetherApi'

export const paymentApi = aetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentSettings: builder.query<PaymentMethodSettings[], void>({
      query: () => `api/v2/payment/front-methods`,
    }),
  }),
})

export const { useGetPaymentSettingsQuery } = paymentApi