import { useContext } from "react";
import { AuthContext } from "../../../common/Auth";

export const useProductArtworkRestrictionState = (props) => {

    const {tempArtworkList, product, selectedDecorationsList, locationName, artworkLocations, artworksByCategories } = props;
    const { user, artworksByUserGroup, userGroupsData, defaultUserGroup, categoriesList } = useContext(AuthContext)

    const getStatusLocation = (artworkId) => {
        let status = true;
        if(tempArtworkList > 0) {
          status = verifyStatusByProductOrGlobal(artworkId);
        } else if (user?.userId || defaultUserGroup) {
          status = verifyStatusByUserGroups(artworkId);
        } else if (product?.ProductCategoryArray?.length > 0) {
          status = verifyStatusByCategories(artworkId);
        } else {
          status = verifyStatusByProductOrGlobal(artworkId);
        }
        return status;
    }
    
    const verifyStatusByProductOrGlobal = (artworkId) => {
        let status = true;
        if(product.restrictAfterFirstChoice){
          let selectedArtworks = []
          let numberChoice = 0;
          selectedDecorationsList.forEach((row) => {
            if(row.artworkID && (locationName === undefined || locationName !== row.location)) {
              selectedArtworks.push(row);
            }
          })
          numberChoice = selectedArtworks.length;
          artworkLocations.length > 0 && artworkLocations.forEach(location => {
            if(location.artworkId === artworkId && location.numberChoice === numberChoice) {
              status = location.isEnable;
            }
          });
        }
        return status;
    }
    
    const verifyStatusByUserGroups = (artworkId) => {
        let status = true;
        if(userGroupsData?.length > 0) {
          let tempStatus = true;
          let selectedArtworks = []
          let numberChoice = 0;
          selectedDecorationsList.forEach(row => {
            if(row.artworkID && (locationName === undefined || locationName !== row.location)) {
              selectedArtworks.push(row);
            }
          });
          numberChoice = selectedArtworks.length;
          userGroupsData.forEach(userGroup => {
            if(userGroup.restrictAfterFirstChoice && artworksByUserGroup?.length > 0) {
              artworksByUserGroup.forEach(row => {
                if(row.artworkId === artworkId && row.numberChoice === numberChoice) {
                  tempStatus = row.isEnable;
                }
              })
            }
          })
          status = tempStatus;
        }
        return status;
    }

    const verifyStatusByCategories = (artworkId) => {
      let status = true;
      if(product?.ProductCategoryArray?.length > 0) {
        let tempStatus = true;
        let selectedArtworks = []
        let numberChoice = 0;
        selectedDecorationsList.forEach(row => {
          if(row.artworkID && (locationName === undefined || locationName !== row.location)) {
            selectedArtworks.push(row);
          }
        });
        numberChoice = selectedArtworks.length;
        product.ProductCategoryArray.forEach(category => {
          const restrictAfterFirstChoice = categoriesList.findIndex(x => x.categoryName === category.name && x.restrictAfterFirstChoice) >= 0
          if(restrictAfterFirstChoice && artworksByCategories?.length > 0) {
            artworksByCategories.forEach(row => {
              if(row.artworkId === artworkId && row.numberChoice === numberChoice) {
                tempStatus = row.isEnable;
              }
            })
          }
        })
        status = tempStatus;
      }
      return status;
  }

    return {
        getStatusLocation,
    }
}