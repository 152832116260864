export const BANNER_ITEMS = [
    {
        label: 'Spring',
        value: 'spring',
        imageSize: '1200x675'
    },
    {
        label: 'Summer',
        value: 'summer',
        imageSize: '1200x675'
    },
    {
        label: 'Fall',
        value: 'fall',
        imageSize: '1200x675'
    },
    {
        label: 'Winter',
        value: 'winter',
        imageSize: '1200x675'
    },
    {
        label: 'Beach',
        value: 'beach',
        imageSize: '1200x675'
    },
    {
        label: 'Apparel',
        value: 'apparel',
        imageSize: '1200x675'
    },
    {
        label: 'Promotional',
        value: 'promotional',
        imageSize: '1200x675'
    },
    {
        label: 'Collegiate',
        value: 'collegiate',
        imageSize: '1200x675'
    },
    {
        label: 'Awards',
        value: 'awards',
        imageSize: '1200x675'
    },
    {
        label: 'Trade Show',
        value: 'trade-show',
        imageSize: '1200x675'
    },
    {
        label: 'Contest',
        value: 'contest',
        imageSize: '1200x675'
    },
    {
        label: 'Fancy',
        value: 'fancy',
        imageSize: '1200x675'
    },
    {
        label: 'Simple',
        value: 'simple',
        imageSize: '1200x675'
    },
    {
        label: 'Complex',
        value: 'complex',
        imageSize: '1200x675'
    },
    {
        label: 'Highlight Banner',
        value: 'highlight-banner',
        imageSize: '1200x675'
    },
    {
        label: 'Banner Slider',
        value: 'banner-slider',
        imageSize: '1200x675'
    },
    {
        label: 'Image Heavy',
        value: 'image-heavy',
        imageSize: '1200x675'
    },
    {
        label: 'Color Background',
        value: 'color-background',
        imageSize: '1200x675'
    },
    {
        label: 'Product Slider',
        value: 'product-slider',
        imageSize: '1200x675'
    },
    {
        label: 'Technology',
        value: 'technology',
        imageSize: '1200x675'
    },
    {
        label: 'Split Banner',
        value: 'split-banner',
        imageSize: '1200x675'
    },
    {
        label: 'Product Based',
        value: 'product-based',
        imageSize: '1200x675'
    },
    {
        label: 'Separate Menu',
        value: 'separate-menu',
        imageSize: '1200x675'
    },
    {
        label: 'Menu Overlay',
        value: 'menu-overlay',
        imageSize: '1200x675'
    },
    {
        label: 'Product Centered',
        value: 'product-centered',
        imageSize: '1200x675'
    },
    {
        label: 'Large Products',
        value: 'large-products',
        imageSize: '1200x675'
    },
    {
        label: 'Dual Pictures',
        value: 'dual-pictures',
        imageSize: '1200x675'
    },
    {
        label: 'Daily Deals',
        value: 'daily-deals',
        imageSize: '1200x675'
    },
    {
        label: 'Pictures Highlighted',
        value: 'pictures-highlighted',
        imageSize: '1200x675'
    },
    {
        label: 'Basic',
        value: 'basic',
        imageSize: '1200x675'
    },
    {
        label: 'Latest News',
        value: 'latest-news',
        imageSize: '1200x675'
    },
    {
        label: 'Full Width',
        value: 'full-width',
        imageSize: '1200x675'
    },
    {
        label: 'Christmas',
        value: 'christmas',
        imageSize: '1200x675'
    },
    {
        label: 'Dark',
        value: 'dark',
        imageSize: '1200x675'
    },
    {
        label: 'Deadline',
        value: 'deadline',
        imageSize: '1200x675'
    },
    {
        label: 'Special Events',
        value: 'special-events',
        imageSize: '1200x675'
    },
]
export const FONT_FAMILY = [
    {
        label: 'Poppins, sans-serif',
        value: 'Poppins, sans-serif',
    },
    {
        label: 'Abril Fatface, cursive',
        value: 'Abril Fatface, cursive',
    },
    {
        label: 'Cormorant Garamond',
        value: 'Cormorant Garamond',
    },
    {
        label: 'Great Vibes, cursive',
        value: 'Great Vibes, cursive',
    },
    {
        label: 'Lobster, cursive',
        value: 'Lobster, cursive',
    },
    {
        label: 'Josefin Sans, sans-serif',
        value: 'Josefin Sans, sans-serif',
    },
    {
        label: 'Arial',
        value: 'Arial',
    },
    {
        label: 'Arial Black',
        value: 'Arial Black',
    },
    {
        label: 'Arial Narrow',
        value: 'Arial Narrow',
    },
    {
        label: 'Arimo',
        value: 'Arimo, sans-serif',
    },
    {
        label: 'Courier New',
        value: 'Courier New',
    },
    {
        label: 'Geometria Light',
        value: 'Geometria Light',
    },
    {
        label: 'Georgia',
        value: 'Georgia',
    },
    {
        label: 'Hurme Bold',
        value: 'Hurme Bold',
    },
    {
        label: 'Hurme Regular',
        value: 'Hurme Regular',
    },
    {
        label: 'Hurme Thin',
        value: 'Hurme Thin',
    },
    {
        label: 'Lato',
        value: 'Lato, sans-serif',
    },
    {
        label: 'Lucida Sans',
        value: 'Lucida Sans',
    },
    {
        label: 'Montserrat',
        value: 'Montserrat,sans-serif',
    },
    {
        label: 'Open Sans',
        value: 'Open Sans,sans-serif',
    },
    {
        label: 'Oswald',
        value: 'Oswald, sans-serif',
    },
    {
        label: 'PT Sans Narrow',
        value: 'PT Sans Narrow,sans-serif',
    },
    {
        label: 'Prompt',
        value: 'Prompt, sans-serif',
    },
    {
        label: 'Rajdhani',
        value: 'Rajdhani, sans-serif',
    },
    {
        label: 'Roboto',
        value: 'Roboto, sans-serif',
    },
    {
        label: 'Roboto Condensed',
        value: 'Roboto Condensed,sans-serif',
    },
    {
        label: 'Rockwell',
        value: 'Rockwell',
    },
    {
        label: 'Shadows Into Light, cursive',
        value: 'Shadows Into Light,cursive',
    },
    {
        label: 'Source Sans Pro, sans-serif',
        value: 'Source Sans Pro, sans-serif',
    },
    {
        label: 'Tahoma',
        value: 'Tahoma',
    },
    {
        label: 'Times New Roman',
        value: 'Times New Roman',
    },
    {
        label: 'Titillium Web',
        value: 'Titillium Web,sans-serif',
    },
    {
        label: 'Trebuchet MS',
        value: 'Trebuchet MS',
    },
    {
        label: 'Verdana',
        value: 'Verdana',
    },
]
export const CLASSNAME_FOR_WIDTH = [
    {
        label: '8%',
        value: 'col-lg-1',
    },
    {
        label: '16%',
        value: 'col-lg-2',
    },
    {
        label: '25%',
        value: 'col-lg-3',
    },
    {
        label: '33%',
        value: 'col-lg-4',
    },
    {
        label: '41%',
        value: 'col-lg-5',
    },
    {
        label: '50%',
        value: 'col-lg-6',
    },
    {
        label: '58%',
        value: 'col-lg-7',
    },
    {
        label: '66%',
        value: 'col-lg-8',
    },
    {
        label: '75%',
        value: 'col-lg-9',
    },
    {
        label: '83%',
        value: 'col-lg-10',
    },
    {
        label: '91%',
        value: 'col-lg-11',
    },
    {
        label: '100%',
        value: 'col-lg-12',
    },

]
export const ALIGN = [
    {
        label: 'Left',
        value: 'left',
    },
    {
        label: 'Right',
        value: 'right',
    }

]
export const DIRECTION = [
    {
        label: 'Left',
        value: 'textFadeInLeft',
    },
    {
        label: 'Right',
        value: 'textFadeInRight',
    },
    {
        label: 'Up',
        value: 'textFadeInUp',
    }, {
        label: 'Down',
        value: 'textFadeInDown',
    }

]
export const ANIMATION_TIME = [
    {
        label: '1s',
        value: 'text1s',
    },
    {
        label: '2s',
        value: 'text2s',
    },
    {
        label: '3s',
        value: 'text3s',
    }, {
        label: '4s',
        value: 'text4s',
    },
    {
        label: '5',
        value: 'text5s',
    }, {
        label: 'Fast',
        value: 'fast',
    }

]
export const CLASSNAME_P_T = [
    {
        label: '0',
        value: 'pt-0',
    },
    {
        label: '10',
        value: 'pt-10',
    },
    {
        label: '15',
        value: 'pt-15',
    },
    {
        label: '20',
        value: 'pt-20',
    },
    {
        label: '25',
        value: 'pt-25',
    },
    {
        label: '30',
        value: 'pt-30 ',
    },
    {
        label: '35',
        value: 'pt-35',
    },
    {
        label: '40',
        value: 'pt-40',
    },
    {
        label: '45',
        value: 'pt-45',
    },
    {
        label: '50',
        value: 'pt-50',
    },
    {
        label: '55',
        value: 'pt-55',
    },
    {
        label: '60',
        value: 'pt-60',
    },
    {
        label: '70',
        value: 'pt-70',
    },
    {
        label: '75',
        value: 'pt-75',
    },
    {
        label: '80',
        value: 'pt-80',
    },
    {
        label: '95',
        value: 'pt-95',
    },
    {
        label: '90',
        value: 'pt-90',
    },
    {
        label: '100',
        value: 'pt-100',
    },
    {
        label: '105',
        value: 'pt-105',
    },
    {
        label: '110',
        value: 'pt-110',
    },
    {
        label: '115',
        value: 'pt-115',
    },
    {
        label: '120',
        value: 'pt-120',
    },
    {
        label: '125',
        value: 'pt-125',
    },
    {
        label: '128',
        value: 'pt-128',
    },
    {
        label: '130',
        value: 'pt-130',
    },
    {
        label: '135',
        value: 'pt-135',
    },
    {
        label: '140',
        value: 'pt-140',
    },
    {
        label: '150',
        value: 'pt-150',
    },
    {
        label: '155',
        value: 'pt-155',
    },
    {
        label: '170',
        value: 'pt-170',
    },
    {
        label: '180',
        value: 'pt-180',
    },
    {
        label: '205',
        value: 'pt-205',
    },
    {
        label: '230',
        value: 'pt-230',
    },
    {
        label: '270',
        value: 'pt-270',
    },
    {
        label: '240',
        value: 'pt-240',
    },
    {
        label: '365',
        value: 'pt-365',
    },
    {
        label: '370',
        value: 'pt-370',
    },
    {
        label: '380',
        value: 'pt-380',
    }
]
export const CLASSNAME_P_B = [
    {
        label: '0',
        value: 'pb-0',
    },
    {
        label: '10',
        value: 'pb-10',
    },
    {
        label: '15',
        value: 'pb-15',
    },
    {
        label: '20',
        value: 'pb-20',
    },
    {
        label: '25',
        value: 'pb-25',
    },
    {
        label: '30',
        value: 'pb-30 ',
    },
    {
        label: '35',
        value: 'pb-35',
    },
    {
        label: '40',
        value: 'pb-40',
    },
    {
        label: '50',
        value: 'pb-50',
    },
    {
        label: '55',
        value: 'pb-55',
    }, ,
    {
        label: '60',
        value: 'pb-60',
    },
    {
        label: '65',
        value: 'pb-65',
    },
    {
        label: '70',
        value: 'pb-70',
    },
    {
        label: '80',
        value: 'pb-80',
    },
    {
        label: '85',
        value: 'pb-b5',
    },
    {
        label: '90',
        value: 'pb-90',
    },
    {
        label: '95',
        value: 'pb-95',
    },
    {
        label: '100',
        value: 'pb-100',
    },
    {
        label: '105',
        value: 'pb-105',
    },
    {
        label: '110',
        value: 'pb-110',
    },
    {
        label: '115',
        value: 'pb-115',
    },
    {
        label: '120',
        value: 'pb-120',
    },
    {
        label: '125',
        value: 'pb-125',
    },
    {
        label: '130',
        value: 'pb-130',
    },
    {
        label: '140',
        value: 'pb-140',
    },
    {
        label: '145',
        value: 'pb-145',
    },
    {
        label: '150',
        value: 'pb-150',
    },
    {
        label: '170',
        value: 'pb-170',
    },
    {
        label: '180',
        value: 'pb-180',
    },
    {
        label: '280',
        value: 'pb-280',
    },
    {
        label: '210',
        value: 'pb-210',
    },
    {
        label: '285',
        value: 'pb-285',
    },
    {
        label: '270',
        value: 'pb-270',
    },
    {
        label: '290',
        value: 'pb-290',
    }
]
export const CLASSNAME_P_L = [
    {
        label: '0',
        value: 'pl-0',
    },
    {
        label: '10',
        value: 'pl-10',
    },
    {
        label: '15',
        value: 'pl-15',
    },
    {
        label: '20',
        value: 'pl-20',
    },
    {
        label: '30',
        value: 'pl-30',
    },
    {
        label: '40',
        value: 'pl-40',
    },
    {
        label: '50',
        value: 'pl-50',
    },
    {
        label: '60',
        value: 'pl-60',
    },
    {
        label: '70',
        value: 'pl-70',
    },
    {
        label: '80',
        value: 'pl-80',
    },
    {
        label: '90',
        value: 'pl-90',
    },
    {
        label: '100',
        value: 'pl-100',
    },
    {
        label: '110',
        value: 'pl-110',
    },
    {
        label: '120',
        value: 'pl-120',
    },
    {
        label: '130',
        value: 'pl-130',
    },
    {
        label: '140',
        value: 'pl-140',
    },
    {
        label: '150',
        value: 'pl-150',
    },
    {
        label: '155',
        value: 'pl-155',
    },
    {
        label: '190',
        value: 'pl-190',
    },

]
export const CLASSNAME_P_R = [
    {
        label: '0',
        value: 'pr-0',
    },
    {
        label: '10',
        value: 'pr-10',
    },
    {
        label: '15',
        value: 'pr-15',
    },
    {
        label: '20',
        value: 'pr-20',
    },
    {
        label: '30',
        value: 'pr-30',
    },
    {
        label: '40',
        value: 'pr-40',
    },
    {
        label: '50',
        value: 'pr-50',
    },
    {
        label: '60',
        value: 'pr-60',
    },
    {
        label: '70',
        value: 'pr-70',
    },
    {
        label: '80',
        value: 'pr-80',
    },
    {
        label: '90',
        value: 'pr-90',
    },
    {
        label: '100',
        value: 'pr-100',
    },
    {
        label: '110',
        value: 'pr-110',
    },
    {
        label: '120',
        value: 'pr-120',
    },
    {
        label: '130',
        value: 'pr-130',
    },
    {
        label: '140',
        value: 'pr-140',
    },
    {
        label: '155',
        value: 'pr-155',
    },
    {
        label: '270',
        value: 'pr-270',
    },

]
export const CLASSNAME_M_B = [
    {
        label: '0',
        value: 'mb-0',
    },
    {
        label: '10',
        value: 'mb-10',
    },
    {
        label: '12',
        value: 'mb-12',
    },
    {
        label: '15',
        value: 'mb-15',
    },
    {
        label: '20',
        value: 'mb-20',
    },
    {
        label: '25',
        value: 'mb-25',
    },
    {
        label: '30',
        value: 'mb-30',
    },
    {
        label: '35',
        value: 'mb-35',
    },
    {
        label: '40',
        value: 'mb-40',
    },
    {
        label: '45',
        value: 'mb-45',
    },
    {
        label: '50',
        value: 'mb-50',
    },
    {
        label: '55',
        value: 'mb-55',
    },
    {
        label: '60',
        value: 'mb-60',
    },
    {
        label: '65',
        value: 'mb-65',
    },
    {
        label: '70',
        value: 'mb-70',
    },
    {
        label: '75',
        value: 'mb-75',
    },
    {
        label: '80',
        value: 'mb-80',
    },
    {
        label: '90',
        value: 'mb-90',
    },
    {
        label: '95',
        value: 'mb-95',
    },
    {
        label: '110',
        value: 'mb-100',
    },
    {
        label: '110',
        value: 'mb-110',
    },
    {
        label: '120',
        value: 'mb-120',
    },
    {
        label: '130',
        value: 'mb-130',
    },
    {
        label: '140',
        value: 'mb-140',
    },
    {
        label: '150',
        value: 'mb-150',
    }
]
export const CLASSNAME_M_T = [
    {
        label: '0',
        value: 'mt-0',
    },
    {
        label: '10',
        value: 'mt-10',
    },
    {
        label: '12',
        value: 'mt-12',
    },
    {
        label: '15',
        value: 'mt-15',
    },
    {
        label: '20',
        value: 'mt-20',
    },
    {
        label: '25',
        value: 'mt-25',
    },
    {
        label: '30',
        value: 'mt-30',
    },
    {
        label: '35',
        value: 'mt-35',
    },
    {
        label: '40',
        value: 'mt-40',
    },
    {
        label: '45',
        value: 'mt-45',
    },
    {
        label: '50',
        value: 'mt-50',
    },
    {
        label: '55',
        value: 'mt-55',
    },
    {
        label: '60',
        value: 'mt-60',
    },
    {
        label: '70',
        value: 'mt-70',
    },
    {
        label: '80',
        value: 'mt-80',
    },
    {
        label: '90',
        value: 'mt-90',
    },
    {
        label: '100',
        value: 'mt-100',
    },
    {
        label: '110',
        value: 'mt-110',
    },
    {
        label: '120',
        value: 'mt-120',
    },
    {
        label: '130',
        value: 'mt-130',
    },
    {
        label: '140',
        value: 'mt-140',
    },
    {
        label: '150',
        value: 'mt-150',
    },
    {
        label: '195',
        value: 'mt-195',
    },
    {
        label: '200',
        value: 'mt-200',
    }
]

export const CLASSNAME_M_R = [
    {
        label: '0',
        value: 'mr-0',
    },
    {
        label: '10',
        value: 'mr-10',
    },
    {
        label: '12',
        value: 'mr-12',
    },
    {
        label: '15',
        value: 'mr-15',
    },
    {
        label: '20',
        value: 'mr-20',
    },
    {
        label: '30',
        value: 'mr-30',
    },
    {
        label: '35',
        value: 'mr-35',
    },
    {
        label: '40',
        value: 'mr-40',
    },
    {
        label: '50',
        value: 'mr-50',
    },
    {
        label: '60',
        value: 'mr-60',
    },
    {
        label: '70',
        value: 'mr-70',
    },
    {
        label: '80',
        value: 'mr-80',
    },
    {
        label: '90',
        value: 'mr-90',
    },
    {
        label: '100',
        value: 'mr-100',
    },
    {
        label: '110',
        value: 'mr-110',
    },
    {
        label: '120',
        value: 'mr-120',
    },
    {
        label: '130',
        value: 'mr-130',
    },
    {
        label: '140',
        value: 'mr-140',
    },
    {
        label: '150',
        value: 'mr-150',
    }
]

export const CLASSNAME_M_L = [
    {
        label: '0',
        value: 'ml-0',
    },
    {
        label: '10',
        value: 'ml-10',
    },
    {
        label: '12',
        value: 'ml-12',
    },
    {
        label: '15',
        value: 'ml-15',
    },
    {
        label: '20',
        value: 'ml-20',
    },
    {
        label: '30',
        value: 'ml-30',
    },
    {
        label: '40',
        value: 'ml-40',
    },
    {
        label: '50',
        value: 'ml-50',
    },
    {
        label: '55',
        value: 'ml-55',
    },
    {
        label: '60',
        value: 'ml-60',
    },
    {
        label: '70',
        value: 'ml-70',
    },
    {
        label: '75',
        value: 'ml-75',
    },
    {
        label: '80',
        value: 'ml-80',
    },
    {
        label: '90',
        value: 'ml-90',
    },
    {
        label: '95',
        value: 'ml-95',
    },
    {
        label: '100',
        value: 'ml-100',
    },
    {
        label: '110',
        value: 'ml-110',
    },
    {
        label: '120',
        value: 'ml-120',
    },
    {
        label: '130',
        value: 'ml-130',
    },
    {
        label: '140',
        value: 'ml-140',
    },
    {
        label: '145',
        value: 'ml-145',
    },
    {
        label: '150',
        value: 'ml-150',
    },
    {
        label: '130',
        value: 'ml-130',
    }
]

export const BANNER_TEXT_ALIGN = [
    {
        label: 'Right',
        value: 'banner-text-align-right',
    },
    {
        label: 'Center',
        value: 'banner-text-align-center',
    },
    {
        label: 'Left',
        value: 'default',
    }
]

export const BANNER_IMAGE_HEIGHT = [
    {
        label: '300',
        value: 'height-300',
    },
    {
        label: '400',
        value: 'height-400',
    },
    {
        label: '500',
        value: 'height-500',
    },
    {
        label: '600',
        value: 'height-600',
    },
    {
        label: '700',
        value: 'height-700',
    },
    {
        label: '800',
        value: 'height-800',
    }

]
export const BANNER_COVER_AREA = [
    {
        label: 'Top Left',
        value: 'top left',
    },
    {
        label: 'Top Right',
        value: 'top right',
    },
    {
        label: 'Bottom Left',
        value: 'bottom left',
    },
    {
        label: 'Bottom Right',
        value: 'bottom right',
    },
    {
        label: 'Left',
        value: 'left',
    },
    {
        label: 'Right',
        value: 'right',
    },
    {
        label: 'Center',
        value: 'center',
    }
]
export const TITLE_ALIGN = [
    {
        label: 'Left',
        value: 'text-left',
    },
    {
        label: 'Right',
        value: 'text-right',
    },
    {
        label: 'Center',
        value: 'text-center',
    }
]
export const TITLE_BORDER = [
    {
        label: 'Both',
        value: 'title-both-border',
    },
    {
        label: 'Bottom',
        value: 'title-bottom-border',
    },
    {
        label: 'Right',
        value: "title-right-border"
    },
    {
        label: 'Left',
        value: "title-left-border"
    },
    {
        label: 'None',
        value: "no-border"
    }

]
export const ICON = [
    {
        label: 'fa fa-glass',
        value: 'fa fa-glass',
    },
    {
        label: 'fa fa-music',
        value: 'fa fa-music',
    },
    {
        label: 'fa fa-search',
        value: 'fa fa-search',
    },
    {
        label: 'fa fa-envelope-o',
        value: 'fa fa-envelope-o',
    },
    {
        label: 'fa fa-heart',
        value: 'fa fa-heart',
    },
    {
        label: 'fa fa-star',
        value: 'fa fa-star',
    },
    {
        label: 'fa fa-star-o',
        value: 'fa fa-star-o',
    },
    {
        label: 'fa fa-user',
        value: 'fa fa-user',
    },
    {
        label: 'fa fa-film',
        value: 'fa fa-film',
    },
    {
        label: 'fa fa-th-large',
        value: 'fa fa-th-large',
    },
    {
        label: 'fa fa-th',
        value: 'fa fa-th',
    },
    {
        label: 'fa fa-th-list',
        value: 'fa fa-th-list',
    },
    {
        label: 'fa fa-check',
        value: 'fa fa-check',
    },
    {
        label: 'fa fa-remove',
        value: 'fa fa-remove',
    },
    {
        label: 'fa fa-close',
        value: 'fa fa-close',
    },
    {
        label: 'fa fa-times',
        value: 'fa fa-times',
    },
    {
        label: 'fa fa-search-plus',
        value: 'fa fa-search-plus',
    },
    {
        label: 'fa fa-search-minus',
        value: 'fa fa-search-minus',
    },
    {
        label: 'fa fa-power-off',
        value: 'fa fa-power-off',
    },
    {
        label: 'fa fa-signal',
        value: 'fa fa-signal',
    },
    {
        label: 'fa fa-gear',
        value: 'fa fa-gear',
    },
    {
        label: 'fa fa-cog',
        value: 'fa fa-cog',
    },
    {
        label: 'fa fa-trash-o',
        value: 'fa fa-trash-o',
    },
    {
        label: 'fa fa-home',
        value: 'fa fa-home',
    },
    {
        label: 'fa fa-file-o',
        value: 'fa fa-file-o',
    },
    {
        label: 'fa fa-clock-o',
        value: 'fa fa-clock-o',
    },
    {
        label: 'fa fa-road',
        value: 'fa fa-road',
    },
    {
        label: 'fa fa-download',
        value: 'fa fa-download',
    },
    {
        label: 'fa fa-arrow-circle-o-down',
        value: 'fa fa-arrow-circle-o-down',
    },
    {
        label: 'fa fa-arrow-circle-o-up',
        value: 'fa fa-arrow-circle-o-up',
    },
    {
        label: 'fa fa-inbox',
        value: 'fa fa-inbox',
    },
    {
        label: 'fa fa-play-circle-o',
        value: 'fa fa-play-circle-o',
    },
    {
        label: 'fa fa-rotate-right',
        value: 'fa fa-rotate-right',
    },
    {
        label: 'fa fa-refresh',
        value: 'fa fa-refresh',
    },
    {
        label: 'fa fa-list-alt',
        value: 'fa fa-list-alt',
    },
    {
        label: 'fa fa-lock',
        value: 'fa fa-lock',
    },
    {
        label: 'fa fa-flag',
        value: 'fa fa-flag',
    },
    {
        label: 'fa fa-headphones',
        value: 'fa fa-headphones',
    },
    {
        label: 'fa fa-volume-off',
        value: 'fa fa-volume-off',
    },
    {
        label: 'fa fa-volume-down',
        value: 'fa fa-volume-down',
    },
    {
        label: 'fa fa-volume-up',
        value: 'fa fa-volume-up',
    },
    {
        label: 'fa fa-qrcode',
        value: 'fa fa-qrcode',
    },
    {
        label: 'fa fa-barcode',
        value: 'fa fa-barcode',
    },
    {
        label: 'fa fa-tag',
        value: 'fa fa-tag',
    },
    {
        label: 'fa fa-tags',
        value: 'fa fa-tags',
    },
    {
        label: 'fa fa-book',
        value: 'fa fa-book',
    },
    {
        label: 'fa fa-bookmark',
        value: 'fa fa-bookmark',
    },
    {
        label: 'fa fa-print',
        value: 'fa fa-print',
    },
    {
        label: 'fa fa-camera',
        value: 'fa fa-camera',
    },
    {
        label: 'fa fa-font',
        value: 'fa fa-font',
    },
    {
        label: 'fa fa-bold',
        value: 'fa fa-bold',
    },
    {
        label: 'fa fa-italic',
        value: 'fa fa-italic',
    },
    {
        label: 'fa fa-text-height',
        value: 'fa fa-text-height',
    },
    {
        label: 'fa fa-text-width',
        value: 'fa fa-text-width',
    },
    {
        label: 'fa fa-align-left',
        value: 'fa fa-align-left',
    },
    {
        label: 'fa fa-align-center',
        value: 'fa fa-align-center',
    },
    {
        label: 'fa fa-align-right',
        value: 'fa fa-align-right',
    },
    {
        label: 'fa fa-align-justify',
        value: 'fa fa-align-justify',
    },
    {
        label: 'fa fa-list',
        value: 'fa fa-list',
    },
    {
        label: 'fa fa-dedent',
        value: 'fa fa-dedent',
    },
    {
        label: 'fa fa-outdent',
        value: 'fa fa-outdent',
    },
    {
        label: 'fa fa-indent',
        value: 'fa fa-indent',
    },
    {
        label: 'fa fa-video-camera',
        value: 'fa fa-video-camera',
    },
    {
        label: 'fa fa-photo',
        value: 'fa fa-photo',
    },
    {
        label: 'fa fa-image',
        value: 'fa fa-image',
    },
    {
        label: 'fa fa-picture-o',
        value: 'fa fa-picture-o',
    },
    {
        label: 'fa fa-pencil',
        value: 'fa fa-pencil',
    },
    {
        label: 'fa fa-map-marker',
        value: 'fa fa-map-marker',
    },
    {
        label: 'fa fa-adjust',
        value: 'fa fa-adjust',
    },
    {
        label: 'fa fa-tint',
        value: 'fa fa-tint',
    },
    {
        label: 'fa fa-edit',
        value: 'fa fa-edit',
    },
    {
        label: 'fa fa-pencil-square-o',
        value: 'fa fa-pencil-square-o',
    },
    {
        label: 'fa fa-share-square-o',
        value: 'fa fa-share-square-o',
    },
    {
        label: 'fa fa-check-square-o',
        value: 'fa fa-check-square-o',
    },
    {
        label: 'fa fa-arrows',
        value: 'fa fa-arrows',
    },
    {
        label: 'fa fa-step-backward',
        value: 'fa fa-step-backward',
    },
    {
        label: 'fa fa-fa fast-backward',
        value: 'fa fa-fa fast-backward',
    },
    {
        label: 'fa fa-backward',
        value: 'fa fa-backward',
    },
    {
        label: 'fa fa-play',
        value: 'fa fa-play',
    },
    {
        label: 'fa fa-pause',
        value: 'fa fa-pause',
    },
    {
        label: 'fa fa-stop',
        value: 'fa fa-stop',
    },
    {
        label: 'fa fa-forward',
        value: 'fa fa-forward',
    },
    {
        label: 'fa fa-fa fast-forward',
        value: 'fa fa-fa fast-forward',
    },
    {
        label: 'fa fa-step-forward',
        value: 'fa fa-step-forward',
    },
    {
        label: 'fa fa-eject',
        value: 'fa fa-eject',
    },
    {
        label: 'fa fa-chevron-left',
        value: 'fa fa-chevron-left',
    },
    {
        label: 'fa fa-chevron-right',
        value: 'fa fa-chevron-right',
    },
    {
        label: 'fa fa-plus-circle',
        value: 'fa fa-plus-circle',
    },
    {
        label: 'fa fa-minus-circle',
        value: 'fa fa-minus-circle',
    },
    {
        label: 'fa fa-times-circle',
        value: 'fa fa-times-circle',
    },
    {
        label: 'fa fa-check-circle',
        value: 'fa fa-check-circle',
    },
    {
        label: 'fa fa-question-circle',
        value: 'fa fa-question-circle',
    },
    {
        label: 'fa fa-info-circle',
        value: 'fa fa-info-circle',
    },
    {
        label: 'fa fa-crosshairs',
        value: 'fa fa-crosshairs',
    },
    {
        label: 'fa fa-times-circle-o',
        value: 'fa fa-times-circle-o',
    },
    {
        label: 'fa fa-check-circle-o',
        value: 'fa fa-check-circle-o',
    },
    {
        label: 'fa fa-ban',
        value: 'fa fa-ban',
    },
    {
        label: 'fa fa-arrow-left',
        value: 'fa fa-arrow-left',
    },
    {
        label: 'fa fa-arrow-right',
        value: 'fa fa-arrow-right',
    },
    {
        label: 'fa fa-arrow-up',
        value: 'fa fa-arrow-up',
    },
    {
        label: 'fa fa-arrow-down',
        value: 'fa fa-arrow-down',
    },
    {
        label: 'fa fa-mail-forward',
        value: 'fa fa-mail-forward',
    },
    {
        label: 'fa fa-expand',
        value: 'fa fa-expand',
    },
    {
        label: 'fa fa-compress',
        value: 'fa fa-compress',
    },
    {
        label: 'fa fa-plus',
        value: 'fa fa-plus',
    },
    {
        label: 'fa fa-minus',
        value: 'fa fa-minus',
    },
    {
        label: 'fa fa-asterisk',
        value: 'fa fa-asterisk',
    },
    {
        label: 'fa fa-exclamation-circle',
        value: 'fa fa-exclamation-circle',
    },
    {
        label: 'fa fa-gift',
        value: 'fa fa-gift',
    },
    {
        label: 'fa fa-leaf',
        value: 'fa fa-leaf',
    },
    {
        label: 'fa fa-fire',
        value: 'fa fa-fire',
    },
    {
        label: 'fa fa-eye',
        value: 'fa fa-eye',
    },
    {
        label: 'fa fa-eye-slash',
        value: 'fa fa-eye-slash',
    },
    {
        label: 'fa fa-warning',
        value: 'fa fa-warning',
    },
    {
        label: 'fa fa-exclamation-triangle',
        value: 'fa fa-exclamation-triangle',
    },
    {
        label: 'fa fa-plane',
        value: 'fa fa-plane',
    },
    {
        label: 'fa fa-calendar',
        value: 'fa fa-calendar',
    },
    {
        label: 'fa fa-random',
        value: 'fa fa-random',
    },
    {
        label: 'fa fa-comment',
        value: 'fa fa-comment',
    },
    {
        label: 'fa fa-magnet',
        value: 'fa fa-magnet',
    },
    {
        label: 'fa fa-chevron-up',
        value: 'fa fa-chevron-up',
    },
    {
        label: 'fa fa-chevron-down',
        value: 'fa fa-chevron-down',
    },
    {
        label: 'fa fa-retweet',
        value: 'fa fa-retweet',
    },
    {
        label: 'fa fa-shopping-cart',
        value: 'fa fa-shopping-cart',
    },
    {
        label: 'fa fa-folder',
        value: 'fa fa-folder',
    },
    {
        label: 'fa fa-folder-open',
        value: 'fa fa-folder-open',
    },
    {
        label: 'fa fa-arrows-v',
        value: 'fa fa-arrows-v',
    },
    {
        label: 'fa fa-arrows-h',
        value: 'fa fa-arrows-h',
    },
    {
        label: 'fa fa-bar-chart-o',
        value: 'fa fa-bar-chart-o',
    },
    {
        label: 'fa fa-bar-chart',
        value: 'fa fa-bar-chart',
    },
    {
        label: 'fa fa-twitter-square',
        value: 'fa fa-twitter-square',
    },
    {
        label: 'social-icon-x',
        value: 'social-icon-x',
    },
    {
        label: 'fa fa-fa facebook-square',
        value: 'fa fa-fa facebook-square',
    },
    {
        label: 'fa fa-camera-retro',
        value: 'fa fa-camera-retro',
    },
    {
        label: 'fa fa-key',
        value: 'fa fa-key',
    },
    {
        label: 'fa fa-gears',
        value: 'fa fa-gears',
    },
    {
        label: 'fa fa-cogs',
        value: 'fa fa-cogs',
    },
    {
        label: 'fa fa-comments',
        value: 'fa fa-comments',
    },
    {
        label: 'fa fa-thumbs-o-up',
        value: 'fa fa-thumbs-o-up',
    },
    {
        label: 'fa fa-thumbs-o-down',
        value: 'fa fa-thumbs-o-down',
    },
    {
        label: 'fa fa-star-half',
        value: 'fa fa-star-half',
    },
    {
        label: 'fa fa-heart-o',
        value: 'fa fa-heart-o',
    },
    {
        label: 'fa fa-sign-out',
        value: 'fa fa-sign-out',
    },
    {
        label: 'fa fa-linkedin-square',
        value: 'fa fa-linkedin-square',
    },
    {
        label: 'fa fa-thumb-tack',
        value: 'fa fa-thumb-tack',
    },
    {
        label: 'fa fa-external-link',
        value: 'fa fa-external-link',
    },
    {
        label: 'fa fa-sign-in',
        value: 'fa fa-sign-in',
    },
    {
        label: 'fa fa-trophy',
        value: 'fa fa-trophy',
    },
    {
        label: 'fa fa-github-square',
        value: 'fa fa-github-square',
    },
    {
        label: 'fa fa-upload',
        value: 'fa fa-upload',
    },
    {
        label: 'fa fa-lemon-o',
        value: 'fa fa-lemon-o',
    },
    {
        label: 'fa fa-phone',
        value: 'fa fa-phone',
    },
    {
        label: 'fa fa-square-o',
        value: 'fa fa-square-o',
    },
    {
        label: 'fa fa-bookmark-o',
        value: 'fa fa-bookmark-o',
    },
    {
        label: 'fa fa-phone-square',
        value: 'fa fa-phone-square',
    },
    {
        label: 'fa fa-unlock',
        value: 'fa fa-unlock',
    }
]

export const STATESLIST = [
    {
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "name": "Minnesota",
        "abbreviation": "MN"
    },
    {
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "name": "North Dakota",
        "abbreviation": "ND"
    },

    {
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "name": "Oregon",
        "abbreviation": "OR"
    },
    {
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "name": "Virgin Islands",
        "abbreviation": "VI"
    },
    {
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "name": "Wyoming",
        "abbreviation": "WY"
    }
]
