import PropTypes from 'prop-types';
import React, { Fragment, useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { GET_STORE_ADMIN, X_TENANT_ID } from '../../common/ActionUrl';
import axios from 'axios';

const DeactiveStore = () => {
    const [storEmail, setStorEmail] = useState("");

    useEffect(() => {
        const requestOptions = {
          headers: { 'Content-Type': 'application/json', 'X-TENANT-ID': X_TENANT_ID },
        };
        axios
          .get(GET_STORE_ADMIN, requestOptions)
          .then((res) => {
            setStorEmail(res?.data?.emailAddress)
            
          })
          .catch((err) => {
            console.log('err', err);
          });
          
      }, []);



    return (
        <Fragment>
            <MetaTags>
                <title>Inactive Store</title>
                <meta name="description" content="deactive page of flone react minimalist eCommerce template." />
            </MetaTags>
            {/* breadcrumb */}

            <div className="deactive-page pt-40 pb-100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-12 col-lg-8 text-center">
                            <div className="error error-contain">
                                <h2>Oops! Your store is currently unavailable.</h2>
                                <h2>Please contact your administrator 
                                    <a href={`mailto:${storEmail}`}>{storEmail}</a>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

DeactiveStore.propTypes = {
    location: PropTypes.object,
};

export default DeactiveStore;
