import { Stack } from '@mui/material'
import React from 'react'
import {
  FieldValues,
  RegisterOptions,
  TextFieldElement,
} from 'react-hook-form-mui'

interface CheckoutTextFieldProps {
  name: string
  label: string
  helperText?: string
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
}

export default function CheckoutTextField({
  name,
  label,
  helperText,
  rules,
}: CheckoutTextFieldProps) {
  return (
    <Stack direction={'column'}>
      <TextFieldElement
        name={name}
        size="small"
        label={label}
        helperText={helperText}
        rules={rules}
        required={rules?.required === true}
        slotProps={{
          htmlInput: {
            'data-testid': name,
          }
        }}
      />
    </Stack>
  )
}
