import PropTypes from "prop-types";
import React, {useContext, useEffect, useState} from 'react'
import parse from "html-react-parser"
import {connect, useDispatch, useSelector} from 'react-redux'
import {useToasts} from 'react-toast-notifications'
import {animateScroll} from 'react-scroll'
import Select from 'react-select'
import {
  getDiscountPrice,
  getFirstPrice,
  getMoqNote,
  getPersColor,
  getPersFont,
  getPersLocation,
  getPersNote,
  getShowMoqNote,
  getShowProductPrice,
  numberWithCommas,
  numberWithCommas2,
  roundedPrice,
  WithCommas
} from '../../helpers/product'
import Swiper from 'react-id-swiper'
import PlaceHolder from '../../assets/img/placeholder-image.png'
import * as productsAPIUtil from '../../util/products/productsAPIUtil'
import * as advanceAPIUtil from '../../util/advance/advanceAPIUtil'
import Copyrowicon from '../../assets/img/copyrow.png'
import {Box, Button, IconButton, Table, useMediaQuery} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import {useHistory} from 'react-router-dom'
import {addItemsRowsTocart} from '../../redux/actions/cartActions'
import {AuthContext} from '../../common/Auth'
import AddDecorationportal from '../../components/product/sub-components/AddDecorationportal'
import DecorationSelectbox from '../../components/product/sub-components/DecorationSelectbox'
import {
  fetchArtworksById,
  fetchDecorationLocations,
  fetchProductStock,
  receiveDecorationLocations,
} from '../../redux/actions/productActions'
import QuoteSeprateList from './QuoteSeprateList'
import AddnewArtDialogue from '../../components/artwork/AddnewArtDialogue'
import useElementSize from '../../util/custom-hooks/useElementSize'
import {LightgalleryItem, LightgalleryProvider} from 'react-lightgallery'
import { productImageExt, productImageUrl} from '../../util/helper'
import {X_TENANT_ID} from "../../common/ActionUrl";
import Axios from "axios";
import {USERTYPES} from '../../constant/userType'
import SharedModal from "../modal/SharedModal";
import { useThemeSettingsData } from "../../layouts/state/useThemeSettingsData";
import { useThemeSettings } from "../../layouts/state/useThemeSettings";
import { useProductDetailState } from "../../pages/shop-product/state/useProductDetailState";
import { useProductConfigState } from "../../pages/shop-product/state/useProductConfigState";
import Loader from "../../common/Loader";
import { useProductPriceToShow } from "../../components/product/state/useProductPriceToShow";
import PriceTable from "./productPrice/PriceTable";
import {useProductArtworkState} from "../../components/product/state/useProductArtworkState";
import { addOnChargesPersBody } from "../../common/AdvanceBodies";
import { useProductPriceState } from "../../components/product/state/useProductPriceState";

let uniqIdcounter = 1
const PortalImageDescription = ({
  product,
  currency,
  cartItems,
  decorationLocations,
  wishlistItems,
  artworkList,
  fetchArtworksById,
  addItemsRowsTocart,
  fetchDecorationLocations,
  compareItems,
  artworkListById,
  isModel=false,
  fetchProductStock,
}) => {
  const { addToast } = useToasts()
  const {
    settingData,
    themeSettingsData,
    storeDetails,
    isDirectToCart,
    showAddToCart,
    showAddToCartOnProd,
    userGroupDiscount,
    decoTypeList,
    categoriesList,
    user,
	userType,
    usePointsAsCurrency,
    ipV4,
  } = useContext(AuthContext)
  const history = useHistory()

  const [imageScroll, setImageScroll] = useState(0)

  const isSmall = useMediaQuery('(max-width: 640px)')
  const isMedium = useMediaQuery('(max-width: 1007px)')
  const [productInventory, setProductInventory] = useState(null)
  const [productDstInventory, setProductDstInventory] = useState(null)
  const [personalizationAdded, setPersonalizationAdded] = useState(false)
  const [statchange, updatestate] = useState(false)
  const [selectedProductSize, setSelectedProductSize] = useState(null)
  const [processSingle, setProcessSingle] = useState(false)
  const [showAddnewart, setAddnewart] = useState(false)
  const [showAddnewartId, setAddnewartId] = useState(0)
  const [loading, setLoading] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')
  const [IsSelectedImageChng, setIsSelectedImageChng] = useState(0)
  const [tempSelectedColor, setTempSelectedColor] = useState('')
  const [imageOnPageLoad, setImageOnPageLoad] = useState('')
  const [primaryImage, setPrimaryImage] = useState(null)
  const [selectedProductColor, setSelectedProductColor] = useState(null)
  const [selectedDecoration, setSelectedDecoration] = useState(null)
  const [selectedLocatons, setLocationSelected] = useState([])
  const [addRowQty, setaddRowQty] = useState(null)
  const [personalizationCost, setPersonalizationCost] = useState({
    matchOrderQty: 0,
    price: 0,
	setupPrice:0,
  chargeId: ''
  })
  const [selectedArtworkID, setselectedArtworkID] = useState([])
  const [toggle, setToggle] = useState(false)
  const [gallerySwiper, getGallerySwiper] = useState(null)
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null)
  const [currentqid, setCurrentqid] = useState(null)
  const [currentActiveId, setCurrentActiveId] = useState(null)
  const [selectedProductLocation, setSelectedProductLocation] = useState('')
  const [selectedProductArtwork, setSelectedProductArtwork] = useState('')
  const [selectedProductDecorations, setSelectedProductDecorations] = useState(
    [],
  )
  const [imgW, imgH] = useElementSize((isModel ? '.modalportal .swiper-slide-active .containerimg img': '.swiper-slide-active .containerimg img'))
  const [imageW, setImageW] = useState(imgW)
  const [imageH, setImageH] = useState(imgH)
  const [toggleDivsArray, setToggleDivs] = useState([])
  const [toggleValue, setToggleValue] = useState(false)
  const [catalogueCustomRows, setCatalogueCustomRows] = useState([])
  const [statChangeTemp, updateStateTemp] = useState(0)
  const [addRowQtyFirst, setaddRowQtyFirst] = useState(0)
  const [notCallDecoHtml, setNotCallDecoHtml] = useState(false)
  const [showData, setShowData] = useState('yes')
  const [cartItem, setCartItem] = useState(0)
  const [prodId, setProdId] = useState(null)
  const [productId, setProductId] = useState(null)
  const [showColor, setShowColor] = useState(true)
  const [showSize, setShowSize] = useState(true)
  const [isMoq, setIsMoq] = useState(false)
  const [isSkuMoq, setIsSkuMoq] = useState(false)
  const [isRestrictQty, setIsRestrictQty] = useState(false)
  const [isPriceBreak, setIsPriceBreak] = useState(false)
  const [partPrice, setPartPrice] = useState([])
  const [minQty, setMinQty] = useState('1')
  const [minAllQty, setMinAllQty] = useState([])
  const [isMinskuQty, setIsMinskuQty] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState('')
  const [selectedLocationIndex, setSelectedLocationIndex] = useState(0)
  const [remainLcoationList, setRemainLcoationList] = useState([])
  const [swipeimageFlag, setSwipeimageFlag] = useState(true)
  const [selectedLocationName, setSelectedLocationName] = useState('')
  const [isDisableProductClone,setIsdisableProductClone]=useState(true)
  const [isReplace,setIsReplace]=useState(true)
  const [searchTextArtwork,setTextSearchArtwork]=useState('')
  const [isSearchArtwork,setIsSearchArtwork]=useState(false)
  const [isTrue,setIsTrue]=useState(false)

  const [customDecorationFee,setCustomDecorationFee]=useState(false)
  const [customDecorationPerUnitPrice,setCustomDecorationPerUnitPrice]=useState(null)
  const [customDecorationSetupFee,setCustomDecorationSetupFee]=useState(null)
  const [personalizationPerUnitPrice,setPersonalizationPerUnitPrice]=useState(null)
  const [personalizationSetupFee,setPersonalizationSetupFee]=useState(null)

  const [storeArtwork, setStoreArtwork] = useState([])
  const disIndProductPrice = getShowProductPrice(product)
  const [IsAllowOutOfStockOrder, setIsAllowOutOfStockOrder] = useState(false)
  const [preventAutoAttach, setPreventAutoAttach] = useState(false)
  const [sizesStock, setSizesStock] = useState(true);
  const [showAddVariation, setShowAddVariation] = useState(false);
  const [artworksToRead, setArtworksToRead] = useState([]);
  const [moqNote, setMoqNote] = useState('Minimum order quantity for this item is [MOQ]');
  const [showMoqNote, setShowMoqNote] = useState(false);
  const [showDecoPopup, setShowDecoPopup] = useState(false);
  const [decoItemData, setDecoItemData] = useState({});
  const [indexPopup, setIndexPopup] = useState([])
  const [mainArrayKeyPopup, setMainArrayKeyPopup] = useState([])
  const [itemsRowsPopup, setItemsRowsPopup] = useState([])
  const [popupText, setPopupText] = useState('');
  const [isNewArtWorkAdded, setIsNewArtWorkAdded] = useState(0);
  const [isNewArtWorkAddedMain, setIsNewArtWorkAddedMain] = useState(0);
  const [artworkLocations, setArtworkLocations] = useState([]);
  const [loadingArtworks, setLoadingartworks] = useState(false);
  const [showDecorationCostInCart, setShowDecorationCostInCart] = useState(false);
  const [showSetupChargeInCart, setShowSetupChargeInCart] = useState(false);
  const [allowOneDec, setallowOneDec] = useState("0")
  const [RequiredDecorationForCheckOutPro, setRequiredDecorationForCheckOutPro] = useState(false)
  const [isProductInventoryEnabled, setIsProductInventoryEnabled] = useState(true)
  const [isAdded, setIsAdded] = useState(false)
  const [productSettingShowDescription, setProductSettingShowDescription] = useState(false)
  const [decorationListOrder, setDecorationListOrder] = useState(false);
  const [personalizationDropDown, setPersonalizationDropDown] = useState(false)
  const [personalizationRequired, setPersonalizationRequired] = useState(false)
  const [requiredLinesPersonalization, setRequiredLinesPersonalization] = useState('')
  const [dropDownValues, setDropDownValues] = useState([])
  const [isDrpDeco, setIsDrpDeco] = useState(false)
  const [allowCustomDecoration, setAllowCustomDecoration] = useState(false);
  const [colorLabel, setColorLabel] = useState('Color')
  const [sizeLabel, setSizeLabel] = useState('Size')
  const [decimalsToShow, setDecimalsToShow] = useState(2);
  const [isStockFor, setIsStockFor] = useState(null)
  const [defaultSizeForAll, setDefaultSizeForAll] = useState([])
  const [fetchedInvProductId, setFetchedInvProductId] = useState('')
  const [appliedFirstDeco, setAppliedFirstDeco] = useState(false)
  const [isFirstTime, setIsFirstTime] = useState(true)
  const [hideDecorationName, setHideDecorationName] = useState(null)
  const [priceTables, setPriceTable] = useState([])
  const [hideLocationName, setHideLocationName] = useState(null)

  const {
    priceDisplay,
    itempriceDisplay,
    itemNumber,
    DefaultProductBackgroundColor,
    artworkSetting,
    sortSizeSetting
  } = useThemeSettings({ settingData })

  useEffect(() => {
    if (sortSizeSetting?.length) {
      setDefaultSizeForAll(sortSizeSetting.map(item => item.value.toLowerCase()));
    }
  }, [sortSizeSetting])

  const  {
    getPointsValue,
    getPointsFromValue
  } = useProductPriceToShow({ productSettings, product })


  useEffect(() => {
    if(!!product?.relabelColor) {
        setColorLabel(product.relabelColor)
    }
    if(!!product?.relabelSize) {
        setSizeLabel(product.relabelSize)
    }
  },[product])

  const colorStyles = {
    container: (styles) => ({...styles, textAlign: 'left',
    }),
    input: (styles) => ({...styles,  position: "absolute",
      top: "0",
      width: "100%",
      height: "40px",
      left: "0",
      padding: "0",
      margin: "0",
      display: "block",
      '& > input' : {
        position: "absolute",
        top: "-4px",
        height: "40px",
        left: "0",
        paddingLeft: "10px !important",
      },
    }),
    singleValue : (styles) => ({...styles,
      top:'3px',
      position: "relative",
    }),
    placeholder : (styles) => ({...styles,
      top:'3px',
      position: "relative",
    }),
    control: (styles) => ({ ...styles, backgroundColor: 'transparent',
      border: '1px solid #ebebeb',
      color: '#333',
      fontSize: '14px',
      margin : "10px 0px !important",
      position: "relative",
      borderRadius:0,
      height:"38px",
      textAlign:"left !important",
      '& > div' : {
        height: "100% !important",
        display: "block !important",
        overflow: "unset !important",
      },
    }),
  };
  const {
    inventorySettings,
    isAllowoutofStockOrder,
    isStock,
    rowText,
    productSettings,
  } = useThemeSettingsData({themeSettingsData});

  useEffect(() => {
    if(product?.allowCustomDecoration === true || product?.allowCustomDecoration === false) {
      setAllowCustomDecoration(product?.allowCustomDecoration)
    } else {
      setAllowCustomDecoration(!!productSettings?.allowCustomDecoration ? productSettings.allowCustomDecoration : false)
    }
    if(product?.hideDecorationName === true || product?.hideDecorationName === false) {
      setHideDecorationName(product?.hideDecorationName)
    } else {
      setHideDecorationName(!!productSettings?.hideDecorationName ? productSettings.hideDecorationName : false)
    }
  },[productSettings, product])

  const artworksListState = useSelector((state) => state.productData.artworkList);
  const artworksProductState = useSelector((state) => state.productData.artworkListById);
  const artworksFromStateAll =  [...artworksListState, ...artworksProductState]
  const productStockState = useSelector((state) => state.productData.productStock);
  const dispatch = useDispatch()

  const {
    getDecoPrice,
  } = useProductArtworkState({
    artworksFromState: artworksFromStateAll,
    setLoading: (loading) => setLoading(loading)
  })

  const {
    getInventorySettingStatus,
    checkMinQty,
    orderAction,
    getSettingStatus,
    getCartItemTotal,
    getLocationsCondensed,
  } = useProductConfigState({
    cartItems,
    catalogueCustomRows,
    product,
    artworksProductState: artworksProductState,
    artworksFromState: artworksFromStateAll,
    artworkSetting,
    selectedLocationIndex,
  })

  const {
    showSellPrice,
    showSell,
    showPrice,
    offerPrice,
    discountType
  } = useProductPriceState({product, currency, userGroupDiscount, itempriceDisplay, priceDisplay, user})

  useEffect(() => {
    setTimeout(() => {
      animateScroll.scrollToTop()
    }, 500);
  },[imageScroll])

  const isColorValid = (color) => {
    return !!color && color !== `Select ${colorLabel}`;
  }

  useEffect(() => {
    if (artworksFromStateAll?.length && selectedProductColor && !appliedFirstDeco) {
      setTimeout(async () => {
        setAppliedFirstDeco(true)
        await applyFirstColor(colors_final.length === 1 ? colors_final[0] : selectedProductColor)
      }, 1)
    }
  }, [artworksProductState])

  const applyFirstColor = async (color) => {
    let getImage = product?.WebstoreProductPartArray.filter((val) => val.color === color && val.isSwapImage === true,)
    if (getImage.length === 0) {
      if (getSettingStatus('showDecoration') || !getSettingStatus('showDecoration')) {
        let findProductArtvariationId = ''
        let locationId = ''
        if (artworksFromStateAll && artworksFromStateAll.length) {
          const numOfArtwork = artworksFromStateAll.reduce((acc, val) => acc +
              (val.productToDecorationMapping || []).filter(x => x.isRequiredForStore === '1' && x.mapping.filter(m => m.color === color)).length, 0
          )
          let mapKey = 0
          artworksFromStateAll.map((val, key) => {
            (val.productToDecorationMapping || []).forEach((decoMap) => {
              if (decoMap.isRequiredForStore === '1') {
                locationId = decoMap.location
                decoMap.mapping.map(async (mapVal) => {
                  if (mapVal.color === color) {
                    handleCheckbox(locationId, 0, '','', val?.id)
                    findProductArtvariationId = mapVal.variationId
                    let isRequiredForStore = decoMap.isRequiredForStore
                    let supplierDeco = decoMap.supplierDeco
                    await handleArtworkselection(
                        val,
                        0,
                        locationId,
                        findProductArtvariationId,
                        mapKey++ === (numOfArtwork - 1),
                        isRequiredForStore,
                        supplierDeco
                    )
                  }
                })
              } else {
                setShowData('yes')
              }
            })
          })
        }
      }
      setIsReplace(true)
    } else {
      setIsReplace(false)
    }
  }


  const getStoreArtwork = () => {
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        'X-TENANT-ID': X_TENANT_ID,
      },
    }
    Axios
      .get('/api/v1/artworkdata/', requestOptions)
      .then((res) => {
        if (res.data.error) {
          addToast(res.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        } else {
          setStoreArtwork(res.data)
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    if(product && !!product?.decorationListOrder) {
      setDecorationListOrder(product?.decorationListOrder)
    }
  }, [product])

  useEffect(()=>{
    if ( product?.AllowOutofStock === true || product?.AllowOutofStock === false ) {
      setIsAllowOutOfStockOrder(product?.AllowOutofStock)
    } else {
      setIsAllowOutOfStockOrder(isAllowoutofStockOrder)
    }
    if(product) {
      setIsProductInventoryEnabled(getInventorySettingStatus(product))
    }
  }, [product, isAllowoutofStockOrder])

  useEffect(()=>{
    if ( product?.preventAutoAttach === true || product?.preventAutoAttach === false ) {
      setPreventAutoAttach(product?.preventAutoAttach)
    } else {
      setPreventAutoAttach(!!productSettings?.preventAutoAttach ? productSettings.preventAutoAttach : false)
    }
  }, [product, productSettings])

  useEffect(() => {
    if(!!!product.isStockFor || product.isStockFor === 'globalsetting') {
      setIsStockFor(isStock)
    } else {
      setIsStockFor(product.isStockFor)
    }
  }, [product, isStock])

  useEffect(()=>{
    setArtworks()
  }, [artworkListById, artworkList])

  useEffect(()=>{
    setTimeout(() => {
      setArtworks()
    }, 2000);
  }, [isNewArtWorkAdded])

  const setArtworks = () => {
    if ((artworkListById?.length > 0 || artworkList?.length > 0)) {
      const list = artworkList?.length ? artworkList : []
      const listById = artworkListById?.length ? artworkListById : []
      setArtworksToRead( [...list, ...listById])
    }
  }


  useEffect(() => {
    getStoreArtwork()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      let element = document.querySelectorAll('.swiper-slide-active .containerimg img');
      if(isModel) {
        element = document.querySelectorAll('.modalportal .swiper-slide-active .containerimg img');
      }
      setImageW(element[0]?.offsetWidth)
      setImageH(element[0]?.offsetHeight)
    }, 1000);
  },[selectedDecoration]);

  useEffect(() => {
    setTimeout(() => {
      let element = document.querySelectorAll('.swiper-slide-active .containerimg img');
      if(isModel) {
        element = document.querySelectorAll('.modalportal .swiper-slide-active .containerimg img');
      }
      setImageW(element[0]?.offsetWidth)
      setImageH(element[0]?.offsetHeight)
    }, 500);
  },[imgW, imgH]);

  let getSingleImage = ""
  const minQuantity = () => {
    let allQty = [];
    let minObj = partPrice.reduce(function (prev, curr) {
      return Number(prev.minQuantity) < Number(curr.minQuantity) ? prev : curr
    })
    setMinQty(minObj.minQuantity)
    allQty = partPrice.map((item) => {
      return Number(item.minQuantity)
    })
    setMinAllQty(allQty)
  }

  useEffect(() => {
    if (product?.showColorProd === true || product?.showColorProd === false) {
      setShowColor(product?.showColorProd)
    } else {
      setShowColor(productSettings?.showColor)
    }
    if (product?.showSizeProd === true || product?.showSizeProd === false) {
      setShowSize(product?.showSizeProd)
    } else {
      setShowSize(productSettings?.showSize)
    }
	  if (productSettings && productSettings.disableProductClone === false) {
		  setIsdisableProductClone(false)
	  } else {
		  setIsdisableProductClone(true)
	  }
    if(product?.showAddVariation === true || product?.showAddVariation === false) {
      setShowAddVariation(product.showAddVariation);
    } else {
      setShowAddVariation(productSettings?.showAddVariation);
    }
    if(!!product?.AllowOneDecorationLimitPro && product?.AllowOneDecorationLimitPro !== " ") {
      setallowOneDec(product?.AllowOneDecorationLimitPro)
    } else {
      setallowOneDec(productSettings?.AllowOneDecorationLimit)
    }
    if(product?.RequiredDecorationForCheckOutPro === true || product?.RequiredDecorationForCheckOutPro === false) {
			setRequiredDecorationForCheckOutPro(product?.RequiredDecorationForCheckOutPro)
		} else {
			setRequiredDecorationForCheckOutPro(productSettings?.RequiredDecorationForCheckOut)
		}
    if (product?.showDescription === true || product?.showDescription === false) {
      setProductSettingShowDescription(product?.showDescription)
    } else {
      setProductSettingShowDescription(productSettings?.showDescription)
    }
    if (product?.showDecorationCostInCart === true || product?.showDecorationCostInCart === false) {
      setShowDecorationCostInCart(product?.showDecorationCostInCart)
    } else {
      setShowDecorationCostInCart(productSettings?.showDecorationCostInCart)
    }
    if (product?.showSetupChargeInCart === true || product?.showSetupChargeInCart === false) {
      setShowSetupChargeInCart(product?.showSetupChargeInCart)
    } else {
      setShowSetupChargeInCart(productSettings?.showSetupChargeInCart)
    }
    if(product && productSettings && minQty) {
      setMoqNote(getMoqNote(productSettings, product, minQty));
      setShowMoqNote(getShowMoqNote(productSettings, product));
    }
    verifyShowSize();
    // verifyShowColor();
    if(!!product?.decimalsToShow) {
      setDecimalsToShow(Number(product?.decimalsToShow))
    } else if(!!productSettings?.decimalsToShow) {
      setDecimalsToShow(Number(productSettings?.decimalsToShow))
    } else {
      setDecimalsToShow(2);
    }
    if (product?.hideLocationName === true || product?.hideLocationName === false) {
			setHideLocationName(product?.hideLocationName)
		} else {
			setHideLocationName(!!productSettings?.hideLocationName ? productSettings.hideLocationName : false)
		}
  }, [product, productSettings, minQty])

  useEffect(() => {
    verifyShowColor();
  }, [productSettings, productDstInventory, productInventory])

  useEffect(() => {
    if (partPrice && partPrice?.length > 0) {
      minQuantity()
    }
  }, [partPrice])
  useEffect(()=>{
    setIsTrue(false)
    setImageOnPageLoad(product?.MediaContent?.[0]?.url)
    setPrimaryImage(product?.MediaContent && product?.MediaContent.find((dd)=>dd?.primary === true ? dd?.url : ""))
    setIsMoq(product?.isProductMoq)
    setIsPriceBreak(product?.doNotCalculateAtOrder == "1" ? false : product?.isPriceBreak)
    setIsSkuMoq(product?.isProductskuMoq)
    setIsRestrictQty(product?.isRestrictQty)
    setPartPrice(
        product?.WebstoreProductPartArray[0]?.partPrice?.PartPriceArray
            ?.PartPrice ?? product?.ProductPartArray?.ProductPart?.[0]?.partPrice?.PartPriceArray
            ?.PartPrice,
    )
    setProdId(product?.productId)
    setProductId(product?.id)
    setIsSearchArtwork(product?.searchArtwork)

    setCustomDecorationFee(product?.customDecorationFee)
    setCustomDecorationPerUnitPrice(product?.customDecorationPerUnitPrice ?? 0)
    setPersonalizationPerUnitPrice(product?.personalizationPerUnitPrice)
    setPersonalizationSetupFee(product?.personalizationSetupFee)
    setCustomDecorationSetupFee(product?.customDecorationSetupFee)
    let selectedCartItem = null
    let cartCustomRows = null
    cartCustomRows = product?.MediaContent.filter(x=>x.primary).map(x=>{

      return {
        id: uniqIdcounter ++,
        size: '',
        quantity: '',
        color: '',
        displayGroup: x.displayGroup,
        group: x.group,
        groups: x.groups,
        hex: x.hex,
        hidden: x.hidden,
        index: x.index,
        logoBlockCount: x.logoBlockCount,
        mediaType: x.mediaType,
        partId: x.partId,
        primary: x.primary,
        productId: x.productId,
        secondary: x.secondary,
        select: x.select,
        sku: x.sku,
        url: x.url,
        price: 0,
        totalPrice: 0,
        cost: 0,
        totalCost: 0,
        product: { sku: x.sku },
        weight: product.weight,
        shipFrom: {
          countryFrom: product.shipFromCountry,
          zipCodeFrom: product.shipFromPostalCode,
        },
      }
    });
    cartCustomRows = cartCustomRows.length > 0 ? [cartCustomRows] : [
      [
        {
          id: uniqIdcounter ++,
          size: '',
          quantity: '',
          image: product?.MediaContent?.[0]?.url,
          color: "",
          product: { sku: 0 },
          price: 0,
          totalPrice: 0,
        },
      ],
    ]

    setPersonalizationCost((preVal)=>({
      ...preVal,
      ['matchOrderQty']:
      cartCustomRows[0][0]?.personalizationCost?.matchOrderQty,
      ['price']: cartCustomRows[0][0]?.personalizationCost?.price,
      ['chargeId']: cartCustomRows[0][0]?.personalizationCost?.chargeId,
    }))
    setCatalogueCustomRows(cartCustomRows)
	setTimeout(() => {
		setIsTrue(true)
	}, 1000);

  }, [product])
  useEffect(() => {
    let key = 0
	if (selectedImage) {
		let temp = product?.WebstoreProductPartArray.filter((val) => val.image===selectedImage && val?.isSwapImage,)
		if (temp.length > 0) {
		  if (product?.WebstoreProductPartArray) {
			product.WebstoreProductPartArray.filter(
				(ele, ind) => ind ===  product?.WebstoreProductPartArray.findIndex( elem => elem.color === ele.color)).map((single, index) => {
					if (single.image === selectedImage) {
						return key = index
					}
				})
		  }
		} else {
			if (product.MediaContent) {
				product.MediaContent.filter((mediaArray) => !mediaArray.hidden).map((single, index) => {
					if (single.url === selectedImage) {
						key = index
					}
				})
			}
		}

		if (document.querySelectorAll('.swiper-pagination-bullet')) {
			let color_image_url = ''
			if (document.querySelector('#sw1')) {
				if (
					document.querySelector('#sw1').querySelector('.swiper-slide-active')
				) {
					if (
						document
							.querySelector('#sw1')
							.querySelector('.swiper-slide-active')
							.querySelector('span')
					) {
						color_image_url = document
							.querySelector('#sw1')
							.querySelector('.swiper-slide-active')
							.querySelector('img').src
					}
				}
			}
			if (color_image_url !== selectedImage) {
				let frameZones = Array.from(
					document.querySelectorAll('.swiper-pagination-bullet'),
				)
        if (isModel) {
          frameZones = Array.from(
            document.querySelectorAll('.modalportal .swiper-pagination-bullet'),
          )
        }
				let flag = true
				frameZones &&
					frameZones.map(async (el, k) => {
						if (k === key && flag) {
							flag = false
							let opts = {
								view: window,
								bubbles: true,
								cancelable: true,
								buttons: 1,
							}
							el.dispatchEvent(new MouseEvent('mousedown', opts))
							await new Promise((r) => setTimeout(r, 50))
							el.dispatchEvent(new MouseEvent('mouseup', opts))
							el.dispatchEvent(new MouseEvent('click', opts))
						}
					})
			}
		}
	}
  }, [selectedImage, IsSelectedImageChng])

  useEffect(() => {
    if(product?.personalizationDropDown === true || product?.personalizationDropDown === false) {
      setPersonalizationDropDown(product?.personalizationDropDown)
      setRequiredLinesPersonalization(product?.requiredLinesPersonalization)
      setDropDownValues(product?.dropDownValues)
    } else if(productSettings?.personalizationDropDown === true || productSettings?.personalizationDropDown === false) {
      setPersonalizationDropDown(productSettings?.personalizationDropDown)
      setRequiredLinesPersonalization(productSettings?.requiredLinesPersonalization)
      setDropDownValues(productSettings?.dropDownValues)
    }
    if (product?.personalizationRequired === true || product?.personalizationRequired === false) {
      setPersonalizationRequired(product?.personalizationRequired)
    } else if (productSettings?.personalizationRequired === true || productSettings?.personalizationRequired === false) {
      setPersonalizationRequired(productSettings?.personalizationRequired)
    }
  },[product, productSettings])

  useEffect(() => {
    if (personalizationRequired) {
      addPersonalization();
      setPersonalizationAdded(true);
    }
  }, [personalizationRequired])

  useEffect(() => {
    if (product) {
      if (product.WebstoreProductPartArray?.length) {
        const partPriceArray = product.WebstoreProductPartArray.find(x =>
          x.active &&
            tempSelectedColor && tempSelectedColor !== colorLabel ? x.color === tempSelectedColor : x.color === colors_final[0]
        );
        if (partPriceArray) {
          const priceTable = partPriceArray.partPrice?.PartPriceArray?.PartPrice ?? []
          setPriceTable(priceTable)
        }
      } else if (product?.ProductPartArray?.ProductPart && product?.ProductPartArray?.ProductPart.length > 0) {
        const defaultColor = colors_final?.[0] || '';
        const selectedColorToCompare = tempSelectedColor || defaultColor;
        const part = product.ProductPartArray.ProductPart.find(part => {
          const colors = part?.ColorArray?.Color?.colorName ?? part.color;
          return selectedColorToCompare.toLowerCase() === colors.toLowerCase();
        });
        if (part) {
          const priceTable = part.partPrice?.PartPriceArray?.PartPrice ?? [];
          setPriceTable(priceTable);

        }
      }
    }
  }, [product, tempSelectedColor])

  useEffect(() => {
    if (personalizationRequired) {
      catalogueCustomRows.forEach((_, key) => {
        if (toggleDivsArray?.[key]?.personalization === 'none') {
          ToggleDivs(key, 'P')
        }
      })
    }
  }, [personalizationRequired, catalogueCustomRows, toggleDivsArray])

  useEffect(() => {
    if (processSingle) {
      setProcessSingle(false)
      addtoCart()
    }
  }, [processSingle])

  useEffect(() => {
    getAlertMessageProductDecoration()
  }, [])

	const searchArtworkName = (e) => {
		setTextSearchArtwork(e.target.value)
	}
  const verifyShowSize = () => {
    if(priceTable.length === 1) {
      setSelectedProductSize(priceTable[0].size);
    }
    if(priceTable.length > 1 && !showSize) {
      setShowSize(true);
    }
  }
  const verifyShowColor = () => {
    if(colors_final.length === 1) {
      setSelectedProductColor(colors_final[0]);
      setTempSelectedColor(colors_final[0]);
      setIsReplace(true);
      let tempRows = catalogueCustomRows.filter((rowGroupArray) => {
        rowGroupArray.filter((rowArray) => {
          rowArray.color = colors_final[0]
          return rowArray
        })
        return rowGroupArray
      })
      if(tempRows.length > 0) {
        setCatalogueCustomRows(tempRows)
        setTempValue(tempValue + 1)
      }
    }
    if(colors_final.length > 1 && !showColor) {
      setShowColor(true);
    }
  }

  useEffect(() => {
    verifyShowSize()
    verifyShowColor()
  }, [showColor, showSize])

  const wishlistItem = wishlistItems.filter(
    (wishlistItem) => wishlistItem.id === product.id,
  )[0]
  const compareItem = compareItems.filter(
    (compareItem) => compareItem.id === product.id,
  )[0]

  var rWithoutDiscPrice = 0

  const fetchPersonalizationCost = async () => {
    /** Api for Personalization cost. Its get from advance; **/
    try {
      let result = await advanceAPIUtil.customAdvanceCall(addOnChargesPersBody)
      if (result?.data?.data) {
        let personalizationData = result?.data?.data.filter(
          (val) => val.itemCode === 'PERS',
        )
        if (personalizationData) {
          setPersonalizationCost((preVal) => ({
            ...preVal,
            ['matchOrderQty']:1,
            ['price']:personalizationPerUnitPrice ? personalizationPerUnitPrice: 0,
			['setupPrice']:personalizationSetupFee ? personalizationSetupFee:0,
      ['chargeId']: personalizationPerUnitPrice ? '' : ''
          }))
          totalPriceData()
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const getPrimaryImage = () => {
    let image = ''
    if (product?.MediaContent?.length) {
      product.MediaContent.map((mediaArray) => {
        if (mediaArray.primary) {
          image = mediaArray.url
        }
      })
      if (image === '') {
        product.MediaContent.map((mediaArray) => {
          if (mediaArray.secondary) {
            image = mediaArray.url
          }
        })
      }
      if (image === '') {
        image = product?.MediaContent?.[0]?.url
      }
    }
    return productImageUrl(image)
  }


  if (catalogueCustomRows?.length > 0) {
    catalogueCustomRows.map((rowArray) => {
      rowArray && rowArray.length > 0 && rowArray.map((rowArrayInner) => {
        if (Number(rowArrayInner.id) >= Number(uniqIdcounter)) {
          uniqIdcounter = Number(rowArrayInner.id) + 1
        }
      })
    })
  }

  useEffect(() => {
    if (storeDetails?.accountId) {
      setLoadingartworks(true)
      fetchArtworksById({
        account_id: storeDetails.accountId,
        product_id: product.id,
        userId: user?.userId || undefined,
        categories: product.ProductCategoryArray?.map(x => x.id) || [],
        restrict: true,
      }).then(x => {
        setArtworksToRead(x)
      }).catch(err => {
        console.log(err)
      }).finally(() => setLoadingartworks(false))
    }
  }, [storeDetails, product.id])

  useEffect(() => {
    const locations = getLocationsCondensed();
    if (locations?.locationIds?.length > 0 && isFirstTime) {
      setIsFirstTime(false);
      fetchDecorationLocations(locations.locationIds)
    } else if(locations?.locationIds?.length === 0 && isFirstTime) {
      dispatch(
          receiveDecorationLocations({
                data: []
              }
          ),
      )
      setIsFirstTime(false);
    }
  }, [product, catalogueCustomRows, artworksFromStateAll, selectedProductColor])

  useEffect(() => {
    // console.log('productStockState: ', productStockState, " ==== isModel === ", isModel,  " ==== fetchedInvProductId === ", fetchedInvProductId,  " ==== product.id === ", product.id );
    if((!!!productStockState || (isModel && (fetchedInvProductId === ""  || (!!fetchedInvProductId && fetchedInvProductId !== product.id)))) && isProductInventoryEnabled) {
      console.log('iscalll: ');
      fetchProductStock(product.id)
      setFetchedInvProductId(product.id)
    }
  },[productStockState, product])

  useEffect(() => {
    getArtworkLocations()
  }, [])

  const getArtworkLocations = () => {
    if(artworkLocations.length === 0) {
      productsAPIUtil.getEnabledArtworkLocations(product._id).then(response => {
        setArtworkLocations(response.data);
      }).catch(error => {
        console.log('error', error);
        addToast('Error while loading data!', {
            appearance: "error",
            autoDismiss: true
        });
      })
    }
  }
  let productPrice = product.price ? product.price : 0
  let productDiscount = product.discount ? product.discount : 0
  const handleCancel = () => {
    setAddnewart(false)
  }
  const handleAddnewArt = (addedNewartdata) => {
    let artwork = addedNewartdata.newArtData
    let locationID = addedNewartdata.location
    let showAddnewartIdS = addedNewartdata.showAddnewartId
	fetchArtworksById({
        account_id: storeDetails.accountId,
        product_id: product.id,
        userId: user?.userId || undefined,
        categories: product.ProductCategoryArray?.map(x => x.id) || [],
        restrict: true,
      })
    setAddnewart(false)
    setLoading(true)
    if (artwork.id) {
      setSelectedProductArtwork(artwork.id)
      if (artwork?.design?.variation) {
        if (artwork.design.variation.length === 1) {
          let variation = artwork.design.variation[0]
          let tempDecoArray = selectedProductDecorations
            ? selectedProductDecorations
            : []
          let locationArray = decorationLocations?.filter(
            (locationArray) => locationArray?.locationName === locationID,
          )
          let decoObj = {
            artworkID: artwork.id,
			      artworkSource:artwork.artworkSource,
            location: locationArray?.[0]?.locationName,
            locationId: locationArray?.[0]?.id,
            variationUniqID: variation.design_variation_unique_id,
          }
          let getDecoOfLocation = tempDecoArray.filter(val => val.locationId === locationArray?.[0]?.id)
          if (getDecoOfLocation.length === 0) {
            tempDecoArray.push(decoObj)
            setSelectedProductDecorations(tempDecoArray)
          }
          else if (getDecoOfLocation.length === 1) {
            let replaceTempDecoArray = tempDecoArray.filter(val => val.locationId !== locationArray?.[0]?.id)
            tempDecoArray = replaceTempDecoArray
            tempDecoArray.push(decoObj)
            setSelectedProductDecorations(tempDecoArray)
          }
          let tempcatalogueCustomRows = catalogueCustomRows.filter(
            (rowGroupArray, groupkey) => {
              if (groupkey === showAddnewartIdS) {
                rowGroupArray.filter((rowArray) => {
                  if(rowArray?.decorations)
                    rowArray.decorations = tempDecoArray
                  return rowArray
                })
              }
              return rowGroupArray
            },
          )
          setCatalogueCustomRows(tempcatalogueCustomRows)
          temp(showAddnewartIdS, artwork.id, locationArray?.[0]?.locationName)
          setIsNewArtWorkAddedMain(isNewArtWorkAddedMain+1)
          setSelectedProductLocation('')
          // setSelectedProductArtwork('')
        }
      }
    }
  }
  useEffect(() => {
    if(isNewArtWorkAddedMain > 0) {
      setTimeout(() => {
        setLoading(false)
      }, 10000);
    }
  }, [isNewArtWorkAddedMain])
  const addPersonalization = (key) => {

    if (!personalizationCost.price) {
      fetchPersonalizationCost()
    }

    let tempData = catalogueCustomRows.filter((rowGroup, index) => {
      if (index === key || !key) {
        rowGroup.map((rowArray) => {
          if (!rowArray.personalization) {
            rowArray['personalization'] = []
          }
          let persQty = rowArray.quantity
          let tempMainrows = rowArray.personalization.filter(
            (row) => Number(row.sequance) === 1,
          )
          let persRows = []
          if (Number(persQty) > 0) {
            let itemQty = Number(persQty)
            let pushedIds = []
            for (let index = 0; index < itemQty; index++) {
              if (tempMainrows[index]) {
                let tempPersRow = rowArray.personalization.filter(
                  (row) => row.rowQtyNo === tempMainrows[index].rowQtyNo,
                )
                if (pushedIds.indexOf(tempPersRow[0].rowQtyNo) === -1) {
                  pushedIds.push(tempPersRow[0].rowQtyNo)
                  tempPersRow.map((tempRow) => {
                    persRows.push({
                      ...tempRow,
                      itemSize: rowArray.size,
                      itemColor: rowArray.color,
                      rowId: rowArray.id,
                    })
                  })
                }
              } else {
               if(!!personalizationDropDown && !!requiredLinesPersonalization) {
                  !!dropDownValues && dropDownValues.length > 0 && dropDownValues.map((resData, keysData) => {
                    persRows.push({
                      color: getPersColor(product, productSettings),
                      displayText: '',
                      font: getPersFont(product, productSettings),
                      itemCode: product.itemCode,
                      itemColor: rowArray.color,
                      itemId: product.id,
                      itemNo: product.productId,
                      itemSize: rowArray.size,
                      location: getPersLocation(product, productSettings),
                      notes: getPersNote(product, productSettings),
                      orderId: '',
                      rowId: rowArray.id,
                      rowQtyId: '',
                      rowQtyNo: index + 1,
                      key : key,
                      sequance: 1 + keysData,
                      lineName: resData.personalizationDropDownRename,
                      isDrp: resData.isPersonalizationDropDown,
                      drpValue: resData.personalizationDropDownValue,
                      isEnabled: personalizationDropDown,
                      requiredLine: requiredLinesPersonalization,
                    })
                  })
               } else {
                persRows.push({
                  color: getPersColor(product, productSettings),
                  displayText: '',
                  font: getPersFont(product, productSettings),
                  itemCode: product.itemCode,
                  itemColor: rowArray.color,
                  itemId: product.id,
                  itemNo: product.productId,
                  itemSize: rowArray.size,
                  location: getPersLocation(product, productSettings),
                  notes: getPersNote(product, productSettings),
                  orderId: '',
                  rowId: rowArray.id,
                  rowQtyId: '',
                  rowQtyNo: index + 1,
                  key : key,
                  sequance: 1,
                })
               }
               if(rowArray?.extraParam === 'onCopyRow') {
                ToggleDivs(index + 1, 'P', 'ForOnCopyRow')
               }
              }
            }
          }
          rowArray.personalization = persRows
          return rowArray
        })
      }
      return rowGroup
    })
    //add personalization
    setCatalogueCustomRows(tempData)
    updatestate(!statchange)
  }
  const removeDeco = (mainArraykey, itemsRows, index, decoitem={}) => {
    if(!!selectedProductArtwork && (decoitem?.artworkID === selectedProductArtwork || index === null)) {
      deleteArtWork()
      setSelectedProductDecorations([])
      setDecoItemData({})
      setSelectedProductArtwork('')
    }
    let tempData = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      if (groupkey === mainArraykey) {
        rowGroupArray = rowGroupArray.filter((groupArray) => {
          if (groupArray.decorations) {
            if(index !== '' && index !== null)
              groupArray.decorations = groupArray.decorations.filter(
                (decoArray, decoIndex) => decoIndex !== index,
              )
            else
              groupArray.decorations = []
          }
          return groupArray
        })
      }
      return rowGroupArray
    })

    //remove deco
    //setNotCallDecoHtml(true)
    //ToggleDivs(index, 'D')
    setCatalogueCustomRows(tempData)
    //setSelectedLocation('')
    //setSelectedLocationIndex('')
    updatestate(!statchange)
  }

  const deleteArtWork = () => {
    setLoading(true)
    Promise.resolve(productsAPIUtil.deleteArtWork({id : selectedProductArtwork}))
    .then((res) => {
      if (res.data.status == 'success') {
        setLoading(false)
        addToast('Artwork is deleted succesfully!', {
          appearance: 'success',
          autoDismiss: true,
        })
        fetchArtworksById({
          account_id: storeDetails.accountId,
          product_id: product.id,
        })
      }
    })
    .catch((error) => {
      console.log('error', error)
      addToast('Artwork is not deleted! please try again', {
        appearance: 'error',
        autoDismiss: true,
      })
    })
  }

  const showPopupText = async (mainArraykey, itemsRows, index, decoitem) => {
    if(product.restrictAfterFirstChoice && !(!!selectedProductArtwork)) {
      setIndexPopup(index)
      setMainArrayKeyPopup(mainArraykey)
      setItemsRowsPopup(itemsRows)
      setShowDecoPopup(true);
    } else if(product.restrictAfterFirstChoice && (!!selectedProductArtwork && selectedProductArtwork != decoitem?.artworkID)) {
      setIndexPopup(index)
      setMainArrayKeyPopup(mainArraykey)
      setItemsRowsPopup(itemsRows)
      setShowDecoPopup(true);
      setDecoItemData(decoitem);
    } else {
      removeDeco(mainArraykey, itemsRows, index, decoitem);
    }
  }

  const getAlertMessageProductDecoration = () => {
    if(product._id) {
      productsAPIUtil.getProductDecorationPopup(product._id).then(response => {
        if(response?.data?.alertMessage) {
          setPopupText(response?.data?.alertMessage);
        }
      }).catch(error => {
        console.log('error', error);
          addToast('Error while loading data!', {
              appearance: "error",
              autoDismiss: true
          });
      })
    }
  }

  const photoZoom = (cls='') => {

    let node = document.querySelector(".swiper-slide-active .single-image.lightGal");
    if(isModel) {
      node = document.querySelector(
        '.modalportal .swiper-slide-active .single-image.lightGal',
      )
    }
    let getOuterBOxCheck = document.querySelector('.lg-img-wrap .single-image.lightGal')

    let zoomPictureNode = document.getElementsByClassName("lg-img-wrap")
    if(!!zoomPictureNode && !!zoomPictureNode?.[0] && !!DefaultProductBackgroundColor && cls !== '')
      zoomPictureNode[0].classList.add(cls)

    if (node) {
      const clone = node.cloneNode(true);
      zoomPictureNode[0].classList.add('lg-img-zoom')
      if (zoomPictureNode) {
        zoomPictureNode[0].appendChild(clone);
      }
      var fourChildNode = zoomPictureNode[0].querySelector('.containerimg');
      if ( !!fourChildNode ) {
        fourChildNode.style.width = 'unset'
      }
      let fourChildNode0 = zoomPictureNode[0].querySelector('.containerimg .react_lightgallery_item');
      let fourChildNode1 = zoomPictureNode[0].querySelector('.containerimg .react_lightgallery_item .img-fluid');
      console.log('fourChildNode1: ', fourChildNode1?.width);
      if (!!fourChildNode1 && fourChildNode1?.width > 2000) {
        fourChildNode1.style.height = 'auto'
        fourChildNode0.style.display = 'flex'
        fourChildNode0.style.alignItems = 'center'
        fourChildNode0.style.justifyContent = 'center'
      }
    }

    //get outerbox
    let getOuterBOx = document.querySelector('.lg-img-wrap .react_lightgallery_item')
  if(!!getOuterBOx)
  {
    let imgWidthZoom = getSingleImage?.iw
    let imgHeightZoom = getSingleImage?.ih
    let singleZoomX = getSingleImage?.x
    let singleZoomY = getSingleImage?.y
    let singleZoomW = getSingleImage?.w
    let singleZoomH = getSingleImage?.h

    if(getSingleImage?.isNewLogo == "1" && !!getSingleImage?.processedImage) {
      imgWidthZoom = getSingleImage?.processedImageWidth
      imgHeightZoom = getSingleImage?.processedImageHeight
      singleZoomX = getSingleImage?.newX
      singleZoomY = getSingleImage?.newY
      singleZoomW = getSingleImage?.newW
      singleZoomH = getSingleImage?.newH
    }
    let imgWidth = imgWidthZoom
    let imgHeight = imgHeightZoom

    let viewPortHeight = window.innerHeight
    let reducePer = getReducePer(imgHeight, viewPortHeight)

    imgWidth = (imgWidth * reducePer) / 100
    imgHeight = (imgHeight * reducePer) / 100

    // add left top postion to artwork
    let positionSetLeft = (getOuterBOx.offsetWidth * singleZoomX) / imgWidthZoom
    let positionSetTop = (getOuterBOx.offsetHeight * singleZoomY) / imgHeightZoom
    let positionSetBoxWidth = (getOuterBOx?.offsetWidth * singleZoomW) / imgWidthZoom
    let positionSetBoxHeight = (getOuterBOx?.offsetHeight * singleZoomH) / imgHeightZoom

    let overlayImag = document.querySelectorAll(".lg-img-wrap .overlayimg")
    const css = '.overlayimg:hover{  transform: scale(1.5); }';
    const style = document.createElement('style');
    if (style.sheet) {
      style.sheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
    for (let i = 0; i < overlayImag.length; i++) {
      overlayImag[i].style.left = positionSetLeft + "px"
      overlayImag[i].style.top = positionSetTop + "px"
      overlayImag[i].style.width = positionSetBoxWidth + "px"
      overlayImag[i].style.height = positionSetBoxHeight + "px"
      overlayImag[i].style.transition = 'transform 0.3s';
      overlayImag[i].appendChild(style)
    }

    //add width height to artwork

    let overlayImag2 = document.querySelectorAll(".lg-img-wrap .overlayimg .img-fluid")
    for (let i = 0; i < overlayImag2.length; i++) {
      overlayImag2[i].style.maxWidth = positionSetBoxWidth + 'px'
      overlayImag2[i].style.maxHeight = positionSetBoxHeight + 'px'
      overlayImag2[i].style.width = '100%'
      overlayImag2[i].style.height = '100%'
      overlayImag2[i].style.objectFit = 'contain'
    }
  }
    let getOuterBOxCheck2 = document.querySelector('.lg-img-wrap .lightGal')
    if(!getOuterBOxCheck2) {
      return
    }
    let zoomInBtn = document.getElementById('lg-zoom-in');
    let zoomOutBtn = document.getElementById('lg-zoom-out');

    let scaleItem = 1

    zoomInBtn.addEventListener('click', function (event) {
      getOuterBOxCheck2.style.transform = `scale(${scaleItem + 0.5}) translateX(-50%)`
      scaleItem = scaleItem + 0.5
    });
    zoomOutBtn.addEventListener('click', function (event) {
      getOuterBOxCheck2.style.transform = `scale(${scaleItem - 0.5}) translateX(-50%)`
      scaleItem = scaleItem - 0.5
    });
  }

  const getReducePer = (width, param) => {
    let reducePer = 100
    while ((Number(width) * reducePer) / 100 > param) {
      reducePer = reducePer - 1
    }
    return reducePer
  }
  useEffect(() => {
    if (
      gallerySwiper !== null &&
      gallerySwiper.controller &&
      thumbnailSwiper !== null &&
      thumbnailSwiper.controller
    ) {
      gallerySwiper.controller.control = thumbnailSwiper
      thumbnailSwiper.controller.control = gallerySwiper
    }
  }, [gallerySwiper, thumbnailSwiper])

  const gallerySwiperParams = {
    getSwiper: getGallerySwiper,
    spaceBetween: 10,
    loopedSlides: 4,
    loop: product?.MediaContent?.length <= 1 ? false : true,
  }

  const swipeimage = product?.MediaContent?.filter(
    (mediaArray) => !mediaArray.hidden,
  ).map((mediaElement, key) => {
    let single = null
    let findlogoinfo = ''
    let productColorList = catalogueCustomRows.filter(val => val[0].color === mediaElement.color)
	let getImage=product?.WebstoreProductPartArray.filter((val)=>val.color==mediaElement.color&&val.isSwapImage==true,)
    let prImage = mediaElement.url
    let newImage = mediaElement.url
    if(mediaElement?.isNewLogo == "1" && !!mediaElement?.processedImage) {
      prImage = mediaElement.processedImage
    }
	  if (getImage.length > 0) {
		  prImage = getImage[0].image
	  }
    if (productColorList?.length > 0 && getSettingStatus('showDecoration')) {
      let getDecoData = productColorList[0][0]?.decorations
      if (selectedDecoration !== "" && selectedDecoration !== null && selectedDecoration !== "null" && selectedDecoration !== undefined) {
        for (let i = 0; i < product?.LogoInfo.length; i++) {
          const logoInfoData = product?.LogoInfo[i]
          if (logoInfoData.color === mediaElement.color) {
            if (mediaElement.url === logoInfoData.image && logoInfoData.location === selectedDecoration.location) {
              findlogoinfo = logoInfoData
              break;
            } else if (logoInfoData.location === selectedDecoration.location) {
              findlogoinfo = logoInfoData
              break;
            } else if (mediaElement.url === logoInfoData.image) {
              let extra_logo = logoInfoData.extra_logo.filter(val => val.location === selectedDecoration.location)
              if (extra_logo.length > 0) {
                findlogoinfo = extra_logo
                findlogoinfo[0].image = logoInfoData.image
                findlogoinfo[0].color = logoInfoData.color
                findlogoinfo[0].ih = logoInfoData.ih
                findlogoinfo[0].iw = logoInfoData.iw
                if (logoInfoData?.isNewLogo == "1" && !!logoInfoData?.processedImage) {
                  findlogoinfo[0].processedImageWidth = logoInfoData?.processedImageWidth
                  findlogoinfo[0].processedImageHeight = logoInfoData?.processedImageHeight
                  findlogoinfo[0].isNewLogo = logoInfoData?.isNewLogo
                  findlogoinfo[0].processedImage = logoInfoData?.processedImage
                }
                break;
              }
            }
          }
        }
      }
      else if (getDecoData?.length > 0) {
        getDecoData.map(deco => {

          return findlogoinfo = product.LogoInfo.filter(function (val) {
            return val.image === prImage &&
              val.location === deco.location && deco.variationUniqID !== "";
          });
        })
      }
    }


    if (findlogoinfo !== '' && findlogoinfo?.length) {
      //setTempSelectedColor(mediaElement.color)
      single = findlogoinfo[0]
      getSingleImage = single
    } else if (findlogoinfo !== '') {
      single = findlogoinfo
      getSingleImage = single
    }
    if (single !== null && single !== '' && single && !mediaElement.hidden) {
      let selectedRow = null
      let decoselected = []
      if (catalogueCustomRows?.length) {
        catalogueCustomRows.map((groupArray) => {
          groupArray.map((row) => {
            if (row.color === single.color) {
              if (currentqid || currentActiveId) {
                if (currentqid === row.id || currentActiveId === row.id) selectedRow = row
              } else {
                selectedRow = row
              }
            }
          })
        })
        if (selectedRow?.id) {
          if (selectedRow.decorations) {
            decoselected = selectedRow.decorations
          }
        }
      }
      let imgWidth = single?.iw
      let imgHeight = single?.ih
      let singleX = single?.x
      let singleY = single?.y
      let singleW = single?.w
      let singleH = single?.h
      if(single?.isNewLogo == "1" && !!single?.processedImage) {
        imgWidth = single?.processedImageWidth
        imgHeight = single?.processedImageHeight
        singleX = single?.newX
        singleY = single?.newY
        singleW = single?.newW
        singleH = single?.newH
      }

      if(imgWidth > 900 || imgHeight > 1100){
        var ratio = Math.min(800 / imgWidth, 800 / imgHeight);

        imgWidth = imgWidth * ratio
        imgHeight = imgHeight * ratio
      }


      let reducePer = getReducePer(imgHeight, imageH)
      //imgWidth = (imgWidth * reducePer) / 100
      //imgHeight = (imgHeight * reducePer) / 100

      //artwork box left top position set set
      let positionSetLeft = (imageW * singleX) / imgWidth
      let positionSetTop = (imageH * singleY) / imgHeight

      //artwork box width/height set
      let positionSetBoxWidth = (imageW * singleW) / imgWidth
      let positionSetBoxHeight = (imageH * singleH) / imgHeight
      let mainCls = 'single-image lightGal'
      if(!!prImage && productImageExt(prImage))
      {
        mainCls = 'single-image lightGal product-img-bg'
      }
      return (
        <div key={key}>
          <div className={mainCls}>
            <div className="containerimg" style={(imageW < 450) ? {width: imageW + 'px'} : {}}>
              <LightgalleryProvider onAfterOpen={() => photoZoom(productImageExt(prImage) ? 'product-img-bg' : '')}>
                <LightgalleryItem
                  galleryClassName=" "
                  group="any"
                  src={productImageUrl(prImage)}
                >
                  <img
                    className="img-fluid imagei"
                    src={productImageUrl(prImage)}
                    style={{ maxWidth: isModel ? '' : '450px'}}
                  />
                  {artworksFromStateAll?.length > 0 && (selectedImage == prImage || newImage === selectedImage) && getSettingStatus('showDecoration') &&
                    decoselected?.map((li, key) => {

                      let to = ''
                      let lef = ''
                      let wid = ''
                      let hei = ''

                      if (li.location === single.location) {
                        to = single.y
                        lef = single.x
                        wid = single.w
                        hei = single.h
                        if(single?.isNewLogo == "1" && !!single?.processedImage) {
                          lef = single?.newX
                          to = single?.newY
                          wid = single?.newW
                          hei = single?.newH
                        }
                      } else {
                        single?.extra_logo &&
                          single.extra_logo.map((el) => {
                            if (single.location === el.location) {
                              to = el.y
                              lef = el.x
                              wid = el.w
                              hei = el.h
                              if(single?.isNewLogo == "1" && !!single?.processedImage) {
                                to = el?.newY
                                lef = el?.newX
                                wid = el?.newW
                                hei = el?.newH
                              }
                            }
                          })

                      }
                      if (to !== "") {
                        to = ((Number(to) * reducePer) / 100).toString()
                        lef = ((Number(lef) * reducePer) / 100).toString()
                        wid = ((Number(wid) * reducePer) / 100).toString()
                        hei = ((Number(hei) * reducePer) / 100).toString()
                        let artwork_selected = null
                        let artwork_selectedOld = null
                        if (li?.artworkID !== '' && li?.artworkID !== undefined) {

                          const idToSearch = selectedDecoration ? selectedDecoration.artworkID : li.artworkID
                          //console.log('idToSearch: ', idToSearch);
                          artwork_selectedOld = artworksToRead?.find(
                            (artworkArray) => artworkArray.id === idToSearch,
                          )
                          artwork_selected = artworksFromStateAll?.find(
                            (artworkArray) => artworkArray.id === idToSearch,
                          )
                        }
                        //console.log('artwork_selected: ', artwork_selected, " ===== artwork_selectedOld ==== ", artwork_selectedOld);

                        let rowVariation = null
                        if (artwork_selected?.design?.variation?.length > 0) {
                          rowVariation = artwork_selected.design.variation.filter(
                            (variationArray) =>
                              variationArray.design_variation_unique_id ==
                              li.variationUniqID,
                          )[0]
                            if(artwork_selected?.design?.variation?.itemImageThumbnail != "" && artwork_selected?.design?.variation?.itemImageThumbnail != undefined){
                              rowVariation = artwork_selected?.image
                            }
                          }
                     //console.log('artwork_selected: ', artwork_selected, " == ", rowVariation);
                     if(rowVariation)
                      {
                        return (

                          <div
                            className="overlayimg"
                            key={key}
                            style={{
                              top: positionSetTop + 'px',
                              left: positionSetLeft + 'px',
                              display: 'flex',
                              alignItems: 'flex-start',
                            }}
                          >
                                {((!!rowVariation && !!rowVariation?.itemImageThumbnail && !!rowVariation?.itemImageThumbnail?.[0]) || (!!artwork_selected?.thumbnail)) && (
                                    <img className="img-fluid" src={
                                      !!rowVariation?.itemImageThumbnail && !!rowVariation.itemImageThumbnail?.[0]
                                        ? productImageUrl(
                                          rowVariation.itemImageThumbnail[0],
                                        )
                                        : (!!artwork_selected?.thumbnail ? productImageUrl(artwork_selected?.thumbnail) : '')
                                    }
                                      alt="thumbnail"
                                      width={wid + "px"}
                                      height={hei + "px"}
                                      style={{
                                        maxWidth: positionSetBoxWidth + 'px',
                                        maxHeight: positionSetBoxHeight + 'px',
                                      }}
                                    />
                                )}
                          </div>
                        )
                      }
                      }
                    })}
                </LightgalleryItem>
              </LightgalleryProvider>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div key={key}>
          <div className="containerimg">
            <div className={`${productImageExt(prImage) ? 'product-img-bg' : ''} single-image test`}>
              <LightgalleryProvider onAfterOpen={() => photoZoom(productImageExt(prImage) ? 'product-img-bg' : '')}>
                <LightgalleryItem
                  galleryClassName="testtt"
                  group="any"
                  src={productImageUrl(prImage)}
                >
                  <img
                    src={productImageUrl(prImage)}
                    className="img-fluid"
                    alt="No Image"
                  />
                </LightgalleryItem>
              </LightgalleryProvider>
            </div>
          </div>
        </div>
      )
    }
  })
  const swipeimagewebstore = product?.WebstoreProductPartArray?.filter(
	(ele, ind) => ind ===  product?.WebstoreProductPartArray.findIndex( elem => elem.color === ele.color)).map((mediaElement, key) => {
	let getImage=product?.WebstoreProductPartArray.filter((val)=>val.color==mediaElement.color&&val.isSwapImage==true,)
	  if (getImage.length > 0) {
      if(!getImage[0].image) {
        const otherimagewebstore = product?.MediaContent?.filter(
          (mediaArray) => !mediaArray.hidden,
        ).map((single) => {
          getImage = product?.WebstoreProductPartArray.filter((val) => val.color === single.color && val.isSwapImage === true,)
        })
      }
		  mediaElement.image = getImage[0].image
	  }
      return (
        <div key={key}>
          <div className="containerimg">

            <div className={`${productImageExt(mediaElement.image) ? 'product-img-bg' : ''} single-image test`}>
              <LightgalleryProvider onAfterOpen={() => photoZoom(productImageExt(mediaElement.image) ? 'product-img-bg' : '')}>
                <LightgalleryItem
                  galleryClassName="testtt"
                  group="any"
                  src={productImageUrl(mediaElement.image)}
                >
                  <img
                    src={productImageUrl(mediaElement.image)}
                    className="img-fluid"
                    alt="No Image"
                  />
                </LightgalleryItem>
              </LightgalleryProvider>
            </div>
          </div>
        </div>
      )

  })
  const getDecoAttach = (color_current, get_image_url, isclicked = 0)=>{
    setSelectedDecoration(null)
    setTempValue(tempValue + 1)
    let color_image_url = get_image_url
    if ( get_image_url.includes('anterasaas.com') ) {

      const url = new URL(get_image_url);
      color_image_url = url.searchParams.get('url');
    }

    let locationName = null
    let getIndex = ''
    for ( let i = 0; i < catalogueCustomRows.length; i ++ ) {
      if ( catalogueCustomRows[i][0].decorations && catalogueCustomRows[i][0].decorations.length > 0 ) {
        if ( catalogueCustomRows[i][0].color === color_current ) {
          for ( let p = 0; p < product.LogoInfo.length; p ++ ) {

            if ( product.LogoInfo[p].color === color_current ) {

              if ( product.LogoInfo[p].image === color_image_url ) {
                let checkLocation = catalogueCustomRows[i][0].decorations?.filter((val)=>val.location === product.LogoInfo[p].location)
                if ( checkLocation?.length > 0 ) {
                  locationName = product.LogoInfo[p].location
                  getIndex = i
                  break;
                } else {
                  for ( let e = 0; e < product.LogoInfo[p].extra_logo.length; e ++ ) {

                    if ( product.LogoInfo[p].extra_logo[e].color === color_current && product.LogoInfo[p].extra_logo[e].image === color_image_url ) {

                      let checkLocation = catalogueCustomRows[i][0].decorations?.filter((val)=>val.location === product.LogoInfo[p].extra_logo[e].location)
                      if ( checkLocation?.length > 0 ) {
                        locationName = product.LogoInfo[p].extra_logo[e].location
                        getIndex = i
                        break;
                      }

                    }
                  }
                }
                break;
              }
            }
          }
          break;
        }
      }
      if ( productSettings?.isColorSelCarousel === true ) {
        if (
            color_current !== "" &&
            isclicked === 1 &&
            i === catalogueCustomRows.length - 1
        ) {
          changeColor(color_current, catalogueCustomRows[i][0], i, color_image_url);
          locationName = null;
        }
      }
    }

    if ( locationName !== null && locationName !== 'undefined' ) {
      let getselectedLocation = catalogueCustomRows[getIndex][0].decorations.filter((val)=>val.location === locationName)
      setSelectedDecoration(getselectedLocation[0])
      setTempValue(tempValue + 1)
    }

  }
  const prevNextBtn = () => {

    setSelectedDecoration(null)
    if (document.querySelector('#sw1')) {
      if (
        document.querySelector('#sw1').querySelector('.swiper-slide-active')
      ) {
        if (
          document
            .querySelector('#sw1')
            .querySelector('.swiper-slide-active')
            .querySelector('span')
        ) {

          let color_current = document
            .querySelector('#sw1')
            .querySelector('.swiper-slide-active')
            .querySelector('span').innerText
          setSelectedProductColor(color_current)

          let color_image_url = document
            .querySelector('#sw1')
            .querySelector('.swiper-slide-active')
            .querySelector('img').src
          getDecoAttach(color_current, color_image_url, 1)

        }
      }
    }
  }
  const thumbnailSwiperParams = {

    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    on: {
      init: () => { },
      slideChangeTransitionEnd: (el) => {
        if (document.querySelector('#sw1')) {
          if (
            document.querySelector('#sw1').querySelector('.swiper-slide-active')
          ) {
            if (
              document
                .querySelector('#sw1')
                .querySelector('.swiper-slide-active')
                .querySelector('span')
            ) {
              let color_current = document
                .querySelector('#sw1')
                .querySelector('.swiper-slide-active')
                .querySelector('span').innerText
              // setSelectedProductColor(color_current)
              let color_image_url = document
                .querySelector('#sw1')
                .querySelector('.swiper-slide-active')
                .querySelector('img').src

              // getDecoAttach(color_current, color_image_url)
            }
          }
        }
      },
    },
    getSwiper: getThumbnailSwiper,
    spaceBetween: 20,
    slidesPerView:
      product?.MediaContent?.length >= 4
        ? 4
        : product?.MediaContent?.length >= 3
          ? 3
          : product?.MediaContent?.length >= 2
            ? 2
            : 1,
    // slidesPerView: 4,
    loopedSlides: 4,
    touchRatio: 0.2,
    freeMode: true,
    // loop: true,
    loop: product?.MediaContent?.length <= 1 ? false : true,
    rebuildOnUpdate: false,
    slideToClickedSlide: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    renderPrevButton: () => (
      <>
        {product?.MediaContent?.length > 4 && (
        <button className="antera-swiper-button swiper-button-prev ht-swiper-button-nav" onClick={prevNextBtn}>
          <i className="pe-7s-angle-left" />
        </button>
        )}
      </>
    ),
    renderNextButton: () => (
      <>
        {product?.MediaContent?.length > 4 && (
        <button className="antera-swiper-button swiper-button-next ht-swiper-button-nav" onClick={prevNextBtn}>
          <i className="pe-7s-angle-right" />
        </button>
        )}
      </>
    ),
  }
  const addFirstRow = () => {
    let newRow = [
      {
        id: uniqIdcounter++,
        size: '',
        quantity: '',
        image: '',
        color: '',
        product: { sku: 0 },
        price: 0,
        totalPrice: 0,
      },
    ]
    let tempRows = catalogueCustomRows
    tempRows.push(newRow)

    //add first row
    setCatalogueCustomRows(tempRows)

    updatestate(!statchange)
  }
  const MainSize = ['XS', 'S', 'M', 'L', 'XL', '1XL', '2XL', '3XL']
  let groups = []
  let colors = []
  let colors_final = []
  let colors_ids = []
  let colors_idsColor = []
  let colors_hex = []
  let priceTable = []
  let priceTableTemp = []
  let show_color = true
  if (product?.ProductPartArray?.ProductPart?.length) {
    let tempOptions = product.ProductPartArray.ProductPart
    if (product?.WebstoreProductPartArray?.length > 0) {
      tempOptions = product.WebstoreProductPartArray
    }
    tempOptions.forEach((part) => {
      let priceOption = part
      if (part?.ApparelSize?.labelSize) {
        const size = part.ApparelSize.labelSize
        if (!groups[size]) {
          groups[size] = []
        }
        groups[size].push(priceOption)

        const color = part.ColorArray.Color.colorName
        colors_ids[part.partPrice.partId] = color
        colors_idsColor[color] = part.partPrice.partId
        colors_hex[color] = part.ColorArray.Color.hex
        if (!part.ColorArray.Color.hex || part.ColorArray.Color.hex === '') {
          show_color = false
        }
        if (!colors[color]) {
          colors[color] = []
        }
      }
    })
  }
  for (let size in groups) {
    priceTableTemp.push({ size: size, priceOptions: groups[size] })
  }

  priceTableTemp = priceTableTemp.sort((a,b) => {
    return defaultSizeForAll.indexOf(a.size.toLocaleLowerCase()) - defaultSizeForAll.indexOf(b.size.toLocaleLowerCase());
  })
  //console.log('priceTableTemp: ', priceTableTemp);
  let priceTableTemp1 = []
  let priceTableTemp2 = []

  priceTableTemp.map((a) => { 
    if(defaultSizeForAll.indexOf(a.size.toLocaleLowerCase()) !== -1) {
      priceTableTemp1.push(a)
    } else {
      priceTableTemp2.push(a)
    }
  })

  if(priceTableTemp2.length > 0) {
    priceTableTemp1 = priceTableTemp1.concat(priceTableTemp2)
  }

  priceTableTemp1.map(res => {
    priceTable.push(res)
  })

  for (let color in colors) {
    colors_final.push(color)
  }
  const numericSort = (a, b) => {
    const aNumeric = parseInt(a);
    const bNumeric = parseInt(b);
    return aNumeric - bNumeric;
  };
  colors_final = colors_final.sort(numericSort);
  let tempCount = 0
  const [tempValueCheck, setTempValueCheck] = useState(0)
  const colorselectbox = (quoteItemRow, selectedValue, mainKey) => {
    if(colors_final.length === 1 && tempValueCheck === 0) {
      verifyShowColor()
      selectedValue = colors_final[0]
      changeColor(colors_final[0], quoteItemRow, mainKey)
      setTempValueCheck(tempValueCheck+1)
    }
    if (colors_final.length) {
      return (
        <select
          lang="en"
          className="colorDropdown"
          value={selectedValue}
          onChange={(e) => changeColor(e, quoteItemRow, mainKey)}
        >
          <option key={'colorkey'}>Select {colorLabel}</option>
          {colors_final.map((color, key) => {
            return <option value={color}>{color}</option>
          })}
        </select>
      )
    }
  }
  useEffect(() => {
    if (artworksProductState?.length && colors_final.length === 1 && tempValueCheck === 1) {
      verifyShowColor()
      changeColor(colors_final[0], {}, 0)
      setTempValueCheck(tempValueCheck + 1)
    }
  }, [artworksProductState])

  const checkPriceOptionsArray = (
    priceoption,
    itemRowArraycolor,
    pricerowsize,
  ) => {
    let selectDefaultColor = (showColor === false ? priceoption?.ColorArray?.Color?.colorName === itemRowArraycolor : true)
    if (
      priceoption?.ApparelSize?.labelSize &&
      priceoption.ApparelSize.labelSize === pricerowsize &&
      pricerowsize !== '' && selectDefaultColor
    ) {
      if (
        (priceoption?.ColorArray?.Color?.colorName &&
          priceoption.ColorArray.Color.colorName === itemRowArraycolor &&
          itemRowArraycolor !== '')
      ) {
        if (priceoption?.partPrice?.PartPriceArray) {
          return true
        }
      }
    }
    return false
  }
  //when select location for decoration
  const handleCheckboxDefault = (decorationId, arrayIndex) => {
    //setNotCallDecoHtml(true)
    setSelectedLocationIndex(arrayIndex)
    let tempRows = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      if (groupkey === arrayIndex) {
        let locaName = decorationLocations?.filter(
          (locationFilter) => locationFilter.id === decorationId,
        )[0].locationName
        setSelectedLocationName(locaName)
        setSelectedLocation(decorationId)
        temp(arrayIndex, '', locaName)
        rowGroupArray.filter((rowArray) => {
          if (rowArray.decorations) {
            let findIfexist = rowArray.decorations.filter(
              (decorationArray) => decorationArray.locationId === decorationId,
            )
            if (decorationId !== '') {
              if (findIfexist?.length === 0) {
                let tempaddDeco = {
                  location: locaName,
                  locationId: decorationId,
                }
                rowArray.decorations.push(tempaddDeco)
                setSelectedDecoration(tempaddDeco)
              }
            }
          } else {
            rowArray['decorations'] = []
            let tempaddDeco = {
              location: locaName,
              locationId: decorationId,
            }
            setSelectedDecoration(tempaddDeco)

            rowArray.decorations.push(tempaddDeco)
          }
          return rowArray
        })
      }
      return rowGroupArray
    })

    //when single location of decoration
    setCatalogueCustomRows(tempRows)
  }
  //when we have multiple location for decoration
  const handleCheckbox = (e, arrayIndex, decorationId = '', allowOneDecq = '', artworkID = '') => {

    let selectedLocationId = e?.target?.value ? e.target.value.slice(0, -2) : e

    setSelectedLocationIndex(arrayIndex)
    setIsAdded(false)
    let tempRows = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      if (groupkey === arrayIndex) {
        let locaName = decorationLocations?.filter(
          (locationFilter) => locationFilter.id === selectedLocationId,
        )[0]?.locationName

        setSelectedLocationName(locaName)
        setSelectedLocation(selectedLocationId)
        temp(arrayIndex, artworkID, locaName)

        rowGroupArray.filter((rowArray) => {
          if (rowArray.decorations?.length > 0 && allowOneDecq === "2") {
            let checkLocation = rowArray.decorations && rowArray.decorations.find((d) => !!d?.locationId && !!d?.variationUniqID)
            if(!!checkLocation && checkLocation?.locationId !== selectedLocationId) {
              rowArray.decorations = []
            }
          }
          if (rowArray.decorations?.length > 0) {
            let findIfexist = rowArray.decorations.filter(
              (decorationArray) => decorationArray.locationId === selectedLocationId,
            )
            if (selectedLocationId !== '') {
              if (findIfexist?.length === 0) {
                let tempaddDeco = {
                  location: locaName,
                  locationId: selectedLocationId,
                }
                if(!!locaName) {
                  rowArray.decorations.push(tempaddDeco)
                  setSelectedDecoration(tempaddDeco)
                } else {
                  rowArray.decorations = rowArray.decorations.filter(x => !!x.artworkID);
                }
              }
            }
          } else {
            rowArray['decorations'] = []
            let tempaddDeco = {
              location: locaName,
              locationId: selectedLocationId,
            }
            if(!!locaName) {
              setSelectedDecoration(tempaddDeco)
              rowArray.decorations.push(tempaddDeco)
            } else {
              rowArray.decorations = rowArray.decorations.filter(x => !!x.artworkID);
            }
          }
          return rowArray
        })
      }
      return rowGroupArray
    })

    //when we have multiple location for decoration
    setCatalogueCustomRows(tempRows)

  }

  const handleCheckboxAddRow = (e, decorationId, locationName, itemsRows) => {
    let checkID = selectedLocatons.filter(
      (locationArray) => locationArray === e.target.value,
    )
    if (checkID?.length > 0) {
      if (!e.target.checked) {
        let tempArray = selectedLocatons.filter(
          (location) => location !== e.target.value,
        )
        setLocationSelected(tempArray)
      }
    } else {
      if (e.target.checked) {
        let tempArray = selectedLocatons
        tempArray.push(e.target.value)
        setLocationSelected(tempArray)
      }
    }
    setToggleValue(!toggleValue)
  }
  const changeQty = (e, mainArraykey, rowSize, callFrm = 0, colorVal = '') => {
    let quantityFromCart = 0;
    if(rowSize && colorVal) {
      quantityFromCart = getCartItemTotal(product.productId, colorVal, rowSize);
    }
   if(callFrm === 0) {
    e.target.placeholder = ''
    const regularExpression = /^[0-9\b]+$/
    if (e.target.value !== '' && !regularExpression.test(e.target.value)) {
      return false;
    }
    if (!Number(e.target.value) && e.target.value !== '') {
      return false;
    }
   }
    if(product.showColorProd && (!tempSelectedColor || tempSelectedColor === `Select ${colorLabel}`) && callFrm === 0) {
      e.target.value = '';
      return false;
    }
    let tempRows = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      if (groupkey === mainArraykey) {
        let checkEditRowexist = rowGroupArray.filter(
          (rowArray) => rowArray.size === rowSize,
        )
        if (checkEditRowexist?.length > 0) {
          rowGroupArray.filter((rowArray) => {
            if (rowArray.size === rowSize) {
              rowArray.quantity = callFrm === 1 ? e : e.target.value
              priceTable.map((priceTableRow) => {
                if (priceTableRow.size === rowArray.size) {
                  let priceOptionsArray = priceTableRow.priceOptions
                  priceOptionsArray.map((priceOption) => {
                    if (
                      priceOption.ColorArray.Color.colorName === rowArray.color
                    ) {
                      let qtStock = getStock(rowArray.size, rowArray.color)
                      let totalToCompare = (qtStock - quantityFromCart) < 0 ? 0 : qtStock - quantityFromCart
                      if (IsAllowOutOfStockOrder) {
                      } else if (totalToCompare < rowArray.quantity) {
                        rowArray.quantity = totalToCompare;
                      }
                      if (!rowArray.product) {
                        rowArray['product'] = {}
                      }
                      rowArray.product.sku = priceOption.sku
                      if (
                        priceOption.partPrice.PartPriceArray.PartPrice.length >0
                      ) {
                        /*let priceRow  = priceOption.partPrice.PartPriceArray.PartPrice[0]
                        const minQuantityRow = priceOption.partPrice.PartPriceArray.PartPrice.filter(x =>
                            Number(x.minQuantity) <= Number(rowArray.quantity))
                        priceRow = minQuantityRow.length > 0 ?
                            minQuantityRow.reduce((prev, current) =>
                                (Number(prev?.salePrice) < Number(current.salePrice)) ? prev : current
                            ): priceRow
                        rowArray.price = priceRow.salePrice
                        rowArray.cost = priceRow.price*/
                        const quantitys = Number(rowArray.quantity);
                        priceOption.partPrice.PartPriceArray.PartPrice.map(
                          (partPrice, k) => {
                            if (
                              quantitys !== '' &&
                              Number(quantitys) > 0 &&
                              Number(quantitys) >= Number(partPrice.minQuantity)
                            ) {
                              rowArray.price = partPrice.salePrice
                              rowArray.cost = partPrice.price
                            } else if (k === 0) {
                              rowArray.price = partPrice.salePrice
                              rowArray.cost = partPrice.price
                            }
                          },
                        )
                      }

                      if (Number(userGroupDiscount > 0) && Number(rowArray.price) > 0) {
                        rowArray.price =
                          Number(rowArray.price) -
                          (Number(rowArray.price) * Number(userGroupDiscount)) /
                          100
                        if (rowArray.price < 0) {
                          rowArray.price = 0
                        }
                      }

                      if (discountType === '1' && showSell && Number(rowArray.price) > 0) {
                        if (offerPrice > 0) {
                          rowArray.price =
                            Number(rowArray.price) - Number(offerPrice)
                          if (rowArray.price < 0) {
                            rowArray.price = 0
                          }
                        }
                      } else if (discountType === '0' && showSell && Number(rowArray.price) > 0) {
                        if (offerPrice > 0) {
                          rowArray.price =
                            Number(rowArray.price) -
                            (Number(rowArray.price) * Number(offerPrice)) / 100
                          if (rowArray.price < 0) {
                            rowArray.price = 0
                          }
                        }
                      }

                      rowArray.totalPrice = rowArray.price * rowArray.quantity
                      rowArray.totalCost = rowArray.cost * rowArray.quantity
                    }
                  })
                }
              })
            }
            return rowArray
          })
        } else {
          if (rowGroupArray[0]) {
            if (rowGroupArray[0].size === '') {
              rowGroupArray[0].size = rowSize
              rowGroupArray[0].quantity = callFrm === 1 ? e : e.target.value
              if(!product.showColorProd && colors_final.length === 1) {
                rowGroupArray[0].color = colors_final[0];
              }
              let tempGroupArray = { ...rowGroupArray[0] }
              priceTable.map((priceTableRow) => {
                if (priceTableRow.size === tempGroupArray.size) {
                  let priceOptionsArray = priceTableRow.priceOptions
                  priceOptionsArray.map((priceOption) => {
                    if (
                      priceOption.ColorArray.Color.colorName ==
                      tempGroupArray.color
                    ) {
                      if (!tempGroupArray.product) {
                        tempGroupArray['product'] = {}
                      }
                      tempGroupArray.product.sku = priceOption.sku
                      if (
                        priceOption.partPrice.PartPriceArray.PartPrice.length >0
                      ) {
                        priceOption.partPrice.PartPriceArray.PartPrice.map(
                          (p, pKey) => {
                            tempGroupArray.price = p.salePrice;
                            tempGroupArray.cost = p.price;
                          },
                        )
                      }
                      tempGroupArray.totalPrice =
                        tempGroupArray.price * tempGroupArray.quantity
                    }
                  })
                }
              })
              rowGroupArray[0] = tempGroupArray
            } else {
              let newRow = {
                id: uniqIdcounter++,
                size: rowSize,
                quantity: callFrm === 1 ? e : e.target.value,
                image: rowGroupArray[0].image,
                color: rowGroupArray[0].color,
                decorations: rowGroupArray[0].decorations,
                product: { sku: 0 },
                price: 0,
                totalPrice: 0,
                cost: 0,
                totalCost: 0,
                weight: rowGroupArray[0].weight,
                shipFrom: {
                  countryFrom: rowGroupArray[0].shipFromCountry,
                  zipCodeFrom: rowGroupArray[0].shipFromPostalCode,
                },
              }
              priceTable.map((priceTableRow) => {
                if (priceTableRow.size === newRow.size) {
                  let priceOptionsArray = priceTableRow.priceOptions
                  priceOptionsArray.map((priceOption) => {
                    if (
                      priceOption.ColorArray.Color.colorName === newRow.color
                    ) {
                      newRow.product.sku = priceOption.sku
                      if (
                        priceOption.partPrice.PartPriceArray.PartPrice.length > 0
                      )  {
                        /*let priceRow  = priceOption.partPrice.PartPriceArray.PartPrice[0]
                        const minQuantityRow = priceOption.partPrice.PartPriceArray.PartPrice.filter(x =>
                            Number(x.minQuantity) <= Number(newRow.quantity))
                        priceRow = minQuantityRow.length > 0 ?
                            minQuantityRow.reduce((prev, current) =>
                                (Number(prev?.salePrice) < Number(current.salePrice)) ? prev : current
                            ): priceRow */
                        priceOption.partPrice.PartPriceArray.PartPrice.map(
                          (partPrice, k) => {
                            if (
                              newRow.quantity !== '' &&
                              Number(newRow.quantity) > 0 &&
                              Number(newRow.quantity) >= Number(partPrice.minQuantity)
                            ) {
                              newRow.price = partPrice.salePrice
                              newRow.cost = partPrice.price
                            } else if (k === 0) {
                              newRow.price = partPrice.salePrice
                              newRow.cost = partPrice.price
                            }
                          },
                        )
                      }
                      newRow.totalPrice = newRow.price * newRow.quantity
                      newRow.totalCost = newRow.cost * newRow.quantity
                    }
                  })
                }
              })
              rowGroupArray.push(newRow)
            }
          }
        }
      }
      return rowGroupArray
    })
    if (isPriceBreak) {
      let totalSum = 0;
      tempRows.map((object, index) => {
        object.map((sizeObj, index) => {
          totalSum = totalSum + Number(sizeObj.quantity)
        })
      })
      if (totalSum > 0) {
        // let priceArray = []
        let obj1;
        tempRows.map((catItem, index) => {
          let productPartArray = product?.WebstoreProductPartArray && product?.WebstoreProductPartArray.length > 0 ? product?.WebstoreProductPartArray : product?.ProductPartArray?.ProductPart
          catItem.map((obj, index) => {
            obj1 = productPartArray.find((productPart) => productPart?.ColorArray?.Color?.colorName === obj.color && productPart?.ApparelSize?.labelSize === obj.size)
            let rPrice = 0
            let rCost = 0
            if(obj1?.ApparelSize?.labelSize === obj?.size && obj?.color === obj1?.ColorArray?.Color?.colorName) {
              obj1.partPrice.PartPriceArray.PartPrice.map(
                (partPrice, k) => {
                  if (
                      Number(partPrice.minQuantity) <= Number(totalSum)
                  ) {
                    rPrice = partPrice.salePrice
                    rCost = partPrice.price
                  } else if (k === 0) {
                    rPrice = partPrice.salePrice
                    rCost = partPrice.price
                  }
                },
              )
              obj.price = rPrice
              obj.cost = rCost
            }
          })
          //priceArray.push(obj1)
        })
        /*let minQuantity = []
        priceArray.map((price) => {
          let min = price?.partPrice?.PartPriceArray?.PartPrice.filter(
            (val) => Number(val.minQuantity) <= Number(totalSum)
          )
          if (min && min.length > 0) {
            minQuantity.push(min)
          }
        })
        let allMaxQty = [];
        minQuantity.length > 0 && minQuantity.map((qtyMin) => {
          let maxQty = qtyMin.length > 0 && qtyMin.reduce((prev, current) => (Number(prev?.minQuantity) > Number(current.minQuantity)) ? prev : current)
          allMaxQty.push(maxQty)
        })
        let salePrice = allMaxQty.length > 0 && allMaxQty.reduce((prev, current) => (Number(prev?.salePrice) > Number(current.salePrice)) ? prev : current)
        tempRows.map((cartItem, index) => {
          cartItem.map((order, index) => {
            order.price = (salePrice?.salePrice && Number(salePrice?.salePrice) !== 0) ? Number(salePrice?.salePrice) : Number(order.price)
            order = order
          })
        });*/
      }
    }

    //call when change qty
    setCatalogueCustomRows(tempRows)
    if (personalizationAdded) {
      let totalQuantity = tempRows[mainArraykey]?.reduce((acc, val) => acc + Number(val.quantity), 0) ?? 0;
      if (totalQuantity > 0) {
        ToggleDivs(mainArraykey, 'P', 'changeQty')
      } else {
        ToggleDivs(mainArraykey, 'P', 'changeQtyZero')
      }
      addPersonalization(mainArraykey)
    }
  }

  const handleArtworkselection = async (
    artwork,
    parentKey,
    locationID,
    findProductArtvariationId,
    selectDeco,
    isRequiredForStore,
    supplierDeco
  ) => {

    let tempcatalogueCustomRows = await catalogueCustomRows.filter(
      async (rowGroupArray, groupkey) => {
        if (groupkey === parentKey) {
          await rowGroupArray.filter(async (rowArray) => {
            rowArray.decorations &&
            await rowArray.decorations.map(async (decoFilter) => {
              if (decoFilter.locationId === locationID) {
                decoFilter.artworkID = artwork.id
                decoFilter.isRequiredForStore = isRequiredForStore
                decoFilter.supplierDeco = supplierDeco
                decoFilter.variationUniqID = null
                if (artwork.design && artwork.design.variation) {
                  if (artwork.design.variation.length) {
                    if (artwork.design.variation.length > 0) {
                      let getSelectedVaria = artwork.design.variation.filter(
                          (val) =>
                              val.design_variation_unique_id ==
                              findProductArtvariationId
                      )
                      if (getSelectedVaria) {
                        decoFilter.variationUniqID =
                            getSelectedVaria[0].design_variation_unique_id
                      } else {
                        decoFilter.variationUniqID =
                            artwork.design.variation[0].design_variation_unique_id
                      }
                    }

                    await select_variant(
                        artwork.id,
                        parentKey,
                        findProductArtvariationId,
                        locationID,
                        selectDeco
                    )
                  }
                }
              }
              return decoFilter
            })
            return rowArray
          })
        }
        return rowGroupArray
      },
    )
    //click on handle art work
    setCatalogueCustomRows(tempcatalogueCustomRows)
  }

  const select_variant = async (
      artWorkID,
      parentKey,
      findProductArtvariationId,
      locationID,
      selectDeco
  ) => {
    ;
    let artwork_selected = null
    if (artWorkID) {
      artwork_selected =
      artworksFromStateAll.length && artworksFromStateAll.filter((f) => f.id === artWorkID)[0]

      if (artwork_selected.design && artwork_selected.design.variation) {
        if (artwork_selected.design.variation.length) {
          let artData = {}
          if (artwork_selected.design.variation.length > 0) {
            let getSelectedVaria = artwork_selected.design.variation.filter(
                (val) =>
                    val.design_variation_unique_id == findProductArtvariationId,
            )

            if (getSelectedVaria) {
              artData = getSelectedVaria[0]
            } else {
              artData = artwork_selected.design.variation[0]
            }
          }

          await handleVariationselection(
            parentKey,
            locationID,
            artData,
            artwork_selected,
            selectDeco
          )
        }
      }
    }
  }

  const handleVariationselection = async (
    parentKey,
    locationID,
    artwork,
    artwork_selected,
    selectDeco
  ) => {

    let dPrice = await getDecoPrice(artwork_selected.id)

    let decoPricedetails = []
    if (dPrice?.length > 0) {
      dPrice.map((priceArray) => {
        if (artwork_selected?.design?.designType === priceArray.decoratorType) {
          decoPricedetails.push(priceArray)
        }
      })
    }

    let tempcatalogueCustomRows = await  catalogueCustomRows.filter(
      (rowGroupArray, groupkey) => {
        if (groupkey === parentKey) {
          rowGroupArray.filter((rowArray) => {
            rowArray.decorations &&
              rowArray.decorations.map((decoFilter) => {
                if (decoFilter.locationId === locationID) {
                  if (decoPricedetails) {
                    decoFilter.decoPricedetails = decoPricedetails
                  }
                  decoFilter.variationUniqID =
                    !!artwork?.design_variation_unique_id ? artwork?.design_variation_unique_id : ''
                }
                return decoFilter
              })
            return rowArray
          })
        }
        return rowGroupArray
      },
    )
    if(selectDeco) {
      getDisplayStyle(parentKey, 'D')

      let currentProduct = catalogueCustomRows[parentKey][0]
      let productColor = catalogueCustomRows[parentKey][0].color
      let decoData = currentProduct?.decorations?.filter(
          (val) => val.artworkID === artwork_selected.id,
      )

      let decoLocation = decoData?.[0]?.location
      let getIndex = product.LogoInfo.filter(
          (val) =>
              val.color === productColor &&
              val.location === decoLocation,
      )

      if (getIndex.length > 0) {
        animateScroll.scrollToTop()
        setSelectedImage(getIndex[0]?.image)
        setIsSelectedImageChng(IsSelectedImageChng+1)
      } else {

        let getIndexOfExtra_logo = []
        product.LogoInfo.map(val => {
          if (val.color === productColor) {

            if (val.extra_logo.filter(e => e.location === decoLocation).length > 0) {
              getIndexOfExtra_logo.push(val)
            }
          }
        })
        if (getIndexOfExtra_logo.length) {

          animateScroll.scrollToTop()
          setSelectedImage(getIndexOfExtra_logo[0]?.image)
          setIsSelectedImageChng(IsSelectedImageChng+1)
        } else {
          let getImgColorIndex = product.LogoInfo.filter(
              (val) =>
                  val.color === productColor
          )

          if (getImgColorIndex.length > 0) {
            animateScroll.scrollToTop()
            setSelectedImage(getImgColorIndex[0]?.image)
            setIsSelectedImageChng(IsSelectedImageChng+1)
          }
        }
      }
      //art variation
      setCatalogueCustomRows(tempcatalogueCustomRows)
    }

  }
  const changeColor = async (e, quoteItemRow, mainKey, selectedImage = '') => {
    ToggleDivs(mainKey, 'D', 'onChangeColor')
    setSelectedLocationIndex(mainKey)
    setSelectedImage('')
    let color =
        e && e.target && e.target.value && e.target.value ? e.target.value : e

    setTempSelectedColor(color)
    setSelectedProductColor(color);
    let getImage = product?.WebstoreProductPartArray.filter((val)=>val.color == color && val.isSwapImage === true,)


    let tempRows = catalogueCustomRows.filter((rowGroupArray, groupkey)=>{
      if ( groupkey === mainKey ) {
        rowGroupArray.filter((rowArray)=>{
          rowArray.color = color
          if ( product.MediaContent?.length > 0 ) {
            let flag = true
            let defaultImages = product.MediaContent.filter((val)=>val.color === rowArray.color && !val?.hidden)
            if(defaultImages?.length) {
              const index = defaultImages.findIndex(x => x.url === selectedImage)
              rowArray.image = rowArray.image = (index >= 0 && defaultImages[index]) ? defaultImages[index].url : defaultImages[0].url
            }
          }
          return rowArray
        })
      }
      return rowGroupArray
    })
    tempRows[mainKey][0]['decorations'] = []
    if ( getImage.length === 0 ) {
      if ( getSettingStatus('showDecoration') || !getSettingStatus('showDecoration')) {
        setIsAdded(false)
        let findProductArtvariationId = ''
        let locationId = ''
        if ( artworksFromStateAll && artworksFromStateAll.length ) {
          const numOfArtwork = artworksFromStateAll.reduce((acc, val) => acc +
              (val.productToDecorationMapping || []).filter(x => x.isRequiredForStore === '1' && x.mapping.filter(m => m.color === color)).length, 0
          )
          let  keyMap = 0
          artworksFromStateAll.map((val, key)=>{
            (val?.productToDecorationMapping || []).forEach((decoMap)=>{
              if ( decoMap.isRequiredForStore === '1' ) {
                locationId = decoMap.location
                decoMap.mapping.map(async (mapVal)=>{
                  if ( mapVal.color === color ) {
                    handleCheckbox(locationId, mainKey, '','', val?.id)
                    findProductArtvariationId = mapVal.variationId
                    let isRequiredForStore = decoMap.isRequiredForStore
                    let supplierDeco = decoMap.supplierDeco
                    await handleArtworkselection(
                        val,
                        mainKey,
                        locationId,
                        findProductArtvariationId,
                        keyMap++ ===  (numOfArtwork - 1),
                        isRequiredForStore,
                        supplierDeco
                    )
                  }
                })
              } else {
                setShowData('yes')
              }
            })
          })
        }
      }
      setIsReplace(true)
    } else {
      setIsReplace(false)
    }

    //change color
    let Quantities = document.getElementsByName('quantity');
    if ( !!Quantities ) {
      if ( !!Quantities.length && Quantities.length > 0 ) {
        Quantities.forEach((element, key)=>{
          if ( element.value !== '' ) {
            element.focus()
          }
        });
      }
    }

    setCatalogueCustomRows(tempRows)
    setTempValue(tempValue + 1)
    if (personalizationAdded) {
      let totalQuantity = catalogueCustomRows[mainKey]?.reduce((acc, val) => acc + Number(val.quantity), 0) ?? 0;
      if (totalQuantity > 0) {
        ToggleDivs(mainKey, 'P', 'onChangeColor')
      }
			addPersonalization(mainKey)
		}
  }

  const changeSize = (e, quoteItemRow, mainKey) => {
    let tempRows = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      if (groupkey === mainKey) {
        rowGroupArray.filter((rowArray) => {
          rowArray.size = e.target.value
          if (product.MediaContent?.length > 0) {
            let flag = true
            product.MediaContent.map((mediaArray) => {
              if (mediaArray.color === rowArray.color && flag) {
                rowArray.image = mediaArray.url
                flag = false
              }
            })
          }
          return rowArray
        })
      }
      return rowGroupArray
    })

    //change size
    setCatalogueCustomRows(tempRows)
  }
  const deleteRow = (rowkey) => {
    let tempRows = catalogueCustomRows.filter(
      (rowGroup, rowGroupkey) => rowkey !== rowGroupkey,
    )

    //delete row

    setCatalogueCustomRows(tempRows)
    updatestate(!statchange)
  }
  const ToggleDivs = (RowID, Type, extraParm = '') => {
    let temptoggleDivsArray = toggleDivsArray
    if (!temptoggleDivsArray[RowID]) {
      temptoggleDivsArray[RowID] = []
    }
    if (temptoggleDivsArray[RowID]) {
      let rowCurrentState = temptoggleDivsArray[RowID]
      if (Type === 'D') {
        rowCurrentState.attributes = false
        rowCurrentState.personalization = false

        if (rowCurrentState.decorations && (extraParm !== 'onChangeColor' && extraParm !== 'clickOnArtWork')) {

          //rowCurrentState.decorations = false
        } else {
          setShowData('yes')
          // rowCurrentState.decorations = true
          setNotCallDecoHtml(false)
        }
        temptoggleDivsArray[RowID] = rowCurrentState
      }
      rowCurrentState.decorations = true
      if (Type === 'A') {
        rowCurrentState.decorations = false
        rowCurrentState.personalization = false
        if (rowCurrentState.attributes) {
          rowCurrentState.attributes = false
        } else {
          rowCurrentState.attributes = true
        }
        temptoggleDivsArray[RowID] = rowCurrentState
      }
      if (Type === 'P') {
        if (extraParm !== 'changeQtyZero') {
          rowCurrentState.decorations = false
          rowCurrentState.attributes = false
        }
        if (rowCurrentState.personalization) {
         if(extraParm !== 'onChangeColor' && extraParm !== 'onCopyRow' && extraParm !== 'changeQty') {
           rowCurrentState.personalization = false
         }
         else {
           rowCurrentState.personalization = true
         }
        } else {
          rowCurrentState.personalization = true
        }
        if(extraParm === 'DeleteRow' || extraParm === 'changeQtyZero') {
          rowCurrentState.personalization = false
        }
        if(extraParm === 'ForOnCopyRow') {
          rowCurrentState.personalization = true
        }
        temptoggleDivsArray[RowID] = rowCurrentState
      }
    }

    setToggleDivs(temptoggleDivsArray)
    setToggleValue(!toggleValue)
  }
  const getDisplayStyle = (key, Type) => {
    if (toggleDivsArray[key]) {
      if (Type === 'D') {
        if (toggleDivsArray[key].decorations) {
          return 'block'
        } else {
          return 'none'
        }
      }
      if (Type === 'A') {
        if (toggleDivsArray[key].attributes) {
          return 'block'
        } else {
          return 'none'
        }
      }
      if (Type === 'P') {
        if (toggleDivsArray[key].personalization && personalizationAdded) {
          return 'block'
        } else {
          return 'none'
        }
      }
      return 'none'
    } else {
      return 'none'
    }
  }
  const copyRow = (rowkey) => {
    let findRow = null
    let tempRows = [...catalogueCustomRows]
    let Main = []
    tempRows.map((rowGroup, rowGroupkey) => {
      Main.push(JSON.parse(JSON.stringify(rowGroup)))
      if (rowGroupkey === rowkey && !findRow) {
        if (rowGroup) {
          let tempObj = []
          rowGroup.map(obj => {
            obj.id = uniqIdcounter++;
            if(!!obj?.personalization)
            {
              obj.personalization.map(perArra => {
                perArra.rowId = obj.id
                return perArra
              })
            }
            obj.extraParam = 'onCopyRow'; tempObj.push(obj) })
            Main.push(JSON.parse(JSON.stringify(tempObj)))
        }
      }
    })

    // copy row
    if (personalizationAdded) {
      let maincnt = Number(Main.length - 1)
      ToggleDivs(maincnt, 'P', 'onCopyRow')
      addPersonalization(maincnt, 'onCopyRow')
    }

    setCatalogueCustomRows(Main)
    updatestate(!statchange)

    // if (personalizationAdded) {
    //   let maincnt = Number(tempRows.length - 1)
    //   ToggleDivs(maincnt, 'P', 'onCopyRow')
    //   addPersonalization(maincnt, 'onCopyRowLast')
    // }
    // updatestate(!statchange)
  }
  const getDecoPricebyqty = (decorationsArray, qty) => {
    let decosalePrice = 0
    decorationsArray &&
      decorationsArray.map((decoitemArray, index) => {
        if (decoitemArray?.decoPricedetails?.length > 0) {
          let artworkselected = artworksFromStateAll.filter(
            (artorkArray) => artorkArray.id === decoitemArray.artworkID,
          )[0]
          let priceq = 0
          decoitemArray.decoPricedetails.map((decoPriceeArray) => {
            let sCount = artworkselected.design.stitchCount

            if (sCount === '' || !Number(sCount)) {
              sCount = 0
            }

            if (
              Number(sCount) >= Number(decoPriceeArray.stitchesStart) &&
              Number(sCount) <= Number(decoPriceeArray.stitchesUpto)
            ) {
              if (
                Number(qty !== '' ? qty : 1) >=
                Number(decoPriceeArray.qunatityStart) &&
                Number(qty !== '' ? qty : 1) <=
                Number(decoPriceeArray.quantityUpto)
              ) {
                priceq = Number(decoPriceeArray.salePrice)
              }
            }
          })

          decosalePrice = decosalePrice + priceq
        }
      })
    return Number(decosalePrice)
  }
  const getAdditionalDecorationCharge = (artwork, quantity, location, productId) => {
    let totalAdditionalCharges = 0
    const additionalCharges = []
    if(artwork.productToDecorationMapping && artwork.productToDecorationMapping?.length) {
      const productToDecorationMapping = artwork.productToDecorationMapping
      productToDecorationMapping.filter(x => x.locationName === location).forEach(productMap => {
        productMap.charges.forEach(charge => {
          if(additionalCharges.findIndex(x => x.chargeCode === charge.chargeCode) === -1) {
            let price = 0
            let cost = 0
            charge.ChargePriceArray.ChargePrice.forEach(x => {
              if(x.xMinQty <= quantity) {
                price = x.salePrice
                cost = x.price
              }
            })
            const chargePrice = charge.matchOrderQty === "1" ? Number(price) * Number(quantity) :  Number(price)
            const chargeCost = charge.matchOrderQty === "1" ? Number(cost) * Number(quantity) :  Number(cost)
            additionalCharges.push({
              totalSetupCharge: chargePrice,
              totalCostSetupCharge: chargeCost,
              setupChargeName: charge.chargeName,
              chargeCode: charge.chargeCode,
              chargeId: charge.chargeId
            })
            totalAdditionalCharges =
                Number(totalAdditionalCharges) + chargePrice
          }
        })
      })
    }
    return {
      totalAdditionalCharges,
      additionalCharges
    }
  }
  const getDecoChargeArraybyqty = (decorationsArray, qty) => {
    let decosalePrice = []
    decorationsArray &&
      decorationsArray.map((decoitemArray, index) => {
        if (decoitemArray?.decoPricedetails?.length > 0) {
          let artworkselected = artworksFromStateAll.filter(
            (artorkArray) => artorkArray.id === decoitemArray.artworkID,
          )[0]
          let priceq = 0

          decoitemArray.decoPricedetails.map((decoPriceeArray) => {
            let sCount = artworkselected.design.stitchCount
            if (sCount === '' || !Number(sCount)) {
              sCount = 0
            }
            if (
              Number(sCount) >= Number(decoPriceeArray.stitchesStart) &&
              Number(sCount) <= Number(decoPriceeArray.stitchesUpto)
          ) {
            if (
                Number(qty !== '' ? qty : 1) >=
                Number(decoPriceeArray.qunatityStart) &&
                Number(qty !== '' ? qty : 1) <=
                Number(decoPriceeArray.quantityUpto)
            ) {
                priceq = decoPriceeArray
              }
            }
          })
          decosalePrice.push(priceq)
        }
      })
    return decosalePrice
  }
  const checkTaotalQty = () => {
    let totalSumqw = 0
    catalogueCustomRows.map((itemsRowsabs, keya) => { itemsRowsabs && itemsRowsabs.length > 0 && itemsRowsabs.map((sizeObj, index) => {
        totalSumqw = totalSumqw + Number(sizeObj?.quantity && sizeObj?.quantity)
      })
    })
    return totalSumqw;
  }

  const displayDrop = (parentKeyId) => {
    setIsDrpDeco(true)
  }

  let toatlQty = 0
  let toatlPrice = 0
  let totalStock = 0

	const getRowHTML = (itemsRows, key) => {
		let itemRowArray = itemsRows[0]
		let rowTotalQty = '0'
		let rowTotal = 0
		let minIsPriceBreak;
		let totalSum=0
        const selectedCarouselImage = document.querySelector('#sw1')?.querySelector('.swiper-slide-active')?.querySelector('img').src
		let getImage = product?.WebstoreProductPartArray.filter((val) => val.color === itemRowArray.color && val.isSwapImage === true,)
        const artWorkCount = document.querySelectorAll(`[findparent=parent${key}]`)
		let artwork_selected = null
        let imagesToShow = []

      if ( itemRowArray?.decorations?.[0]?.artworkID && artworksFromStateAll?.length ) {
        artwork_selected = artworksFromStateAll.filter(
            (artorkArray)=>
                artorkArray.id === itemRowArray.decorations[0].artworkID,
        )[0]
      }

      if ( itemRowArray?.decorations?.length >= 1 ) {
        let decoIndex = itemRowArray?.decorations?.length - 1
        let getColorIndex = product.LogoInfo.filter(
            (val)=>val.color === tempSelectedColor
        )

        let imagesFromMediaContent = []
        if ( getColorIndex.length > 1 ) {
          imagesFromMediaContent = product.LogoInfo.filter(
              (val)=>
                  val.color === tempSelectedColor && !val?.primary &&
                  (val.location === itemRowArray.decorations[decoIndex].location || val.extra_logo.filter(e => e.location === itemRowArray.decorations[decoIndex].location)),
          )
        } else {
          imagesFromMediaContent = getColorIndex
        }

        if ( imagesFromMediaContent.length > 0 ) {
          if ( selectedImage === '' ) {
            imagesToShow = imagesFromMediaContent
          }
        }
      } else {
        if ( getImage.length > 0 ) {
          if ( selectedImage === '' ) {
            imagesToShow = getImage
          }
        } else {
          let getIndex = product.MediaContent.filter((val)=>val.color === itemRowArray.color && !val?.hidden,
          )
          if ( getIndex.length ) {
            if ( selectedImage === '' ) {
              imagesToShow = getIndex
            }
          } else {
            if ( selectedImage === '' ) {
              imagesToShow = product.MediaContent.filter(
                  (val)=>val.color === itemRowArray.color && !val?.hidden,
              )
            }
          }
        }
      }
      if(imagesToShow.length) {
        imagesToShow = imagesToShow.map(x => {
          return {
            ...x ,
            url: x.url || x.image
          }
        })
        const checkedIndex = imagesToShow.findIndex(x => x.url === selectedCarouselImage || x.image === selectedCarouselImage)
        setSelectedImage( imagesToShow[checkedIndex >= 0 ? checkedIndex : 0].url)
        setIsSelectedImageChng(IsSelectedImageChng + 1)
      }

      let rowVariation = null
      if ( artwork_selected?.design?.variation?.length > 0 ) {
        rowVariation = artwork_selected.design.variation[0]
      }

      let addDecoPriceFlag = true
      let totalSetupDecoCharges = 0
      let totalDecoCharges = 0
      let totalSetupCharge = 0
      let setupChargeName = 'Setup Charge'
      let setupCharges = [];
      if ( isPriceBreak ) {
        itemsRows && itemsRows.length > 0 && itemsRows.map((sizeObj, index)=>{
          totalSum = totalSum + Number(sizeObj?.quantity && sizeObj?.quantity)
        })
      }
    if(showSetupChargeInCart && catalogueCustomRows?.[0]?.[0]?.quantity) {
      let quantity = 0;
      if(catalogueCustomRows?.[0]?.length > 0) {
        catalogueCustomRows[0].forEach(row => {
          quantity += Number(row.quantity)
        })
      }
      if(product?.ChargeArray?.Charge?.length > 0) {
        product.ChargeArray.Charge.forEach(charge => {
          if(setupCharges.findIndex(x => x.chargeCode === charge.chargeCode) === -1) {
            const chargeByQuantity = charge?.ChargePriceArray?.ChargePrice?.filter(x => Number(x.xMinQty) <= quantity)
            if(chargeByQuantity.length > 0) {
              const totalSetupChargeUnit = (chargeByQuantity?.[chargeByQuantity.length - 1]?.salePrice ?? 0) * (charge?.matchOrderQty === "1" ? quantity : 1);
              if (totalSetupChargeUnit > 0) {
                setupChargeName = charge?.chargeName ?? 'Setup Charge';
                totalSetupCharge = Number(totalSetupCharge) + totalSetupChargeUnit
                toatlPrice = Number(toatlPrice) + Number(totalSetupCharge)
                setupCharges.push({ totalSetupCharge: totalSetupChargeUnit, setupChargeName, chargeCode: charge.chargeCode })
              }
            }
          }
        })
      }
    }
    if(getSettingStatus('showDecoration')) {
      itemRowArray.decorations &&
      itemRowArray.decorations.filter((de)=>de?.artworkID && de?.location && de?.locationId).map((decoArray, index)=>{
        let setupChargeSalePrice = 0;
        let salePrice = 0;
        if (isPriceBreak) {
          let decoChargeArray = getDecoChargeArraybyqty([decoArray], totalSum);
          setupChargeSalePrice = Number(decoChargeArray?.[0]?.setupChargeSalePrice || 0);
          salePrice = Number(decoChargeArray?.[0]?.salePrice || 0) * totalSum;
        } else {
          itemsRows.forEach((res, key) => {
            const dtmainArrr = getDecoChargeArraybyqty([decoArray], res.quantity);
            if (key === 0) {
              setupChargeSalePrice = Number(dtmainArrr?.[0]?.setupChargeSalePrice || 0);
            }
            salePrice += Number(dtmainArrr?.[0]?.salePrice || 0) * res.quantity;
          });
        }

        let artwork = null
        if ( artworksFromStateAll?.length ) {
          artwork = artworksFromStateAll.find(
              (artworkArray)=>(artworkArray.id === decoArray.artworkID && artworkArray.artworkLocation === decoArray.location),
          )
          if (!!!artwork && artworksProductState?.length) {
            artwork = artworksProductState.find((artworkArray) =>
              artworkArray?.productToDecorationMapping?.some(
                (res) => res.locationName === decoArray.location && artworkArray.id === decoArray.artworkID
              )
            );
          }
        }
        let itemsQty = 0
        itemsRows && itemsRows.length > 0 && itemsRows.map((sizeObj, index)=>{
          itemsQty = itemsQty + Number(sizeObj?.quantity ? sizeObj?.quantity : 0)
        })

        const additionalCharges = []
        let totalAdditionalCharges = 0
        if(artwork) {
          let totalItem = 0
            itemsRows && itemsRows.length > 0 && itemsRows.map((sizeObj, index)=>{
              totalItem = totalItem + Number(sizeObj?.quantity && sizeObj?.quantity)
            })
            const additionalChargeData = getAdditionalDecorationCharge(
              artwork || {},
              totalItem,
              decoArray.location,
              product?.id
            );
            totalAdditionalCharges += additionalChargeData.totalAdditionalCharges;
            additionalCharges.push(...additionalChargeData.additionalCharges);
        }

        if ( artwork?.artworkSource == 'aether' && ((getSettingStatus('showDecoration') && customDecorationFee && allowCustomDecoration) || (showSetupChargeInCart && !getSettingStatus('showDecoration'))) ) {
          // if (getSettingStatus('showDecoration') && customDecorationFee && product?.allowCustomDecoration) {
          if ( showSetupChargeInCart ) {
            toatlPrice =
                Number(toatlPrice) +
                Number(customDecorationSetupFee)
          }
          totalSetupDecoCharges =
              totalSetupDecoCharges +
              Number(customDecorationSetupFee)
          totalDecoCharges =
              totalDecoCharges + (Number(customDecorationPerUnitPrice) * itemsQty)

          //if (priceTable.length && priceTable.length == 1) {
          if ( (!!showDecorationCostInCart) ) {
            toatlPrice = (Number(toatlPrice) + (Number(customDecorationPerUnitPrice) * Number(itemsQty)))
          }
          //}
        } else {
          if ( showSetupChargeInCart ) {
            additionalCharges.forEach(additionalCharge => {
              if (additionalCharge?.totalSetupCharge) {
                setupCharges.push(additionalCharge)
              }
            })
            totalSetupCharge = totalSetupCharge + totalAdditionalCharges
            toatlPrice =
                Number(toatlPrice) +
                Number(setupChargeSalePrice) +
                Number(totalAdditionalCharges)
          }

          totalSetupDecoCharges =
              Number(totalSetupDecoCharges) +
              Number(setupChargeSalePrice)
          totalDecoCharges = Number(totalDecoCharges) + Number(salePrice)

          //if (priceTable.length && priceTable.length == 1) {
          if ( (!!showDecorationCostInCart) ) {
            toatlPrice = Number(toatlPrice) + salePrice
          }
          //}
        }
      })
    }
    let mainCls = 'product-img col-lg pt-2'
    if(imageOnPageLoad && itemRowArray.image && ((getImage && getImage.length > 0 && !!getImage[0]?.image && productImageExt(getImage[0]?.image)) || productImageExt(itemRowArray.image)))
    {
      mainCls = 'product-img col-lg pt-2 product-img-bg'
    }
    if(imageOnPageLoad && !itemRowArray.image && ((getImage && getImage.length > 0 && productImageExt(getImage[0].image)) || productImageExt(primaryImage?.url)))
    {
      mainCls = 'product-img col-lg pt-2 product-img-bg'
    }
		return (
			<div className="col-md-12 lineitemsrow mb-15 pr-3" key={key}>
				<div className="row pt-2 pl-2 m-0">
					{imageOnPageLoad !== '' && (
						<div
							className={mainCls}
							onClick={() => showLogoblock(itemRowArray)}
						>
							{itemRowArray.image && (
								<img
									className="default-img1"
									src={getImage && getImage.length>0?productImageUrl(getImage[0].image):productImageUrl(itemRowArray.image)}
									style={{ maxHeight: '100px', maxWidth: '100px' }}
								/>
							)}
							{!itemRowArray.image && (
								<img
									className="default-img1"
									style={{ maxHeight: '70px', maxWidth: '70px' }}
									src={getImage && getImage.length > 0 ? productImageUrl(getImage[0].image) : productImageUrl(primaryImage?.url)}
									alt="No Image"
								/>
							)}
						</div>
					)}

					<div className={'col-md-9 col-sm-6 pt-1 pl-1 lineflex'}>
						<div className="col-md-4 colorBox">
							{isProductInventoryEnabled && isColorValid(itemRowArray?.color) &&
								!isSmall &&
								!isMedium && (
									<label className="inventoryLabelportal">
										{inventorySettings?.['inventoryLabel']
											? inventorySettings['inventoryLabel']
											: 'Available'}
									</label>
								)}

							{(showColor) && (
								<>
									<b>{colorLabel}:</b>{' '}
									{colorselectbox(itemRowArray, itemRowArray.color, key)}
								</>
							)}
							<div
								className="col-md-12 col-sm-6 pt-1 pl-1 pr-1"
								style={{ display: 'none' }}
							>
								<div className="row decporow p-1 decoBlockContainer"></div>
							</div>
						</div>
						<div className="col-lg-8 pt-2">
							{isProductInventoryEnabled && isColorValid(itemRowArray?.color)  &&
								(isSmall || isMedium) && (
									<label className="inventoryLabelportalother">
										{inventorySettings?.['inventoryLabel']
											? inventorySettings['inventoryLabel']
											: 'Available'}
									</label>
								)}
							<div className="row sizediv" style={{overflowX: !isProductInventoryEnabled && priceTable.length === 1 && showSize && priceTable?.[0]?.size == 'OSFA' ? 'unset' :  'auto'}}>
								{priceTable.map((pricerow, keyP) => {
                                  let rQty = ''
                                  let rPrice = '0'
                                  let discountPrice = 0;
									let isActive = true
									let selRowmain = itemsRows.filter(
										(row) => row.size === pricerow.size,
									)
									let selpriceRowmain = itemsRows.slice(-1)

									if (pricerow?.priceOptions?.length) {
										pricerow.priceOptions.map((priceoption) => {
											// if color is present and showCOlor toggle btn hide from admin side
											let defaultColor = (showColor === false ? (pricerow?.priceOptions[0]?.ColorArray?.Color?.colorName) : itemRowArray.color);
                                            if(!itemRowArray.color || itemRowArray.color === `Select ${colorLabel}`) {
                                              defaultColor = pricerow?.priceOptions[0]?.ColorArray?.Color?.colorName;
                                            }
											if (
												checkPriceOptionsArray(
													priceoption,
													defaultColor,
													pricerow.size,
												)
											) {
												if (
													priceoption.active ||
													priceoption.active === false ||
													priceoption.active === 'false'
												) {
													if (
														priceoption.active === false ||
														priceoption.active === 'false'
													) {
														isActive = false
													}
												}

												if (selRowmain?.length > 0) {
													rQty = selRowmain[0].quantity
													rowTotalQty = (
														Number(rowTotalQty) + Number(selRowmain[0].quantity)
													).toString()
												}
                                                toatlQty = Number(toatlQty) + Number(rQty)
                                              if (isPriceBreak) {
                                                priceoption.partPrice.PartPriceArray.PartPrice.map(
                                                    (partPrice, k) => {
                                                      if (
                                                          /*rQty !== '' &&
                                                          Number(rQty) > 0 &&
                                                          Number(partPrice.minQuantity) <= Number(toatlQty)*/
                                                          Number(partPrice.minQuantity) <= checkTaotalQty()
                                                      ) {
                                                        rPrice = partPrice.salePrice
                                                        minIsPriceBreak = partPrice.minQuantity
                                                      } else if ( k === 0 ) {
                                                        minIsPriceBreak = partPrice.minQuantity
                                                        rPrice = partPrice.salePrice

                                                      }
                                                    },
                                                )

                                              } else {
                                                priceoption.partPrice.PartPriceArray.PartPrice.map(
                                                    (partPrice, k)=>{
                                                      if (
                                                          rQty !== '' &&
                                                          Number(rQty) > 0 &&
                                                          Number(rQty) >= Number(partPrice.minQuantity)
                                                      ) {
                                                        rPrice = partPrice.salePrice
                                                      } else if ( k === 0 ) {
                                                        rPrice = partPrice.salePrice
                                                      }
                                                    },
                                                )
                                              }
                                              let decosalePrice = 0;
                                              if (addDecoPriceFlag && (Number(rQty) > 0 || (isPriceBreak && Number(totalSum) > 0))) {
                                                //addDecoPriceFlag = false;
                                                if ( artwork_selected?.artworkSource == 'aether' && getSettingStatus("showDecoration") && customDecorationFee && allowCustomDecoration ) {
                                                  itemRowArray && itemRowArray.decorations && itemRowArray.decorations.map((a)=>{
                                                    decosalePrice = decosalePrice + Number(customDecorationPerUnitPrice);
                                                  });
                                                } else {
                                                  decosalePrice = getDecoPricebyqty(
                                                      itemRowArray?.decorations,
                                                      isPriceBreak ? totalSum : rQty,
                                                  )
                                                }
                                              } else if ( addDecoPriceFlag && Number(rQty) === 0 ) {
                                                if ( artwork_selected?.artworkSource == 'aether' && getSettingStatus("showDecoration") && customDecorationFee && allowCustomDecoration ) {
                                                  itemRowArray && itemRowArray.decorations && itemRowArray.decorations.map((a)=>{
                                                    decosalePrice = decosalePrice + Number(customDecorationPerUnitPrice);
                                                  });
                                                } else {
                                                  decosalePrice = getDecoPricebyqty(
                                                      itemRowArray?.decorations,
                                                      1,
                                                  )
                                                }
                                              }
                                              let mainDecorationCost = 0
                                              let mainPersonalizationCost = 0
                                              let mainDecoCost = 0
                                              if ( !showDecorationCostInCart && getSettingStatus('showDecoration')) {
                                                rPrice = Number(rPrice) + Number(decosalePrice)
                                                if (product?.doNotCalculateAtOrder == "1" && isRestrictQty) {
                                                  mainDecorationCost = Number(decosalePrice) * Number(rQty)
                                                  mainDecoCost =  Number(decosalePrice)
                                                }
                                              }

                                              if (Number(userGroupDiscount > 0) && Number(rPrice) > 0) {
                                                  rPrice = Number(rPrice) -  ((Number(rPrice) * Number(userGroupDiscount)) / 100)
                                              }
                                              let personalizationCost2=0
                                              if ( getSettingStatus('showPersonalization') && !getSettingStatus('showPersonalizationCostInCart') && !!itemRowArray.personalization && !!personalizationCost?.price ) {
                                                personalizationCost2 = (rowTotalQty > 0 ? Number(personalizationCost.price).toFixed(decimalsToShow) : 0)
                                                rPrice = Number(rPrice) + Number(personalizationCost2)
                                                if (personalizationCost.matchOrderQty == '1' && !getSettingStatus('showPersonalizationCostInCart') && product?.doNotCalculateAtOrder == "1" && isRestrictQty) {
                                                  mainPersonalizationCost = Number(personalizationCost2) * Number(rQty)
                                                }
                                              }

                                              rWithoutDiscPrice = rPrice
                                              if ( discountType === '1' && showSell && Number(rPrice) > 0) {
                                                if ( offerPrice > 0 ) {
                                                  discountPrice = Number(offerPrice)
                                                  rPrice = Math.max(0, Number(rPrice) - Number(offerPrice)); // if less than zero, then assign zero
                                                  if(!showSellPrice) {
                                                    rWithoutDiscPrice = Math.max(0, Number(rWithoutDiscPrice) - Number(offerPrice));
                                                  }
                                                }
                                              } else if ( discountType === '0' && showSell && Number(rPrice) > 0 ) {
                                                if ( offerPrice > 0 ) {
                                                  discountPrice = Number((Number(rPrice) * Number(offerPrice)) / 100)
                                                  rPrice =
                                                      Number(rPrice) -
                                                      (Number(rPrice) * Number(offerPrice)) / 100
                                                  if(!showSellPrice) {
                                                    rWithoutDiscPrice = Math.max(0, Number(rWithoutDiscPrice) - (Number(rWithoutDiscPrice) * Number(offerPrice) / 100));
                                                  }
                                                }
                                              }
                                              let calPriceIsPrice = Number(rPrice) * Number(rQty)
                                              if (product?.doNotCalculateAtOrder == "1" && Number(rQty) > 0 && product?.isRestrictQty) {
                                                calPriceIsPrice = Number(rPrice) + (Number(mainPersonalizationCost) - Number(personalizationCost2)) + (Number(mainDecorationCost) - Number(mainDecoCost))
                                              }
                                              if ( selRowmain.length > 0 && isPriceBreak ) {
                                                //rowTotal = Number(rowTotal) + Number(Number(rPrice) + (!showDecorationCostInCart ? Number(decosalePrice) : 0)) * Number(rQty)
                                                rowTotal = Number(rowTotal) + calPriceIsPrice
                                                toatlPrice = Number(toatlPrice) + calPriceIsPrice
                                              } else {
                                                rowTotal = Number(rowTotal) + calPriceIsPrice
                                                toatlPrice = Number(toatlPrice) + calPriceIsPrice
                                              }
                                            }
                                        })
                                    }

                                  let color = itemRowArray.color;
                                  if ( !product.showColorProd && colors_final.length === 1 ) {
                                    color = colors_final[0];
                                  }
                                  let inventoryValue = getStock(
                                      pricerow.size,
                                      color,
                                  )
                                  let inventoryFlag = false

                                  if (
                                      isProductInventoryEnabled &&
                                      !IsAllowOutOfStockOrder &&
                                      inventoryValue === 0
                                  ) {
                                    inventoryFlag = true
                                  }
                                  totalStock += Number(inventoryValue)

                                  let setSelectedColor = pricerow.priceOptions.filter(item=>item.ColorArray.Color.colorName == itemRowArray.color)

                                  if ( isActive )
                                    return (
                                        <div
                                            className={ `col-lg sizeContainer ${ itemRowArray?.color ? ((itemRowArray?.color !== `Select ${ colorLabel }`) ? (setSelectedColor[0] ? 'active' : 'deactive') : "") : "" }` }
                                            key={ keyP }>
                                          { isProductInventoryEnabled && isColorValid(itemRowArray?.color) && (
                                              <label title="Inventory" className="inventorylabel">
                                                { WithCommas(inventoryValue) }
                                              </label>
                                          ) }
                                          <div
                                              title={ sizeLabel }
                                              className={`sizelabelwrap ${inventoryFlag ? 'bglightgray rejectsize' : ''} ${inventoryFlag && showPrice === true ? ' rejectsizeTop' : ''}`}
                                                  style={ { height: (showPrice === false) ? "56px" : "" } }
                                              >
                                                { inventoryFlag && showSize && <div className="line"></div> }
                                                {
                                                    showSize &&
                                                    <b style={ {
                                                      marginLeft: '3px',
                                                      marginRight: '3px',
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center"
                                                    } }>
                                                      { ' ' }
                                                      { pricerow.size }
                                                    </b>
                                                }
                                              </div>
                                              {(showPrice !== false) && (
                                                  <>
                                                    <p
                                                        className={`${showSellPrice ? 'breakable-price' : ''
                                                        } bggray`}
                                                        title="Price"
                                                    >
                                                      {
                                                        usePointsAsCurrency ? getPointsValue(selpriceRowmain[0]?.price && isPriceBreak && totalSum >= minIsPriceBreak ? (showSellPrice ? rWithoutDiscPrice : rPrice) :rWithoutDiscPrice) :
                                                            <p>{`${currency.currencySymbol}${numberWithCommas2(selpriceRowmain[0]?.price && isPriceBreak && totalSum >= minIsPriceBreak ? (showSellPrice ? rWithoutDiscPrice : rPrice) :rWithoutDiscPrice, decimalsToShow)}`}</p>

                                                      }
                                                    </p>
                                                    {showSellPrice && (
                                                        <label className="bggray 51a" title="Price">
                                                          {
                                                            usePointsAsCurrency ? getPointsValue(selpriceRowmain[0]?.price && isPriceBreak && totalSum >= minIsPriceBreak ? rPrice :  Math.max(0,Number(rWithoutDiscPrice - discountPrice))) :
                                                                <>
                                                                  {currency.currencySymbol}
                                                                  {numberWithCommas2((selpriceRowmain[0]?.price && isPriceBreak && totalSum >= minIsPriceBreak ? rPrice :  Math.max(0, Number(rWithoutDiscPrice - discountPrice))),
                                                                      decimalsToShow)}
                                                                </>
                                                          }
                                                        </label>
                                                    )}
                                                  </>
                                              )}

                                              <label className="inputQty">
                                                <input
                                                    type="text"
                                                    placeholder="0"
                                                    onClick={ (e)=>changeQty(e, key, pricerow.size, 0, itemRowArray?.color) }
                                                    onChange={ (e)=>changeQty(e, key, pricerow.size, 0, itemRowArray?.color) }
                                                    onBlur={ (e)=>changeQty(e, key, pricerow.size, 0, itemRowArray?.color) }
                                                    style={ { minWidth: '80px' } }
                                                    disabled={
                                                      !!inventoryFlag
                                                    }
                                                    value={ rQty ? rQty : '' }
                                                    name="quantity"
                                                    id={ `quantity${ key }` }
                                                    key={ `quantity${ key }` }
                                                />
                                                <span
                                                    className="inputQtyTitle">{ `${ (!!itemRowArray?.color && itemRowArray?.color !== `Select ${ colorLabel }`) && !!showColor ? 'Quantity' : (!!showColor ? `Please select ${ colorLabel } first` : 'Quantity') }` }</span>
                                              </label>
                                        </div>
                                    )
                                }) }
                            </div>
                          <div className="row">
                            { isReplace && getSettingStatus('showDecoration') && (
                                <>
                                  { itemRowArray.color !== '' &&
                                      itemRowArray.color !== 'undefined' &&
                                      itemRowArray.color !== undefined && itemRowArray.color !== `Select ${ colorLabel }` && (
                                          <div
                                              className="col-md-6"
                                              onClick={ ()=>ToggleDivs(key, 'D') }
                                          >
                                            { (getSettingStatus('showPersonalization') || isDrpDeco === true || (!!artWorkCount && artWorkCount.length > 0 && getSettingStatus('showPersonalization') && isDrpDeco === false) || (!!artWorkCount && artWorkCount.length > 1 && !getSettingStatus('showPersonalization') && isDrpDeco === false)) &&
                                                <i
                                                    className={
                                                      getDisplayStyle(key, 'D') === 'none'
                                                          ? 'fa fa-chevron-right pr-1'
                                                          : 'fa fa-chevron-down pr-1'
                                                    }
                                                    aria-hidden="true"
                                                />
                                            }
                                            <label className="toggleLabel">
                                              { productSettings?.['showDecorationLable']
                                                  ? productSettings['showDecorationLable']
                                                  : 'Customize This Item' }
                                            </label>
                                          </div>) }
                                </>
                            ) }
                            { getSettingStatus('showPersonalization') && (
                                <div
                                    className="col-md-6"
                                    onClick={ ()=>{
                                      addPersonalization(key)
                                      setPersonalizationAdded(true)
                                      ToggleDivs(key, 'P')
                                      } }
                                  >
                                    <i
                                        className={
                                          getDisplayStyle(key, 'P') === 'none'
                                              ? 'fa fa-chevron-right pr-1'
                                              : 'fa fa-chevron-down pr-1'
                                        }
                                        aria-hidden="true"
                                    />
                                    <label className="toggleLabel">
                                      { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' }
                                    </label>
                                  </div>
                              ) }
                {(isSkuMoq || isMoq) && showMoqNote && (
                  <p style={{color: 'red'}}>{moqNote}</p>
                ) }
                          </div>
                        </div>
                    </div>
                  <div className={ 'col-md pr-3 pl-3' }>
                    <div className="row pt-2" style={ { display: "block" } }>
                      <div className="pl-2" style={ { display: "flex" } }>
                        <Box className="isDeleteVariation" sx={ { minWidth: "40px" } }>
                          { itemRowArray.id && (showAddVariation || isDisableProductClone) && catalogueCustomRows?.length > 1 && (
                              <IconButton
                                  aria-label="delete"
                                  className="pt-2 p-1 removeeffect iconDisplaystyle"
                                  title="Delete line"
                                  onClick={ ()=>deleteRow(key) }
                              >
                                <DeleteIcon style={ { fontSize: 30, marginBottom: '1px' } }/>
                                <span className="iconSpan">Delete</span>
                              </IconButton>
                          ) }
                        </Box>
                        <Box className="isDisableProductClone" sx={ { minWidth: "40px" } }>
                          { itemRowArray.id && isDisableProductClone && (
                              <IconButton
                                  aria-label="copy"
                                  title="Copy line"
                                  className="pt-2 p-1 removeeffect iconDisplaystyle"
                                  onClick={ (e)=>{
											copyRow(key)
										}}
									>
										<img
											src={Copyrowicon}
											height="30px"
											width="30px"
											style={{
												marginBottom: '1px',
												border: '1px solid lightgray',
												padding: '2px',
											}}
										/>
										<span className="iconSpan">Clone</span>
									</IconButton>
								)}
                </Box>
							</div>
						</div>
						<div className="row pt-2" style={{maxWidth: "fit-content", overflowWrap: "anywhere"}}>
              <div
                className="pl-2 rowTotalQty"
                style={{ maxWidth: 'fit-content' }}
              >
                <b>Qty:</b>
                <p className="pt-1 mb-0">{rowTotalQty}</p>
              </div>
              {(showPrice !== false) && (
                <div className="pl-2 rowTotal">
                  <b>Total:</b>
                  {
                    usePointsAsCurrency ? getPointsFromValue(rowTotal) :
                      <p className="pt-1 mb-0">
                        {`${currency.currencySymbol}${productSettings?.priceRoundUp ? roundedPrice(Number(rowTotal), decimalsToShow) : numberWithCommas2(Number(rowTotal), decimalsToShow)}`}
                      </p>
                  }
                </div>
              )}
            </div>
                  </div>
        </div>

        {(showPrice !== false) && rowTotalQty > 0 && (
                    <div className="row">
                      <div className="col-md pr-0 mb-1">
                        <div className="chargeTable float-right mr-4">
                          <table>
                            <tbody>
                            { showSetupChargeInCart && setupCharges?.length > 0 && Number(totalSetupCharge) > 0 && (
                                <>
                                  { setupCharges.map(charge=>{
                                    return (
                                        <tr>
                                          <td className="deco_per_stup_currency">{ charge.setupChargeName }</td>
                                          <td>
                                            <div className="deco_per_stup">
                                              {
                                                usePointsAsCurrency ? getPointsFromValue(charge.totalSetupCharge) : <>
                                                  <div className="">{ currency.currencySymbol }</div>
                                                  <div>
                                                    { Number(charge.totalSetupCharge).toFixed(decimalsToShow) }
                                                  </div>
                                                </>
                                              }
                                            </div>
                                          </td>
                                        </tr>
                                    )

                                  }) }
                                </>
                            ) }

                    { showSetupChargeInCart && Number(totalSetupDecoCharges) > 0 && (
                        <>
                          <tr>
                            <td className="deco_per_stup_currency">Setup Decoration Charge</td>
                            <td>
                              <div className="deco_per_stup">
                                {
                                  usePointsAsCurrency ? getPointsFromValue(totalSetupDecoCharges) : <>
                                    <div className="">{currency.currencySymbol}</div>
                                    <div>
                                      {Number(totalSetupDecoCharges).toFixed(decimalsToShow)}
                                    </div>
                                  </>
                                }
                              </div>
                            </td>
                          </tr>
                        </>
                    ) }

                    { showDecorationCostInCart && totalDecoCharges > 0 && (
                        <tr>
                          <td className="deco_per_stup_currency">
                            Decoration Charge
                          </td>
                          <td>
                            <div className="deco_per_stup">
                              {
                                usePointsAsCurrency ? getPointsFromValue(Number(totalDecoCharges)) : <>
                                  <div className="">{ currency.currencySymbol }</div>
                                  <div>
                                    { rowTotalQty > 0
                                        ? (
                                            Number(totalDecoCharges)
                                        ).toFixed(decimalsToShow)
                                        : 0 }
                                  </div>
                                </>
                              }
                            </div>
                          </td>
                        </tr>
                    ) }

                    { showSetupChargeInCart && !!itemRowArray.personalization && personalizationCost.setupPrice > 0 && (
                        <tr>
                          <td className="deco_per_stup_currency">
                          { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' } setup Charge
                          </td>
                          <td>
                            <div className="deco_per_stup">
                              {
                                usePointsAsCurrency ? getPointsFromValue(personalizationCost.setupPrice) : <>
                                  <div className="">{ currency.currencySymbol }</div>
                                  <div>
                                    { rowTotalQty > 0
                                        ?
                                        Number(personalizationCost.setupPrice)
                                            .toFixed(decimalsToShow) : 0 }

                                  </div>
                                </>
                              }
                            </div>
                          </td>
                        </tr>
                    ) }
                    { getSettingStatus('showPersonalization') && getSettingStatus('showPersonalizationCostInCart') && !!itemRowArray.personalization && personalizationCost.price > 0 && (
                        <tr>
                          <td className="deco_per_stup_currency">
                          { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' } Charge
                          </td>
                          <td>
                            <div className="deco_per_stup">
                              {
                                usePointsAsCurrency ? getPointsFromValue(Number(personalizationCost.price) * rowTotalQty) : <>
                                  <div className="">{ currency.currencySymbol }</div>
                                  <div>
                                    { rowTotalQty > 0
                                        ? personalizationCost.matchOrderQty == 1 ? (
                                            Number(personalizationCost.price) * rowTotalQty
                                        ).toFixed(decimalsToShow) : (
                                            Number(personalizationCost.price)
                                        ).toFixed(decimalsToShow)
                                        : 0 }
                                  </div>
                                </>
                              }
                            </div>
                          </td>
                        </tr>
                    ) }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                ) }

				{isReplace && getSettingStatus('showDecoration') && (
					<div
						className="row decoBlock pl-2" style={{margin : '0', paddingBottom : '20px'}}
					>
						<div className="col-md-12" style={{ display: (!!itemRowArray?.decorations && itemRowArray.decorations.length > 0) ? 'flex' : 'none' }}>
							<div className="row pl-2">
								{itemRowArray?.decorations?.map((decoitem, index) => {
									let artworkSelected = null
									let rowVariationArray = null
									if (decoitem.artworkID && artworksFromStateAll?.length) {
										artworkSelected = artworksFromStateAll.filter(
											(artorkArray) => artorkArray.id === decoitem.artworkID,
										)[0]
									}
									let variations = null
									if (!artworkSelected) {
										return ''
									} else {
										variations = artworkSelected.design.variation.filter(
											(variationArray) =>
												variationArray.design_variation_unique_id ==
												decoitem.variationUniqID,
										)

										let getColorIndex = product.LogoInfo.filter(
											(val) => val.color === tempSelectedColor,
										)

										let getIndex = []
										if (getColorIndex > 1) {
											getIndex = product.LogoInfo.filter(
												(val) =>
													val.color === tempSelectedColor &&
													val.location === decoitem.location,
											)
										} else {
											getIndex = getColorIndex
										}

										let label = decoTypeList.filter(
											(val) => val.code === artworkSelected?.design?.designType,
										)

										if (variations)
											return variations.map((vari, indexi) => {
												return (
													<div
														className="decorows inlinefexDecorationDiv"
														key={'idx' + indexi}
														style={{ cursor: 'pointer' }}
														onClick={async (e) => {
                                                          setSelectedLocationIndex(key)
															setSwipeimageFlag(false)
															setTempSelectedColor(itemRowArray.color)
															getIndex = product.LogoInfo.filter(
																(val) =>
                                                                    val.color === itemRowArray.color &&
                                                                    val.location === decoitem.location,
                                                            )
                                                          setSelectedLocation(decoitem.locationId)
                                                          setSelectedDecoration(decoitem)
                                                          setCurrentActiveId(itemRowArray.id)

                                                          if ( getIndex.length > 0 ) {
                                                            setSwipeimageFlag(true)
                                                            setSelectedImage(getIndex.length > 0 ? getIndex[0].image : [])
                                                            setIsSelectedImageChng(IsSelectedImageChng + 1)
                                                            setImageScroll(imageScroll + 1)
                                                            // animateScroll.scrollToTop()
                                                          } else {
                                                            getIndex = product.LogoInfo.filter(t=>t.color === itemRowArray.color && t.extra_logo.filter(y=>y.location === decoitem.location).length > 0);
                                                            if ( getIndex.length > 0 ) {
                                                              setSwipeimageFlag(true)
                                                              setSelectedImage(getIndex[0]?.image)
                                                              setIsSelectedImageChng(IsSelectedImageChng + 1)
                                                              // animateScroll.scrollToTop()
                                                              setImageScroll(imageScroll + 1)
                                                            }
                                                          }
														}}
													>
														<img
															className="default-img1 pointer"
                              alt=""
															style={{
																maxHeight: '60px',
																maxWidth: '60px',
															}}
															src={
																!!vari?.itemImageThumbnail?.[0] ? productImageUrl(vari.itemImageThumbnail)
                                  : (!!artworkSelected?.thumbnail ? productImageUrl(artworkSelected?.thumbnail) : productImageUrl(PlaceHolder))
															}
														/>
														<div>
															<div className="col-md-12">
                                {!!!hideDecorationName &&
                                  <p>
                                    <b>{artworkSelected?.identity}</b>
                                  </p>
                                }
																{!!!hideLocationName && <p>{decoitem.location}</p>}
                                {(!decoitem?.isRequiredForStore || decoitem?.isRequiredForStore == "0") &&
                                  <DeleteIcon
                                    className="float-right"
                                    style={{ cursor: 'pointer' }}
                                    onClick={async () => {
                                      await showPopupText(key, itemsRows, index, decoitem)
                                      if (itemRowArray?.decorations && itemRowArray?.decorations?.length > 0) {
                                        decoitem = itemRowArray.decorations[0];
                                      }
                                    }}
                                  />
                                }
															</div>
														</div>
													</div>
												)
											})
									}
								})}
							</div>
						</div>
            {getDisplayStyle(key, 'D') !== 'none' &&
						<div className="col-md-12">
							{getSettingStatus('showDecoration') &&
								getDisplayStyle(key, 'D') !== 'none' &&
								!notCallDecoHtml && !loadingArtworks &&
								getDecoHtml(key, itemsRows)}
                          <p className={"ml-3"}>{ loadingArtworks ? 'Loading ...' : ''}</p>
                        </div>
            }
					</div>
				)}
				{personalizationAdded && (
					<div
						className="col-md-12"
						style={{ display: getDisplayStyle(key, 'P') }}
					>
						<div
							className="row ml-2"
							style={{ borderBottom: '1px solid lightgray' }}
						>
							<div className="col-md-2">
								<h3>{ !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' }</h3>
							</div>
              {!personalizationRequired &&
                <div className="col-md">
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      deletePersonalization(key)
                    }}
                    color="primary"
                    className="float-right mr-2"
                  >
                    Delete { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                            ? productSettings['showPersonalizationLabel']
                                            : 'Personalization' }
                  </Button>
                </div>
              }
						</div>
						<div className="row pl-3 decporow">
							{!personalizationAdded && (
								<div className="col-md-12 text-center p-2">
									<Button
										variant="contained"
										onClick={() => {
											addPersonalization(key)
											setPersonalizationAdded(true)
										}}
									>
										Add { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' }
									</Button>
								</div>
							)}
						</div>
						{personalizationAdded && (
							<div className="row pl-3 decporow" style={!!personalizationDropDown && !!requiredLinesPersonalization && !!dropDownValues && dropDownValues.length > 0 && dropDownValues.filter((res) => !!res?.isPersonalizationDropDown).length > 0 ? {overflowX: 'unset'} : {}}>
								<div className="col-md-12 text-center p-2">
									{getPesonalizationTable(itemsRows, key)}
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		)
	}

  useEffect(() => {
    /**  include personalizationCost in total price.
                 logic is if personalizationCost.matchOrderQty=0 then include personalizationCost in total price else personalizationCost.matchOrderQty=1 then  personalizationCost multiple with qty then total cost inculde in total Price **/
    /*if (personalizationCost.price > 0) {
      if (personalizationCost.matchOrderQty === 0) {
        toatlPrice = Number(toatlPrice) + Number(personalizationCost.price)
      } else if (personalizationCost.matchOrderQty === 1 && getSettingStatus('showPersonalizationCostInCart')) {
        toatlPrice =
          Number(toatlPrice) * Number(toatlQty) +
          Number(personalizationCost.price)
      } else if (personalizationCost.matchOrderQty == '1') {
        toatlPrice = Number(toatlPrice) + Number(personalizationCost.setupPrice)
      }
    }*/
  }, [personalizationCost])

  const deletePersonalization = (key) => {
    // setPersonalizationArray(null)
    let tempData = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      // if (groupkey === key) {
        rowGroupArray = rowGroupArray.filter((groupArray) => {
          if(groupkey === key) {
            ToggleDivs(key, 'P', 'DeleteRow')
            groupArray.personalization = null
            return groupArray
          }
        })
      // }
      return rowGroupArray
    })

    //delete personalization
    let prdt = tempData.filter(rowGroupArray => {
      let cnt = rowGroupArray.filter(groupArray => {
         if(!!groupArray?.personalization) {
          return groupArray
         }
      });
      return cnt.length > 0
    });
    setCatalogueCustomRows(tempData)
   if(!(prdt.length > 0)) {
    setPersonalizationAdded(false)
    setPersonalizationCost((preVal) => ({
      ...preVal,
      ['matchOrderQty']: 0,
      ['price']: 0,
	  ['setupPrice']:0,
    ['chargeId'] : ''
    }))
    updatestate(!statchange)
   }
   totalPriceData()
  }
  const getPesonalizationTable = (itemsRows, Rowkey) => {
    let personalizationArray = []
    catalogueCustomRows &&
      catalogueCustomRows.map((rowArray, key) => {
       if(Rowkey === key) {
        if (rowArray.length > 0) {
          rowArray.map((item) => {
            itemsRows.map((itemRowArray) => {
              if (itemRowArray.id === item.id) {
                item?.personalization &&
                  item.personalization.map((personalization) => {
                    personalizationArray.push(personalization)
                  })
              }
            })
          })
        }
       }
      })
    let rowQtyNo = null
    let rowId = null
    return (
      <Table className="personalizationTabel">
        <thead>
          <tr>
            <td>Item</td>
            <td>{sizeLabel}</td>
            <td>Item {colorLabel}</td>
            <td>Line</td>
            <td>Text</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {personalizationArray.map((personalization, key) => {
            let flag = true
            if (Number(personalization.sequance) !== 1) {
              flag = false
            }
            rowQtyNo = personalization.rowQtyNo
            rowId = personalization.rowId
            const objOptions = []
            if(!!personalization?.isDrp) {
              !!personalization?.drpValue && personalization?.drpValue.length > 0 && personalization.drpValue.map(op => {
                objOptions.push({ value: op, label: op })
              })
            }
            return (
              <tr key={key}>
                <td>{flag && 'Item ' + personalization.rowQtyNo}</td>
                <td>{flag && personalization.itemSize}</td>
                <td>{flag && personalization.itemColor}</td>
                <td>{!!personalization?.lineName ? personalization?.lineName : 'Line ' + personalization.sequance}</td>
                <td>
                  {
                    !(!!personalization?.isDrp) ? (
                      <input
                        onChange={(e) => {
                          changePersonalizatinArray(e, "displayText", personalization);
                        }}
                        value={personalization.displayText}
                      />
                    ) : (
                      <div className="selectDropDown">
                        <Select
                          noOptionsMessage={() => "No matches for this search"}
                          options={objOptions}
                          styles={colorStyles}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                          name="isDrpValuePer"
                          onChange={(e) =>
                            changePersonalizatinArray(e, "displayText", personalization, 1)
                          }
                        />
                      </div>
                    )
                  }
                </td>
               {(!(!!personalization?.isDrp) && !(!!personalization?.isEnabled) && !(!!personalization?.requiredLine)) ? (
                <td>
                  {flag && (
                    <AddIcon
                      onClick={() => {
                        AddPersonalizationRow(personalization)
                      }}
                    />
                  )}
                  {!flag && (
                    <DeleteIcon
                      onClick={() => {
                        DeletePersonalizationRow(personalization, key)
                      }}
                    />
                  )}
                </td>
               ) : '' }
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }
  const selectcolorBox = (selectcolorBox) => {
    return (
      <select
        onChange={(e) => {
          changePersonalizatinArray(e, 'Color', selectcolorBox)
        }}
        value={selectcolorBox.color}
      >
        <option value="0">Select</option>
        <option value="Black">Black</option>
        <option value="White">White</option>
      </select>
    )
  }
  const changePersonalizatinArray = (e, type, DataArray, isDrp = 0) => {
    let tempArray = catalogueCustomRows.filter((rowGroup) => {
      if (rowGroup.length > 0) {
        rowGroup.filter((rowArray) => {
          if (rowArray.id === DataArray.rowId) {
            rowArray.personalization = rowArray.personalization.filter(
              (ItemArray) => {
                if (
                  ItemArray.rowQtyId === DataArray.rowQtyId &&
                  ItemArray.sequance === DataArray.sequance &&
                  ItemArray.rowQtyNo === DataArray.rowQtyNo
                ) {
                  if (type === 'Font') {
                    ItemArray.font = e.target.value
                  } else if (type === 'Color') {
                    ItemArray.color = e.target.value
                  } else if (type === 'Location') {
                    ItemArray.location = e.target.value
                  } else if (type === 'Note') {
                    ItemArray.notes = e.target.value
                  } else if (type === 'displayText') {
                    ItemArray.displayText = isDrp === 1 ? e.value : e.target.value
                  }
                }
                return ItemArray
              },
            )
          }
          return rowArray
        })
      }
      return rowGroup
    })

    //change personaliztion
    setCatalogueCustomRows(tempArray)
    setToggleValue(!toggleValue)
  }
  const selectfontBox = (selectfontBox) => {
    return (
      <select
        onChange={(e) => {
          changePersonalizatinArray(e, 'Font', selectfontBox)
        }}
        value={selectfontBox.font}
      >
        <option value="0">Select</option>
        <option value="Script">Script</option>
        <option value="Block Upper Lower">Block Upper Lower</option>
        <option value="Block Upper">Block Upper</option>
        <option value="Italic Upper Lower">Italic Upper Lower</option>
        <option value="Italic Upper">Italic Upper</option>
        <option value="Drax Standard Font">Drax Standard Font</option>
      </select>
    )
  }
  const selectLocationBox = (selectlocationBox) => {
    let decoExistLocations = []
    if (product?.LocationArray?.Location) {
      product.LocationArray.Location.map((location) => {
        if (location?.locationName !== '')
          decoExistLocations.push(location.locationName)
      })
    }
    return (
      <select
        value={selectlocationBox.location}
        onChange={(e) => {
          changePersonalizatinArray(e, 'Location', selectlocationBox)
        }}
      >
        <option value="Select">Select</option>
        {decorationLocations?.map((location, key) => {
          if (decoExistLocations.indexOf(location.locationName) !== -1) {
            return (
              <option value={location.locationName} key={key}>
                {location.locationName}
              </option>
            )
          } else {
            return false
          }
        })}
      </select>
    )
  }
  const AddPersonalizationRow = (personalization) => {
    let newRowData = null
    let tempArray = catalogueCustomRows.filter((rowGroup) => {
      if (rowGroup.length > 0) {
        rowGroup.filter((rowArray) => {
          if (rowArray.id === personalization.rowId) {
            let rowIndex = 0
            rowArray.personalization.map((ItemArray, key) => {
              if (
                ItemArray.rowQtyId === personalization.rowQtyId &&
                ItemArray.rowQtyNo === personalization.rowQtyNo
              ) {
                rowIndex = key
                newRowData = {
                  ...ItemArray,
                  color: getPersColor(product, productSettings),
                  location: getPersLocation(product, productSettings),
                  font: getPersFont(product, productSettings),
                  notes: getPersNote(product, productSettings),
                  displayText: '',
                  sequance: (Number(ItemArray.sequance) + 1).toString(),
                }
              }
            })
            let TempPersonalization = []
            rowArray.personalization.map((Row, key) => {
              TempPersonalization.push(Row)
              if (rowIndex === key) {
                TempPersonalization.push(newRowData)
              }
            })
            rowArray.personalization = TempPersonalization
          }
          return rowArray
        })
      }
      return rowGroup
    })

    //add personaliztion row
    setCatalogueCustomRows(tempArray)
    setToggleValue(!toggleValue)
  }
  const DeletePersonalizationRow = (personalization, arrayKey) => {
    let newRowData = null
    let tempArray = catalogueCustomRows.filter((rowGroup) => {
      if (rowGroup.length > 0) {
        rowGroup.filter((rowArray) => {
          if (rowArray.id === personalization.rowId) {
            let TempPersonalization = rowArray.personalization
            TempPersonalization = TempPersonalization.filter((row, key) => {
              if (
                row.rowQtyNo === personalization.rowQtyNo &&
                personalization.sequance === row.sequance &&
                personalization.rowId === row.rowId
              ) {
                return false
              }
              return true
            })
            let seq = null
            TempPersonalization = TempPersonalization.filter((row) => {
              if (
                seq !== null &&
                row.sequance !== 1 &&
                row.rowQtyNo === personalization.rowQtyNo &&
                personalization.rowId === row.rowId
              ) {
                if (Number(seq) + 1 !== Number(row.sequance)) {
                  row.sequance = Number(seq) + 1
                  seq = row.sequance
                }
              } else {
                seq = row.sequance
              }
              return row
            })
            rowArray.personalization = TempPersonalization
          }
          return rowArray
        })
      }
      return rowGroup
    })

    //delete PERS row
    setCatalogueCustomRows(tempArray)
    setToggleValue(!toggleValue)
  }
  const getDecoHtml = (parentKey, itemsRows) => {
    const locations = getLocationsCondensed();
    let locID = locations.locID
    let locationName = locations.locationName
    let artWorkID = locations.artWorkID
    let productColor = locations.productColor;
    let decoExistLocations = locations.decoExistLocations
    let yFilter = locations.yFilter
    let filteredX = decorationLocations.filter(itemX => yFilter.includes(itemX.locationName));

    let tempDecorationLocations = filteredX
    if (decoExistLocations?.length > 0 && tempDecorationLocations?.length > 0) {
      tempDecorationLocations = tempDecorationLocations.filter((f) => {
        if (decoExistLocations.indexOf(f.locationName) !== -1) {
          if(product.restrictAfterFirstChoice && artworksProductState.length > 0) {
            let numberChoice = 0;
            let selectedArtworks = itemsRows?.[0]?.decorations?.filter((row) => row.artworkID) || []
            numberChoice = selectedArtworks.length === tempDecorationLocations.length
              ? selectedArtworks.length - 1
              : selectedArtworks.length;
            let arrayArtworks = artworkLocations.filter(x => x.numberChoice === numberChoice && !x.isEnable) ?? [];
            let artworksToReadFiltered = [];
            let arrayArtworksTemp = [];
            artworksFromStateAll.forEach(x => {
              let index = arrayArtworks.findIndex(z => z.artworkId === x.id);
              if(index === -1) {
                arrayArtworksTemp.push(x);
              }
            })
            arrayArtworksTemp.forEach(x => {
              let insert = x.productToDecorationMapping.filter(y =>  y.locationName === f.locationName).length;
              if(insert > 0) {
                artworksToReadFiltered.push(x);
              }
            });
            return artworksToReadFiltered.length > 0;
          } else {
            return true;
          }
        }
        return false
      })
    }

    return (
      <div className="mt-3 mb-3">
        <DecorationSelectbox
          itemsRows={itemsRows?.[0]}
          decoExist={itemsRows?.[0]?.decorations}
          key={parentKey}
          handleCheckbox={handleCheckbox}
          setAddnewartId={setAddnewartId}
          handleCheckboxDefault={handleCheckboxDefault}
          tempDecorationLocations={tempDecorationLocations}
          locID={ locID ? `${locID}-${selectedLocationIndex}`: ""}
          clearDecoSelection={clearDecoSelection}
          parentKey={parentKey}
          artworkListById={artworksFromStateAll}
          decoLocation={product?.customArtworkLocation}
          setAddnewart={setAddnewart}
          allowCustomDecoration={allowCustomDecoration}
          productAllowOneDecoration={product?.AllowOneDecorationLimitPro}
          productSettingsAllowOneDecoration={productSettings?.AllowOneDecorationLimit}
          productSettingsRenameLocationNameLabel={productSettings?.renameLocationNameLabel}
          productRenameLocationNameLabel={product?.renameLocationNameLabel}
          productSettingsRenameLocationNamePrompt={productSettings?.renameLocationNamePrompt}
          productRenameLocationNamePrompt={product?.renameLocationNamePrompt}
          productSettingsShowLocationNameLabel={productSettings?.showDecorationLocationLabel}
          productShowLocationNameLabel={product?.showDecorationLocationLabel}
          locationName={locationName}
          isSearchArtwork={isSearchArtwork}
          searchTextArtwork={searchTextArtwork}
          searchArtworkName={searchArtworkName}
          artworkSearchPlaceholderText = {product?.artworkSearchPlaceholderText}
          displayDrop = {displayDrop}
          allowToUpCustArtwork={productSettings?.allowToUpCustArtwork}
        />
        {getSettingStatus('showDecoration') && locID !== "" && (
          <AddDecorationportal
            setSelectedProductArtwork={setSelectedProductArtwork}
            selectedProductArtwork={selectedProductArtwork}
            parentKey={parentKey}
            getDecoPrice={getDecoPrice}
            locationID={`${locID}-${selectedLocationIndex}`}
            artWorkID={artWorkID}
            setCatalogueCustomRows={setCatalogueCustomRows}
            catalogueCustomRows={catalogueCustomRows}
            addDecoration={addDecoration}
            allowCustomDecoration={allowCustomDecoration}
            showSelectArtwork={product?.showSelectArtwork}
            setAddnewart={setAddnewart}
            productColor={productColor}
            setShowData={setShowData}
            showData={showData}
            temp={temp}
            tempDecorationLocations={tempDecorationLocations}
            locationName={locationName}
            itemsRows={itemsRows?.[0]}
            searchTextArtwork={searchTextArtwork}
            artworkSetting={artworkSetting}
            storeArtwork={ storeArtwork }
            selectedDecorationsList={ itemsRows?.[0]?.decorations }
            artworkDuplicatesAllowed={ productSettings?.artworkDuplicatesAllowed }
            artworkDuplicatesAllowedPro={ product?.artworkDuplicatesAllowed }
            product={ product }
            decoExist={ itemsRows?.[0]?.decorations }
            allowOneDec={ allowOneDec }
            setIsAdded={setIsAdded}
            isAdded={isAdded}
            decorationListOrder = {decorationListOrder}
            preventAutoAttach = {preventAutoAttach}
            setSelectedLocationIndex = {setSelectedLocationIndex}
            hideDecorationName={hideDecorationName}
          />
        )}
      </div>
    )
  }
  const clearDecoSelection = () => {
    let tempcatalogueCustomRows = catalogueCustomRows.filter(
      (rowGroupArray, groupkey) => {
        rowGroupArray.filter((rowArray) => {
          rowArray.decorations = []
          //rowArray.decorations = rowArray.decorations.filter((decoFilter) => {
          //  if (
          //    decoFilter.artworkID === '' ||
          //    decoFilter.variationUniqID === '' ||
          //    decoFilter.artworkID === null ||
          //    decoFilter.variationUniqID === null
          //  ) {
          //    return false
          //  }
          //  return decoFilter
          //})
          return rowArray
        })

        return rowGroupArray
      },
    )

    //clear deco collection
    setCatalogueCustomRows(tempcatalogueCustomRows)
  }
  const showLogoblock = (quoteItemRowArray) => {
    if (product?.MediaContent) {
      if (product.MediaContent.length > 0) {
        let flag = true
        product.MediaContent.map((logoArray, key) => {
          if (logoArray.color === quoteItemRowArray.color && flag) {
            flag = false
            const frameZones = Array.from(
              document.querySelectorAll('.swiper-pagination-bullet'),
            )
            frameZones.map(async (el, k) => {
              if (k === key) {
                let opts = {
                  view: window,
                  bubbles: true,
                  cancelable: true,
                  buttons: 1,
                }
                el.dispatchEvent(new MouseEvent('mousedown', opts))
                await new Promise((r) => setTimeout(r, 50))
                el.dispatchEvent(new MouseEvent('mouseup', opts))
                el.dispatchEvent(new MouseEvent('click', opts))
              }
            })
          }
        })
      }
    }
    setCurrentqid(quoteItemRowArray.id)
  }
  const addDecoration = (variation, parentKey) => {
    let locationArray = decorationLocations?.filter(
      (locationArray) => locationArray.id === selectedProductLocation,
    )

    let tempRows = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      if (groupkey === parentKey) {
        rowGroupArray.filter((rowArray) => {
          let tempDecoArray = rowArray.decorations ? rowArray.decorations : []
          tempDecoArray.push({
            artworkID: selectedProductArtwork,
            location: locationArray[0].locationName,
            locationId: selectedProductLocation,
            variationUniqID: variation.design_variation_unique_id,
          })
          rowArray.decorations = tempDecoArray
          return rowArray
        })
      }
      return rowGroupArray
    })

    //add deco
    setCatalogueCustomRows(tempRows)
    setSelectedProductLocation('')
    // setSelectedProductArtwork('')
  }


  useEffect(async ()=>{
    if(productStockState !== null) {
      setProductDstInventory(productStockState.dstInventory)
      setProductInventory(productStockState.inventory)
    }
  }, [productStockState])

  const getStock = (sizeDefault, sizcolorDefault) => {
    let inventoryArray = null
    let dstInventoryArray = null

    if (productInventory) {
      //supplierStock
     // if (productInventory.productId === product.productId) {
        inventoryArray = productInventory?.PartInventoryArray?.PartInventory
     // }
    }
    if (productDstInventory) {
      dstInventoryArray = productDstInventory
    }

    let stock = 0
    if (
      (product?.poType === 'DropShip' || product?.poType === 'Stock') &&
      (isStockFor === 'supplierStock' || isStockFor === 'both')
    ) {
      inventoryArray &&
        inventoryArray.map((stockArray) => {
          if (
            sizcolorDefault?.toLowerCase() === stockArray?.partColor?.toLowerCase() &&
            stockArray?.labelSize?.toLowerCase() === sizeDefault?.toLowerCase()
          ) {
            if (stockArray?.InventoryLocationArray?.InventoryLocation?.length) {
              stockArray.InventoryLocationArray.InventoryLocation.map(
                (inventoryArray) => {
                  if (
                    inventoryArray?.inventoryLocationQuantity?.Quantity?.value
                  ) {
                    stock =
                      Number(stock) +
                      Number(
                        inventoryArray.inventoryLocationQuantity.Quantity.value,
                      )
                  }
                },
              )
            }
          }
        })
    }

    let dstStock = 0
    //distributorStock or both
    //distributor inventory
    if (
      product?.poType === 'Stock' &&
      (isStockFor === 'distributorStock' || isStockFor === 'both')
    ) {
      dstInventoryArray &&
        dstInventoryArray.map((dstStockArray) => {
          if (dstStockArray.id === product.id) {
            if (
              (sizcolorDefault.replace("No Color", "").toLowerCase() === dstStockArray.color.replace("No Color", "").toLowerCase()) &&
              dstStockArray.size.toLowerCase() === sizeDefault.toLowerCase()
            ) {
              dstStock = Number(dstStockArray.quantity)
            }
          }
        })
    }
    return Number(stock) + Number(dstStock)
  }
  const getCartItemSizeTotal = (productId, rowKey) => {
    let totalqty = 0
    cartItems.forEach((caItems) => {
      if (caItems?.product?.productId === productId) {
        return caItems?.currentOrder.forEach((sku) => {
          return sku?.forEach((skuItem) => {
            if (skuItem.size === rowKey) totalqty += Number(skuItem?.quantity)
          })
        })
      }
    })
    return totalqty
  }
  const restrictValidation = () => {
    orderAction(`Quantity restrictions for this product are ${minAllQty.toString()}`, 'error', false)
    addFirstRow()
  }
  const checkMinimumQty = (e, key, rowKey) => {
    if (isRestrictQty) {
      let total = Number(e.target.value)
      if (!minAllQty.includes(total)) {
        //priceTable[key].rQty = minQty
        e.target.value = minQty
        changeQty(e, key, rowKey)
        orderAction(`Qty must be ${minAllQty.toString()}`, 'error', false)
      }
    } else {
      let total = getCartItemSizeTotal(prodId, rowKey)
      total = total ? Number(total) : 0 + Number(e.target.value)
      if (Number(total) < Number(minQty) && isMoq && !isSkuMoq) {
        //priceTable[key].rQty = minQty
        e.target.value = minQty
        changeQty(e, key, rowKey)
        orderAction(`Minimum quantity should be ${minQty}`, 'error', false)
      }
    }
  }
  const checkOrderRows = () => {
    let flag = true
    if (catalogueCustomRows.length === 0) {
      orderAction('Please add row', 'error', false)
      flag = false
    }
    catalogueCustomRows.map((rowGroup) => {
      if (flag) {
        if (rowGroup.length === 0) {
          orderAction('Please add row', 'error', false)
          flag = false
        } else {
          let checkQty = true
          rowGroup.map((rowArray) => {
            if (flag) {

              if ((rowArray.color === '' || rowArray.color === `Select ${colorLabel}`) && showColor) {
                orderAction(`Please select ${colorLabel}!`, 'error', false)
                flag = false
              } else {
                if (rowArray.size !== '') {
                  if (rowArray.decorations && rowArray.decorations.length > 0) {
                    rowArray.decorations.map((decoArray) => {
                      if (decoArray.locationId !== '') {
                        if (decoArray.artworkID && decoArray.artworkID !== '') {
                          if (
                            decoArray.variationUniqID &&
                            decoArray.variationUniqID !== '' &&
                            decoArray.variationUniqID !== null
                          ) {
                          } else {
                            orderAction(
                              'Please select variation',
                              'error',
                              false,
                            )
                            flag = false
                          }
                        } else {
                          orderAction('Please select artwork', 'error', false)
                          flag = false
                        }
                      } else {
                        if (decoArray.artworkID !== '') {
                          if (
                            decoArray.variationUniqID === '' ||
                            decoArray.variationUniqID === null
                          ) {
                            orderAction(
                              'Please select variation',
                              'error',
                              false,
                            )
                            flag = false
                          }
                        } else {
                          orderAction('Please select artwork', 'error', false)
                          flag = false
                        }
                      }
                    })
                  }
                }
                if(personalizationAdded && rowArray?.personalization) {
                  let blnktest = rowArray.personalization.filter(x => x.displayText === '')
                  if(blnktest.length > 0) {
                    orderAction('You have added personalization to this product, but you haven’t added your text. Please add your text or delete Personalization to add this product to your cart.', 'error', false)
                    flag = false
                  }
                }
                if (Number(rowArray.quantity) > 0) {
                  checkQty = false
                }
              }
            }
          })
          if (checkQty) {
            orderAction('Please enter quantity', 'error', false)
            flag = false
          }
        }
      }
    })
    if(RequiredDecorationForCheckOutPro === true) {
      let tempRow = []
      catalogueCustomRows.forEach(catalogueCustomRow => {
          let tempEachRow = [];
          catalogueCustomRow.forEach(row => {
              if (row.quantity && Number(row.quantity) > 0) {
              tempEachRow.push(row);
              }
          });
          tempRow.push(tempEachRow);
      });
      if(!!tempRow[0][0]?.decorations && !(tempRow[0][0]?.decorations.length > 0)) {
          orderAction('Please choose a decoration to add this product to your cart', 'error', false)
          flag = false
      }
    }
    return flag
  }
  const addtoCart = () => {
    /*if (disIndProductPrice !== false) {*/
      catalogueCustomRows.map((rowGroup) => {
        rowGroup.map((rowArray) => {
          if(!!rowArray?.decorations && rowArray.decorations.filter(res=>res.artworkID).length === 0){
            rowArray.decorations = []
          }
        })
      })
      if (checkOrderRows()) {
        let selectedCartItem = cartItems.filter(
          (cartItem) => cartItem.product && cartItem.product.id === product.id,
        )[0]

        // catalogueCustomRows[0][0].personalizationCost = personalizationCost
        catalogueCustomRows[0][0].rollDecoChargesToProduct = 0
        catalogueCustomRows[0][0].rollDecoAddonChargesToProduct = 0

        if (catalogueCustomRows[0][0]?.decorations?.length) {
          catalogueCustomRows[0][0].rollDecoChargesToProduct = 1
          catalogueCustomRows[0][0].rollDecoAddonChargesToProduct = 1
        }
        let tempRow = []
        let cnt = 0
        let isPersonalization = false
        catalogueCustomRows.map((itemsRowsabs, keya) => {
          itemsRowsabs && itemsRowsabs.length > 0 && itemsRowsabs.map((sizeObj, index) => {
            if (!!sizeObj?.personalization) {
              isPersonalization = true
            }
          })
        })
        catalogueCustomRows.forEach(catalogueCustomRow => {
          let tempEachRow = [];
          catalogueCustomRow.forEach(row => {
            if(row.quantity && Number(row.quantity) > 0) {
              cnt = cnt + 1
              if(cnt === 1 && getSettingStatus('showPersonalization') && isPersonalization === true) {
                row.personalizationCost = personalizationCost
              }
              tempEachRow.push(row);
            }
          });
          tempRow.push(tempEachRow);
        });
        tempRow.forEach(async (item, index) => {
          addItemsRowsTocart(product, [item], index === tempRow.length -1 ? addToast: null, isPriceBreak, ipV4, userGroupDiscount, index !== tempRow.length - 1);
        })

        goToCart()
        let cartCustomRows = product?.MediaContent.filter(x => x.primary).map(x => {

          return {
            id: uniqIdcounter++,
            size: '',
            quantity: '',
            color: '',
            displayGroup: x.displayGroup,
            group: x.group,
            groups: x.groups,
            hex: x.hex,
            hidden: x.hidden,
            index: x.index,
            logoBlockCount: x.logoBlockCount,
            mediaType: x.mediaType,
            partId: x.partId,
            primary: x.primary,
            productId: x.productId,
            secondary: x.secondary,
            select: x.select,
            sku: x.sku,
            url: x.url,
            price: 0,
            totalPrice: 0,
            cost: 0,
            totalCost: 0,
            product: { sku: x.sku },
            weight: product.weight,
            shipFrom: {
              countryFrom: product.shipFromCountry,
              zipCodeFrom: product.shipFromPostalCode,
            },
          }
        });
        cartCustomRows = cartCustomRows.length > 0 ? [cartCustomRows] : [
          [
            {
              id: uniqIdcounter++,
              size: '',
              quantity: '',
              image: product?.MediaContent?.[0]?.url,
              color: "",
              product: { sku: 0 },
              price: 0,
              totalPrice: 0,
            },
          ],
        ]

        setPersonalizationCost((preVal) => ({
          ...preVal,
          ['matchOrderQty']:
            cartCustomRows[0][0]?.personalizationCost?.matchOrderQty,
          ['price']: cartCustomRows[0][0]?.personalizationCost?.price,
          ['setupPrice']: cartCustomRows[0][0]?.personalizationCost?.setupPrice,
          ['chargeId']: cartCustomRows[0][0]?.personalizationCost?.chargeId,
        }))
        setCatalogueCustomRows(cartCustomRows)
        setToggleDivs([])
        updateStateTemp(1)
        document.getElementsByName('quantity').forEach(element => element.placeholder='0');
        // if(priceTable.length === 1) { setaddRowQtyFirst(tempRow[0][0].quantity) }
      }
    /*} else {
      addToast('Price not available', {
        appearance: "error",
        autoDismiss: true
      })
    }*/
  }
  const validateData = () => {
    let flag = true
    if (selectedProductColor === '' && selectedProductColor === null) {
      addToast(`Please select product ${colorLabel}`, {
        appearance: 'error',
        autoDismiss: true,
      })
      flag = false
    } else if (selectedProductSize === '' || selectedProductSize === null) {
      addToast(`Please select product ${sizeLabel}`, {
        appearance: 'error',
        autoDismiss: true,
      })
      flag = false
    } else if (addRowQty === '' || addRowQty === null) {
      addToast('Please enter quantity', {
        appearance: 'error',
        autoDismiss: true,
      })
      flag = false
    } else if (selectedLocatons?.length > 0) {
      selectedLocatons.map((locationArray) => {
        if (
          !selectedArtworkID[locationArray] ||
          selectedArtworkID[locationArray] === ''
        ) {
          addToast('Please select artwork', {
            appearance: 'error',
            autoDismiss: true,
          })
          flag = false
        }
        if (
          selectedArtworkID[locationArray]?.artworkID !== '' &&
          selectedArtworkID[locationArray].variation === ''
        ) {
          addToast('Please select variation', {
            appearance: 'error',
            autoDismiss: true,
          })
          flag = false
        }
      })
    }
    return flag
  }
  useEffect(() => {
    if(catalogueCustomRows.length > 0 && statChangeTemp === 1) {
      if(colors_final.length === 1) {
        verifyShowColor()
        changeColor(colors_final[0], '', 0)
      }
      if(priceTable.length === 1 && colors_final.length === 1) {
        verifyShowSize()
        changeQty(addRowQtyFirst, 0 , priceTable[0].size, 1)
      }
      updateStateTemp(0)
    }
  },[statChangeTemp, catalogueCustomRows])
  const addRowFromSelection = () => {
    if (validateData() && selectedProductColor !== '') {
      let totalPrice = 0
      let price = 0
      let cost = 0
      let totalCost = 0
      let image = ''
      let sku = 0
      priceTable.map((priceTabel) => {
        if (priceTabel.size === selectedProductSize) {
          let priceTableOptions = priceTabel.priceOptions
          priceTableOptions.map((priceOption) => {
            if (
              priceOption.ColorArray.Color.colorName === selectedProductColor
            ) {
              sku = priceOption.sku
              if (priceOption.partPrice.PartPriceArray.PartPrice.length === 1) {
                price =
                  priceOption.partPrice.PartPriceArray.PartPrice[0].salePrice
                cost =
                  priceOption.partPrice.PartPriceArray.PartPrice[0].price
              } else {
                priceOption.partPrice.PartPriceArray.PartPrice.map(
                  (p, pKey) => {
                    if (
                      addRowQty !== null &&
                      Number(p.minQuantity) <= Number(addRowQty)
                    ) {
                      price = p.salePrice
                      cost = p.price
                    } else if (pKey === 0) {
                      price = p.salePrice
                      cost = p.price
                    }
                  },
                )
              }
              if (addRowQty !== '' && addRowQty !== null) {
                totalPrice = price * addRowQty
                totalCost = cost * addRowQty
              }
              product &&
                product.MediaContent.forEach((m) => {
                  if (m.color === selectedProductColor) {
                    if (m.url && m.url !== '') {
                      image = m.url
                    }
                  }
                })
            }
          })
        }
      })
      let newRow = [
        {
          id: uniqIdcounter++,
          size: selectedProductSize,
          quantity: addRowQty,
          image: image,
          color: selectedProductColor,
          product: { sku: sku },
          price: price,
          totalPrice: totalPrice,
          cost: cost,
          totalCost: totalCost,
          weight: product.weight,
          shipFrom: {
            countryFrom: product.shipFromCountry,
            zipCodeFrom: product.shipFromPostalCode,
          },
        },
      ]
      let deco = []
      selectedLocatons &&
        selectedLocatons.map((location) => {
          if (selectedArtworkID[location]) {
            let tempSelection = selectedArtworkID[location]
            let locationArray = product?.LocationArray?.Location.filter(
              (locationArray) => locationArray.locationId === location,
            )
            if (tempSelection.artworkID && tempSelection.variation) {
              deco.push({
                artworkID: tempSelection.artworkID,
                location: locationArray[0].locationName,
                locationId: location,
                variationUniqID: tempSelection.variation,
              })
            }
          }
        })
      selectedProductDecorations &&
        selectedProductDecorations.map((decoItem) => {
          if (decoItem.artworkID && decoItem.variationUniqID) {
            deco.push({
              artworkID: decoItem.artworkID,
              location: decoItem.location,
              locationId: decoItem.locationId,
              variationUniqID: decoItem.variationUniqID,
            })
          }
        })
      if (deco.length > 0) {
        newRow[0]['decorations'] = deco
      }
      let tempRows = catalogueCustomRows
      tempRows.push(newRow)
      tempRows = tempRows.filter((customRow) => {
        let flag = true
        customRow.map((row) => {
          if (row.color === '') {
            flag = false
          }
        })
        if (flag) {
          return customRow
        } else {
          return false
        }
      })

      //add row from section
      setCatalogueCustomRows(tempRows)
      setaddRowQty(null)
      setSelectedProductSize(null)
      setSelectedProductDecorations([])
      setselectedArtworkID([])
      setLocationSelected([])
      updatestate(!statchange)
    }
  }
  const addLodationToarray = (decorationId, locationName) => {
    let checkID = selectedLocatons.filter(
      (locationArray) => locationArray === locationName,
    )
    if (checkID?.length > 0) {
    } else {
      let tempArray = selectedLocatons
      tempArray.push(locationName)
      setLocationSelected(tempArray)
    }
    setToggleValue(!toggleValue)
  }
  let colorsTemp = []
  product?.MediaContent &&
    product.MediaContent.map((mediaElement) => {
      if (mediaElement?.color !== '') {
        if (colorsTemp.indexOf(mediaElement.color) === -1) {
          colorsTemp.push(mediaElement.color)
        }
      }
    })
  const getAlternateLayout = () => {
    let colorsTemp = []
    product?.MediaContent &&
      product.MediaContent.map((mediaElement) => {
        if (mediaElement?.color !== '') {
          if (colorsTemp.indexOf(mediaElement.color) === -1) {
            colorsTemp.push(mediaElement.color)
          }
        }
      })
    return (
      <>
        <QuoteSeprateList
          product={product}
          getSettingStatus={getSettingStatus}
          isAllowoutofStockOrder={IsAllowOutOfStockOrder}
          setToggleValue={setToggleValue}
          toggleValue={toggleValue}
          getStock={getStock}
          productSettings={productSettings}
          deleteRow={deleteRow}
          copyRow={copyRow}
          addFirstRow={addFirstRow}
          personalizationAdded={personalizationAdded}
          deletePersonalization={deletePersonalization}
          getPesonalizationTable={getPesonalizationTable}
          removeDeco={removeDeco}
          getDisplayStyle={getDisplayStyle}
          ToggleDivs={ToggleDivs}
          addPersonalization={addPersonalization}
          setPersonalizationAdded={setPersonalizationAdded}
          colors_final={colors_final}
          setCatalogueCustomRows={setCatalogueCustomRows}
          catalogueCustomRows={catalogueCustomRows}
          showLogoblock={showLogoblock}
          getDecoHtml={getDecoHtml}
          priceTable={priceTable}
          inventorySettings={inventorySettings}
          setSelectedImage={setSelectedImage}
          tempSelectedColor={tempSelectedColor}
          selectedImage={selectedImage}
		  isDisableProductClone={isDisableProductClone}
        />
      </>
    )
  }
  const removeDecoSingle = (itemsRows, index) => {
    let tempArray = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      if (groupkey === 0) {
        rowGroupArray = rowGroupArray.filter((groupArray) => {
          if (groupArray.decorations) {
            groupArray.decorations = groupArray.decorations.filter(
              (decoArray, decoIndex) => decoIndex !== index,
            )
          }
          return groupArray
        })
      }
      return rowGroupArray
    })

    //remove deco single
    setCatalogueCustomRows(tempArray)
    updatestate(!statchange)
  }
  const processSingleRow = () => {
    addRowFromSelectionSingle()
    setProcessSingle(true)
  }
  const addRowFromSelectionSingle = () => {
    if (validateData()) {
      let totalPrice = 0
      let price = 0
      let cost = 0
      let totalCost = 0
      let image = ''
      let sku = 0
      priceTable.map((priceTabel) => {
        if (priceTabel.size === selectedProductSize) {
          let priceTableOptions = priceTabel.priceOptions
          priceTableOptions.map((priceOption) => {
            if (
              priceOption.ColorArray.Color.colorName === selectedProductColor
            ) {
              sku = priceOption.sku
              if (priceOption.partPrice.PartPriceArray.PartPrice.length === 1) {
                price =
                  priceOption.partPrice.PartPriceArray.PartPrice[0].salePrice
                cost =
                  priceOption.partPrice.PartPriceArray.PartPrice[0].price
              } else {
                priceOption.partPrice.PartPriceArray.PartPrice.map(
                  (p, pKey) => {
                    if (Number(p.minQuantity) <= Number(addRowQty)) {
                      price = p.salePrice
                      cost = p.price
                    } else if (pKey === 0) {
                      price = p.salePrice
                      cost = p.price
                    }
                  },
                )
              }
              if (addRowQty !== '' && addRowQty !== null) {
                totalPrice = price * addRowQty
                totalCost = cost * addRowQty
              }
              product?.MediaContent &&
                product.MediaContent.forEach((m) => {
                  if (m.color === selectedProductColor) {
                    if (m?.url !== '') {
                      image = m.url
                    }
                  }
                })
            }
          })
        }
      })
      let newRow = [
        {
          id: uniqIdcounter++,
          size: selectedProductSize,
          quantity: addRowQty,
          image: image,
          color: selectedProductColor,
          product: { sku: sku },
          price: price,
          totalPrice: totalPrice,
          cost: cost,
          totalCost: totalCost,
          weight: product.weight,
          shipFrom: {
            countryFrom: product.shipFromCountry,
            zipCodeFrom: product.shipFromPostalCode,
          },
        },
      ]

      let deco = []
      selectedProductDecorations &&
        selectedProductDecorations.map((decoItem) => {
          if (decoItem.artworkID && decoItem.variationUniqID) {
            deco.push({
              artworkID: decoItem.artworkID,
              location: decoItem.location,
              locationId: decoItem.locationId,
              variationUniqID: decoItem.variationUniqID,
            })
          }
        })
      if (deco.length > 0) {
        newRow[0]['decorations'] = deco
      }

      //add row from single section
      setCatalogueCustomRows([newRow])
      setaddRowQty(null)
      setSelectedProductSize(null)
      setselectedArtworkID([])
      setLocationSelected([])
      updatestate(!statchange)
    }
  }
  let showBtn = true
  const goToCart = () => {
    localStorage.setItem('redirectUrl', window.location.pathname)
    if (isDirectToCart) {
      history.push('/cart')
    }
  }

  const totalPriceData = () => {
    let prdt = catalogueCustomRows.filter(rowGroupArray => {
      let cnt = rowGroupArray.filter(groupArray => {
         if(!!groupArray?.personalization) {
          return groupArray
         }
      });
      return cnt.length > 0
    });
    let totalSumqw = 0
    catalogueCustomRows.map((itemsRowsabs, keya) => { itemsRowsabs && itemsRowsabs.length > 0 && itemsRowsabs.map((sizeObj, index) => {
        if(!!sizeObj?.personalization) {
          totalSumqw = totalSumqw + Number(sizeObj?.quantity && sizeObj?.quantity)
        }
      })
    })
    if (personalizationCost?.price !== '' && personalizationCost?.price !== 'undefined' && personalizationCost?.price !== undefined && prdt.length > 0) {
      if (personalizationCost.matchOrderQty == '0') {
        toatlPrice = Number(toatlPrice) + Number(personalizationCost.price)+ (showSetupChargeInCart ? Number(personalizationCost.setupPrice) : 0)
      } else if (personalizationCost.matchOrderQty == '1') {
        toatlPrice =
          Number(toatlPrice)  +
          (getSettingStatus('showPersonalizationCostInCart') ? Number(personalizationCost.price)* Number(totalSumqw) : 0)+ (showSetupChargeInCart ? (Number(personalizationCost.setupPrice) * Number(prdt.length)) : 0)
      }
      else if (personalizationCost.matchOrderQty == '1') {
        toatlPrice = Number(toatlPrice) + (showSetupChargeInCart ? (Number(personalizationCost.setupPrice) * Number(prdt.length)) : 0)
      }
    }
    const totalValue = toatlPrice === 0 || toatlPrice > 0 ? (productSettings?.priceRoundUp ?  roundedPrice(Number(toatlPrice), decimalsToShow) : Number(toatlPrice).toFixed(decimalsToShow)) : 0
    return (
        <>
          {(showPrice !== false) && (
              usePointsAsCurrency ? getPointsValue(totalValue) :
                 <p><b>{`Total: ${currency.currencySymbol}${totalValue}`}</b></p>
          )}
        </>
    )
  }
  const [tempValue, setTempValue] = useState(0)
  const temp = (parentKey = '', artworkId = '', locaName = "") => {

    let productColor = catalogueCustomRows[parentKey][0].color
    let currentProduct = catalogueCustomRows[parentKey][0]
    if ((parentKey === 0 && artworkId !== '' && locaName === "") || (parentKey !== '' && artworkId !== '' && locaName === "")) {

      let decoData = currentProduct?.decorations?.filter(
        (val) => val.artworkID === artworkId && val.location === locaName,
      )

      let decoLocation = decoData?.[0]?.location
      setSelectedDecoration(decoData?.[0])

      let getIndex = product.LogoInfo.filter(
        (val) =>
          val.color === productColor &&
          val.location === decoLocation,
      )

      if (getIndex.length > 0) {
        setSelectedImage(getIndex[0]?.image)
        setIsSelectedImageChng(IsSelectedImageChng+1)
        setImageScroll(imageScroll+1)
      } else {

        let getIndexOfExtra_logo = []
        product.LogoInfo.map(val => {
          if (val.color === productColor) {

            if (val.extra_logo.filter(e => e.location === decoLocation).length > 0) {
              getIndexOfExtra_logo.push(val)
            }
          }
        })
        if (getIndexOfExtra_logo.length) {

          setSelectedImage(getIndexOfExtra_logo[0]?.image)
          setIsSelectedImageChng(IsSelectedImageChng+1)
          setImageScroll(imageScroll+1)
        } else {
          let getImgColorIndex = product.LogoInfo.filter(
            (val) =>
              val.color === productColor
          )
          if (getImgColorIndex.length > 0) {
            setSelectedImage(getImgColorIndex[0]?.image)
            setIsSelectedImageChng(IsSelectedImageChng+1)
            setImageScroll(imageScroll+1)
          }
        }
      }

    } else if (locaName !== "" && locaName !== undefined) {

      let decoData = currentProduct?.decorations?.filter(
        (val) => val.artworkID === artworkId && val.location === locaName,
      )
      setSelectedDecoration(decoData?.[0])

      let getIndex = product.LogoInfo.filter(function (val) {
        return val.color === productColor && val.location === locaName;
      });

      if (getIndex.length > 0) {
        setSelectedImage(getIndex[0].image)
        setIsSelectedImageChng(IsSelectedImageChng+1)
        setImageScroll(imageScroll+1)
      } else {
        let getIndexOfExtra_logo = []
        product.LogoInfo.map(val => {
          if (val.color === productColor) {

            if (val.extra_logo.filter(e => e.location === locaName).length > 0) {
              getIndexOfExtra_logo.push(val)
            }
          }
        })
        if (getIndexOfExtra_logo.length) {

          setSelectedImage(getIndexOfExtra_logo[0]?.image)
          setIsSelectedImageChng(IsSelectedImageChng+1)
          setImageScroll(imageScroll+1)
        }
      }
      setTempValue(tempValue + 1)
    } else if(locaName === "" && !!selectedLocation) {
      let locationNameData = catalogueCustomRows?.[parentKey]?.[0]?.decorations.filter(res=>res.locationId === selectedLocation)
      if (locationNameData?.length) {
        let decoData = currentProduct?.decorations?.filter(
          (val) => val.artworkID === artworkId && val.location === locationNameData[0].location,
        )
        setSelectedDecoration(decoData?.[0])
        let getIndex = product.LogoInfo.filter(function (val) {
          return val.color === productColor && val.location === locationNameData[0].location;
        });

        if (getIndex.length > 0) {
          setSelectedImage(getIndex[0].image)
          setIsSelectedImageChng(IsSelectedImageChng + 1)
        }
      }

      setTempValue(tempValue + 1)
    }
    setNotCallDecoHtml(false)
    ToggleDivs(parentKey, 'D', 'clickOnArtWork')
    setIsNewArtWorkAdded(isNewArtWorkAdded+1)
  }
  let categoryName = []
  if (product.ProductCategoryArray) {
    product.ProductCategoryArray.map((cat) => {
      let findcat = categoriesList.filter((val) => val.categoryName === cat.name || val._id === cat.id);
      if (findcat.length) {
        categoryName.push(findcat[0]?.categoryName)
      }
    })
  }
  return (
    <div className={`shop-area portalLayout container pt-100 pb-100`}>
      {loading && <Loader />}
      <div className="col-md-12">
        <div className="row p-2">
          <div className="col-md-5 col-sm-12 col-xs-12">
            <div className="product-large-image-wrapper">
                        {swipeimageFlag && (
							  isReplace ? isTrue&&<Swiper {...gallerySwiperParams}>{swipeimage}</Swiper> :
							  isTrue&&<Swiper {...gallerySwiperParams}>{swipeimagewebstore}</Swiper>

						  )}

            </div>
          </div>
          <div className="col-md-7 col-sm-12 col-xs-12">
            <h3>{product.productName}</h3>
            <div className="product-details-content quickview-content">
              <p>
                <b>Item Number</b>:{' '}
                {itemNumber ? product[itemNumber] : product['productId']}
              </p>
              {productSettings?.showCategory === true && (
                <p>
                  <b>Categories</b>: {categoryName?.join(', ')}
                </p>
              )}
            {productSettingShowDescription === true && (
            <>
              <b>Product Description</b>:
              {product?.description && parse(product.description)}
            </>
            )}
              <hr />
            </div>
            <div className="product-details-content quickview-content">
              {(product && priceTables.length > 0 && !!product?.displayPriceGrid) &&
                <PriceTable
                  priceTable={priceTables}
                  currency={currency}
                  decimalsToShow={decimalsToShow}
                  doNotCalculateAtOrder={product?.doNotCalculateAtOrder}
                  userGroupDiscount={userGroupDiscount}
                  showSellPrice={showSellPrice}
                  showSell={showSell}
                  offerPrice={offerPrice}
                  discountType={discountType}
                  layout='condense'
                />}
            </div>
            <div className="row">
              <div className="product-details-content col-md-10 col-sm-12 col-xs-12">
                <div
                  id="sw1"
                  className="product-details-content col-md-12 col-sm-12 col-xs-12 p-1 text-center"
                >
                  <div className="product-small-image-wrapper product-slider-img mt-15">
                    {isReplace ? isTrue && <Swiper {...thumbnailSwiperParams}>
                      {product?.MediaContent?.filter(
                          (mediaArray) => !mediaArray.hidden,
                      ).map((single, key) => {
                        let getImage = product?.WebstoreProductPartArray.filter((val) => val.color === single.color && val.isSwapImage === true,)
                        let mainCls = "single-image"
                        if((getImage && getImage.length > 0 && !!getImage[0]?.image && productImageExt(getImage[0]?.image)) || (!!single?.url && productImageExt(single.url)))
                        {
                          mainCls = 'single-image product-img-bg'
                        }
                        return (
                            <div key={key} onClick={() => getDecoAttach(single.color, single.url, 1)}>
                              <div
                                  className={mainCls}
                                  style={{maxHeight: '200px'}}
                              >
                                <img
                                    src={getImage && getImage.length > 0 ? productImageUrl(getImage[0]?.image) : productImageUrl(single.url)}
                                    className="img-fluid"
                                    alt=""
                                />
                              </div>
                              <span>{single.color}</span>
                            </div>
                        )
                      })}
                    </Swiper> : isTrue && <Swiper {...thumbnailSwiperParams}>
                      {product?.WebstoreProductPartArray?.filter(
                          (ele, ind) => ind === product?.WebstoreProductPartArray.findIndex(elem => elem.color === ele.color)).map((single, key) => {
                        let getImage = product?.WebstoreProductPartArray.filter((val) => val.color === single.color && val.isSwapImage === true,)
                        let mediaImage = product?.MediaContent?.filter(
                            (mediaArray) => !mediaArray.hidden && mediaArray.color === single.color,)
                        let mainCls = 'single-image'
                        if((getImage && getImage.length > 0 && !!getImage[0]?.image && productImageExt(getImage[0]?.image)) || (!!single?.image && productImageExt(single.image)) ||  productImageExt(mediaImage[0]?.url))
                        {
                          mainCls = 'single-image product-img-bg'
                        }
                        return (
                            <div key={key} onClick={() => getDecoAttach(single.color, single.image, 1)}>
                              <div
                                  className={mainCls}
                                  style={{maxHeight: '200px'}}
                              >
                                <img
                                    src={getImage && getImage.length > 0 ? productImageUrl(getImage[0]?.image) : single.image ? productImageUrl(single.image) : productImageUrl(mediaImage[0]?.url)}
                                    className="img-fluid"
                                    alt=""
                                />
                              </div>
                              <span>{single.color}</span>
                            </div>
                        )
                      })}
                    </Swiper>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

            <div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row p-2">
                    <div className="col-md-2">
                      <h2>Items</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md text-center">
                  {product?.showColorSizeDesc === true && (
                    <span>
                      {product?.colorSizeDesc ? parse(product.colorSizeDesc) : ""}
                    </span>
                  )}

                </div>
              </div>
              <div className="row">
            <div className="col-md-12 p-2">
                  {product &&
                    catalogueCustomRows?.length > 0 &&
                    catalogueCustomRows.map((quoteItemRow, key) => {

                      return getRowHTML(quoteItemRow, key)
                    })}
                    {showAddVariation &&
                      <div className="row">
                        <div className="col-md-12 mb-15 pr-3">
                          <button
                            className="btn add-row float-right"
                            onClick={isRestrictQty ? () => restrictValidation() : () => addFirstRow()}
                          >
                            {!!rowText ? rowText : 'Add Variations'}
                          </button>
                        </div>
                      </div>
                    }
                  <div
                    className="lineitemsrow mb-25"
                    style={{ backgroundColor: 'lightgray' }}
                  >
                    <div className="row p-2">
                      <div
                        className={'dispnon col-lg-5 col-sm-6 p-1 lineflex'}
                      ></div>
                      <div className="col-md-3 col-sm-6 p-1 dispnon"></div>
                      <div className={'col-lg-2 lineflex'}>
                        <div className="p-1">
                          <b>{`Total Qty: ${toatlQty}`}</b>
                        </div>
                      </div>
                      <div className={'col-lg-2 col-sm-6 pl-3 lineflex'}>
                        <div className="p-1"> {totalPriceData()}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
        {/*)}*/}
        {/*{product?.productOrderType === '1' && <div>{getAlternateLayout()}</div>}*/}
        {showBtn && ((showAddToCartOnProd !== false && getSettingStatus('showAddToCart') !== false) || getSettingStatus('showAddToCart') !== false) && (
          <div className="row  ">
            <div className="col-md-12 ">
              <div className="cartBtn float-right">
                {(totalStock > 0 || IsAllowOutOfStockOrder) ? (
                  <button
                    onClick={() => {
                      if (checkMinQty(prodId, toatlQty, isRestrictQty, minAllQty, isMoq, isSkuMoq, minQty, null, product?.doNotCalculateAtOrder)) {
                        if(product.showColorProd) {
                          if(!selectedProductColor || selectedProductColor === `Select ${colorLabel}`){
                            orderAction(`You must select a ${colorLabel}`, "error");
                            return;
                          }
                        }
                        addtoCart()
                      }
                    }}
                  >
                    {' '}
                    Add To Cart{' '}
                  </button>
                ) : (
                  <button disabled>Out of Stock</button>
                )}
              </div>
            </div>
          </div>
        )}
        {showAddnewart && (
          <AddnewArtDialogue
		  	productId={productId}
            open={showAddnewart}
            show={showAddnewart}
            showAddnewartId={showAddnewartId}
            title="Add New Artwork"
            onHide={() => handleCancel()}
            handleSubmit={handleAddnewArt}
            cancel={() => handleCancel()}
            catalogueItem={product}
          />
        )}
        {showDecoPopup && (
          <SharedModal
            indexPopup={indexPopup}
            mainArrayKeyPopup={mainArrayKeyPopup}
            itemsRowsPopup={itemsRowsPopup}
            popupText={popupText}
            removeDeco={removeDeco}
            setShowDecoPopup={setShowDecoPopup}
            showDecoPopup={showDecoPopup}
            decoItemData={decoItemData}
          />
        )}
      </div>
    </div>
  )
}

PortalImageDescription.propTypes = {
  cartItems: PropTypes.array,
  compareItems: PropTypes.array,
  currency: PropTypes.object,
  galleryType: PropTypes.string,
  product: PropTypes.object,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  wishlistItems: PropTypes.array,
}

const mapStateToProps = (state) => {
  return {
    currency: state.currencyData,
    cartItems: state.cartData.cartItems,
    wishlistItems: state.wishlistData,
    artworkList: state.productData.artworkList,
    artworkListById: state.productData.artworkListById,
    compareItems: state.compareData,
    decorationLocations: state.productData.decorationLocations,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    addItemsRowsTocart: (item, rows, addToast, isPriceBreak, ipV4, userGroupDiscount, holdServerUpdate) => {
      dispatch(addItemsRowsTocart(item, rows, addToast, isPriceBreak, ipV4, userGroupDiscount, holdServerUpdate))
    },
    fetchDecorationLocations: (data) => dispatch(fetchDecorationLocations(data)),
    fetchArtworksById: (data) => dispatch(fetchArtworksById(data)),
    fetchProductStock: (data) => dispatch(fetchProductStock(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PortalImageDescription);
