import { aetherApi } from './aetherApi'

export const fulfillmentMethodApi = aetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getFulfillmentTypes: builder.query<string[], void>({
      query: () => `api/v1/fulfillment-methods/types`,
    }),
  }),
})

export const { useGetFulfillmentTypesQuery } = fulfillmentMethodApi