import { Stack } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import React from 'react'
import { DatePickerElement } from 'react-hook-form-mui/date-pickers'
import { useWatch } from 'react-hook-form'
import moment from 'moment'

interface CheckoutDateFieldProps {
  name: string
  label: string
  required: boolean
  helperText?: string
}

export default function CheckoutDateField({
  name,
  label,
  required,
  helperText,
}: CheckoutDateFieldProps) {
  const value = useWatch({ name })
  console.log(typeof value)

  return (
    <Stack direction={'column'}>
      <DatePickerElement
        name={name}
        format="MM/DD/YYYY"
        label={label}
        helperText={helperText}
        disablePast
        rules={{
          required,
          validate: {
            internal: (value) =>
              !required || (value !== null && value !== '0000-00-00'),
          },
        }}
        required={required}
        transform={{
          input: (value) =>
            value && value !== '0000-00-00' ? moment(value) : null,
          output: (value) => (value ? value.format('YYYY-MM-DD') : null),
        }}
        slotProps={{ field: { clearable: true } }}
        defaultValue={null}
        inputProps={{
          size: 'small',
          required,
          slotProps: {
            htmlInput: {
              'data-testid': name,
            },
          },
        }}
      />
    </Stack>
  )
}
