import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {AuthContext} from '../../../common/Auth';
import { useShopFormatColors } from "./useShopFormatColors";
import {
    setCategoriesProducts,
    setColors, setProductSearchQuery,
    setSizesProduct,
    setTagsProducts
} from "../../../redux/actions/productActions";

export const useShopGridStandardState = (props) => {
    const dispatch = useDispatch();
    const [selectedColors, setSelectedColors] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [selectedSizes, setSelectedSizes] = useState([])
    const [productQuery, setProductQuery] = useState('')
    const {categoriesList } = useContext(AuthContext);

    const {
        colorsParams, tagsParams, categoriesParams, sizeParams
    } = props

    const {
        groupedColors
    } = useShopFormatColors({
        colors: props.colors
    })

    useEffect(()=>{
        if(!!!props.tags?.length) {
            return
        }
        if(!!!tagsParams) {
            setSelectedTags([])
            return;
        }
        const tags = []
        const tagsId = tagsParams.split('_')
        tagsId.forEach(tag => {
            const index = props.tags.findIndex(x => x._id === tag)
            if(index >= 0) {
                tags.push(props.tags[index])
            }
        })
        setSelectedTags(tags)
    }, [props.tags, tagsParams])

    useEffect(() => {
        if(!!!props.colors?.length) {
            return
        }
        if(!!!colorsParams) {
            setSelectedColors([])
            return;
        }
        const formattedColors = groupedColors();
        const colorNames = colorsParams?.split('_');
        const colors = []
        if(colorNames?.length > 0) {
            colorNames.forEach(name => {
                const index = formattedColors.findIndex(x => name === Object.keys(x)[0])
                if(index >= 0) {
                    colors.push(formattedColors[index]);
                }
            })
        }
        setSelectedColors(colors)
    },[props.colors, colorsParams])

    useEffect(() => {
        if(!!!categoriesList?.length) {
            return
        }
        if(!!!categoriesParams) {
            setSelectedCategories([])
            return;
        }
        const catIds = categoriesParams?.split('_');
        const categories = []
        if(catIds?.length > 0) {
            catIds.forEach(id => {
                const index = categoriesList.findIndex(x => id === x._id)
                if(index >= 0) {
                    categories.push({
                        category: categoriesList[index]
                    });
                }
            })
        }
        setSelectedCategories(categories)
    },[categoriesParams, categoriesList])

    useEffect(() => {
        if(!!!props.searchParams) {
            setProductQuery('')
            return
        }
        const query  = props.searchParams.replace('+', ' ');
        setProductQuery(query)
    },[props.searchParams])

    useEffect(() => {
        if(!!!props.sizes?.length) {
            return
        }
        if(!!!sizeParams) {
            setSelectedSizes([])
            return;
        }
        const sizeIds = sizeParams?.split('_');
        const sizes = []
        if(sizeIds?.length > 0) {
            sizeIds.forEach(size => {
                const index = props.sizes.findIndex(x => size === x)
                if(index >= 0) {
                    sizes.push(props.sizes[index]);
                }
            })
        }
        setSelectedSizes(sizes)
    },[props.sizes, sizeParams])

    useEffect(() => {
        dispatch(setColors(selectedColors));
    }, [selectedColors])

    useEffect(() => {
        dispatch(setTagsProducts(selectedTags))
    }, [selectedTags])

    useEffect(() => {
        dispatch(setCategoriesProducts(selectedCategories))
    }, [selectedCategories])

    useEffect(() => {
        dispatch(setSizesProduct(selectedSizes))
    }, [selectedSizes])

    useEffect(() => {
        dispatch(setProductSearchQuery(productQuery))
    }, [productQuery])

    return {
        selectedColors,
        selectedTags,
        selectedCategories,
        selectedSizes
    }
};
