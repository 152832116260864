import React from "react";
import ClearIcon from "@material-ui/icons/Clear";
import { useHistory } from "react-router-dom";
import { PRODUCTS } from '../../../common/ActionUrl'
import { useShopCategoryDescriptionState } from "../state/useShopCategoryDescriptionState";

const ShopGridRightSidebar = ({
  showAllProduct,
  selectedCategories,
  selectedTags,
  selectedColors,
  selectedSizes,
  deleteSelectedCat,
  deleteSelectedColor,
  deleteSelectedTag,
  deleteSelectedSize,

  ShopTopbarComponent,
  ShopSidebarComponent,
  PaginatorComponent,
  ShopProductsComponent,
  generalDescription,
}) => {

  const history = useHistory()
  const  {
    categoryDescription,
        categoryBottomDescription
    } = useShopCategoryDescriptionState({
        generalDescription,
        selectedCategories
    })
  return (
    <div className="shop-area pt-30 pb-100">
      <div className="container">
        <div className="d-flex mb-5">
          <div className="left selectAll">
            <span
              onClick={(e) => {
                history.push(PRODUCTS)
              }}
            >
              All Products
            </span>
          </div>
          <div className="right selectedItem">
            {selectedCategories.map(({ category }) => {
              return (
                <span>
                  {category.categoryName}{" "}
                  <ClearIcon
                    style={{ fontSize: "12px", cursor: "pointer" }}
                    onClick={() => deleteSelectedCat([category._id])}
                  />
                </span>
              );
            })}
            {selectedTags.map((tag) => {
              return (
                <span onClick={() => deleteSelectedTag(tag._id)}>
                  {tag.tagName}{" "}
                  <ClearIcon style={{ fontSize: "12px", cursor: "pointer" }} />
                </span>
              );
            })}
            {selectedColors.map((color) => {
              let colorName=Object.keys(color)[0].toLowerCase()
              const isWhite = colorName === "white";
              return (
                <span
                  className="selectedColorBox"
                  style={{ background: colorName, border: `${isWhite && "1px solid black"}` }}
                  onClick={() => deleteSelectedColor(colorName)}
                >
                  {" "}
                  <ClearIcon className="colorDeleteIcon" />
                </span>
              );
            })}
            {selectedSizes.map((size) => {
              return (
                <span onClick={() => deleteSelectedSize(size)}>
                  {size}{" "}
                  <ClearIcon style={{ fontSize: "12px", cursor: "pointer" }} />
                </span>
              );
            })}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 order-2">
            {/* shop sidebar */}

            {ShopSidebarComponent()}
          </div>
          <div className="col-lg-9 order-1">
            {/* shop topbar default */}
            <div className="row d-flex mb-12 mt-3">
              <div className="col-md-12 col-12">
                { (categoryDescription !== "" &&
                    <h6 dangerouslySetInnerHTML = {{__html: `${categoryDescription}`}}/>
                )
                }
              </div>
            </div>
            {ShopTopbarComponent()}

            {/* shop page content default */}
            {ShopProductsComponent()}

            {/* shop product pagination */}
            <div className="pro-pagination-style text-center mt-30">
              {PaginatorComponent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopGridRightSidebar;
