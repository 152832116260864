import * as productsAPIUtil from "../../util/products/productsAPIUtil"

export const RECEIVE_FEA_GROUP_PRODUCTS = "RECEIVE_FEA_GROUP_PRODUCTS";
export const RECEIVE_FEATURED_PRODUCTS = "RECEIVE_FEATURED_PRODUCTS";
export const RECEIVE_FEATURED_PRODUCTS_REQUEST = "RECEIVE_FEATURED_PRODUCTS_REQUEST";
export const IS_LOADING = "IS_LOADING";
export const FETCH_ERROR = "FETCH_ERROR";

export const isLoading = (loading) => ({
    type: IS_LOADING,
    loading,
  })

  export const sendFetchError = () => ({
    type: FETCH_ERROR,
  })

export const receiveFeaturedGroupProducts = (data) => ({
  type: RECEIVE_FEA_GROUP_PRODUCTS,
  payload: data,
})

export const receiveFeaturedProducts = (data, count, meta) => ({
    type: RECEIVE_FEATURED_PRODUCTS,
    payload: data,
    count,
    meta,
  })

export const receiveFeaturedProductsRequest = () => ({
    type: RECEIVE_FEATURED_PRODUCTS_REQUEST,
  })

export const fetchFeaturedProducts =
  (
    limit = 50,
    offset = 0,
    order = 'productName',
    orient = 'asc',
    filters = [],
  ) =>
  (dispatch) =>{
    dispatch(receiveFeaturedProductsRequest())
    productsAPIUtil
      .fetchFeaturedProducts(limit, offset, order, orient, filters)
      .then((res) => {
        const response = res.data
        const listData = response.results.map((dataArray) => {
          return dataArray
        })
        dispatch(
          receiveFeaturedProducts(
            listData,
            response.meta.page_count,
            response.meta,
          ),
        )
      })
      .catch((error) => {
        dispatch(sendFetchError())
      }).finally(() => dispatch(isLoading(false)))
    }

  export const fetchFeaturedGroupProducts =
    (
      limit = 50,
      offset = 0,
      order = 'productName',
      orient = 'asc',
      filters = [],
    ) =>
    (dispatch) => {
      dispatch(receiveFeaturedProductsRequest())
      productsAPIUtil
        .getFeatureProducts(limit, offset, order, orient, filters)
        .then((res) => {
          const response = res.data
          dispatch(
            receiveFeaturedGroupProducts(
              response
            ),
          )
        })
        .catch((error) => {
          dispatch(sendFetchError())
        }).finally(() =>  dispatch(isLoading(false)))
    }
