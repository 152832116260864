import { useState } from 'react';

const useForm = (initialValues, validate, callback, fromEditAction) => {
    const [inputs, setInputs] = useState(initialValues);
    const [temp, setTamp] = useState(0);
    const [errors, setErrors] = useState({});

    const handleSubmit = (event) => {

        if (event) {
            event.preventDefault();
        }

        if (validate) {
            const validationErrors = validate(inputs, fromEditAction);
            const noErrors = Object.keys(validationErrors).length === 0;
            setErrors(validationErrors);
            if (noErrors) {
                callback();
            } else {
                console.log("errors try again", validationErrors);
            }
        } else {
            callback();
        }
    }

    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === "image1" || event.target.name === "popupImg") {
            setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.files[0] }));
        } else if (event.target.name === "showButton") {
            setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.checked }));
        } else {
            setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
        }

        if (validate && event.target.value.length > 0) {
            setErrors(errors => ({ ...errors, [event.target.name]: "" }));
        }
    }

    const handleColorPicker = (name, value) => {
        setInputs(inputs => ({ ...inputs, [name]: value }));
        if (validate && name) {
            setErrors(errors => ({ ...errors, [name]: "" }));
        }

    }

    const resetColor = (value, name) => {
        setInputs(inputs => ({ ...inputs, [name]: (value) }));
        if (validate && name) {
            setErrors(errors => ({ ...errors, [name]: "" }));
        }
    }
    const tempData = () => {
        setTamp(temp + 1)
    }
    const resetCode = (value, name) => {
        setInputs(inputs => ({ ...inputs, [name]: (value) }));
        tempData()
        if (validate && name) {
            setErrors(errors => ({ ...errors, [name]: "" }))
        }
    }

    const handleCheckboxInputChange = (event) => {

        if (event.target.checked && event.target.checked != undefined && event.target.checked != "undefined") {
            setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.checked }));
        } else {
            setInputs(inputs => ({ ...inputs, [event.target.name]: false }));
        }

        if (validate && event.target.value.length > 0) {
            setErrors(errors => ({ ...errors, [event.target.name]: "" }));
        }

    }

    const handleFileInputChange = (event) => {
        setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.files[0] }));
        if (validate && event.target.value.length > 0) {
            setErrors(errors => ({ ...errors, [event.target.name]: "" }));
        }
    }
    const setImage = (value, name) => {
        setInputs(inputs => ({ ...inputs, [name]: (value) }));
    }

    const handleCustomeInput = (name, value) => {
        setInputs(inputs => ({ ...inputs, [name]: value }));
        if (validate && name) {
            setErrors(errors => ({ ...errors, [name]: "" }));
        }
    }
    const resetForm = () => {
        setInputs(initialValues)
    }

    const resetErrors = () => {
        setErrors("")
    }

    const handleDualListbox = (selected, name) => {
        setInputs((formStates) => ({ ...formStates, [name]: selected }))
    }

    return {
        handleSubmit,
        handleInputChange,
        handleColorPicker,
        handleCheckboxInputChange,
        handleFileInputChange,
        setImage,
        resetColor,
        handleCustomeInput,
        inputs,
        errors,
        resetCode,
        resetForm,
        resetErrors,
        handleDualListbox,
    };
}

export default useForm;