import React from "react";

const NoProductFound = () => {
    return (
        <>
            <div className="NoProductFound">
              <p>No Product Found</p>
            </div>
        </>
    );
};

export default NoProductFound;