import {
  RECEIVE_CATEGORIES,
  RECEIVE_COLORS,
  RECEIVE_SIZES,
  FETCHING_CATEGORIES,
  FETCHING_COLORS
} from "../actions/shopActions";


const initState = {
  categories: [],
  sizes: [],
  colors:[],
  fetchingCategories: false,
  fetchingColors: false
};

const shopReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCHING_CATEGORIES: {
      return {
        ...state,
        fetchingCategories: action.payload,
      }
    }
    case FETCHING_COLORS: {
      return {
        ...state,
        fetchingColors: action.payload,
      }
    }
    case RECEIVE_CATEGORIES: {
      return {
        ...state,
        categories: action.payload
      }
    }
    case RECEIVE_COLORS: {
      return {
        ...state,
        colors: action.payload
      }
    }
    case RECEIVE_SIZES: {
      return {
        ...state,
        sizes: action.payload
      }
    }
    default:
      return state;
  }
};

export default shopReducer;
