import { CircularProgress, MenuItem, Stack, Typography } from "@mui/material";
import React from "react";
import { FieldValues, RegisterOptions, SelectElement } from "react-hook-form-mui";

interface CheckoutDropdownProps {
    name: string;
    label: string;
    helperText?: string;
    rules?: Omit<RegisterOptions<FieldValues, string>, "disabled" | "valueAsNumber" | "valueAsDate" | "setValueAs">;
    options: {id: string, label: string}[];
    loading?: boolean;
}

export default function CheckoutDropdown({ name, label, helperText, rules, options, loading }: CheckoutDropdownProps) {
    return (
        <Stack direction={"column"}>
            <SelectElement
                name={name}
                size="small"
                label={label}
                helperText={helperText}
                rules={rules}
                required={rules?.required === true}
                options={options}
                disabled={loading}
                slotProps={{
                  select: {
                    startAdornment: loading ? <CircularProgress size={20} /> : undefined,
                  },
                  htmlInput: {
                    "data-testid": name,
                  },
                }}
            />
        </Stack>
    );
}