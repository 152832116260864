import * as shopApiUtils from "../../util/shop/shopAPIUtil"
import { NEWCOLOR, X_TENANT_ID } from "../../common/ActionUrl";
import axios from "axios";
import { fetchColors } from "../../util/shop/shopAPIUtil";

export const RECEIVE_CATEGORIES = "RECEIVE_CATEGORIES";
export const RECEIVE_COLORS = "RECEIVE_COLORS";
export const RECEIVE_SIZES = "RECEIVE_SIZES";
export const FETCHING_CATEGORIES = "FETCHING_CATEGORIES";
export const FETCHING_COLORS = "FETCHING_COLORS";

export const receiveCategories = (data) => ({
  type: RECEIVE_CATEGORIES,
  payload: data,
});

export const receiveColors = (data) => ({
  type: RECEIVE_COLORS,
  payload: data,
});

export const fetchingCategories = (data) => ({
  type: FETCHING_CATEGORIES,
  payload: data,
});

export const fetchingColors = (data) => ({
  type: FETCHING_COLORS,
  payload: data,
});


export const receiveSizes = (data, count, meta) => ({
    type: RECEIVE_SIZES,
    payload: data,
});

export const getCategoryList = (data) => dispatch => shopApiUtils.fetchCategoryList(data)
  .then(res => {
    const response = res.data;
    // dispatch(receiveCategories(response.categories))
    dispatch(receiveSizes(response.sizes))
  }).catch(error => {
      console.log(error)
  }).finally(() => fetchingCategories(false) );

export const getColorList = () => dispatch => shopApiUtils.fetchColors()
  .then(res => {
    const response = res.data;
    dispatch(receiveColors(response))
  }).catch(error => {
      console.log(error)
    }).finally(() => fetchingColors(false) );
