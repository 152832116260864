import React, {useEffect, useState} from "react"

export const useShopSizesState = (props) => {
    const {sizes, selectedSizes, sortSizeSetting} = props;
    const {isExpand} = props;
    const [sortSizes, setSortSizes] = useState(sizes)
    const sortOrder = sortSizeSetting ? sortSizeSetting.map(s => s.value) : [];
    const [defaultExpand, setDefaultExpand] = useState(null)
    const dataFilterStyle = {}
    useEffect(() => {
        if (isExpand === true) {
            setDefaultExpand(true)
        } else {
            setDefaultExpand(false)
        }
    }, [isExpand])

    if (defaultExpand) {
        Object.assign(dataFilterStyle, {maxHeight: 'none'});
    }
    useEffect(() => {
        let sort = [];
        if (sizes && sizes.length > 0) {
            sortOrder.map((v) => {
                let size = sizes.find((s) => s === v)
                if (size) {
                    sort.push(size)
                }
            })
            sizes.map((v) => {
                let size = sort.find((s) => s === v)
                if (!size) {
                    sort.push(v)
                }
            })
            setSortSizes(sort)
        }
    }, [sizes])

    return {
        defaultExpand,
        sortSizes,
        selectedSizes,
        dataFilterStyle
    }
}
