import { Country } from "../../models/Country";
import { State } from "../../models/State";
import { aetherApi } from "./aetherApi";


const advanceApi = aetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getCountries: builder.query<Country[], void>({
      query: () => ({
        url: `api/v1/advance/custom`,
        method: 'POST',
        body: { method: 'country-list' },
      }),
      transformResponse: (
        countries: { CountryName: string; isoCode: string }[],
      ) => countries.map((c) => ({ name: c.CountryName, code: c.isoCode })),
    }),
    getStates: builder.query<State[], string>({
      query: (countryCode) => ({
        url: `api/v1/advance/orders/global-state-list-by-country`,
        method: 'POST',
        body: { country: countryCode },
      }),
      transformResponse: (states: { state: string; stateCode: string }[]) =>
        states.map((s) => ({ name: s.state, code: s.stateCode })),
    }),
  }),
})

export const {
  useGetCountriesQuery,
  useGetStatesQuery,
  useLazyGetStatesQuery,
} = advanceApi
