import React, { lazy } from "react";
const ProductTabBottom = lazy(() => import("../pages/shop-product/Product"));
const ProductTabLeft = lazy(() => import("../pages/shop-product/ProductTabLeft"));
const ProductTabRight = lazy(() => import("../pages/shop-product/ProductTabRight"));
const ProductSticky = lazy(() => import("../pages/shop-product/ProductSticky"));
const ProductSlider = lazy(() => import("../pages/shop-product/ProductSlider"));
const ProductFixedImage = lazy(() => import("../pages/shop-product/ProductFixedImage"));
const ProductSimple = lazy(() => import("../pages/shop-product/Product"));
const ProductVariation = lazy(() => import("../pages/shop-product/Product"));
const ProductAffiliate = lazy(() => import("../pages/shop-product/Product"));
const ProductPortal = lazy(() => import("../pages/shop-product/ProductPortal"));
const ProductPortalCondensed = lazy(() => import("../pages/shop-product/ProductPortalCondensed"));
const ProductPromotional = lazy(() => import("../pages/shop-product/ProductPromotional"));
const ProductECom = lazy(() => import("../pages/shop-product/ProductEcom"));
const ProductPrint = lazy(() => import("../pages/shop-product/ProductPrint"));
const ProductAttributeImage = lazy(() => import("../pages/shop-product/ProductAttributeImage"));
const ProductCatalogueLayout = lazy(() => import("../pages/shop-product/ProductCatalogueLayout"));
const ProductHatsLayout = lazy(() => import("../pages/shop-product/ProductHatsLayout"));

const productComponent = {
    "product-tab-bottom": ProductTabBottom,
    "product-tab-left": ProductTabLeft,
    "product-tab-right": ProductTabRight,
    "product-sticky": ProductSticky,
    "product-slider": ProductSlider,
    "product-fixed-image": ProductFixedImage,
    "product-simple": ProductSimple,
    "product-variation": ProductVariation,
    "product-affiliate": ProductAffiliate,
    "product-portal": ProductPortal,
    "product-condensed": ProductPortalCondensed,
    "promotional-product": ProductPromotional,
    "eComm-product-template": ProductECom,
    "product-printable": ProductPrint,
    "attribute-image": ProductAttributeImage,
    "stepper": ProductAttributeImage,
    "catalogue": ProductCatalogueLayout,
    "decoration-expanded": ProductHatsLayout,
};

export default productComponent
