import React, { useEffect, useState } from 'react';
import { Card, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, makeStyles } from '@material-ui/core';
import * as productsAPIUtil from '../../util/products/productsAPIUtil';
import { getPersColor, getPersFont, getPersLocation, getPersNote } from '../../helpers/product';
import { Add, Delete } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles({
  table: {
    "& .MuiTableRow-root:last-child": {
      "& td": {
        borderBottom: 0,
      }
    }
  }
});

function PersonalizationTable({
	personalizationArray,
	sizeLabel,
	colorLabel,
	addPersonalizationRow,
	deletePersonalizationRow,
	changePersonalizationArray
}) {
	const classes = useStyles();
	
	return (
		<Card variant="outlined">
			<Table className={classes.table}>
				<TableHead>
					<TableRow>
						<TableCell>Item</TableCell>
						<TableCell>{sizeLabel}</TableCell>
						<TableCell>Item {colorLabel}</TableCell>
						<TableCell>Line</TableCell>
						<TableCell>Text</TableCell>
						<TableCell></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{personalizationArray.map((personalization, key) => {
						let flag = Number(personalization.sequance) === 1;
						const objOptions = []
						if (!!personalization?.isDrp) {
							!!personalization?.drpValue && personalization?.drpValue.length > 0 && personalization.drpValue.map(op => {
								objOptions.push({ value: op, label: op })
							})
						}
						return (
							<TableRow key={key}>
								<TableCell>{flag && 'Item ' + personalization.rowQtyNo}</TableCell>
								<TableCell>{flag && personalization.itemSize}</TableCell>
								<TableCell>{flag && personalization.itemColor}</TableCell>
								<TableCell>{!!personalization?.lineName ? personalization?.lineName : 'Line ' + personalization.sequance}</TableCell>
								<TableCell>
									{!(!!personalization?.isDrp) ? (
										<TextField
											variant="outlined"
											size="small"
											placeholder="Value"
											fullWidth
											onChange={(e) => {
												changePersonalizationArray(e.target.value, 'displayText', personalization)
											}}
											value={personalization.displayText}
										/>
									) : (
										<Autocomplete
											value={objOptions.find(op => op.value === personalization.displayText) ?? ''}
											options={objOptions}
											name="isDrpValuePer"
											clearOnBlur
											blurOnSelect
											size="small"
											renderInput={(params) => <TextField {...params} required placeholder="Option" variant="outlined" />}
											getOptionLabel={(option) => option?.label ?? ""}
											onChange={(_, value) => {
												changePersonalizationArray(value?.value ?? null, "displayText", personalization)
											}}
										/>
									)}
								</TableCell>
								{(!(!!personalization?.isDrp) && !(!!personalization?.isEnabled) && !(!!personalization?.requiredLine)) ? (
									<TableCell>
										{flag && (
											<Add
												onClick={() => {
													addPersonalizationRow(personalization, key)
												}}
											/>
										)}
										{!flag && (
											<Delete
												onClick={() => {
													deletePersonalizationRow(personalization, key)
												}}
											/>
										)}
									</TableCell>
								) : ''}
							</TableRow>
						)
					})}
				</TableBody>
			</Table>
		</Card>
	)
}

export default PersonalizationTable;