import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import validate from './Validate';
import useForm from './customHooks';
import { BANNER, X_TENANT_ID, EDIT_BANNER } from '../../common/ActionUrl'
import axios from 'axios';
import { AuthContext } from "../../common/Auth"
import { getNewBody } from "../../util/base64-aws-util"
import BannerForm from './BannerForm';
import { useToasts } from "react-toast-notifications";
import { USERTYPES } from "../../constant/userType"

const Banner = ({ bannerId, handleClose, fromAction, getAllBannerList, fromEditAction }) => {

	const { user, getAllBanner, userType } = useContext(AuthContext)
	const adminUserId = user?.userId

	const { inputs, handleInputChange, handleSubmit, handleColorPicker,
		handleCheckboxInputChange, resetColor, errors } = useForm({
			primaryText: "",
			background_image_size: 0,
			background_mobile_image_size: 0,
			primaryTextHeight: 0,
			primaryTextMT: 0,
			primaryTextMB: 0,
			primaryTextPL: 0,
			primaryTextPR: 0,
			buttonTextMT: 0,
			buttonTextMB: 0,
			buttonTextPL: 0,
			buttonTextPR: 0,
			primaryTextAnimationDelay: "1.2s",
			primaryTextAnimationName: "fadeInUp",
			secondaryText: "",
			secondaryTextHeight: 0,
			secondaryTextMT: 0,
			secondaryTextMB: 0,
			secondaryTextPL: 0,
			secondaryTextPR: 0,
			secondaryTextAnimationName: "fadeInUp",
			secondaryTextAnimationDelay: "1.2s",
			shapeText: "",
			text: "",
			textHeight: 0,
			textMT: 0,
			textMB: 0,
			textAnimationDelay: "1.2s",
			textAnimationName: "fadeInUp",
			buttonText: "",
			mobile_banner_image: "",
			btncolor: "", buttonUrl: "", image1: "", popupImg: "", deadLineDate: "", timeColor: "", timeFontSize: "", timeTextColor: "", timeTextFontSize: "", timeTextFont: "", timeFont: "",
			buttonTextColor: "", buttonHoverColor: "", price: "0", oldPrice: "0", adminUserId,
			showButton: true, popupImageHeight: 0, image_file_height: "",
			image_file_align: "",
			popupImageAlign: "",
			btnAnimationDelay: "",
			btnAnimationName: "",
			bgColor: "",
			textAlign: "default",
			bannerCoverArea: "center",
			videoLink: "",
			innerImageLeftRightSpace: 0,
			bannerUserGroup: "",
			videoSize: "",
			videoBorder: "",
			videoStartDelay: "",
			videoNavigate: "",

		}, validate, createBannerCallback);

	//TODO When we will implement user role than we will implement dynamic value of isAdmin
	const { addToast } = useToasts();
	const [isAdmin, setIsAdmin] = useState(true);
	const history = useHistory();
	const [bannersData, setBannersData] = useState({})
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (userType == undefined || userType == "user") {
			window.location.replace("/notFound")
		}
	}, [userType])

	async function createBannerCallback() {

		let newPrimaryText = inputs.primaryText != "undefined" && inputs.primaryText != undefined ? inputs.primaryText : ""

		//if (newPrimaryText.includes("<p>")) {
		//	newPrimaryText = newPrimaryText.replace(/<p>/g, "").replace(/<\/p>/g, "<br/>");
		//	newPrimaryText = newPrimaryText.substr(0, newPrimaryText.length - 5);
		//}

		let newSecondaryText = inputs.secondaryText != "undefined" && inputs.secondaryText != undefined ? inputs.secondaryText : ""

		//if (newSecondaryText.includes("<p>")) {
		//	newSecondaryText = newSecondaryText.replace(/<p>/g, "").replace(/<\/p>/g, "<br/>");
		//	newSecondaryText = newSecondaryText.substr(0, newSecondaryText.length - 5);
		//}
		if (newPrimaryText != '' || inputs.image1 != '') {
			let newText = inputs.text

			//if (newText.includes("<p>")) {
			//	newText = newText.replace(/<p>/g, "").replace(/<\/p>/g, "<br/>");
			//	newText = newText.substr(0, newText.length - 5);
			//}

			var { nbody } = await getNewBody(newPrimaryText)
			newPrimaryText = nbody

			var { nbody } = await getNewBody(newSecondaryText)
			newSecondaryText = nbody

			var { nbody } = await getNewBody(newText)
			newText = nbody
			const formData = new FormData()
			formData.append("image", inputs.image1)
			formData.append("popupImg", inputs.popupImg)
			formData.append("primaryText", newPrimaryText)
			formData.append("secondaryText", newSecondaryText)
			formData.append("shapeText", inputs.shapeText != "undefined" && inputs.shapeText != undefined ? inputs.shapeText : "")
			formData.append("text", newText)
			formData.append("btncolor", inputs.btncolor != "undefined" && inputs.btncolor != undefined ? inputs.btncolor : "")
			formData.append("buttonText", inputs.buttonText != "undefined" && inputs.buttonText != undefined ? inputs.buttonText : "")
			formData.append("buttonUrl", inputs.buttonUrl != "undefined" && inputs.buttonUrl != undefined ? inputs.buttonUrl : "")
			formData.append("buttonTextColor", inputs.buttonTextColor != "undefined" && inputs.buttonTextColor != undefined ? inputs.buttonTextColor : "")
			formData.append("buttonHoverColor", inputs.buttonHoverColor != "undefined" && inputs.buttonHoverColor != undefined ? inputs.buttonHoverColor : "")
			formData.append("popupUrl", inputs.popupUrl != "undefined" && inputs.popupUrl != undefined ? inputs.popupUrl : "")
			formData.append("showButton", inputs.showButton)
			formData.append("primaryTextHeight", inputs.primaryTextHeight > 0 ? inputs.primaryTextHeight : 0)
			formData.append("primaryTextMB", inputs.primaryTextMB > 0 ? inputs.primaryTextMB : 0)
			formData.append("primaryTextMT", inputs.primaryTextMT > 0 ? inputs.primaryTextMB : 0)
			formData.append("primaryTextAnimationDelay", inputs.primaryTextAnimationDelay != "undefined" && inputs.primaryTextAnimationDelay != undefined ? inputs.primaryTextAnimationDelay : "")
			formData.append("primaryTextAnimationName", inputs.primaryTextAnimationName != "undefined" && inputs.primaryTextAnimationName != undefined ? inputs.primaryTextAnimationName : "")
			formData.append("secondaryTextHeight", inputs.secondaryTextHeight > 0 ? inputs.secondaryTextHeight : 0)
			formData.append("secondaryTextMT", inputs.secondaryTextMT > 0 ? inputs.secondaryTextMT : 0)
			formData.append("secondaryTextMB", inputs.secondaryTextMB > 0 ? inputs.secondaryTextMB : 0)
			formData.append("secondaryTextAnimationDelay", inputs.secondaryTextAnimationDelay != "undefined" && inputs.secondaryTextAnimationDelay != undefined ? inputs.secondaryTextAnimationDelay : "")
			formData.append("secondaryTextAnimationName", inputs.secondaryTextAnimationName != "undefined" && inputs.secondaryTextAnimationName != undefined ? inputs.secondaryTextAnimationName : "")
			formData.append("textHeight", inputs.textHeight > 0 ? inputs.textHeight : 0)
			formData.append("textMT", inputs.textMT > 0 ? inputs.textMT : 0)
			formData.append("textMB", inputs.textMB > 0 ? inputs.textMB : 0)
			formData.append("textAnimationDelay", inputs.textAnimationDelay != "undefined" && inputs.textAnimationDelay != undefined ? inputs.textAnimationDelay : "")
			formData.append("textAnimationName", inputs.textAnimationName != "undefined" && inputs.textAnimationName != undefined ? inputs.textAnimationName : "")
			formData.append("price", inputs.price > 0 ? inputs.price : 0)
			formData.append("oldPrice", inputs.oldPrice > 0 ? inputs.oldPrice : 0)
			formData.append("popupImageHeight", inputs.popupImageHeight > 0 ? inputs.popupImageHeight : 0)
			formData.append("image_file_height", inputs.image_file_height)
			formData.append("image_file_align", inputs.image_file_align);
			formData.append("popupImageAlign", inputs.popupImageAlign);
			formData.append("deadLineDate", inputs.deadLineDate != "undefined" && inputs.deadLineDate != undefined ? inputs.deadLineDate : "");
			formData.append("adminUserId", inputs.adminUserId);
			formData.append("btnAnimationDelay", inputs.btnAnimationDelay != "undefined" && inputs.btnAnimationDelay != undefined ? inputs.btnAnimationDelay : "");
			formData.append("btnAnimationName", inputs.btnAnimationName != "undefined" && inputs.btnAnimationName != undefined ? inputs.btnAnimationName : "");
			formData.append("bgColor", inputs.bgColor != "undefined" && inputs.bgColor != undefined ? inputs.bgColor : "");
			formData.append("textAlign", inputs.textAlign);
			formData.append("bannerUserGroup", inputs.bannerUserGroup);
			formData.append("timeColor", inputs.timeColor != "undefined" && inputs.timeColor != undefined ? inputs.timeColor : "");
			formData.append("timeFontSize", inputs.timeFontSize);
			formData.append("timeTextColor", inputs.timeTextColor != "undefined" && inputs.timeTextColor != undefined ? inputs.timeTextColor : "");
			formData.append("timeTextFontSize", inputs.timeTextFontSize);
			formData.append("timeTextFont", inputs.timeTextFont);
			formData.append("timeFont", inputs.timeFont);
			formData.append("bannerCoverArea", inputs.bannerCoverArea != "undefined" && inputs.bannerCoverArea != undefined ? inputs.bannerCoverArea : "");
			formData.append("videoLink", inputs.videoLink != undefined && inputs.videoLink != "undefined" ? inputs.videoLink : "");

			formData.append("buttonTextMB", inputs.buttonTextMB > 0 ? inputs.buttonTextMB : 0);
			formData.append("buttonTextMT", inputs.buttonTextMT > 0 ? inputs.buttonTextMT : 0);
			formData.append("buttonTextPL", inputs.buttonTextPL > 0 ? inputs.buttonTextPL : 0);
			formData.append("buttonTextPR", inputs.buttonTextPR > 0 ? inputs.buttonTextPR : 0);
			formData.append("mobile_banner_image", inputs.mobile_banner_image);
			formData.append("innerImageLeftRightSpace", inputs.innerImageLeftRightSpace);
			formData.append("videoSize", inputs.videoSize !== undefined && inputs.videoSize !== "undefined" ? inputs.videoSize : "");
			formData.append("videoBorder", inputs.videoBorder !== undefined && inputs.videoBorder !== "undefined" ? inputs.videoBorder : "");
			formData.append("videoStartDelay", inputs.videoStartDelay !== undefined && inputs.videoStartDelay !== "undefined" ? inputs.videoStartDelay : "");
			formData.append("videoNavigate", inputs.videoNavigate !== undefined && inputs.videoNavigate !== "undefined" ? inputs.videoNavigate : "");
			formData.append(
				"primaryTextPL",
				inputs.primaryTextPL > 0 ? inputs.primaryTextPL : 0
			);
			formData.append(
				"primaryTextPR",
				inputs.primaryTextPR > 0 ? inputs.primaryTextPR : 0
			);
			formData.append(
				"secondaryTextPL",
				inputs.secondaryTextPL > 0 ? inputs.secondaryTextPL : 0
			);
			formData.append(
				"secondaryTextPR",
				inputs.secondaryTextPR > 0 ? inputs.secondaryTextPR : 0
			);
			formData.append("background_image_size", inputs.background_image_size);
			formData.append("background_mobile_image_size", inputs.background_mobile_image_size);


			const requestOptions = {
				headers: { 'Content-Type': 'multipart/form-data', 'X-TENANT-ID': X_TENANT_ID },
			};
			setIsLoading(true)
			if (bannerId) {
				axios.patch(BANNER + bannerId, formData, requestOptions)
					.then(res => {
						setIsLoading(false)
						if (res.data.error) {
							addToast(res.data.message, {
								appearance: "error",
								autoDismiss: true
							});
						} else {
							getAllBanner()
							handleClose()
							addToast('Banner Updated Succesfully', {
								appearance: 'success',
								autoDismiss: true,
							})
							if (fromAction === 'FromList') {
								getAllBannerList()
								return history.push("/banner");
							} else {
								if (fromEditAction == 'deadLineDate') {
									window.location.reload()
								}
								return history.push(process.env.PUBLIC_URL + "/");
							}
						}
					}).catch(err => {
						addToast(err.message, {
							appearance: "error",
							autoDismiss: true
						});
						console.log('err', err);
						setIsLoading(false)
					});
			} else {
				axios.post(BANNER, formData, requestOptions)
					.then(res => {
						setIsLoading(false)
						if (res.data.error) {
							addToast(res.data.message, {
								appearance: "error",
								autoDismiss: true
							});
						} else {
							addToast('Banner Updated Succesfully', {
								appearance: 'success',
								autoDismiss: true,
							})
							getAllBanner()
							handleClose()
							if (fromAction === 'FromList') {
								getAllBannerList()
								return history.push("/banner");
							} else {
								return history.push("/");
							}
						}
					}).catch(err => {
						setIsLoading(false)
						addToast(err.response.data.message, {
							appearance: "error",
							autoDismiss: true
						});
						console.log('err', err);
					});

			}
		} else {
			addToast('Required any one field.Primary Text or Background Image', {
				appearance: "error",
				autoDismiss: true
			});
		}
	}

	const getBanner = () => {
		setIsLoading(true)
		const requestOptions = {
			headers: { 'Content-Type': 'application/json', 'X-TENANT-ID': X_TENANT_ID },
		};
		axios.get(EDIT_BANNER + bannerId, requestOptions)
			.then(res => {
				setIsLoading(false)
				if (res.data.error) {
					addToast(res.data.message, {
						appearance: "error",
						autoDismiss: true
					});
				} else {
					setBannersData(res.data)
				}
			})
			.catch(err => {
				console.log('err', err);
			});
	}

	useEffect(() => {
		if (bannerId) {
			getBanner()
		}
	}, [bannerId])

	//its a temp value for update inputs value when edit banner
	const [value, setValue] = useState(0);
	useEffect(() => {

		if (Object.keys(bannersData).length > 0) {
			inputs.buttonText = bannersData.buttonText;
			inputs.btnAnimationDelay = bannersData.btnAnimationDelay;
			inputs.btnAnimationName = bannersData.btnAnimationName;
			inputs.primaryText = bannersData.primaryText;
			inputs.primaryTextHeight = bannersData.primaryTextHeight;
			inputs.primaryTextMT = bannersData.primaryTextMT;
			inputs.primaryTextMB = bannersData.primaryTextMB;
			inputs.bannersData = bannersData.primaryTextPL;
			inputs.primaryTextPR = bannersData.primaryTextPR;
			inputs.buttonTextMT = bannersData.buttonTextMT;
			inputs.buttonTextMB = bannersData.buttonTextMB;
			inputs.buttonTextPL = bannersData.buttonTextPL;
			inputs.buttonTextPR = bannersData.buttonTextPR;
			inputs.primaryTextAnimationDelay = bannersData.primaryTextAnimationDelay;
			inputs.primaryTextAnimationName = bannersData.primaryTextAnimationName;
			inputs.secondaryText = bannersData.secondaryText;
			inputs.secondaryTextHeight = bannersData.secondaryTextHeight;
			inputs.secondaryTextMT = bannersData.secondaryTextMT;
			inputs.secondaryTextMB = bannersData.secondaryTextMB;
			inputs.secondaryTextPL = bannersData.secondaryTextPL;
			inputs.secondaryTextPR = bannersData.secondaryTextPR;
			inputs.secondaryTextAnimationName = bannersData.secondaryTextAnimationName;
			inputs.secondaryTextAnimationDelay = bannersData.secondaryTextAnimationDelay;
			inputs.text = bannersData.text;
			inputs.textHeight = bannersData.textHeight;
			inputs.textMT = bannersData.textMT;
			inputs.textMB = bannersData.textMB;
			inputs.textAnimationDelay = bannersData.textAnimationDelay;
			inputs.textAnimationName = bannersData.textAnimationName;
			inputs.shapeText = bannersData.shapeText;
			inputs.buttonUrl = bannersData.buttonUrl;
			inputs.popupUrl = bannersData.popupUrl;
			inputs.image_file = bannersData.image_file;
			inputs.popupImage = bannersData.popupImage;
			inputs.btncolor = bannersData.btncolor;
			inputs.buttonTextColor = bannersData.buttonTextColor;
			inputs.buttonHoverColor = bannersData.buttonHoverColor;
			inputs.deadLineDate = bannersData.deadLineDate;
			inputs.showButton = bannersData.showButton;
			inputs.price = bannersData.price;
			inputs.popupImageHeight = bannersData.popupImageHeight;
			inputs.image_file_height = bannersData.image_file_height;
			inputs.image_file_align = bannersData.image_file_align;
			inputs.popupImageAlign = bannersData.popupImageAlign;
			inputs.bgColor = bannersData.bgColor;
			inputs.timeColor = bannersData.timeColor;
			inputs.textAlign = bannersData.textAlign;
			inputs.timeFontSize = bannersData.timeFontSize;
			inputs.timeTextColor = bannersData.timeTextColor;
			inputs.timeTextFontSize = bannersData.timeTextFontSize;
			inputs.timeTextFont = bannersData.timeTextFont;
			inputs.timeFont = bannersData.timeFont;
			inputs.image1 = bannersData.image_file;
			inputs.popupImg = bannersData.popupImage;
			inputs.bannerCoverArea = bannersData.bannerCoverArea;
			inputs.videoLink = bannersData.videoLink;
			inputs.background_image_size = bannersData.background_image_size ? bannersData.background_image_size : "0";
			inputs.background_mobile_image_size = bannersData.background_mobile_image_size ? bannersData.background_mobile_image_size : "0";
			inputs.mobile_banner_image = bannersData.mobile_banner_image;
			inputs.innerImageLeftRightSpace = bannersData.innerImageLeftRightSpace;
			inputs.bannerUserGroup = bannersData.bannerUserGroup;
			inputs.videoSize = bannersData.videoSize;
			inputs.videoBorder = bannersData.videoBorder;
			inputs.videoStartDelay = bannersData.videoStartDelay;
			inputs.videoNavigate = bannersData.videoNavigate;

			setValue(value => value + 1);
		}
	}, [bannersData])

	return (
		<>
			<div className="contact-area pt-10 pb-10">
				{userType !== USERTYPES.USER ?
					<>
						<BannerForm
							resetColor={resetColor}
							handleCheckboxInputChange={handleCheckboxInputChange}
							isLoading={isLoading}
							inputs={inputs}
							handleSubmit={handleSubmit}
							handleInputChange={handleInputChange} errors={errors}
							handleColorPicker={handleColorPicker}
							bannerId={bannerId}
							handleClose={handleClose}
							fromEditAction={fromEditAction}
							fromAction={fromAction}
						/>
					</> : <h2>You haven't authority for create banner</h2>}
			</div>
		</>
	);
};

export default Banner;
