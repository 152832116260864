import React, { useEffect, useMemo, useState } from 'react'
import { Stack, useMediaQuery, useTheme } from '@mui/system'
import {
  Alert,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Radio,
  Tooltip,
  Typography,
} from '@mui/material'
import AddressSelector from '../AddressSelector'
import {
  FormContainer,
  useForm,
} from 'react-hook-form-mui'
import { AetherPaymentRequest } from '../../../models/Payment'
import { PaymentMethodType } from '../../../models/PaymentMethodType'
import { useAppSelector } from '../../../redux/hooks'
import {
  selectParams,
  selectCurrency,
  selectOrderDecimalsToShow,
  makeSelectMethodPaymentData,
} from '../../../redux/selectors/checkoutSelectors'
import { getPaymentForm } from './PaymentMethodForms'
import { Warning } from '@mui/icons-material'
import { grey } from '@mui/material/colors'

interface PaymentMethodCardProps {
  icon: React.ReactNode
  name: string
  request?: Partial<AetherPaymentRequest>
  errorMessage?: string
  methodType: PaymentMethodType
  index: number | null
  onSelect: () => void
  onSubmit: (data: AetherPaymentRequest) => void
  selected: boolean
}

export default function PaymentMethodCard({
  icon,
  name,
  request,
  errorMessage,
  methodType,
  index,
  onSelect,
  onSubmit,
  selected,
}: PaymentMethodCardProps) {
  const params = useAppSelector(selectParams)
  const currency = useAppSelector(selectCurrency)
  const orderDecimalsToShow = useAppSelector((state) => selectOrderDecimalsToShow(state, params))
  const theme = useTheme()
  const xsDisplay = useMediaQuery(theme.breakpoints.only('xs'))
  const [editingContact, setEditingContact] = useState(false)
  const [editingAddress, setEditingAddress] = useState(
    request?.address === undefined,
  )

  const selectMethodPaymentData = makeSelectMethodPaymentData()

  const { amount: methodBalanceDue, itemIds: methodItemIds, restrictedItemIds } =
    useAppSelector((state) => selectMethodPaymentData(state, params, index, methodType))

  const formContext = useForm<AetherPaymentRequest>({
    defaultValues: useMemo(
      () =>
        request
          ? {
              ...request,
            }
          : {},
      [request],
    ),
  })

  const { reset, handleSubmit, control } = formContext

  useEffect(() => {
    reset({
      ...(request ?? {}),
    })
  }, [request])

  const handleFormSubmit = (data: AetherPaymentRequest) => {
    onSubmit({
      ...data,
      methodType,
      itemIds: methodItemIds,
      amount: methodBalanceDue.toNumber(),
    })
  }

  const methodDisabled = useMemo(
    () => methodBalanceDue.equals(0),
    [methodBalanceDue],
  )

  if (methodDisabled) {
    return <></>
  }

  return (
    <FormContainer formContext={formContext}>
      <Card sx={{ backgroundColor: methodDisabled ? grey[200] : undefined }}>
        <CardActionArea
          onClick={onSelect}
          disabled={selected || methodDisabled}
        >
          <CardContent>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Stack direction={'row'} spacing={2}>
                {icon}
                <Typography variant="body1">{name}</Typography>
              </Stack>
              {!methodDisabled && (
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                  {(restrictedItemIds?.length ?? 0) > 0 && !selected && (
                    <Tooltip
                      title={'Some items cannot be paid with this method'}
                    >
                      <Warning color={'warning'} />
                    </Tooltip>
                  )}
                  <Radio checked={selected} />
                </Stack>
              )}
            </Stack>
          </CardContent>
        </CardActionArea>
        {selected && (
          <>
            <AddressSelector
              label={'Billing'}
              editingContact={editingContact}
              setEditingContact={setEditingContact}
              editingAddress={editingAddress}
              setEditingAddress={setEditingAddress}
            />
            {!editingAddress && !editingContact && (
              <Stack direction={'column'} margin={2} spacing={2}>
                {(restrictedItemIds?.length ?? 0) > 0 && (
                  <Alert severity="warning">
                    Some items cannot be paid with this method
                  </Alert>
                )}
                <Typography variant="body1">
                  Payment Amount: {currency.currencySymbol}
                  {methodBalanceDue.toFixed(orderDecimalsToShow)}
                </Typography>
                {getPaymentForm(methodType, {})}
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                <Stack direction={'row'} justifyContent={'center'}>
                  <Button
                    variant={'contained'}
                    size={'large'}
                    onClick={handleSubmit(handleFormSubmit)}
                    fullWidth={xsDisplay}
                  >
                    Continue
                  </Button>
                </Stack>
              </Stack>
            )}
          </>
        )}
      </Card>
    </FormContainer>
  )
}
