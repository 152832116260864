import React, { useEffect, useState } from 'react'
import { GOOGLE_SCRIPT_SRC, GOOGLE_SELECTOR_DIV_NAME } from '../../../constant/googleTranstaleWidget';

const GoogleTranslateLanguageChanger = (defaultLanguage = 'en') => {
  const defaultStoreLanguage = defaultLanguage.defaultLanguage || 'en';
  const avoidDefaultLang = sessionStorage.getItem('AVOID_DEFAULT_LANGUAGE');


  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'google-translate-script';
    script.src = GOOGLE_SCRIPT_SRC;
    document.body.appendChild(script);
    script.onload = function () {
      loadLanguageSelector();
      setTimeout(()=>{
        changeToDefaultStoreLanguage(defaultStoreLanguage);

        const languageSelector = document.querySelector('.goog-te-combo');
        // observer was needed instead of eventListener since lang could be changed from google toolbar
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.type === 'childList' && mutation.target === languageSelector ) {
              const selectedValue = languageSelector.value;
              if(!selectedValue) {
                sessionStorage.setItem('AVOID_DEFAULT_LANGUAGE', '0');
              }else{
                sessionStorage.setItem('AVOID_DEFAULT_LANGUAGE', '1');
              }
            }
          });
        });
        observer.observe(languageSelector, { childList: true });

      }, 3000);
    };
  }, []);

  const changeToDefaultStoreLanguage = (language) => {
    const languageSelector = document.querySelector('.goog-te-combo');

    if(!avoidDefaultLang || avoidDefaultLang !== '1' ) {
      if(languageSelector && language!=='en' && language!== '0'){
        languageSelector.value = language;
        languageSelector.dispatchEvent(new Event('change'));
      }
    }
    if(language === '0'){
      languageSelector.value = 'en';
      languageSelector.dispatchEvent(new Event('change'));
    }


  };


  const loadLanguageSelector = () =>{
      window.googleTranslateElementInit = function() {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: 'en',
            autoDisplay: false,
            layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
          },
          GOOGLE_SELECTOR_DIV_NAME
        );
      }
  }

  return (
    <div>
      <div style={{maxWidth: '150px'}} id={GOOGLE_SELECTOR_DIV_NAME} ></div>
    </div>
  );
};

export default GoogleTranslateLanguageChanger;