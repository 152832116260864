export enum QuestionType {
    FREE = "free",
    MULTIPLE = "multiple",
    MULTIPLE_FORM = "multipleForm",
}

export interface QuestionResponse {
    results: Question[];
    total: number;
}

export interface Question {
    _id: string;
    type: QuestionType;
    size: string;
    question: string;
    choices?: [];
    sendToAdvance: boolean;
    status: boolean;
    isRequired: boolean;
}