import { Box, Grid2, Stack, Typography } from '@mui/material'
import React from 'react'

export default function PassiveRow({
  field,
  value,
  values,
}: {
  field: string
  value?: string
  values?: (string | undefined)[]
}) {
  return (
    <Grid2 container columnSpacing={2}>
      <Grid2 size={{ xs: 5, md: 4 }}>
        <Typography variant="body2">{field}</Typography>
      </Grid2>
      <Grid2 size={{ xs: 7, md: 8 }}>
        <Stack >
          {(values ?? [value]).filter(v => v !== undefined).map((v, index) => (
            <Typography key={index} variant="body2">{v}</Typography>
          ))}
        </Stack>
      </Grid2>
    </Grid2>
  )
}
