import PropTypes from "prop-types"
import React from "react"
import { Checkbox, FormControlLabel } from "@material-ui/core"
import { firstLetterUpper } from '../../util/helper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useShopTagState} from "./state/useShopTagState";

const ShopTag = ((props) => {
    const {
        defaultExpand,
        tagSettings,
        selectedTags
    } = useShopTagState(props);
    const { tags, onSelectTag } = props;
    const {isExpand} = props?.sideBarSettings

    return (
        <div className="sidebar-widget mt-20">
            {isExpand!==null&& <Accordion defaultExpanded={isExpand}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <h4 className="pro-sidebar-title">{tagSettings?.customLabel || 'Tag'} </h4>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="sidebar-widget-tag mt-25 filterTag w-100">
                        {tags ? (
                            <ul className={`${!isExpand ? 'dataFilter' : ''}`}>
                                {tags.map((tag, key) => {
                                    const isChecked = !!selectedTags.find(selectedTag => selectedTag._id === tag._id)
                                    return (
                                        <li key={key}>
                                            <FormControlLabel
                                                control={<Checkbox name={tag.tagName} id={tag.tagName} onClick={(e) => { onSelectTag(tag, e.target.checked) }} value={tag.tagName} checked={isChecked} />}
                                                label={tag.tagName}
                                            />
                                        </li>
                                    )
                                })}
                            </ul>
                        ) : (
                            "No tags found"
                        )}
                    </div>
                </AccordionDetails>
            </Accordion>}
        </div>
    )
});

ShopTag.propTypes = {
    getSortParams: PropTypes.func,
    tags: PropTypes.array
}

export default ShopTag
