import { SavedAddress } from '../../models/Address'
import { aetherApi } from './aetherApi'

const coShippingLocationApi = aetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getSavedAddresses: builder.query<SavedAddress[], { userId: string }>({
      query: (params) => ({
        url: `api/v1/co-shipping-location/saved-addresses/all`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetSavedAddressesQuery } = coShippingLocationApi
