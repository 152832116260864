import React, { useContext, useEffect } from 'react';
import { getCartById } from '../util/cart/cartAPIUtil';
import { getWishlistById } from '../util/wishlist/wishlistAPIUtil';
import { wishlistItemData } from '../redux/actions/wishlistActions';
import { cartItemData } from '../redux/actions/cartActions';
import { AuthContext } from './Auth';
import { useDispatch } from 'react-redux';

export default function GlobalLoader({ children }) {
	const { user, ipV4, ipV4Loaded } = useContext(AuthContext);
	const dispatch = useDispatch();

	useEffect(() => {
		const getCart = async () => {
			const cartData = await getCartById(user, ipV4);
			if (cartData) {
				dispatch(cartItemData(cartData));
			}
		}

		const getWishlist = async () => {
			const wishlistData = await getWishlistById(ipV4);
			if (wishlistData) {
				dispatch(wishlistItemData(wishlistData));
			}
		}
		if (ipV4Loaded) {
			getCart();
			getWishlist();
		}
	}, [user, ipV4, ipV4Loaded]);

	return children;
}