import React, { Fragment, useState, useEffect, useContext } from "react";
import { connect, useSelector } from "react-redux";
import { AuthContext } from "../../../common/Auth"
import { replaceOrigin } from "../../../util/helper";
import { useToasts } from "react-toast-notifications";
import DeleteIcon from '@material-ui/icons/Delete'
import { useProductArtworkRestrictionState } from "../state/useProductArtworkRestrictionState";
const AddDecorationHats = ({ artworkList,
    setAddnewart,
    selectedProductArtwork,
    setSelectedProductArtwork,
    addDecoration,
    selectedProductLocation,
    artworkListById,
    selectedProductColor,
    locationName,
    searchTextArtwork,
    artworkSetting,
    storeArtwork,
    decorationLocations,
    allowCustomDecoration,
    selectedDecorationsList,
    artworkDuplicatesAllowed,
    artworkDuplicatesAllowedPro,
    tempDecorationLocations,
    product,
    artworkLocations,
    allowOneDec,
    isAdded,
    setIsAdded,
    decorationListOrder,
    preventAutoAttach,
    showPopupText,
    artworkCategoriesRestriction,
}) => {
    let getLocationName = decorationLocations && decorationLocations.filter(item => item.id === selectedProductLocation)
    const { storeDetails, userType, user } = useContext(AuthContext)
    const [productDesignId, setProductDesignId] = useState('')
    const [ArtworkDuplicatesAllowed, setArtworkDuplicatesAllowed] = useState(true)
    const [artwork_selected, setArtwork_selected] = useState(null)
    const [findProductArtvariationId, setFindProductArtvariationId] = useState('')
    const [tempArtworkListById, setArtworkListById] = useState(artworkListById)
    const [showArtwork, setShowArtwork] = useState(true)
    const { addToast } = useToasts();
    const [remainDecoLocation, setRemainDecoLocation] = useState(
        tempDecorationLocations,
    )

    const artworkGlobalIndicator = useSelector((state) => state.productData.artworkGlobalIndicator);

    const {
        getStatusLocation,
    } = useProductArtworkRestrictionState({
        tempArtworkList: tempArtworkListById,
        product,
        selectedDecorationsList,
        locationName,
        artworkLocations,
        artworksByCategories: artworkCategoriesRestriction
    });

    useEffect(() => {
        if ((artworkSetting && artworkGlobalIndicator) && artworkListById.length === 0) {
            artworkListById = artworkListById?.concat(artworkList?.filter(al => artworkListById?.every(ali => ali?.id != al?.id)));
        } else {
            if (!artworkGlobalIndicator && artworkListById.length === 0) {
                artworkListById = artworkListById?.concat(artworkList?.filter(al => artworkListById?.every(ali => ali?.id != al?.id)));
            }
        }
    }, [artworkList, artworkListById, searchTextArtwork, artworkGlobalIndicator])


    useEffect(() => {
        let temp = artworkListById.filter((item) => {
            if (item.artworkSource == 'aether' && (!(!!user?.userId) || artworkSetting)) {
                if (allowCustomDecoration || artworkSetting) {
                    return item
                }
            } else {
                return item
            }
        })
        temp = temp.filter((item => item.singleUseOnly == '0'))

        const artworkIDs = selectedDecorationsList.map(item => item.artworkID) || [];
        let tempartworkListById = temp.filter((item => item.identity && item.identity.toLowerCase().indexOf(searchTextArtwork.length > 0 ? searchTextArtwork.toLowerCase() : searchTextArtwork) !== -1 || item.designNo && item.designNo.toLowerCase().indexOf(searchTextArtwork.length > 0 ? searchTextArtwork.toLowerCase() : searchTextArtwork) !== -1 || item.designTypeName && item.designTypeName.toLowerCase().indexOf(searchTextArtwork.length > 0 ? searchTextArtwork.toLowerCase() : searchTextArtwork) !== -1 || artworkIDs.includes(item.id)));
        if (!!decorationListOrder && decorationListOrder.length > 0 && artworkListById.length > 0) {
            tempartworkListById = tempartworkListById.sort(function (a, b) {
                return decorationListOrder.indexOf(a.id) - decorationListOrder.indexOf(b.id);
            })
        }
        setArtworkListById(tempartworkListById)
    }, [searchTextArtwork, artworkListById])
    useEffect(() => {
        if (selectedProductArtwork) {
            let artwork = artworkListById.filter(val => val.id == selectedProductArtwork)
            if (artwork.length > 0) {
                setArtwork_selected(artwork[0])
            }

        } else if (artworkListById && artworkListById.length) {
            let locationCount = 0
            artworkListById.map((val, key) => {
                if (val?.productToDecorationMapping[0]?.location === selectedProductLocation && val?.productToDecorationMapping[0]?.isRequiredForStore == '1') {
                    val.productToDecorationMapping[0].mapping.map((mapVal, mapkey) => {
                        if (mapVal.color === selectedProductColor) {
                            locationCount = locationCount + 1
                            setFindProductArtvariationId(mapVal.variationId)
                            setProductDesignId(val?.id)
                        }
                    })

                    if (locationCount > 1) {
                        // setShowData('yes')
                        setFindProductArtvariationId('')
                        setProductDesignId('')
                    }
                } else {
                    setFindProductArtvariationId('')
                    setProductDesignId('')
                    //setShowData('yes')
                }
            })
        }
    }, [artworkListById, selectedProductArtwork])

    useEffect(() => {
        if (artworkListById && artworkListById.length) {
            let locationCount = 0
            let tempartWorkCount = 0
            artworkListById.map((val, key) => {

                if (val?.productToDecorationMapping[0]?.location === selectedProductLocation && val?.productToDecorationMapping[0]?.isRequiredForStore == '1') {
                    tempartWorkCount = tempartWorkCount + 1
                    val.productToDecorationMapping[0].mapping.map((mapVal, mapkey) => {
                        if (mapVal.color === selectedProductColor) {
                            locationCount = locationCount + 1
                            setFindProductArtvariationId(mapVal.variationId)
                            setProductDesignId(val?.id)
                        }
                    })

                    if (locationCount > 1) {
                        setFindProductArtvariationId('')
                        setProductDesignId('')
                    }
                } else {
                    setFindProductArtvariationId('')
                    setProductDesignId('')
                    val.productToDecorationMapping.map((val) => {
                        if (val.location == selectedProductLocation && val.isRequiredForStore == '0') {
                            tempartWorkCount = tempartWorkCount + 1
                            setTimeout(() => {
                                if (tempartWorkCount == 1 && !!document.querySelectorAll(".artMain")?.length && document.querySelectorAll(".artMain")?.length === 1 && !isAdded && !preventAutoAttach) {
                                    setIsAdded(true)
                                    let id = 'artew_' + '1'
                                    var link = document.getElementById(id);
                                    link && link.click()
                                }
                            }, 2000)
                        }
                    })
                }
            })
        }
    }, [artworkListById, selectedProductLocation, selectedProductColor])

    const handleArtworkselection = (artwork) => {

        setSelectedProductArtwork(artwork.id)
        setArtwork_selected(artwork)
        if (artwork.design.variation.length == 1) {
            addDecoration(artwork.design.variation[0], artwork.id, selectedProductLocation, selectedProductColor, artworkListById)
        } else {
            let checkLocation = artwork.productToDecorationMapping.filter(val => val.location == selectedProductLocation)
            if (checkLocation.length >= 1) {
                let getVariationId = checkLocation[checkLocation.length - 1].mapping.filter((val) => val.color == selectedProductColor)
                if (getVariationId.length > 0) {
                    let getvarionIdFromMap = getVariationId[0].variationId

                    let getdesignVationid = artwork.design.variation.filter(val => val.design_variation_unique_id == getvarionIdFromMap)
                    addDecoration(getdesignVationid[0], artwork.id, selectedProductLocation, selectedProductColor)
                }
            } else {
                let VarDat = !!artwork?.design?.variation && artwork.design.variation.filter(res => {
                    if (!!res?.design_variation_color && res?.design_variation_color.split(',').length > 0 && res?.design_variation_color.split(',').map(word => word.toLowerCase()).includes(selectedProductColor.toLowerCase()) && !!res?.itemImageThumbnail?.[0]) {
                        return res;
                    }
                })
                if (VarDat && VarDat.length > 0) {
                    addDecoration(VarDat[0], artwork.id, selectedProductLocation, selectedProductColor)
                } else if (!!artwork?.design?.variation?.[0]) {
                    addDecoration(artwork?.design?.variation?.[0], artwork.id, selectedProductLocation, selectedProductColor)
                }
            }
        }
    }

    /*useEffect(() => {
        if (artworkDuplicatesAllowedPro === true || artworkDuplicatesAllowedPro === false) {
            if (artworkDuplicatesAllowedPro === true)
                setArtworkDuplicatesAllowed(true)
            else
                setArtworkDuplicatesAllowed(false)
        } else if (artworkDuplicatesAllowed === true || artworkDuplicatesAllowed === false) {
            if (artworkDuplicatesAllowed === true)
                setArtworkDuplicatesAllowed(true)
            else
                setArtworkDuplicatesAllowed(false)
        }
    }, [artworkDuplicatesAllowed, artworkDuplicatesAllowedPro])*/

    useEffect(() => {
        let newLocation = []
        tempDecorationLocations &&
            tempDecorationLocations.map((val, index) => {
                let artworkSelected = null
                let checkLocation =
                    selectedDecorationsList && selectedDecorationsList.find((d) => d.locationId === val.id && d.variationUniqID && d.variationUniqID !== "" && d.variationUniqID !== undefined && allowOneDec !== "2")
                if (checkLocation === undefined || checkLocation === '') {
                    newLocation.push(val)
                }
            })
        setRemainDecoLocation(newLocation)
    }, [selectedDecorationsList, tempDecorationLocations])

    return (
        <div className="location-hats-list">
            {productDesignId ? (
                <div className="location-hats-data mb-10">

                    {tempArtworkListById.length > 0 && showArtwork && tempArtworkListById.map((artwork, index) => {
                        let imgUrl = ""
                        let getCustomArtworkByUser = true
                        let displayArtwork = true
                        if (ArtworkDuplicatesAllowed === false) {
                            let isExist = selectedDecorationsList.filter(decoration => {
                                return decoration.artworkID == artwork.id
                            })
                            if (selectedDecorationsList.length > 0) {
                                if (isExist.length > 0) {
                                    displayArtwork = false
                                }
                            }
                        }
                        if (displayArtwork === true && getStatusLocation(artwork.id)) {
                            let storeItem = storeArtwork.filter(item => item.designId == artwork?.designId)
                            getCustomArtworkByUser = (storeItem?.[0]?.userId === user.userId)
                            let checkLocation = artwork.productToDecorationMapping.filter(val => val.location == selectedProductLocation)

                            if (checkLocation.length >= 1) {
                                let getVariationId = checkLocation[checkLocation.length - 1].mapping.filter(val => val.color == selectedProductColor);
                                if (getVariationId.length > 0) {
                                    let imageUrl = artwork.design.variation.filter((val) => val.design_variation_unique_id == getVariationId[0].variationId)
                                    if (imageUrl.length > 0) {
                                        if (storeItem && storeItem.length > 0) {
                                            if (storeItem?.[0]?.isEnable && getCustomArtworkByUser) {
                                                imgUrl = imageUrl[0].itemImageThumbnail
                                            } else if (storeItem?.[0]?.isEnable && (artworkSetting || allowCustomDecoration)) {
                                                imgUrl = imageUrl[0].itemImageThumbnail
                                            } else if (artwork.artworkSource !== 'aether' && !(!!storeItem?.[0]?.userId)) {
                                                imgUrl = imageUrl[0].itemImageThumbnail
                                            }
                                        } else {
                                            imgUrl = imageUrl[0].itemImageThumbnail
                                        }
                                    }
                                }
                            } else {
                                if ((!(!!artwork?.artworkLocation) || artwork?.artworkLocation === locationName) && artwork?.design?.variation != "" && artwork.productToDecorationMapping == "") {
                                    let VarDat = artwork.design.variation.filter(res => {
                                        if (!!res?.design_variation_color && res?.design_variation_color.split(',').length > 0 && res?.design_variation_color.split(',').map(word => word.toLowerCase().trim()).includes(selectedProductColor.toLowerCase()) && !!res?.itemImageThumbnail?.[0]) {
                                            return res
                                        }
                                    })
                                    if (VarDat && VarDat.length > 0) {
                                        if (storeItem && storeItem.length > 0 && artworkGlobalIndicator) {
                                            if (storeItem?.[0]?.isEnable && getCustomArtworkByUser) {
                                                imgUrl = VarDat?.[0]?.itemImageThumbnail?.[0]
                                            } else if (storeItem?.[0]?.isEnable && (artworkSetting || allowCustomDecoration)) {
                                                imgUrl = VarDat?.[0].itemImageThumbnail?.[0]
                                            } else if (artwork.artworkSource !== 'aether' && !(!!storeItem?.[0]?.userId) && storeItem?.[0]?.isEnable) {
                                                imgUrl = VarDat?.[0]?.itemImageThumbnail?.[0]
                                            }
                                        } else {
                                            if (artwork?.statusId === "5") {
                                                imgUrl = VarDat?.[0]?.itemImageThumbnail?.[0]
                                            }
                                        }
                                    } else {
                                        if (storeItem && storeItem.length > 0 && artworkGlobalIndicator) {
                                            if (storeItem?.[0]?.isEnable && getCustomArtworkByUser) {
                                                imgUrl = artwork?.design?.variation?.[0]?.itemImageThumbnail?.[0]
                                            } else if (storeItem?.[0]?.isEnable && (artworkSetting || allowCustomDecoration)) {
                                                imgUrl = artwork?.design?.variation?.[0].itemImageThumbnail?.[0]
                                            } else if (artwork.artworkSource !== 'aether' && !(!!storeItem?.[0]?.userId) && storeItem?.[0]?.isEnable) {
                                                imgUrl = artwork?.design?.variation?.[0]?.itemImageThumbnail?.[0]
                                            }
                                        } else {
                                            if (artwork?.statusId === "5") {
                                                imgUrl = artwork?.design?.variation?.[0]?.itemImageThumbnail?.[0]
                                            }
                                        }
                                    }
                                }
                            }
                            let customeIndex = 0
                            let selectedArtWrk = selectedDecorationsList?.length && selectedDecorationsList.find(res => res.location === locationName && (productDesignId === artwork?.designId || productDesignId === artwork?.id))
                            let selectedIndex = selectedDecorationsList?.length && selectedDecorationsList?.findIndex(res => res.location === locationName && (productDesignId === artwork?.designId || productDesignId === artwork?.id));

                            if (!!imgUrl && imgUrl.length > 0) {
                                customeIndex = +1
                                return <div className={"ArtDecOther artMain"}>
                                    {selectedArtWrk && (
                                        <div className={!!selectedArtWrk ? "artworkbox artworkbox-selected decorows_selartwork" : "artworkbox artworkbox-selectednot decorows_selartwork"} key={index} style={{ minWidth: 'unset', position: "relative" }}>
                                            <img
                                                onClick={() => {
                                                    handleArtworkselection(artwork)
                                                }}
                                                id={'artew_' + customeIndex}
                                                src={replaceOrigin(imgUrl)}
                                                className="img-fluid"
                                                alt="No Image"
                                                style={{ maxHeight: "150px" }}
                                            />
                                            {(selectedArtWrk && (!selectedArtWrk?.isRequiredForStore || selectedArtWrk?.isRequiredForStore == "0")) ?
                                                <div style={{
                                                    bottom: "5px",
                                                    right: " 5px",
                                                    position: "absolute",
                                                }}>
                                                    <DeleteIcon
                                                        className="float-right"
                                                        onClick={() => {
                                                            showPopupText([], [], selectedIndex)
                                                        }}
                                                    /></div> : ''
                                            }
                                        </div>)}
                                </div>
                            }
                        }
                    })}

                </div>
            ) : (
                <div className="location-hats-data mb-10">

                    {tempArtworkListById.length > 0 && showArtwork && tempArtworkListById.map((artwork, index) => {
                        let imgUrl = ""
                        let displayArtwork = true
                        let getCustomArtwotkByUser = true
                        let storeItem = storeArtwork.filter(item => item.designId == artwork?.designId)
                        getCustomArtwotkByUser = (storeItem?.[0]?.userId === user.userId)
                        let checkLocation = artwork && artwork.productToDecorationMapping && artwork.productToDecorationMapping.filter(val => val.location == selectedProductLocation)
                        if (ArtworkDuplicatesAllowed === false) {
                            let isExist = selectedDecorationsList.filter(decoration => {
                                return decoration.artworkID == artwork.id
                            })
                            if (selectedDecorationsList.length > 0) {
                                if (isExist.length > 0) {
                                    displayArtwork = false
                                }
                            }
                        }
                        if (displayArtwork === true && getStatusLocation(artwork.id)) {
                            if (checkLocation.length >= 1) {
                                let getVariationId = checkLocation[checkLocation.length - 1].mapping.filter(val => val.color == selectedProductColor);

                                if (getVariationId.length > 0) {
                                    let imageUrl = artwork.design.variation.filter((val) => val.design_variation_unique_id == getVariationId[0].variationId)
                                    if (imageUrl.length > 0) {
                                        if (storeItem && storeItem.length > 0) {
                                            if (storeItem?.[0]?.isEnable && getCustomArtwotkByUser) {
                                                imgUrl = imageUrl[0].itemImageThumbnail
                                            } else if (storeItem?.[0]?.isEnable && (artworkSetting || allowCustomDecoration)) {
                                                imgUrl = imageUrl[0].itemImageThumbnail
                                            } else if (artwork.artworkSource !== 'aether' && !(!!storeItem?.[0]?.userId)) {
                                                imgUrl = imageUrl[0].itemImageThumbnail
                                            }
                                        } else {
                                            imgUrl = imageUrl[0].itemImageThumbnail
                                        }
                                    }
                                }
                            } else {
                                if ((!(!!artwork?.artworkLocation) || artwork?.artworkLocation === locationName) && artwork?.design?.variation != "" && artwork.productToDecorationMapping == "") {
                                    let VarDat = artwork.design.variation.filter(res => {
                                        if (!!res?.design_variation_color && res?.design_variation_color.split(',').length > 0 && res?.design_variation_color.split(',').map(word => word.toLowerCase().trim()).includes(selectedProductColor.toLowerCase()) && !!res?.itemImageThumbnail?.[0]) {
                                            return res
                                        }
                                    })
                                    if (VarDat && VarDat.length > 0) {
                                        if (storeItem && storeItem.length > 0 && artworkGlobalIndicator) {
                                            if (storeItem?.[0]?.isEnable && getCustomArtwotkByUser) {
                                                imgUrl = VarDat?.[0]?.itemImageThumbnail?.[0]
                                            } else if (storeItem?.[0]?.isEnable && (artworkSetting || allowCustomDecoration)) {
                                                imgUrl = VarDat?.[0].itemImageThumbnail?.[0]
                                            } else if (artwork.artworkSource !== 'aether' && !(!!storeItem?.[0]?.userId) && storeItem?.[0]?.isEnable) {
                                                imgUrl = VarDat?.[0]?.itemImageThumbnail?.[0]
                                            }
                                        } else {
                                            if (artwork?.statusId === "5") {
                                                imgUrl = VarDat?.[0]?.itemImageThumbnail?.[0]
                                            }
                                        }
                                    } else {
                                        if (storeItem && storeItem.length > 0 && artworkGlobalIndicator) {
                                            if (storeItem?.[0]?.isEnable && getCustomArtwotkByUser) {
                                                imgUrl = artwork?.design?.variation?.[0]?.itemImageThumbnail?.[0]
                                            } else if (storeItem?.[0]?.isEnable && (artworkSetting || allowCustomDecoration)) {
                                                imgUrl = artwork?.design?.variation?.[0].itemImageThumbnail?.[0]
                                            } else if (artwork.artworkSource !== 'aether' && !(!!storeItem?.[0]?.userId) && storeItem?.[0]?.isEnable) {
                                                imgUrl = artwork?.design?.variation?.[0]?.itemImageThumbnail?.[0]
                                            }
                                        } else {
                                            if (artwork?.statusId === "5") {
                                                imgUrl = artwork?.design?.variation?.[0]?.itemImageThumbnail?.[0]
                                            }
                                        }
                                    }
                                }
                            }
                            let customeIndex = 0

                            let selectedArtWrk = selectedDecorationsList?.length && selectedDecorationsList.find(res => res.location === locationName && res.artworkID === artwork.id)
                            let selectedIndex = selectedDecorationsList?.length && selectedDecorationsList?.findIndex(res => res.location === locationName && res.artworkID === artwork.id);

                            if (!!imgUrl && imgUrl.length > 0) {
                                customeIndex = +1
                                return <div className={`ArtDecOther artMain`} key={'artew_' + index + '_' + selectedProductLocation}>
                                    <div className={!!selectedArtWrk ? "artworkbox artworkbox-selected decorows_selartwork" : "artworkbox artworkbox-selectednot decorows_selartwork"} key={index} style={{ minWidth: 'unset', position: "relative" }}>
                                        <img
                                            onClick={() => {
                                                handleArtworkselection(artwork)
                                            }}
                                            id={'artew_' + customeIndex}
                                            src={replaceOrigin(imgUrl)}
                                            className="img-fluid"
                                            alt="No Image"
                                            style={{ maxHeight: "150px" }}
                                        />
                                        {(selectedArtWrk && (!selectedArtWrk?.isRequiredForStore || selectedArtWrk?.isRequiredForStore == "0")) ?
                                            <div style={{
                                                bottom: "5px",
                                                right: " 5px",
                                                position: "absolute",
                                            }}>
                                                <DeleteIcon
                                                    className="float-right"
                                                    onClick={() => {
                                                        showPopupText([], [], selectedIndex)
                                                    }}
                                                /></div> : ''
                                        }
                                    </div>
                                </div>
                            }
                        }
                    })}

                </div>
            )}
        </div>
    );
}
const mapDispatchToProps = dispatch => {
    return {
    };
};
const mapStateToProps = state => {
    return {
        artworkList: state.productData.artworkList,
        artworkListById: state.productData.artworkListById,
        artworkCategoriesRestriction: state.productData.artworkCategoriesRestriction,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddDecorationHats);