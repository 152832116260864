import { useCallback } from "react";

export const useShopFormatColors = (props) =>{
    const groupedColors = useCallback(() => {
        const groupedColors = [];
        let colors = [...props.colors];
        let sortingArr = ['white','red','green','blue','yellow','purple','gray','black','other']
        colors.sort(function(a, b){
            return sortingArr.indexOf(a.primaryColor) - sortingArr.indexOf(b.primaryColor);
        });

        let result=[]
        result= colors.reduce(function (r, a) {
            r[a.primaryColor] = r[a.primaryColor] || [];
            r[a.primaryColor].push(a);
            return r;
        }, Object.create(null));

        Object.entries(result).forEach((obj) => {
            groupedColors.push({[obj[0]]:obj[1]})
        });

        return groupedColors;
    }, [props.colors]);

    return {
        groupedColors
    }
}
