import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import {useShopColorState} from "./state/useShopColorState";
import { useShopFormatColors } from "../../pages/shop/state/useShopFormatColors";

const ShopColor = (props) => {
	const {isExpand} = props?.sideBarSettings
	const {
		defaultExpand,
		selectedColors
	} = useShopColorState(props);

	const {
		groupedColors
	} = useShopFormatColors({
		colors: props.colors
	})

	const { onSelectColor } = props;
	const dataFilterStyle = {
	}

	if (defaultExpand) {
		Object.assign(dataFilterStyle, { maxHeight: 'none' });
	}
	const colorBox = (colorName,hexCode) => {
		let isWhite =null
		let hex
		if (hexCode) {
			hex = hexCode&&hexCode.indexOf("#") !== -1 ? hexCode : '#' + hexCode
			isWhite = (hex.toUpperCase() === "#FFFFFF");
		}
		if(colorName){
			isWhite = colorName.toLowerCase() === "white";
		}
		return (
			<>
				{" "}
				<span style={{backgroundColor: `${hex ? hex : colorName}`, border: `${isWhite && "1px solid black"}`}} className="colorbox"/>
				{colorName}
			</>
		);
	};

	const renderGroupedColors = () => {
		if (!props.colors.length) return [];

		const render = [];
		const colorsMap = groupedColors();

		colorsMap.forEach((colors,index) => {
			const isChecked = selectedColors.find(
				(colorObject) => Object.keys(colorObject)[0] === Object.keys(colors)[0]
			);
			render.push(
				<li  key={index} className="mb-0">
					<div className="sidebar-widget-list-left colorFilter">
						<div className="">

							<FormControlLabel
								control={
									<Checkbox
										style={{ marginLeft: "" }}
										name={Object.keys(colors)[0]}
										id={Object.keys(colors)[0]}
										onChange={(e) =>
											onSelectColor(colors, e.target.checked)
										}
										checked={isChecked?true:false}
									/>
								}
								label={colorBox(Object.keys(colors)[0])}
							/>
						</div>
					</div>
				</li>
			);

		})

		return <ul className={`${!isExpand ? 'dataFilter' : ''}`} style={dataFilterStyle}>{render || "no records"}</ul>;
	};
	return (
		<div className="sidebar-widget mt-20">
			{isExpand!==undefined&& <Accordion defaultExpanded={isExpand}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<h4 className="pro-sidebar-title">Color </h4>
				</AccordionSummary>
				<AccordionDetails>
					<div className="sidebar-widget-list w-100">
						{renderGroupedColors()}
					</div>
				</AccordionDetails>
			</Accordion>}
		</div>
	);
};

ShopColor.propTypes = {
	colors: PropTypes.array,
	getSortParams: PropTypes.func,
	isExpand:PropTypes.bool
};

export default ShopColor;
