import axios from 'axios';
import {
	X_TENANT_ID,
	CART_URL
} from '../../common/ActionUrl';
import React, { useEffect, useState, useContext } from "react";
const publicIp = require("react-public-ip");

const requestOptions = {
	headers: { 'X-TENANT-ID': X_TENANT_ID, },
}

export const updateCart = async (data, publicIpLoaded) => {
	let user = localStorage.getItem('user')
		? JSON.parse(localStorage.getItem('user'))
		: ''
	const cartSession = localStorage.getItem('-') || '';
	let userId = user?.userId ? user?.userId : null;
	const ipv4 = !!publicIpLoaded ? publicIpLoaded : await publicIp.v4();
	let res = {
		"cartItems": data,
		"userId": userId ? userId : cartSession,
		"ipAddress": ipv4
	}
	return axios.post(`${CART_URL}`, res, requestOptions);
};

export const getCartById = async (user, publicIpLoaded) => {
	let userId = user?.userId ? user?.userId : null;
	const cartSession = localStorage.getItem('-') || '';

	const ipv4 = !!publicIpLoaded ? publicIpLoaded : await publicIp.v4();
	let cartFromUser = await axios.get(`${CART_URL}/${userId}/${ipv4}`, requestOptions);
	let cartItemsFromUser = cartFromUser?.data[0]?.cartItems && cartFromUser?.data[0]?.cartItems.length >0?cartFromUser?.data[0]?.cartItems:[];
	let cartFromGuestUser = await axios.get(`${CART_URL}/${cartSession}/${ipv4}`, requestOptions);
	const cartItemsFromGuestUser = cartFromGuestUser?.data[0]?.cartItems && cartFromGuestUser?.data[0]?.cartItems.length >0?cartFromGuestUser?.data[0]?.cartItems:[];

	if (!cartItemsFromUser) {
		cartItemsFromUser = cartItemsFromGuestUser;
	}else{
		const mergedCartItems = [...cartItemsFromUser,...cartItemsFromGuestUser] ;
		cartItemsFromUser = mergedCartItems;
	}
  const cartId = cartFromUser?.data[0]?._id ?? cartFromGuestUser?.data[0]?._id;
	return {_id: cartId, cartItems: cartItemsFromUser};
};
export const getCartList = () => {
	return axios.get(CART_URL, requestOptions);
};
export const updateUserIdCart = async (publicIpLoaded) => {
	const ipv4 = !!publicIpLoaded ? publicIpLoaded : await publicIp.v4();
	let user = localStorage.getItem('user')
		? JSON.parse(localStorage.getItem('user'))
		: ''
	let userId = user?.userId ? user?.userId : null;
	let res = {
		"userId": userId ? userId : '',
		"ipAddress": ipv4
	}
	return axios.post(`${CART_URL}/update-userid`, res, requestOptions);
};