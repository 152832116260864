import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import ProductgridList from "./ProductgridList";
import { useSelector } from "react-redux";
import ShopProductsNotFound from "../../pages/shop/views/ShopProductsNotFound";

const ShopProducts = ({ products, layout, loading }) => {
    const loadingCategories = useSelector((state) => state.shopData.fetchingCategories);

    const [showComponent, setShowComponent] = useState(false);

    useEffect(() => {
        if  (!(loading || loadingCategories)) {
            const timer = setTimeout(() => {
                setShowComponent(true);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [loading, loadingCategories]);

    return (
    <div className="shop-bottom-area mt-35">
      <div className={`row ${layout ? layout : ""}`}>
        {
            !(loading || loadingCategories) ?
                (products && products.length > 0 ? <ProductgridList products={products} spaceBottomClass="mb-25" />:
                    <ShopProductsNotFound  show={showComponent}/> ):
                <></>
        }
      </div>
    </div>
  );
};

ShopProducts.propTypes = {
  layout: PropTypes.string,
  products: PropTypes.array
};

export default ShopProducts;
