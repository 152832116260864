import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../common/Auth";
import { useThemeSettings } from "../../layouts/state/useThemeSettings";

export  const GoogleAnalytics  = () => {
    const { settingData } = useContext(AuthContext)
    const {
        googleTagManagerId,
        googleMeasurementId
    } = useThemeSettings({ settingData })


    useEffect(() => {
        if(googleMeasurementId) {
            addAnalyticScripts(googleMeasurementId)
        }
    }, [googleMeasurementId]);


    useEffect(() => {
        if(googleTagManagerId) {
            addTagManageScript(googleTagManagerId)
        }
    }, [googleTagManagerId]);

    const noScript = (id) => {
        const scriptBody = document.createElement('noscript');
        scriptBody.innerHTML = `
            <iframe src="https://www.googletagmanager.com/ns.html?id=${id}" height="0" width="0" style="display:none;visibility:hidden">
            
            </iframe>`
        return scriptBody
    }

    const addTagManageScript = (googleTagManagerId) => {
        const scriptHead = document.createElement('script');
        scriptHead.type = 'text/javascript';
        scriptHead.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
                            var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})
                            (window,document,'script','dataLayer','${googleTagManagerId}');`;

        document.head.appendChild(scriptHead);
        document.body.insertBefore(noScript(googleTagManagerId), document.body.childNodes[0])
        return () => {
            document.head.removeChild(scriptHead);
            document.head.removeChild(noScript(googleTagManagerId));
        }

    }

    const addAnalyticScripts = (id) => {
        const scriptTag = document.createElement('script');
        const scriptTagAsync = document.createElement('script');
        scriptTag.cfasync = false;
        scriptTagAsync.async = true;
        scriptTag.type = 'text/javascript';
        scriptTag.text = `window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${id}');`;
        scriptTagAsync.src = `https://www.googletagmanager.com/gtag/js?id=${id}`
        document.head.appendChild(scriptTagAsync);
        document.head.appendChild(scriptTag);
        return () => {
            document.head.removeChild(scriptTag);
            document.head.removeChild(scriptTagAsync);
        }
    }


    return <></>
}
