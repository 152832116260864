import * as blogsAPIUtil from "../../util/blog/blogsAPIUtil"

export const IS_LOADING = "IS_LOADING";
export const FETCH_ERROR = "FETCH_ERROR";
export const RECEIVE_BLOGS = "RECEIVE_BLOGS";
export const RECEIVE_BLOG = "RECEIVE_BLOG";
export const RECEIVE_FEATURED_BLOGS = "RECEIVE_FEATURED_BLOGS";
export const RECEIVE_COUNT_BY_CAT = "RECEIVE_COUNT_BY_CAT";

export const isLoading = (loading) => ({
  type: IS_LOADING,
  loading
});
export const sendFetchError = () => ({
  type: FETCH_ERROR
});

export const receiveBlog = (data) => ({
  type: RECEIVE_BLOG,
  payload: data,
});
export const receiveBlogcountsbycategories = (data) => ({
  type: RECEIVE_COUNT_BY_CAT,
  payload: data,
});
export const receiveBlogs = (data, count, meta) => ({
  type: RECEIVE_BLOGS,
  payload: data,
  count,
  meta
});
export const receiveFeaturedBlogs = (data, count, meta) => ({
  type: RECEIVE_FEATURED_BLOGS,
  payload: data,
  count,
  meta
});
export const getFeaturedBlogs = (limit = 50, offset = 0, order = "createdAt", orient = "desc", filters = []) => dispatch => blogsAPIUtil.fetchBlogs(limit, offset, order, orient, filters)
  .then(res => {
    const response = res.data;
    const listData = response.results.map(dataArray => {
      return (dataArray);
    });
    dispatch(receiveFeaturedBlogs(listData, response.meta.page_count, response.meta))
  }).catch(error => {
    dispatch(isLoading(false));
    dispatch(sendFetchError());
  });
export const fetchBlogs = (limit = 50, offset = 0, order = "createdAt", orient = "desc", filters = []) => dispatch => blogsAPIUtil.fetchBlogs(limit, offset, order, orient, filters)
  .then(res => {
    const response = res.data;
    const listData = response.results.map(dataArray => {
      return (dataArray);
    });
    dispatch(receiveBlogs(listData, response.meta.page_count, response.meta))
  }).catch(error => {
    dispatch(isLoading(false));
    dispatch(sendFetchError());
  });
export const fetchBlog = (url) => dispatch => blogsAPIUtil.fetchBlog(url)
  .then(res => {
    const response = {...res.data.blog, blogCommentsAccuracy: res.data.blogCommentsAccuracy};
    dispatch(receiveBlog(response))
  }).catch(error => {
    dispatch(isLoading(false));
    dispatch(sendFetchError());
  });
export const getBlogCountForeachCategory = (catIds) => dispatch => blogsAPIUtil.getBlogCountForeachCategory(catIds)
  .then(res => {
    const response = res.data;
    dispatch(receiveBlogcountsbycategories(response))
  }).catch(error => {
    dispatch(isLoading(false));
    dispatch(sendFetchError());
  });