import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Banner from "../../../pages/banner/Banner"
export default function DialogEditBanner ({ bannerId, open, handleClose, fromAction, getAllBannerList, fromEditAction }) {
    return (
        <div>
            <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="lg">
                <DialogTitle id="form-dialog-title">{bannerId ? "Update" : "Create"} Banner</DialogTitle>
                <DialogContent>
                    <Banner bannerId={bannerId} handleClose={handleClose} fromAction={fromAction} getAllBannerList={getAllBannerList} fromEditAction={fromEditAction} />
                </DialogContent>
            </Dialog>
        </div >
    );
}