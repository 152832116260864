import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../common/Auth";

function ChatSupport (props) {
    const { settingData } = useContext(AuthContext)
    const [chatSettings, setChatSettings] = useState('')

    useEffect(() => {
        if (settingData && settingData.length > 0) {
            settingData.map((settings) => {
                if (settings.page === 'General') {
                    setChatSettings(settings.values.chatSettings)
                }
            })
        }
    }, [settingData]);

    useEffect(() => {
        const scriptTag = document.createElement('script');
        scriptTag.cfasync = false;
        scriptTag.type = 'text/javascript';
        scriptTag.text = chatSettings;
        document.body.appendChild(scriptTag);
        return () => {
            document.body.removeChild(scriptTag);
        }
    }, [chatSettings]);


    return (
        <div>
        </div>
    );
}
export default ChatSupport;
