import React from "react";
import { PaymentMethodType } from "../../../models/PaymentMethodType"
import POForm from "./POForm";

interface PaymentMethodFormProps {

}

export const getPaymentForm = (methodType: PaymentMethodType, props: PaymentMethodFormProps) => {
  const map = {
    [PaymentMethodType.DIRECT_BILL]: undefined,
    [PaymentMethodType.PURCHASE_ORDER]: <POForm {...props} />,
  }
  return map[methodType];
}