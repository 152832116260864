import React, { useMemo, useState } from 'react'
import { Alert, Stack } from '@mui/material'
import PaymentRequestContent from './PaymentRequestContent'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { selectBalanceDue, selectParams, selectPaymentRequests, selectSessionId, selectStatus } from '../../../redux/selectors/checkoutSelectors'
import { AetherPaymentRequest, PaymentResult } from '../../../models/Payment'
import _ from 'lodash'
import { useSubmitOrderMutation, useSubmitPaymentsMutation } from '../../../redux/api/checkoutApi'
import { LoadingButton } from '@mui/lab'
import { getError } from '../../../helpers/checkout'
import { CheckoutStatus } from '../../../models/CheckoutSession'
import { PaymentMethodType } from '../../../models/PaymentMethodType'
import { addPaymentRequest, clearPaymentRequests, updatePaymentRequest, updatePaymentRequests } from '../../../redux/reducers/checkoutReducer'

export default function PaymentActiveContent() {
  const dispatch = useAppDispatch()
  const params = useAppSelector(selectParams)
  const sessionId = useAppSelector((state) => selectSessionId(state, params));
  const balanceDue = useAppSelector((state) => selectBalanceDue(state, params))
  const status = useAppSelector((state) => selectStatus(state, params))
  const paymentRequests = useAppSelector(selectPaymentRequests)
  const [paymentErrors, setPaymentErrors] = useState<{ method: PaymentMethodType, message: string }[]>([])
  const [selected, setSelected] = useState<number | null>(null)

  const [submitPayments, { isLoading: submitPaymentsLoading, error: submitPaymentsError }] = useSubmitPaymentsMutation()
  const [submitOrder, { isLoading: submitOrderLoading, error: submitOrderError }] = useSubmitOrderMutation()

  const remainingBalance = useMemo(() => balanceDue - paymentRequests.reduce((acc, request) => acc + request.amount, 0), [balanceDue, paymentRequests])

  const onSubmit = ({ index, data }: { index: number | null, data: AetherPaymentRequest }) => {
    if (index === null) {
      dispatch(addPaymentRequest(data))
    } else {
      dispatch(updatePaymentRequest({ index, request: data }))
    }
    setSelected(null)
  }

  const handleSelect = (index: number | null) => () => {
    setSelected(index)
  }

  const handlePlaceOrder = async () => {
    try {
      if (sessionId) {
        const newPaymentRequests = [];
        const newPaymentErrors = [];
        let submit = true;
        if (status === CheckoutStatus.PENDING_PAYMENT) {
          const { paymentResponses, session } = await submitPayments({ sessionId, paymentRequests }).unwrap()
          if (session.status === CheckoutStatus.PENDING_PAYMENT) {
            // Update form with failed payments
            if (paymentResponses.length !== paymentRequests.length) {
              throw new Error('Payment response count does not match request count')
            }
            for (let i = 0; i < paymentResponses.length; i++) {
              const response = paymentResponses[i]
              if (response.result !== PaymentResult.SUCCESS) {
                newPaymentRequests.push(paymentRequests[i])
                if (response.result === PaymentResult.FAILURE) {
                  newPaymentErrors.push({
                    method: response.methodType,
                    message: response.message ?? 'Unknown error processing payment'
                  });
                  if (selected === null) {
                    setSelected(i)
                  }
                }
              }
            }
            dispatch(updatePaymentRequests(newPaymentRequests))
            setPaymentErrors(newPaymentErrors)
            submit = false
          }
        }
        if (submit) {
          dispatch(clearPaymentRequests())
          setPaymentErrors([])
          await submitOrder({ sessionId }).unwrap()
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Stack direction={'column'} spacing={2}>
      {paymentRequests.map((_, index) => (
        <PaymentRequestContent
          key={index}
          index={index}
          errorMessage={paymentErrors[index]}
          editing={selected === index}
          onEdit={handleSelect(index)}
          onSubmit={onSubmit}
        />
      ))}
      {selected === null && remainingBalance > 0 && (
        <PaymentRequestContent
          index={null}
          editing={true}
          onEdit={handleSelect(null)}
          onSubmit={onSubmit}
        />
      )}
      <LoadingButton variant="contained" size={"large"} disabled={remainingBalance !== 0} loading={submitPaymentsLoading || submitOrderLoading} onClick={handlePlaceOrder}>Place Order</LoadingButton>
      {submitPaymentsError && <Alert severity='error'>Error Processing Payments: {getError(submitPaymentsError)}</Alert>}
      {submitOrderError && <Alert severity='error'>Error Submitting Order: {getError(submitOrderError)}</Alert>}
    </Stack>
  )
}
