import axios from 'axios';
import { X_TENANT_ID, ALL_USER_GROUP, ASSIGN_USER_GROUP, GET_CONTACT, } from '../../common/ActionUrl';
const requestOptions = {
    headers: { 'X-TENANT-ID': X_TENANT_ID },
}

export const getUserGroupsByUser = (userId) => {
    return axios.get(ALL_USER_GROUP + userId + "?offset=0&limit=0", requestOptions)
}

export const updateUserGroups = (userId, inputs) => {
    return axios.patch(ASSIGN_USER_GROUP + userId, inputs, requestOptions)
}

export const getContact = (userId) => {
    return axios.get(GET_CONTACT.replace(":userId", userId), requestOptions);
}