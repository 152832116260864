import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./common/Auth"
import ThemeComponent from "./constant/themeComponent"
import { useHistory, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

const DynamicComponent = () => {
    const { addToast } = useToasts();
    const [theme, setTheme] = useState("spring");
    const { themeSettingsData, user } = useContext(AuthContext)
    const useQuery = () => {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    const [tokenId, setIdToken] = useState(query.get('id_token'))
    const [accessToken , setAccessToken] = useState(query.get('access_token'))
    const [code , setCode] = useState(query.get('code'))
    const [location, setLocation] = useState(window.location.href)
    const history = useHistory();

    useEffect(() => {
        const urlParts = location.split('#')
        if(urlParts.length > 1) {
            history.push(`/cb?${urlParts[1]}`)
        }
    }, [location])
    

    useEffect(() => {
        if (themeSettingsData && themeSettingsData.length > 0) {
            const theme  = themeSettingsData.find(i =>i.values[0].themeName != "" && ThemeComponent[i.values[0].themeName])
            if(theme?.values?.length) {
                setTheme(theme.values[0].themeName);
            }
        }
    }, [themeSettingsData])

    const Theme = ThemeComponent[theme];
    return (
        <>
            <Theme />
        </>
    )
}

export default DynamicComponent;
