import {UPLOADIMAGE, X_TENANT_ID} from "../common/ActionUrl";
import axios from "axios";

export let TextEditorCustomVideoBtn = {
    name: 'load video', icon: 'video',
    popup: (editor, current, self, close) => {
        const form = editor.create.fromHTML(
            `
              <form>
                <input id="fileupload" type="file" name="fileupload" accept="video/mp4,video/x-m4v,video/*"/>
                <button type="submit">Insert</button>
              </form>
            `
        );

        editor.e.on(form, 'submit', (e) => {
            e.preventDefault();

            const newVideoTarget = form.querySelector('input').files[0];
            const formData = new FormData();
            formData.append("file", newVideoTarget, newVideoTarget.name);

            const requestOptions = {
                headers: { 'Content-Type': 'application/json', 'X-TENANT-ID': X_TENANT_ID }
            };

            axios.post(UPLOADIMAGE, formData, requestOptions)
                .then(res => {
                    if (res.data && res.data.Location != "") {
                        editor.selection.insertHTML(`
                                  <video width="350" controls>
                                  <source src=${res.data.Location} type="video/mp4">
                                  </video>`);
                        close();
                    }
                }).catch((error, config, code, request, response) => {
            });
        });

        return form;
    }
};

export let TextEditorCustomPdfBtn = {
    name: 'load files', icon: 'file',
    popup: (editor, current, self, close) => {
        const form = editor.create.fromHTML(
            `
              <form>
                <input id="fileupload" type="file" name="fileupload" accept="application/pdf,application/vnd.ms-excel, text/plain"/>
                <button type="submit">Insert</button>
              </form>
            `
        );

        editor.e.on(form, 'submit', (e) => {
            e.preventDefault();

            const newVideoTarget = form.querySelector('input').files[0];
            const formData = new FormData();
            formData.append("file", newVideoTarget, newVideoTarget.name);

            const requestOptions = {
                headers: { 'Content-Type': 'application/json', 'X-TENANT-ID': X_TENANT_ID }
            };

            axios.post(UPLOADIMAGE, formData, requestOptions)
                .then(res => {
                    if (res.data && res.data.Location != "") {
                        editor.selection.insertHTML(`
                                           <iframe id=${res.data.key} src="https://docs.google.com/viewer?url=${res.data.Location}&amp;embedded=true"></iframe>
                                          `);

                        close();
                    }
                }).catch((error, config, code, request, response) => {});
        });

        return form;
    }
}
export default [TextEditorCustomPdfBtn,TextEditorCustomVideoBtn]
