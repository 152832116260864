/* eslint-disable no-useless-computed-key */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import PlaceHolder from '../../assets/img/placeholder-image.png'
import {connect, useDispatch, useSelector} from 'react-redux'
import { LightgalleryItem, LightgalleryProvider } from 'react-lightgallery'
import { USERTYPES } from '../../constant/userType'
import AddIcon from '@material-ui/icons/Add'
import Select from 'react-select'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  useMediaQuery,
  Table,
} from '@material-ui/core'
import ReactStars from 'react-rating-stars-component'
import Swiper from 'react-id-swiper'
import { productImageExt, productImageUrl } from '../../util/helper'
import useElementSize from '../../util/custom-hooks/useElementSize'
import { animateScroll } from 'react-scroll'
import { AuthContext } from '../../common/Auth'
import { FRONTREVIEW, X_TENANT_ID } from '../../common/ActionUrl'
import { useToasts } from 'react-toast-notifications'
import {
  fetchArtworksById,
  fetchDecorationLocations,
  fetchProductStock, receiveDecorationLocations,
} from '../../redux/actions/productActions'
import {
  fetchDstProductInventory,
  fetchProductInventory,
  getDecorationPrice,
  getEnabledArtworkLocations,
  getProductDecorationPopup,
} from '../../util/products/productsAPIUtil'
import DisplayReview from './DisplayReview'
import { addItemsRowsTocart } from '../../redux/actions/cartActions'

import {
  getPersColor,
  getPersFont,
  getPersLocation,
  getPersNote,
  roundedPrice,
  getShowProductPrice,
  WithCommas,
  getMoqNote,
  getShowMoqNote,
  numberWithCommas2,
  getFirstPrice,
  getDiscountPrice,
  showCurrencycodeSymbol,
} from '../../helpers/product'
import AddnewArtDialogue from '../../components/artwork/AddnewArtDialogue'
import DecorationSelectbox from '../../components/product/sub-components/DecorationSelectbox'
import AddDecorationportal from '../../components/product/sub-components/AddDecorationportal'
import { useHistory } from 'react-router-dom'
import Loader from '../../common/Loader'
import AddProductReviewModel from './AddProductReviewModel'
import parse from 'html-react-parser'
import SharedModal from '../modal/SharedModal'
import ProductImageGalleryStepper from '../../components/product/ProductImageGalleryStepper'
import { useProductConfigState } from "../../pages/shop-product/state/useProductConfigState";
import { useThemeSettingsData } from '../../layouts/state/useThemeSettingsData'
import { useThemeSettings } from '../../layouts/state/useThemeSettings'
import { useProductPriceToShow } from "../../components/product/state/useProductPriceToShow";
import PriceTable from './productPrice/PriceTable'
import {useProductArtworkState} from "../../components/product/state/useProductArtworkState";
import { useProductPriceState } from '../../components/product/state/useProductPriceState'

let uniqIdcounter = 1
const ProductAttributeImageDescription = ({
  spaceTopClass,
  spaceBottomClass,
  product,
  currency,
  cartItems,
  wishlistItems,
  artworkList,
  artworkListById,
  compareItems,
  decorationLocations,
  addItemsRowsTocart,
  fetchDecorationLocations,
  fetchArtworksById,
  isModel = false,
  fetchProductStock,
}) => {
  const history = useHistory()
  const [imgW, imgH] = useElementSize('.swiper-slide-active .containerimgMain')
  const [expanded, setExpanded] = React.useState('DescriptionPanel')
  const [swipeimageFlag, setSwipeimageFlag] = useState(true)
  const [isReplace, setIsReplace] = useState(true)
  const [isTrue, setIsTrue] = useState(false)
  const [selectedDecoration, setSelectedDecoration] = useState(null)
  const [catalogueCustomRows, setCatalogueCustomRows] = useState([])
  const [imageW, setImageW] = useState(imgW)
  const [imageH, setImageH] = useState(imgH)
  const [searchTextArtwork, setTextSearchArtwork] = useState('')
  const [artworksToRead, setArtworksToRead] = useState([])
  const [showAddnewartId, setAddnewartId] = useState(0)
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null)
  // const [selectedProductColor, setSelectedProductColor] = useState(null)
  const [selectedProductMainColor, setSelectedProductMainColor] = useState(null)
  const [ProductColorTemp, setProductColorTemp] = useState(false)
  const [selectedProductMainImage, setSelectedProductMainImage] = useState(null)
  const [tempValue, setTempValue] = useState(0)
  const [isMoq, setIsMoq] = useState(false)
  const [isPriceBreak, setIsPriceBreak] = useState(false)
  const [isSkuMoq, setIsSkuMoq] = useState(false)
  const [isRestrictQty, setIsRestrictQty] = useState(false)
  const [partPrice, setPartPrice] = useState([])
  const [prodId, setProdId] = useState(null)
  const [productId, setProductId] = useState(null)
  const [showAddnewart, setAddnewart] = useState(false)
  const [isSearchArtwork, setIsSearchArtwork] = useState(false)
  const [customDecorationFee, setCustomDecorationFee] = useState(false)
  const [customDecorationPerUnitPrice, setCustomDecorationPerUnitPrice] =
    useState(null)
  const [personalizationPerUnitPrice, setPersonalizationPerUnitPrice] =
    useState(null)
  const [personalizationSetupFee, setPersonalizationSetupFee] = useState(null)
  const [customDecorationSetupFee, setCustomDecorationSetupFee] = useState(null)
  const [storeArtwork, setStoreArtwork] = useState([])
  const [personalizationCost, setPersonalizationCost] = useState({
    matchOrderQty: 0,
    price: 0,
    setupPrice: 0,
    chargeId: '',
  })
  const [selectedImage, setSelectedImage] = useState('')
  const [gallerySwiper, getGallerySwiper] = useState(null)
  const [toggleDivsArray, setToggleDivs] = useState([])
  const [showData, setShowData] = useState('yes')
  const [notCallDecoHtml, setNotCallDecoHtml] = useState(false)
  const [toggleValue, setToggleValue] = useState(false)
  const [tempSelectedColor, setTempSelectedColor] = useState('')
  const [sizesStock, setSizesStock] = useState(true)
  const [IsAllowOutOfStockOrder, setIsAllowOutOfStockOrder] = useState(false)
  const [isProductInventoryEnabled, setIsProductInventoryEnabled] = useState(true)
  const [productDstInventory, setProductDstInventory] = useState(null)
  const [productInventory, setProductInventory] = useState(null)
  const [loading, setLoading] = useState(false)
  const [statchange, updatestate] = useState(false)
  const [personalizationAdded, setPersonalizationAdded] = useState(false)
  const disIndProductPrice = getShowProductPrice(product)
  const [selectedLocation, setSelectedLocation] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { addToast } = useToasts()
  const [reviews, setReviews] = useState([])
  const [reviewCount, setReviewCount] = useState(0)
  const [showColor, setShowColor] = useState(true)
  const isSmall = useMediaQuery('(max-width: 640px)')
  const isMedium = useMediaQuery('(max-width: 1007px)')
  const [reviewExist, setReviewExist] = useState(false)
  const [showSize, setShowSize] = useState(true)
  const [showMoqNote, setShowMoqNote] = useState(false)
  const [minQty, setMinQty] = useState('1')
  const [minAllQty, setMinAllQty] = useState([])
  const [moqNote, setMoqNote] = useState(
    'Minimum order quantity for this item is [MOQ]',
  )
  const [selectedProductArtwork, setSelectedProductArtwork] = useState('')
  const [selectedProductDecorations, setSelectedProductDecorations] = useState(
    [],
  )
  const [selectedProductLocation, setSelectedProductLocation] = useState('')
  const [OpenReview, setOpenReview] = useState(false)
  const [productSettingShowDescription, setProductSettingShowDescription] = useState(true)
  const [showAddionalDescription, setShowAddionalDescription] = useState(false)
  const [singleSelectedDecorations, setSingleSelectedDecorations] = useState(null);
  const [statChangeTemp, updateStateTemp] = useState(0)
  const [statChangeTempNew, updateStateTempNew] = useState(0)
  const [addRowQtyFirst, setaddRowQtyFirst] = useState(0)
  const [isAdded, setIsAdded] = useState(false)
  const [decorationListOrder, setDecorationListOrder] = useState(false);
  const [preventAutoAttach, setPreventAutoAttach] = useState(false)
  const [personalizationDropDown, setPersonalizationDropDown] = useState(false)
  const [personalizationRequired, setPersonalizationRequired] = useState(false)
  const [requiredLinesPersonalization, setRequiredLinesPersonalization] = useState('')
  const [dropDownValues, setDropDownValues] = useState([])
  const [isDrpDeco, setIsDrpDeco] = useState(false)
  const [colorLabel, setColorLabel] = useState('Color')
  const [sizeLabel, setSizeLabel] = useState('Size')
  const [decimalsToShow, setDecimalsToShow] = useState(2);
  const [hideLocationName, setHideLocationName] = useState(null)
  const [
    RequiredDecorationForCheckOutPro,
    setRequiredDecorationForCheckOutPro,
  ] = useState(false)
  const MainSize = ['XS', 'S', 'M', 'L', 'XL', '1XL', '2XL', 'XXL' ,'3XL', 'XXXL', '4XL']
  const {
    settingData,
    themeSettingsData,
    user,
    userType,
    decoTypeList,
    userGroupDiscount,
    showAddToCartOnProd,
    isDirectToCart,
    storeDetails,
    ipV4,
    usePointsAsCurrency
  } = useContext(AuthContext)
  const [showDecoPopup, setShowDecoPopup] = useState(false);
  const [indexPopup, setIndexPopup] = useState([])
  const [mainArrayKeyPopup, setMainArrayKeyPopup] = useState([])
  const [itemsRowsPopup, setItemsRowsPopup] = useState([])
  const [popupText, setPopupText] = useState('');
  const [artworkLocations, setArtworkLocations] = useState([]);
  const [sliderData, setSliderData] = useState([]);
  const [allowOneDec, setallowOneDec] = useState("0")
  const [loadingArtworks, setLoadingartworks] = useState(false);
  const [showDecorationCostInCart, setShowDecorationCostInCart] = useState(false);
  const [showSetupChargeInCart, setShowSetupChargeInCart] = useState(false);
  const [showPersonalizationCostInCart, setShowPersonalizationCostInCart] = useState(false);
  const [allowCustomDecoration, setAllowCustomDecoration] = useState(false);
  const [isStockFor, setIsStockFor] = useState(null)
  const [defaultSizeForAll, setDefaultSizeForAll] = useState([])
  const [fetchedInvProductId, setFetchedInvProductId] = useState('')
  const [isFirstTime, setIsFirstTime] = useState(true)
  const [selectedLocationIndex, setSelectedLocationIndex] = useState(0)
  const [hideDecorationName, setHideDecorationName] = useState(null)
  const [priceTables, setPriceTable] = useState([])

  const {
    inventorySettings,
    isStock,
    isAllowoutofStockOrder,
    productSettings,
  } = useThemeSettingsData({themeSettingsData});

  const {
    priceDisplay,
    itempriceDisplay,
    itemNumber,
    productLayoutSettings,
    DefaultProductBackgroundColor,
    artworkSetting,
    sortSizeSetting
  } = useThemeSettings({ settingData })
  
  const {
    getPointsFromValue,
    getPointsValue
  } = useProductPriceToShow({ productSettings, product  })

  const dispatch = useDispatch()

  let finalPriceProduct = '0'
  const artworksProductState = useSelector((state) => state.productData.artworkListById);
  const artworksListState = useSelector((state) => state.productData.artworkList);
  const productStockState = useSelector((state) => state.productData.productStock);
  const artworksFromState =  [...artworksListState, ...artworksProductState]
  const {
    getDecoPrice,
  } = useProductArtworkState({
    artworksFromState: artworksFromState,
    setLoading: (loading) => setLoading(loading)
  })

  const {
    checkMinQty,
    orderAction,
    getInventorySettingStatus,
    getSettingStatus,
    getCartItemTotal,
    getLocationsCondensed,
  } = useProductConfigState({
    cartItems,
    catalogueCustomRows,
    product,
    artworksProductState,
    artworksFromState,
    artworkSetting,
    selectedLocationIndex
  })

  const {
    showSellPrice,
    showSell,
    showPrice,
    offerPrice,
    discountType,
    finalProductPrice,
    discountedPrice,
    finalDiscountedPrice,
    finalProductDiscPrice,
  } = useProductPriceState({product, currency, userGroupDiscount, itempriceDisplay, priceDisplay, user})

  const colorStyles = {
    container: (styles) => ({...styles, textAlign: 'left', 
    }),
    input: (styles) => ({...styles,  position: "absolute",
      top: "0",
      width: "100%",
      height: "40px",
      left: "0",
      padding: "0",
      margin: "0",
      display: "block",
      '& > input' : {
        position: "absolute",
        top: "-4px",
        height: "40px",
        left: "0",
        paddingLeft: "10px !important",
      },
    }),
    singleValue : (styles) => ({...styles,
      top:'3px',
      position: "relative",
    }),
    placeholder : (styles) => ({...styles,
      top:'3px',
      position: "relative",
    }),
    control: (styles) => ({ ...styles, backgroundColor: 'transparent',
      border: '1px solid #ebebeb',
      color: '#333',
      fontSize: '14px',
      margin : "10px 0px !important",
      position: "relative",
      borderRadius:0,
      height:"38px",
      textAlign:"left !important",
      '& > div' : {
        height: "100% !important",
        display: "block !important",
        overflow: "unset !important",
      },
    }),
  };
  
  useEffect(() => {
    if (product) {
      if (product.WebstoreProductPartArray?.length) {
        const partPriceArray = product.WebstoreProductPartArray.find(x =>
          x.active &&
            tempSelectedColor && tempSelectedColor !== colorLabel ? x.color === tempSelectedColor : x.color === colors_final[0]
        );
        if (partPriceArray) {
          const priceTable = partPriceArray.partPrice?.PartPriceArray?.PartPrice ?? []
          setPriceTable(priceTable)
        }
      } else if (product?.ProductPartArray?.ProductPart && product?.ProductPartArray?.ProductPart.length > 0) {
        const defaultColor = colors_final?.[0] || '';
        const selectedColorToCompare = tempSelectedColor || defaultColor;
        const part = product.ProductPartArray.ProductPart.find(part => {
          const colors = part?.ColorArray?.Color?.colorName ?? part.color;
          return selectedColorToCompare.toLowerCase() === colors.toLowerCase();
        });
        if (part) {
          const priceTable = part.partPrice?.PartPriceArray?.PartPrice ?? [];
          setPriceTable(priceTable);

        }
      }
    }
  }, [product, tempSelectedColor])

  useEffect(() => {
    if(productStockState) {
      setProductDstInventory(productStockState.dstInventory)
      setProductInventory(productStockState.inventory)
    }
  }, [productStockState])

  useEffect(() => {
    if(!!product?.relabelColor) {
        setColorLabel(product.relabelColor)
    }
    if(!!product?.relabelSize) {
        setSizeLabel(product.relabelSize)
    }
  },[product])

  let showBtn = true
  let userId = user?.userId
  let colors_final = []
  let priceTableTemp = []
  let priceTable = []
  let groups = []
  let colors = []
  let colors_ids = []
  let colors_idsColor = []
  let colors_hex = []
  let getSingleImage = ''
  var rWithoutDiscPrice = 0
  let toatlQty = 0
  let toatlPrice = 0
  let totalStock = 0

  useEffect(()=>{
    if ( product?.preventAutoAttach === true || product?.preventAutoAttach === false ) {
      setPreventAutoAttach(product?.preventAutoAttach)
    } else {
      setPreventAutoAttach(!!productSettings?.preventAutoAttach ? productSettings.preventAutoAttach : false)
    }
    if(product?.hideDecorationName === true || product?.hideDecorationName === false) {
      setHideDecorationName(product?.hideDecorationName)
    } else {
      setHideDecorationName(!!productSettings?.hideDecorationName ? productSettings.hideDecorationName : false)
    }
  }, [product, productSettings])

  useEffect(() => {
    if(!!!product.isStockFor || product.isStockFor === 'globalsetting') {
      setIsStockFor(isStock)
    } else {
      setIsStockFor(product.isStockFor)
    }
  }, [product, isStock])

  useEffect(() => {
    if (sortSizeSetting?.length) {
      setDefaultSizeForAll(sortSizeSetting.map(item => item.value.toLowerCase()));
    }
  }, [sortSizeSetting])

  const isColorValid = (color) => {
    return !!color && color !== `Select ${colorLabel}`;
  }

  useEffect(() => {
    if(product?.allowCustomDecoration === true || product?.allowCustomDecoration === false) {
      setAllowCustomDecoration(product?.allowCustomDecoration)
    } else {
      setAllowCustomDecoration(!!productSettings?.allowCustomDecoration ? productSettings.allowCustomDecoration : false)
    }
  },[productSettings, product])

  useEffect(() => {
    if (product?.MediaContent?.[0] && product?.MediaContent?.[0].displayGroup) {
        let group = product.MediaContent.filter(x => x.group === product?.MediaContent?.[0].displayGroup).filter(x => !x.primary);
        let colors = []
        if(group.length === 0) {
          group = product.MediaContent.filter(x => {
            if(!colors.includes(x.color)) {
              colors.push(x.color)
              return x;
            }
          }).filter(x => !x.primary || !x.secondary);
        }
        let primary = product?.MediaContent.filter(x => x.primary);
        if(primary.length === 0) 
            primary = product?.MediaContent.filter(x => x.secondary);

        group = group.filter(res => res.color !== primary?.[0]?.color)
        product.MediaContent = product.MediaContent.filter(x => x.group !== product?.MediaContent?.[0].displayGroup).filter(x => !x.primary);
        group.unshift(...primary)
        setSliderData(group)
        if(!!primary?.[0]?.color) {
          setSelectedImage(primary?.[0]?.color)
        }
        product.MediaContent.unshift(...group);
    } else {
      setSliderData(product.MediaContent)
    }
  }, [product])

  useEffect(() => {
    if(product?.personalizationDropDown === true || product?.personalizationDropDown === false) {
      setPersonalizationDropDown(product?.personalizationDropDown)
      setRequiredLinesPersonalization(product?.requiredLinesPersonalization)
      setDropDownValues(product?.dropDownValues)
    } else if(productSettings?.personalizationDropDown === true || productSettings?.personalizationDropDown === false) {
      setPersonalizationDropDown(productSettings?.personalizationDropDown)
      setRequiredLinesPersonalization(productSettings?.requiredLinesPersonalization)
      setDropDownValues(productSettings?.dropDownValues)
    }
    if (product?.personalizationRequired === true || product?.personalizationRequired === false) {
      setPersonalizationRequired(product?.personalizationRequired)
    } else if (productSettings?.personalizationRequired === true || productSettings?.personalizationRequired === false) {
      setPersonalizationRequired(productSettings?.personalizationRequired)
    }
  }, [product, productSettings])

  useEffect(() => {
    if (personalizationRequired) {
      addPersonalization();
      setPersonalizationAdded(true)
    }
  }, [personalizationRequired])

  useEffect(() => {
    if(catalogueCustomRows.length > 0 && statChangeTempNew === 1) {
      if(colors_final.length === 1) {
        verifyShowColor()
        changeColor(colors_final[0], 0, 0)
      }
      if(priceTable.length === 1 && colors_final.length === 1) {
        changeQty(addRowQtyFirst, 0 , priceTable[0].size, 1)
      }
      updateStateTemp(0)
    }
  },[statChangeTempNew])
  const [tempValueCheck, setTempValueCheck] = useState(1)
  useEffect(() => {
    if (artworksProductState?.length && colors_final.length === 1 && tempValueCheck === 1) {
      verifyShowColor()
      changeColor(colors_final[0], 0, 0)
      setTempValueCheck(tempValueCheck + 1)
    }
  }, [artworksProductState])
  

  useEffect(() => {
    let showAdditional = product?.showAddionalDescription ?? productSettings?.showAddionalDescription;
		setShowAddionalDescription(showAdditional)
    if (typeof product?.RequiredDecorationForCheckOutPro ===  'boolean') {
      setRequiredDecorationForCheckOutPro(product?.RequiredDecorationForCheckOutPro)
    } else {
      setRequiredDecorationForCheckOutPro(productSettings?.RequiredDecorationForCheckOut)
    }
    if (product?.showDescription === true || product?.showDescription === false) {
      setProductSettingShowDescription(product?.showDescription)
    } else {
      setProductSettingShowDescription(productSettings?.showDescription)
    }
    if (typeof product?.AllowOutofStock === 'boolean') {
      setIsAllowOutOfStockOrder(product?.AllowOutofStock)
    } else {
      setIsAllowOutOfStockOrder(isAllowoutofStockOrder)
    }
    if(product) {
      setIsProductInventoryEnabled(getInventorySettingStatus(product))
    }
    if (product?.showColorProd === true || product?.showColorProd === false) {
      setShowColor(product?.showColorProd)
    } else {
      setShowColor(productSettings?.showColor)
    }

    if (product?.showSizeProd === true || product?.showSizeProd === false) {
      setShowSize(product?.showSizeProd)
    } else {
      setShowSize(productSettings?.showSize)
    }
    if(!!product?.AllowOneDecorationLimitPro && product?.AllowOneDecorationLimitPro !== " ") {
      setallowOneDec(product?.AllowOneDecorationLimitPro)
    } else {
      setallowOneDec(productSettings?.AllowOneDecorationLimit)
    }
    if (product?.showDecorationCostInCart === true || product?.showDecorationCostInCart === false) {
      setShowDecorationCostInCart(product?.showDecorationCostInCart)
    } else {
      setShowDecorationCostInCart(productSettings?.showDecorationCostInCart)
    }
    if (product?.showSetupChargeInCart === true || product?.showSetupChargeInCart === false) {
      setShowSetupChargeInCart(product?.showSetupChargeInCart)
    } else {
      setShowSetupChargeInCart(productSettings?.showSetupChargeInCart)
    }
    if (product && productSettings && minQty) {
      setMoqNote(getMoqNote(productSettings, product, minQty))
      setShowMoqNote(getShowMoqNote(productSettings, product))
    }
    if (product && showAdditional !== false) {
      setExpanded('DescriptionPanel')
    } else if (product && product.showAdditionalInformation) {
      setExpanded('SpecificationPanel')
    } else if (productSettings && productSettings?.['showReviews']) {
      setExpanded('showReviews')
    }
    if(product?.showPersonalizationCostInCart === true || product?.showPersonalizationCostInCart === false) {
      setShowPersonalizationCostInCart(product?.showPersonalizationCostInCart);
    } else {
      setShowPersonalizationCostInCart(productSettings?.showPersonalizationCostInCart);
    }
    if (productSettings && productSettings?.['showReviews']) {
      getReviews()
      checkReviewCount()
    }
    if(!!product?.decimalsToShow) {
      setDecimalsToShow(Number(product?.decimalsToShow))
    } else if(!!productSettings?.decimalsToShow) {
      setDecimalsToShow(Number(productSettings?.decimalsToShow))
    } else {
      setDecimalsToShow(2);
    }
    if (product?.hideLocationName === true || product?.hideLocationName === false) {
			setHideLocationName(product?.hideLocationName)
		} else {
			setHideLocationName(!!productSettings?.hideLocationName ? productSettings.hideLocationName : false)
		}
  }, [product, productSettings, isAllowoutofStockOrder, minQty])

  useEffect(() => {
    if(product && !!product?.decorationListOrder) {
      setDecorationListOrder(product?.decorationListOrder)
    }
  }, [product])

  const addDecoration = (variation, parentKey) => {
    let locationArray = decorationLocations?.filter(
      (locationArray) => locationArray.id === selectedProductLocation,
    )

    let tempRows = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      if (groupkey === parentKey) {
        rowGroupArray.filter((rowArray) => {
          let tempDecoArray = rowArray.decorations ? rowArray.decorations : []
          tempDecoArray.push({
            artworkID: selectedProductArtwork,
            location: locationArray?.[0]?.locationName,
            locationId: selectedProductLocation,
            variationUniqID: variation.design_variation_unique_id,
          })
          rowArray.decorations = tempDecoArray
          return rowArray
        })
      }
      return rowGroupArray
    })

    setCatalogueCustomRows(tempRows)
    setSelectedProductLocation('')
    setSelectedProductArtwork('')
  }

  const searchArtworkName = (e) => {
    setTextSearchArtwork(e.target.value)
  }

  useEffect(() => {
    if (storeDetails?.accountId) {
      setLoadingartworks(true)
      fetchArtworksById({
        account_id: storeDetails.accountId,
        product_id: product.id,
        userId: user?.userId || undefined,
        categories: product.ProductCategoryArray?.map(x => x.id) || [],
        restrict: true,
      }).finally(() => {
        setLoadingartworks(false)
      })
    }
  }, [storeDetails])

  useEffect(() => {
    const locations = getLocationsCondensed();
    if (locations?.locationIds?.length > 0 && isFirstTime) {
      setIsFirstTime(false);
      fetchDecorationLocations(locations.locationIds)
    } else if(locations?.locationIds?.length === 0 && isFirstTime) {
      dispatch(
          receiveDecorationLocations({
                data: []
              }
          ),
      )
      setIsFirstTime(false)
    }
  }, [product, catalogueCustomRows, artworksFromState, selectedProductMainColor])

  useEffect(() => {
    getArtworkLocations()
  }, [])

  useEffect(() => {
    // console.log('productStockState: ', productStockState, " ==== isModel === ", isModel,  " ==== fetchedInvProductId === ", fetchedInvProductId,  " ==== product.id === ", product.id );
    if((!!!productStockState || (isModel && (fetchedInvProductId === ""  || (!!fetchedInvProductId && fetchedInvProductId !== product.id)))) && isProductInventoryEnabled) {
      fetchProductStock(product.id)
      setFetchedInvProductId(product.id)
    }
  },[productStockState, product])

  const getArtworkLocations = () => {
    getEnabledArtworkLocations(product._id).then(response => {
        setArtworkLocations(response.data);
    }).catch(error => {
        console.log('error', error);
        addToast('Error while loading data!', {
            appearance: "error",
            autoDismiss: true
        });
    })
  }

  const changePersonalizatinArray = (e, type, DataArray, isDrp = 0) => {
    let tempArray = catalogueCustomRows.filter((rowGroup) => {
      if (rowGroup.length > 0) {
        rowGroup.filter((rowArray) => {
          if (rowArray.id === DataArray.rowId) {
            rowArray.personalization = rowArray.personalization.filter(
              (ItemArray) => {
                if (
                  ItemArray.rowQtyId === DataArray.rowQtyId &&
                  ItemArray.sequance === DataArray.sequance &&
                  ItemArray.rowQtyNo === DataArray.rowQtyNo
                ) {
                  if (type === 'Font') {
                    ItemArray.font = e.target.value
                  } else if (type === 'Color') {
                    ItemArray.color = e.target.value
                  } else if (type === 'Location') {
                    ItemArray.location = e.target.value
                  } else if (type === 'Note') {
                    ItemArray.notes = e.target.value
                  } else if (type === 'displayText') {
                    ItemArray.displayText = isDrp === 1 ? e.value : e.target.value
                  }
                }
                return ItemArray
              },
            )
          }
          return rowArray
        })
      }
      return rowGroup
    })

    setCatalogueCustomRows(tempArray)
    setToggleValue(!toggleValue)
  }

  const AddPersonalizationRow = (personalization) => {
    let newRowData = null
    let tempArray = catalogueCustomRows.filter((rowGroup) => {
      if (rowGroup.length > 0) {
        rowGroup.filter((rowArray) => {
          if (rowArray.id === personalization.rowId) {
            let rowIndex = 0
            rowArray.personalization.map((ItemArray, key) => {
              if (
                ItemArray.rowQtyId === personalization.rowQtyId &&
                ItemArray.rowQtyNo === personalization.rowQtyNo
              ) {
                rowIndex = key
                newRowData = {
                  ...ItemArray,
                  color: getPersColor(product, productSettings),
                  location: getPersLocation(product, productSettings),
                  font: getPersFont(product, productSettings),
                  notes: getPersNote(product, productSettings),
                  displayText: '',
                  sequance: (Number(ItemArray.sequance) + 1).toString(),
                }
              }
            })
            let TempPersonalization = []
            rowArray.personalization.map((Row, key) => {
              TempPersonalization.push(Row)
              if (rowIndex === key) {
                TempPersonalization.push(newRowData)
              }
            })
            rowArray.personalization = TempPersonalization
          }
          return rowArray
        })
      }
      return rowGroup
    })

    setCatalogueCustomRows(tempArray)
    setToggleValue(!toggleValue)
  }

  const DeletePersonalizationRow = (personalization, arrayKey) => {
    let tempArray = catalogueCustomRows.filter((rowGroup) => {
      if (rowGroup.length > 0) {
        rowGroup.filter((rowArray) => {
          if (rowArray.id === personalization.rowId) {
            let TempPersonalization = rowArray.personalization
            TempPersonalization = TempPersonalization.filter((row, key) => {
              if (
                row.rowQtyNo === personalization.rowQtyNo &&
                personalization.sequance === row.sequance &&
                personalization.rowId === row.rowId
              ) {
                return false
              }
              return true
            })
            let seq = null
            TempPersonalization = TempPersonalization.filter((row) => {
              if (
                seq !== null &&
                row.sequance !== 1 &&
                row.rowQtyNo === personalization.rowQtyNo &&
                personalization.rowId === row.rowId
              ) {
                if (Number(seq) + 1 !== Number(row.sequance)) {
                  row.sequance = Number(seq) + 1
                  seq = row.sequance
                }
              } else {
                seq = row.sequance
              }
              return row
            })
            rowArray.personalization = TempPersonalization
          }
          return rowArray
        })
      }
      return rowGroup
    })

    setCatalogueCustomRows(tempArray)
    setToggleValue(!toggleValue)
  }

  const getPesonalizationTable = (itemsRows, Rowkey) => {
    let personalizationArray = []
    catalogueCustomRows &&
      catalogueCustomRows.map((rowArray, key) => {
        if (Rowkey === key) {
          if (rowArray.length > 0) {
            rowArray.map((item) => {
              itemsRows.map((itemRowArray) => {
                if (itemRowArray.id === item.id) {
                  item?.personalization &&
                    item.personalization.map((personalization) => {
                      personalizationArray.push(personalization)
                    })
                }
              })
            })
          }
        }
      })
    return (
      <Table className="personalizationTabel">
        <thead>
          <tr>
            <td>Item</td>
            <td>{sizeLabel}</td>
            <td>Item {colorLabel}</td>
            <td>Line</td>
            <td>Text</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {personalizationArray.map((personalization, key) => {
            let flag = true
            if (Number(personalization.sequance) !== 1) {
              flag = false
            }
            const objOptions = []
            if(!!personalization?.isDrp) {
              !!personalization?.drpValue && personalization?.drpValue.length > 0 && personalization.drpValue.map(op => {
                objOptions.push({ value: op, label: op })
              })
            }
            return (
              <tr key={key}>
                <td>{flag && 'Item ' + personalization.rowQtyNo}</td>
                <td>{flag && personalization.itemSize}</td>
                <td>{flag && personalization.itemColor}</td>
                <td>{!!personalization?.lineName ? personalization?.lineName : 'Line ' + personalization.sequance}</td>
                <td>
                {
                 !(!!personalization?.isDrp) ? (
                  <input
                    onChange={(e) => {
                      changePersonalizatinArray(
                        e,
                        'displayText',
                        personalization,
                      )
                    }}
                    value={personalization.displayText}
                  />
                 ) : (
                    <div className="selectDropDown">
                      <Select
                        noOptionsMessage={() => "No matches for this search"}
                        options={objOptions}
                        styles={colorStyles}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        name="isDrpValuePer"
                        onChange={(e) =>
                          changePersonalizatinArray(e, "displayText", personalization, 1)
                        }
                      />
                    </div>
                  )
                }
                </td>
               {(!(!!personalization?.isDrp) && !(!!personalization?.isEnabled) && !(!!personalization?.requiredLine)) ? (
                <td>
                  {flag && (
                    <AddIcon
                      onClick={() => {
                        AddPersonalizationRow(personalization)
                      }}
                    />
                  )}
                  {!flag && (
                    <DeleteIcon
                      onClick={() => {
                        DeletePersonalizationRow(personalization, key)
                      }}
                    />
                  )}
                </td>
               ) : '' }
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }

  useEffect(() => {
    const element = document.querySelectorAll(
      '.swiper-slide-active .containerimgMain',
    )
    setTimeout(() => {
      setImageW(element[0]?.offsetWidth)
      setImageH(element[0]?.offsetHeight)
    }, 1000)
  }, [selectedDecoration])

  useEffect(() => {
    const element = document.querySelectorAll(
      '.swiper-slide-active .containerimgMain',
    )
    setTimeout(() => {
      setImageW(element[0]?.offsetWidth)
      setImageH(element[0]?.offsetHeight)
    }, 500)
  }, [imgW, imgH])

  const handleCancel = () => {
    setAddnewart(false)
  }

  const handleCancelReview = () => {
    setOpenReview(false)
  }

  const handleAddnewArt = (addedNewartdata) => {
    let artwork = addedNewartdata.newArtData
    let showAddnewartIdS = !!addedNewartdata?.showAddnewartId?addedNewartdata.showAddnewartId:0
    fetchArtworksById({
      account_id: storeDetails.accountId,
      product_id: product.id,
      userId: user?.userId || undefined,
      categories: product.ProductCategoryArray?.map(x => x.id) || [],
      restrict: true,
    })
    setAddnewart(false)
    if (artwork.id) {
      setSelectedProductArtwork(artwork.id)
      if (artwork?.design?.variation) {
        if (artwork.design.variation.length === 1) {
          let variation = artwork.design.variation[0]
          let tempDecoArray = selectedProductDecorations
            ? selectedProductDecorations
            : []
            let locationArray = decorationLocations?.filter(
              (locationArray) => locationArray?.locationName === addedNewartdata.location,
            )
          let decoObj = {
            artworkID: artwork.id,
			      artworkSource:artwork.artworkSource,
            location: locationArray?.[0]?.locationName,
            locationId: locationArray?.[0]?.id,
            variationUniqID: variation.design_variation_unique_id,
          }
          let getDecoOfLocation = tempDecoArray.filter(val => val.locationId === locationArray?.[0]?.id)
          if (getDecoOfLocation.length === 0) {
            tempDecoArray.push(decoObj)
            setSelectedProductDecorations(tempDecoArray)
          }
          else if (getDecoOfLocation.length === 1) {
            let replaceTempDecoArray = tempDecoArray.filter(val => val.locationId !== locationArray?.[0]?.id)
            tempDecoArray = replaceTempDecoArray
            tempDecoArray.push(decoObj)
            setSelectedProductDecorations(tempDecoArray)
          }
          let tempcatalogueCustomRows = catalogueCustomRows.filter(
            (rowGroupArray, groupkey) => {
              if (groupkey === showAddnewartIdS) {
                rowGroupArray.filter((rowArray) => {
                  if(rowArray?.decorations)
                    rowArray.decorations = tempDecoArray
                  return rowArray
                })
              }
              return rowGroupArray
            },
          )
          setCatalogueCustomRows(tempcatalogueCustomRows)
          temp(showAddnewartIdS, artwork.id)
          setSelectedProductLocation('')
          setSelectedProductArtwork('')
        }
      }
    }
  }

  useEffect(() => {
    verifyShowColor()
  }, [productSettings, productDstInventory, productInventory])

  if (product?.ProductPartArray?.ProductPart?.length) {
    let tempOptions = product.ProductPartArray.ProductPart
    if (product?.WebstoreProductPartArray?.length > 0) {
      tempOptions = product.WebstoreProductPartArray
    }
    tempOptions.forEach((part) => {
      let priceOption = part
      if (part?.ApparelSize?.labelSize) {
        const size = part.ApparelSize.labelSize
        if (!groups[size]) {
          groups[size] = []
        }
        groups[size].push(priceOption)

        const color = part.ColorArray.Color.colorName
        colors_ids[part.partPrice.partId] = color
        colors_idsColor[color] = part.partPrice.partId
        colors_hex[color] = part.ColorArray.Color.hex
        if (!colors[color]) {
          colors[color] = []
        }
      }
    })
  }
  for (let size in groups) {
    priceTableTemp.push({ size: size, priceOptions: groups[size] })
  }
  
  priceTableTemp = priceTableTemp.sort((a,b) => {
    return defaultSizeForAll.indexOf(a.size.toLowerCase()) - defaultSizeForAll.indexOf(b.size.toLowerCase());
  })
  let priceTableTemp1 = []
  let priceTableTemp2 = []

  priceTableTemp.map((a) => {
    if(defaultSizeForAll.indexOf(a.size.toLowerCase()) !== -1) {
      priceTableTemp1.push(a)
    } else {
      priceTableTemp2.push(a)
    }
  })

  if(priceTableTemp2.length > 0) {
    priceTableTemp1 = priceTableTemp1.concat(priceTableTemp2)
  }

  priceTableTemp1.map(res => {
    priceTable.push(res)
  })
  for (let color in colors) {
    colors_final.push(color)
  }
  const numericSort = (a, b) => {
		const aNumeric = parseInt(a);
		const bNumeric = parseInt(b);
		return aNumeric - bNumeric;
	};
	colors_final = colors_final.sort(numericSort);

  const minQuantity = () => {
    let allQty = []
    let minObj = partPrice.reduce(function (prev, curr) {
      return Number(prev.minQuantity) < Number(curr.minQuantity) ? prev : curr
    })
    setMinQty(minObj.minQuantity)
    allQty = partPrice.map((item) => {
      return Number(item.minQuantity)
    })
    setMinAllQty(allQty)
  }

  useEffect(() => {
    if (partPrice && partPrice?.length > 0) {
      minQuantity()
    }
  }, [partPrice])

  const getReviews = () => {
    setIsLoading(true)
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        'X-TENANT-ID': X_TENANT_ID,
      },
    }

    axios
      .get(FRONTREVIEW + '?itemId=' + product.id, requestOptions)
      .then((res) => {
        setIsLoading(false)
        if (res.data.error) {
          addToast(res.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        } else {
          setReviews(res.data)
          setReviewCount(res.data.length)
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log('err', err)
      })
  }

  const checkReviewCount = () => {
    setIsLoading(true)
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        'X-TENANT-ID': X_TENANT_ID,
      },
    }

    axios
      .get(FRONTREVIEW + '/' + product.id + '/' + userId, requestOptions)
      .then((res) => {
        setIsLoading(false)
        if (res.data.error) {
          addToast(res.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        } else {
          if (res.data > 0) {
            setReviewExist(true)
          }
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log('err', err)
      })
  }

  useEffect(() => {
    setIsTrue(false)
    setIsMoq(product?.isProductMoq)
    setIsPriceBreak(product?.doNotCalculateAtOrder == "1" ? false : product?.isPriceBreak)
    setIsSkuMoq(product?.isProductskuMoq)
    setIsRestrictQty(product?.isRestrictQty)
    setPartPrice(
      product?.WebstoreProductPartArray[0]?.partPrice?.PartPriceArray
        ?.PartPrice ?? product?.ProductPartArray?.ProductPart?.[0]?.partPrice?.PartPriceArray
        ?.PartPrice,
    )
    setProdId(product?.productId)
    setProductId(product?.id)
    setIsSearchArtwork(product?.searchArtwork)
    setCustomDecorationFee(product?.customDecorationFee)
    setCustomDecorationPerUnitPrice(product?.customDecorationPerUnitPrice ?? 0)
    setPersonalizationPerUnitPrice(product?.personalizationPerUnitPrice)
    setPersonalizationSetupFee(product?.personalizationSetupFee)
    setCustomDecorationSetupFee(product?.customDecorationSetupFee)
    let cartCustomRows = null
    cartCustomRows = product?.MediaContent.filter((x) => x.primary).map((x) => {
      return {
        id: uniqIdcounter++,
        size: '',
        quantity: '',
        color: '',
        displayGroup: x.displayGroup,
        group: x.group,
        groups: x.groups,
        hex: x.hex,
        hidden: x.hidden,
        index: x.index,
        logoBlockCount: x.logoBlockCount,
        mediaType: x.mediaType,
        partId: x.partId,
        primary: x.primary,
        productId: x.productId,
        secondary: x.secondary,
        select: x.select,
        sku: x.sku,
        url: x.url,
        price: 0,
        totalPrice: 0,
        cost: 0,
        totalCost: 0,
        product: { sku: x.sku },
        weight: product.weight,
        shipFrom: {
          countryFrom: product.shipFromCountry,
          zipCodeFrom: product.shipFromPostalCode,
        },
      }
    })
    cartCustomRows =
      cartCustomRows.length > 0
        ? [cartCustomRows]
        : [
            [
              {
                id: uniqIdcounter++,
                size: '',
                quantity: '',
                image: product?.MediaContent?.[0]?.url,
                color: '',
                product: { sku: 0 },
                price: 0,
                totalPrice: 0,
                cost: 0,
                totalCost: 0,
                weight: product.weight,
                shipFrom: {
                  countryFrom: product.shipFromCountry,
                  zipCodeFrom: product.shipFromPostalCode,
                },
              },
            ],
          ]
    setPersonalizationCost((preVal) => ({
      ...preVal,
      ['matchOrderQty']: !!cartCustomRows[0][0]?.personalizationCost
        ?.matchOrderQty
        ? cartCustomRows[0][0]?.personalizationCost?.matchOrderQty
        : 0,
      ['price']: !!cartCustomRows[0][0]?.personalizationCost?.price
        ? cartCustomRows[0][0]?.personalizationCost?.price
        : 0,
      ['chargeId']: !!cartCustomRows[0][0]?.personalizationCost?.chargeId
        ? cartCustomRows[0][0]?.personalizationCost?.chargeId
        : '',
    }))
    setCatalogueCustomRows(cartCustomRows)
    updateStateTemp(statChangeTemp+1)
    setTimeout(() => {
      setIsTrue(true)
    }, 1000)
  }, [product])

  useEffect(() => {
    getAlertMessageProductDecoration()
  }, [])

  const swipeimagewebstore = product?.WebstoreProductPartArray?.filter(
    (ele, ind) =>
      ind ===
      product?.WebstoreProductPartArray.findIndex(
        (elem) => elem.color === ele.color,
      ),
  ).map((mediaElement, key) => {
    let getImage = product?.WebstoreProductPartArray.filter(
      (val) => val.color == mediaElement.color && val.isSwapImage == true,
    )
    if (getImage.length > 0) {
      if (!getImage[0].image) {
        const otherimagewebstore = product?.MediaContent?.filter(
          (mediaArray) => !mediaArray.hidden,
        ).map((single) => {
          getImage = product?.WebstoreProductPartArray.filter(
            (val) => val.color === single.color && val.isSwapImage === true,
          )
        })
      }
      mediaElement.image = getImage[0].image
    }
    return (
      <div key={key}>
        <div className="containerimgMain">
          <div
            className={`${
              productImageExt(mediaElement.image) ? 'product-img-bg' : ''
            } single-image test as`}
          >
            <LightgalleryProvider
              onAfterOpen={() =>
                photoZoom(
                  productImageExt(mediaElement.image) ? 'product-img-bg' : '',
                )
              }
            >
              <LightgalleryItem
                galleryClassName="testtt"
                group="any"
                src={productImageUrl(mediaElement.image)}
              >
                <img
                  alt=""
                  src={productImageUrl(mediaElement.image)}
                  className="img-fluid"
                />
              </LightgalleryItem>
            </LightgalleryProvider>
          </div>
        </div>
      </div>
    )
  })

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  useEffect(() => {
    if(showColor && colors_final.length === 1 && statChangeTemp === 1) {
      changeColor(colors_final[0], 0, 0)
    }
  }, [statChangeTemp])

  const removeDeco = (mainArraykey, itemsRows, index) => {
    let tempData = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      if (groupkey === mainArraykey) {
        rowGroupArray = rowGroupArray.filter((groupArray) => {
          if (groupArray.decorations) {
            if (index !== '' && index !== null)
              groupArray.decorations = groupArray.decorations.filter(
                (decoArray, decoIndex) => decoIndex !== index,
              )
            else
              groupArray.decorations = [];
          }
          return groupArray
        })
      }
      return rowGroupArray
    })

    setCatalogueCustomRows(tempData)
    updatestate(!statchange)
  }

  const showPopupText = (mainArraykey, itemsRows, index) => {
    if(product.restrictAfterFirstChoice) {
      setIndexPopup(index)
      setMainArrayKeyPopup(mainArraykey)
      setItemsRowsPopup(itemsRows)
      setShowDecoPopup(true);
    } else {
      removeDeco(mainArraykey, itemsRows, index);
    }
  }

  const getAlertMessageProductDecoration = () => {
    if(product._id) {
      getProductDecorationPopup(product._id).then(response => {
        if(response?.data?.alertMessage) {
          setPopupText(response?.data?.alertMessage);
        }
      }).catch(error => {
        console.log('error', error);
          addToast('Error while loading data!', {
              appearance: "error",
              autoDismiss: true
          });
      })
    }
  }

  const getStock = (sizeDefault, sizcolorDefault) => {
    let inventoryArray = null
    let dstInventoryArray = null

    if (productInventory) {
      inventoryArray = productInventory?.PartInventoryArray?.PartInventory
    }
    if (productDstInventory) {
      dstInventoryArray = productDstInventory
    }

    let stock = 0
    if (
      (product?.poType === 'DropShip' || product?.poType === 'Stock') &&
      (isStockFor === 'supplierStock' || isStockFor === 'both')
    ) {
      inventoryArray &&
        inventoryArray.map((stockArray) => {
          if (
            sizcolorDefault === stockArray.partColor &&
            stockArray.labelSize === sizeDefault
          ) {
            if (stockArray?.InventoryLocationArray?.InventoryLocation?.length) {
              stockArray.InventoryLocationArray.InventoryLocation.map(
                (inventoryArray) => {
                  if (
                    inventoryArray?.inventoryLocationQuantity?.Quantity?.value
                  ) {
                    stock =
                      Number(stock) +
                      Number(
                        inventoryArray.inventoryLocationQuantity.Quantity.value,
                      )
                  }
                },
              )
            }
          }
        })
    }

    let dstStock = 0
    if (
      product?.poType === 'Stock' &&
      (isStockFor === 'distributorStock' || isStockFor === 'both')
    ) {
      dstInventoryArray &&
        dstInventoryArray.map((dstStockArray) => {
          if (dstStockArray.productId === product.productId) {
            if (
              sizcolorDefault.replace('No Color', '') ===
                dstStockArray.color.replace('No Color', '') &&
              dstStockArray.size === sizeDefault
            ) {
              dstStock = Number(dstStockArray.quantity)
            }
          }
        })
    }
    return Number(stock) + Number(dstStock)
  }

  const getAdditionalDecorationCharge = (artwork, quantity, location, productId) => {
    let totalAdditionalCharges = 0
    const additionalCharges = []
    if(artwork.productToDecorationMapping && artwork.productToDecorationMapping?.length) {
      const productToDecorationMapping = artwork.productToDecorationMapping
      productToDecorationMapping.filter(x => x.locationName === location).forEach(productMap => {
        productMap.charges.forEach(charge => {
          if(additionalCharges.findIndex(x => x.chargeCode === charge.chargeCode) === -1) {
            let price = 0
            let cost = 0
            charge.ChargePriceArray.ChargePrice.forEach(x => {
              if(x.xMinQty <= quantity) {
                price = x.salePrice
                cost = x.price
              }
            })
            const chargePrice = charge.matchOrderQty === "1" ? Number(price) * Number(quantity) :  Number(price)
            const chargeCost = charge.matchOrderQty === "1" ? Number(cost) * Number(quantity) :  Number(cost)
            additionalCharges.push({
              totalSetupCharge: chargePrice,
              totalCostSetupCharge: chargeCost,
              setupChargeName: charge.chargeName,
              chargeCode: charge.chargeCode,
              chargeId: charge.chargeId
            })
            totalAdditionalCharges =
                Number(totalAdditionalCharges) + chargePrice
          }
        })
      })
    }
    return {
      totalAdditionalCharges,
      additionalCharges
    }
  }

  const getDecoChargeArraybyqty = (decorationsArray, qty) => {
    let decosalePrice = []
    decorationsArray &&
      decorationsArray.map((decoitemArray, index) => {
        if (decoitemArray?.decoPricedetails?.length > 0) {
          let artworkselected = artworksToRead.filter(
            (artorkArray) => artorkArray.id === decoitemArray.artworkID,
          )[0]
          let priceq = 0
          decoitemArray.decoPricedetails.map((decoPriceeArray) => {
            let sCount = artworkselected.design.stitchCount
            if (sCount === '' || !Number(sCount)) {
              sCount = 0
            }
            if (
              Number(sCount) >= Number(decoPriceeArray.stitchesStart) &&
              Number(sCount) <= Number(decoPriceeArray.stitchesUpto)
            ) {
              if (
                Number(qty !== '' ? qty : 1) >=
                  Number(decoPriceeArray.qunatityStart) &&
                Number(qty !== '' ? qty : 1) <=
                  Number(decoPriceeArray.quantityUpto)
              ) {
                priceq = decoPriceeArray
              }
            }
          })
          decosalePrice.push(priceq)
        }
      })
    return decosalePrice
  }

  const getReducePer = (width, param) => {
    let reducePer = 100
    while ((Number(width) * reducePer) / 100 > param) {
      reducePer = reducePer - 1
    }
    return reducePer
  }

  const getStoreArtwork = () => {
    setIsLoading(true)
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        'X-TENANT-ID': X_TENANT_ID,
      },
    }
    axios
      .get('/api/v1/artworkdata/', requestOptions)
      .then((res) => {
        setIsLoading(false)
        if (res.data.error) {
          addToast(res.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        } else {
          setStoreArtwork(res.data)
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log('err', err)
      })
  }

  useEffect(()=>{
    setArtworks()
  }, [artworkListById, artworkList])

  const setArtworks = () => {
    if ((artworkListById?.length > 0 || artworkList?.length > 0)) {
      const list = artworkList?.length ? artworkList : []
      const listById = artworkListById?.length ? artworkListById : []
      setArtworksToRead([...list, ...listById])
    }
  }

  useEffect(() => {
    getStoreArtwork()
  }, [])

  const swipeimage = product?.MediaContent?.filter(
    (mediaArray) => !mediaArray.hidden,
  ).map((mediaElement, key) => {
    let prImage = mediaElement.url
    if(mediaElement?.isNewLogo == "1" && !!mediaElement?.processedImage) {
      prImage = mediaElement.processedImage
    }
    let single = null
    let findlogoinfo = ''
    let productColorList = catalogueCustomRows.filter(
      (val) => val[0].color === mediaElement.color,
    )
    let getImage = product?.WebstoreProductPartArray.filter(
      (val) => val.color == mediaElement.color && val.isSwapImage == true,
    )
    if (getImage.length > 0) {
      prImage = getImage[0].image
    }
    if (productColorList?.length > 0) {
      let getDecoData = productColorList[0][0]?.decorations
      if (
        selectedDecoration !== '' &&
        selectedDecoration !== null &&
        selectedDecoration !== 'null' &&
        selectedDecoration !== undefined
      ) {
        for (let i = 0; i < product?.LogoInfo.length; i++) {
          const logoInfoData = product?.LogoInfo[i]
          if (logoInfoData.color === mediaElement.color) {
            if (mediaElement.url === logoInfoData.image && logoInfoData.location === selectedDecoration.location) {
              findlogoinfo = logoInfoData
              break;
            } else if (logoInfoData.location === selectedDecoration.location) {
              findlogoinfo = logoInfoData
              break;
            } else if (mediaElement.url === logoInfoData.image) {
              let extra_logo = logoInfoData.extra_logo.filter(val => val.location === selectedDecoration.location)
              if (extra_logo.length > 0) {
                findlogoinfo = extra_logo
                findlogoinfo[0].image = logoInfoData.image
                findlogoinfo[0].color = logoInfoData.color
                findlogoinfo[0].ih = logoInfoData.ih
                findlogoinfo[0].iw = logoInfoData.iw
                if (logoInfoData?.isNewLogo == "1" && !!logoInfoData?.processedImage) {
                  findlogoinfo[0].processedImageWidth = logoInfoData?.processedImageWidth
                  findlogoinfo[0].processedImageHeight = logoInfoData?.processedImageHeight
                  findlogoinfo[0].isNewLogo = logoInfoData?.isNewLogo
                  findlogoinfo[0].processedImage = logoInfoData?.processedImage
                }
                break;
              }
            }
          }
        }
      } else if (getDecoData?.length > 0) {
        getDecoData.map((deco) => {
          return (findlogoinfo = product.LogoInfo.filter(function (val) {
            return (
              val.image === mediaElement.url &&
              val.location === deco.location &&
              deco.variationUniqID !== ''
            )
          }))
        })
      }
    }

    if (findlogoinfo !== '' && findlogoinfo?.length) {
      single = findlogoinfo[0]
      getSingleImage = single
    } else if (findlogoinfo !== '') {
      single = findlogoinfo
      getSingleImage = single
    }
    if (
      ((Array.isArray(single) === false &&
        single !== null &&
        single !== '' &&
        single) ||
        (!!single && Array.isArray(single) === true && single.length > 0)) &&
      !mediaElement.hidden
    ) {
      let selectedRow = null
      let decoselected = []
      if (catalogueCustomRows?.length) {
        catalogueCustomRows.map((groupArray) => {
          groupArray.map((row) => {
            if (row.color === single.color) {
              selectedRow = row
            }
          })
        })
        if (selectedRow?.id) {
          if (selectedRow.decorations) {
            decoselected = selectedRow.decorations
          }
        }
      }
      let imgWidth = getSingleImage?.iw
      let imgHeight = getSingleImage?.ih
      let singleX = getSingleImage?.x
      let singleY = getSingleImage?.y
      let singleW = getSingleImage?.w
      let singleH = getSingleImage?.h
      if(getSingleImage?.isNewLogo == "1" && !!getSingleImage?.processedImage) {
        imgWidth = getSingleImage?.processedImageWidth
        imgHeight = getSingleImage?.processedImageHeight
        singleX = getSingleImage?.newX
        singleY = getSingleImage?.newY
        singleW = getSingleImage?.newW
        singleH = getSingleImage?.newH
      }

      if (imgWidth > 900 || imgHeight > 1100) {
        var ratio = Math.min(
          Number(800 / Number(imgWidth)),
          Number(800 / Number(imgHeight)),
        )
        imgWidth = imgWidth * ratio
        imgHeight = imgHeight * ratio
      }

      let reducePer = getReducePer(imgHeight, imageH)

      let positionSetLeft = (imageW * singleX) / imgWidth
      let positionSetTop = (imageH * singleY) / imgHeight

      let positionSetBoxWidth = (imageW * singleW) / imgWidth
      let positionSetBoxHeight = (imageH * singleH) / imgHeight
      let mainCls = 'single-image lightGal'
      if (!!prImage && productImageExt(prImage)) {
        mainCls = 'single-image lightGal product-img-bg'
      }
      return (
        <div key={key}>
          <div className={mainCls}>
            <div className="containerimgMain">
              <LightgalleryProvider
                onAfterOpen={() =>
                  photoZoom(
                    productImageExt(prImage) ? 'product-img-bg' : '',
                  )
                }
              >
                <LightgalleryItem
                  galleryClassName=" "
                  group="any"
                  src={productImageUrl(prImage)}
                >
                  <img
                    alt=""
                    className="img-fluid imagei"
                    src={productImageUrl(prImage)}
                    style={{ maxWidth: '450px' }}
                  />
                  {artworksToRead?.length > 0 &&
                    decoselected?.map((li, key) => {
                      let to = ''
                      let wid = ''
                      let hei = ''

                      if (li.location === single.location) {
                        to = single.y
                        wid = single.w
                        hei = single.h
                        if(getSingleImage?.isNewLogo == "1" && !!getSingleImage?.processedImage) {
                          to = getSingleImage?.newY
                          wid = getSingleImage?.newW
                          hei = getSingleImage?.newH
                        }
                      } else {
                        single?.extra_logo &&
                          single.extra_logo.map((el) => {
                            if (single.location === el.location) {
                              to = el.y
                              wid = el.w
                              hei = el.h
                              if(getSingleImage?.isNewLogo == "1" && !!getSingleImage?.processedImage) {
                                to = getSingleImage?.newY
                                wid = getSingleImage?.newW
                                hei = getSingleImage?.newH
                              }
                            }
                          })
                      }
                      if (to !== '') {
                        to = ((Number(to) * reducePer) / 100).toString()
                        wid = ((Number(wid) * reducePer) / 100).toString()
                        hei = ((Number(hei) * reducePer) / 100).toString()
                        let artwork_selected = null
                        if (
                          li?.artworkID !== '' &&
                          li?.artworkID !== undefined
                        ) {
                          const idToSearch = selectedDecoration
                            ? selectedDecoration.artworkID
                            : li.artworkID
                          artwork_selected = artworksToRead?.find(
                            (artworkArray) => artworkArray.id === idToSearch,
                          )
                        }

                        let rowVariation = null
                        if (artwork_selected?.design?.variation?.length > 0) {
                          rowVariation =
                            artwork_selected.design.variation.filter(
                              (variationArray) =>
                                variationArray.design_variation_unique_id ==
                                li.variationUniqID,
                            )[0]
                          if (
                            artwork_selected?.design?.variation
                              ?.itemImageThumbnail != '' &&
                            artwork_selected?.design?.variation
                              ?.itemImageThumbnail != undefined
                          ) {
                            rowVariation = artwork_selected?.image
                          }
                        }
                        if (
                          rowVariation &&
                          rowVariation?.itemImageThumbnail &&
                          rowVariation?.itemImageThumbnail.length > 0
                        ) {
                          return (
                            <div
                              className="overlayimg"
                              key={key}
                              style={{
                                top: positionSetTop + 'px',
                                left: positionSetLeft + 'px',
                              }}
                            >
                              {rowVariation &&
                              rowVariation?.itemImageThumbnail &&
                              rowVariation.itemImageThumbnail[0] ? (
                                <img
                                  alt=""
                                  className="img-fluid"
                                  src={
                                    rowVariation?.itemImageThumbnail
                                      ? productImageUrl(
                                          rowVariation.itemImageThumbnail[0],
                                        )
                                      : ''
                                  }
                                  width={wid + 'px'}
                                  height={hei + 'px'}
                                  style={{
                                    maxWidth: positionSetBoxWidth + 'px',
                                    maxHeight: positionSetBoxHeight + 'px',
                                  }}
                                />
                              ) : (
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src={
                                    rowVariation &&
                                    rowVariation?.itemImageThumbnail != ''
                                      ? productImageUrl(rowVariation)
                                      : productImageUrl(rowVariation)
                                  }
                                  width={wid + 'px'}
                                  height={hei + 'px'}
                                  style={{
                                    maxWidth: positionSetBoxWidth + 'px',
                                    maxHeight: positionSetBoxHeight + 'px',
                                  }}
                                />
                              )}
                            </div>
                          )
                        }
                      }
                    })}
                </LightgalleryItem>
              </LightgalleryProvider>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div key={key}>
          <div className="containerimgMain">
            <div
              className={`${
                productImageExt(prImage) ? 'product-img-bg' : ''
              } single-image test bs`}
            >
              <LightgalleryProvider
                onAfterOpen={() =>
                  photoZoom(
                    productImageExt(prImage) ? 'product-img-bg' : '',
                  )
                }
              >
                <LightgalleryItem
                  galleryClassName="testtt"
                  group="any"
                  src={productImageUrl(prImage)}
                >
                  <img
                    alt=""
                    src={productImageUrl(prImage)}
                    className="img-fluid"
                  />
                </LightgalleryItem>
              </LightgalleryProvider>
            </div>
          </div>
        </div>
      )
    }
  })

  const prevNextBtn = () => {
    setSelectedDecoration(null)
    if (document.querySelector('#sw1')) {
      if (
        document.querySelector('#sw1').querySelector('.swiper-slide-active')
      ) {
        if (
          document
            .querySelector('#sw1')
            .querySelector('.swiper-slide-active')
            .querySelector('span')
        ) {
          let color_current = document
            .querySelector('#sw1')
            .querySelector('.swiper-slide-active')
            .querySelector('span').innerText
          // setSelectedProductColor(color_current)
          // changeColor(color_current, 0, 5)

          let color_image_url = document
            .querySelector('#sw1')
            .querySelector('.swiper-slide-active')
            .querySelector('img').src
          // getDecoAttach(color_current, color_image_url, 1)
        }
      }

      if (
        document.querySelector('#sw1').querySelectorAll('.swiper-slide')
      ) {
        document.querySelector('#sw1').querySelectorAll('.swiper-slide').forEach((element, index) => {
          if(!!element.querySelector('span')?.innerText) {
            let lableImage = element.querySelector('label img').getAttribute("src")
            if(element.querySelector('span').innerText == selectedProductMainColor && selectedProductMainImage == lableImage) {
              if(element.querySelector('span input')) {
                element.querySelector('span input').checked = true; 
              }
            } else {
              if(element.querySelector('span input')) {
                element.querySelector('span input').checked = false;
              }
            }
          }
        })
      }
    }
  }

  useEffect(() => {
    if (
      document.querySelector('#sw1').querySelectorAll('.swiper-slide')
    ) {
      document.querySelector('#sw1').querySelectorAll('.swiper-slide').forEach((element, index) => {
        if(!!element.querySelector('span')?.innerText) {
          let lableImage = element.querySelector('label img').getAttribute("src")
          if(element.querySelector('span').innerText == selectedProductMainColor && selectedProductMainImage == lableImage) {
            if(element.querySelector('span input')) {
              element.querySelector('span input').checked = true;
            }
          } else {
            if(element.querySelector('span input')) {
              element.querySelector('span input').checked = false;
            }
          }
        }
      })
    }
  }, [selectedProductMainColor])

  useEffect(() => {
    if (
      document.querySelector('#sw1').querySelectorAll('.swiper-slide') && ProductColorTemp === true
    ) {
      document.querySelector('#sw1').querySelectorAll('.swiper-slide').forEach((element, index) => {
        if(!!element.querySelector('span')?.innerText && !!element.querySelector('span input')) {
          let mid = element.querySelector('span input').id + index;
          element.querySelector('label').htmlFor = mid
          element.querySelector('span input').id = mid
        }
      })
    }
  }, [ProductColorTemp])

  const thumbnailSwiperParams = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    on: {
      init: () => {},
      slideChangeTransitionEnd: (el) => {
        if (document.querySelector('#sw1')) {
          if (
            document.querySelector('#sw1').querySelector('.swiper-slide-active')
          ) {
            if (
              document
                .querySelector('#sw1')
                .querySelector('.swiper-slide-active')
                .querySelector('span')
            ) {
              let color_current = document
                .querySelector('#sw1')
                .querySelector('.swiper-slide-active')
                .querySelector('span').innerText

              // setSelectedProductColor(color_current)
              // changeColor(color_current, 0, 4)
              let color_image_url = document
                .querySelector('#sw1')
                .querySelector('.swiper-slide-active')
                .querySelector('img').src

              // getDecoAttach(color_current, color_image_url)
            }
          }
        }
      },
    },
    getSwiper: getThumbnailSwiper,
    spaceBetween: 20,
    slidesPerView:
      product?.MediaContent?.length >= 4
        ? 4
        : product?.MediaContent?.length >= 3
        ? 3
        : product?.MediaContent?.length >= 2
        ? 2
        : 1,
    loopedSlides: 4,
    touchRatio: 0.2,
    freeMode: true,
    loop: product?.MediaContent?.length <= 1 ? false : true,
    rebuildOnUpdate: false,
    slideToClickedSlide: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    renderPrevButton: () => (
      <>
        {product?.MediaContent?.length > 4 && (
          <button
            className="antera-swiper-button swiper-button-prev ht-swiper-button-nav"
            onClick={prevNextBtn}
          >
            <i className="pe-7s-angle-left" />
          </button>
        )}
      </>
    ),
    renderNextButton: () => (
      <>
        {product?.MediaContent?.length > 4 && (
          <button
            className="antera-swiper-button swiper-button-next ht-swiper-button-nav"
            onClick={prevNextBtn}
          >
            <i className="pe-7s-angle-right" />
          </button>
        )}
      </>
    ),
  }

  const ToggleDivs = (RowID, Type, extraParm = '') => {
    let temptoggleDivsArray = toggleDivsArray
    if (!temptoggleDivsArray[RowID]) {
      temptoggleDivsArray[RowID] = []
    }
    if (temptoggleDivsArray[RowID]) {
      let rowCurrentState = temptoggleDivsArray[RowID]
      if (Type === 'D') {
        rowCurrentState.attributes = false
        rowCurrentState.personalization = false

        if (
          rowCurrentState.decorations &&
          extraParm !== 'onChangeColor' &&
          extraParm !== 'clickOnArtWork'
        ) {
        } else {
          setShowData('yes')
          setNotCallDecoHtml(false)
        }
        if (rowCurrentState.decorations === true && extraParm === 'click')
          rowCurrentState.decorations = false
        else if (rowCurrentState.decorations === false && extraParm === 'click')
          rowCurrentState.decorations = true
        else if (
          rowCurrentState.indexOf('decorations') === -1 &&
          extraParm === 'click'
        ) {
          rowCurrentState.decorations = true
        } else if (rowCurrentState.decorations === undefined && extraParm == 'onChangeColor') {
          rowCurrentState.decorations = true
        }

        temptoggleDivsArray[RowID] = rowCurrentState
      }
      if (Type === 'A') {
        rowCurrentState.decorations = false
        rowCurrentState.personalization = false
        if (rowCurrentState.attributes) {
          rowCurrentState.attributes = false
        } else {
          rowCurrentState.attributes = true
        }
        temptoggleDivsArray[RowID] = rowCurrentState
      }
      if (Type === 'P') {
        if (extraParm !== 'changeQtyZero') {
          rowCurrentState.decorations = false
          rowCurrentState.attributes = false
        }
        if (rowCurrentState.personalization) {
          console.log('extraParm: ', RowID, ' ---', extraParm)
          if (extraParm !== 'onChangeColor' && extraParm !== 'changeQty') {
            rowCurrentState.personalization = false
          } else {
            rowCurrentState.personalization = true
          }
        } else {
          rowCurrentState.personalization = true
        }
        if (extraParm === 'DeleteRow' || extraParm === 'changeQtyZero') {
          rowCurrentState.personalization = false
        }
        temptoggleDivsArray[RowID] = rowCurrentState
      }
    }

    setToggleDivs(temptoggleDivsArray)
    setToggleValue(!toggleValue)
  }

  const temp = (parentKey = '', artworkId = '', locaName = '') => {
    let productColor = catalogueCustomRows[parentKey][0].color
    if (
      (parentKey === 0 && artworkId !== '') ||
      (parentKey !== '' && artworkId !== '')
    ) {
      let currentProduct = catalogueCustomRows[parentKey][0]

      let decoData = currentProduct?.decorations?.filter(
        (val) => val.artworkID === artworkId,
      )

      let decoLocation = decoData?.[0]?.location
      setSelectedDecoration(decoData?.[0])
      setSingleSelectedDecorations(decoData?.[0])

      let getIndex = product.LogoInfo.filter(
        (val) => val.color === productColor && val.location === decoLocation,
      )

      if (getIndex.length > 0) {
        animateScroll.scrollToTop()
        setSelectedImage(getIndex[0]?.image)
      } else {
        let getIndexOfExtra_logo = []
        product.LogoInfo.map((val) => {
          if (val.color === productColor) {
            if (
              val.extra_logo.filter((e) => e.location === decoLocation).length >
              0
            ) {
              getIndexOfExtra_logo.push(val)
            }
          }
        })
        if (getIndexOfExtra_logo.length) {
          animateScroll.scrollToTop()
          setSelectedImage(getIndexOfExtra_logo[0]?.image)
        } else {
          let getImgColorIndex = product.LogoInfo.filter(
            (val) => val.color === productColor,
          )
          if (getImgColorIndex.length > 0) {
            animateScroll.scrollToTop()
            setSelectedImage(getImgColorIndex[0]?.image)
          }
        }
      }
    } else if (locaName !== '' && locaName !== undefined) {
      let getIndex = product.LogoInfo.filter(function (val) {
        return val.color === productColor && val.location === locaName
      })

      if (getIndex.length > 0) {
        setSelectedImage(getIndex[0].image)
      }

      setTempValue(tempValue + 1)
    }
    setNotCallDecoHtml(false)
    ToggleDivs(parentKey, 'D', 'clickOnArtWork')
  }

  const handleCheckboxDefault = (decorationId, arrayIndex) => {
    let tempRows = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      if (groupkey === arrayIndex) {
        let locaName = decorationLocations?.filter(
          (locationFilter) => locationFilter.id === decorationId,
        )[0].locationName
        setSelectedLocation(decorationId)
        temp(arrayIndex, '', locaName)
        rowGroupArray.filter((rowArray) => {
          if (rowArray.decorations) {
            let findIfexist = rowArray.decorations.filter(
              (decorationArray) => decorationArray.locationId === decorationId,
            )
            if (decorationId !== '') {
              if (findIfexist?.length === 0) {
                let tempaddDeco = {
                  location: locaName,
                  locationId: decorationId,
                }
                rowArray.decorations.push(tempaddDeco)
                setSingleSelectedDecorations(tempaddDeco)
                setSelectedDecoration(tempaddDeco)
              }
            }
          } else {
            rowArray['decorations'] = []
            let tempaddDeco = {
              location: locaName,
              locationId: decorationId,
            }
            setSingleSelectedDecorations(tempaddDeco)
            setSelectedDecoration(tempaddDeco)

            rowArray.decorations.push(tempaddDeco)
          }
          return rowArray
        })
      }
      return rowGroupArray
    })

    setCatalogueCustomRows(tempRows)
  }

  const clearDecoSelection = () => {
    let tempcatalogueCustomRows = catalogueCustomRows.filter(
      (rowGroupArray, groupkey) => {
        rowGroupArray.filter((rowArray) => {
          rowArray.decorations = []
          return rowArray
        })

        return rowGroupArray
      },
    )

    setCatalogueCustomRows(tempcatalogueCustomRows)
  }

  const getDecoHtml = (parentKey, itemsRows) => {
    const locations = getLocationsCondensed();
    let locID = locations.locID
    let locationName = locations.locationName
    let artWorkID = locations.artWorkID
    let productColor = locations.productColor;
    let decoExistLocations = locations.decoExistLocations
    let yFilter = locations.yFilter
    let filteredX = decorationLocations.filter((itemX) =>
      yFilter.includes(itemX.locationName),
    )

    let tempDecorationLocations = filteredX
    if (decoExistLocations?.length > 0 && tempDecorationLocations?.length > 0) {
      tempDecorationLocations = tempDecorationLocations.filter((f) => {
        if (decoExistLocations.indexOf(f.locationName) !== -1) {
          if(product.restrictAfterFirstChoice && artworksProductState.length > 0) {
            let numberChoice = 0;
            let selectedArtworks = itemsRows?.[0]?.decorations?.filter((row) => row.artworkID) || []
            numberChoice = selectedArtworks.length === tempDecorationLocations.length 
              ? selectedArtworks.length - 1 
              : selectedArtworks.length;
              let arrayArtworks = artworkLocations.filter(x => x.numberChoice === numberChoice && !x.isEnable) ?? [];
              let artworksToReadFiltered = [];
              let arrayArtworksTemp = []; 
              artworksFromState.forEach(x => {
                let index = arrayArtworks.findIndex(z => z.artworkId === x.id);
                if(index === -1) {
                  arrayArtworksTemp.push(x);
                }
              })
              arrayArtworksTemp.forEach(x => {
                let insert = x.productToDecorationMapping.filter(y =>  y.locationName === f.locationName).length;
                if(insert > 0) {
                  artworksToReadFiltered.push(x);
                }
              });
            return artworksToReadFiltered.length > 0;
          } else {
            return true;
          }
        }
        return false
      })
    }

    return (
      <div className="mt-3 mb-3">
        <DecorationSelectbox
          itemsRows={itemsRows?.[0]}
          decoExist={itemsRows?.[0]?.decorations}
          handleCheckbox={handleCheckbox}
          handleCheckboxDefault={handleCheckboxDefault}
          setAddnewartId={setAddnewartId}
          tempDecorationLocations={tempDecorationLocations}
          locID={ locID ? `${locID}-${selectedLocationIndex}`: ""}
          clearDecoSelection={clearDecoSelection}
          parentKey={parentKey}
          artworkListById={artworksToRead}
          decoLocation={product?.customArtworkLocation}
          setAddnewart={setAddnewart}
          allowCustomDecoration={allowCustomDecoration}
          productAllowOneDecoration={product?.AllowOneDecorationLimitPro}
          productSettingsAllowOneDecoration={
            productSettings?.AllowOneDecorationLimit
          }
          productSettingsRenameLocationNameLabel={productSettings?.renameLocationNameLabel}
          productRenameLocationNameLabel={product?.renameLocationNameLabel}
          productSettingsRenameLocationNamePrompt={productSettings?.renameLocationNamePrompt}
          productRenameLocationNamePrompt={product?.renameLocationNamePrompt}
          productSettingsShowLocationNameLabel={productSettings?.showDecorationLocationLabel}
          productShowLocationNameLabel={product?.showDecorationLocationLabel}
          locationName={locationName}
          isSearchArtwork={isSearchArtwork}
          searchTextArtwork={searchTextArtwork}
          searchArtworkName={searchArtworkName}
          artworkSearchPlaceholderText = {product?.artworkSearchPlaceholderText}
          displayDrop = {displayDrop}
          allowToUpCustArtwork= {productSettings?.allowToUpCustArtwork}
        />
        {getSettingStatus('showDecoration') && locID !== '' && (
          <AddDecorationportal
            setSelectedProductArtwork={setSelectedProductArtwork}
            selectedProductArtwork={selectedProductArtwork}
            parentKey={parentKey}
            getDecoPrice={getDecoPrice}
            locationID={`${locID}-${selectedLocationIndex}`}
            artWorkID={artWorkID}
            setCatalogueCustomRows={setCatalogueCustomRows}
            catalogueCustomRows={catalogueCustomRows}
            addDecoration={addDecoration}
            allowCustomDecoration={allowCustomDecoration}
            showSelectArtwork={product?.showSelectArtwork}
            setAddnewart={setAddnewart}
            productColor={productColor}
            setShowData={setShowData}
            showData={showData}
            temp={temp}
            tempDecorationLocations={tempDecorationLocations}
            locationName={locationName}
            itemsRows={itemsRows?.[0]}
            searchTextArtwork={searchTextArtwork}
            artworkSetting={artworkSetting}
            storeArtwork={storeArtwork}
            selectedDecorationsList={itemsRows?.[0]?.decorations}
            artworkDuplicatesAllowed={productSettings?.artworkDuplicatesAllowed}
			artworkDuplicatesAllowedPro={product?.artworkDuplicatesAllowed}
            product={product}
            decoExist={itemsRows?.[0]?.decorations}
            allowOneDec={allowOneDec}
            isAdded={isAdded}
            setIsAdded={setIsAdded}
            decorationListOrder = {decorationListOrder}
            preventAutoAttach = {preventAutoAttach}
            hideDecorationName={hideDecorationName}
          />
        )}
      </div>
    )
  }

  const handleCheckbox = (e, arrayIndex, decorationId = '') => {
    let selectedLocationId = e?.target?.value ? e.target.value.slice(0, -2) : e
    setIsAdded(false)
    setSelectedLocationIndex(arrayIndex)
    let tempRows = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      if (groupkey === arrayIndex) {
        let locaName = decorationLocations?.filter(
          (locationFilter) => locationFilter.id === selectedLocationId,
        )[0]?.locationName

        setSelectedLocation(selectedLocationId)
        temp(arrayIndex, '', locaName)

        rowGroupArray.filter((rowArray) => {
          if (rowArray?.decorations?.length > 0) {
            let findIfexist = rowArray.decorations.filter(
              (decorationArray) =>
                decorationArray.locationId === selectedLocationId,
            )
            if (selectedLocationId !== '') {
              if (findIfexist?.length === 0) {
                let tempaddDeco = {
                  location: locaName,
                  locationId: selectedLocationId,
                }
                if(!!locaName) {
                  rowArray.decorations.push(tempaddDeco)
                  setSelectedDecoration(tempaddDeco)
                  setSingleSelectedDecorations(tempaddDeco)
                } else {
                  rowArray.decorations = rowArray.decorations.filter(x => !!x.artworkID);
                }
              }
            }
          } else {
            rowArray['decorations'] = []
            let tempaddDeco = {
              location: locaName,
              locationId: selectedLocationId,
            }
            if(!!locaName) {
              setSelectedDecoration(tempaddDeco)
              setSingleSelectedDecorations(tempaddDeco)
              rowArray.decorations.push(tempaddDeco)
            } else {
              rowArray.decorations = rowArray.decorations.filter(x => !!x.artworkID);
            }
          }
          return rowArray
        })
      }
      return rowGroupArray
    })

    setCatalogueCustomRows(tempRows)
  }

  const handleArtworkselection = async (
    artwork,
    parentKey,
    locationID,
    findProductArtvariationId,
    selectDeco,
    isRequiredForStore,
    supplierDeco
  ) => {
    let tempcatalogueCustomRows = await catalogueCustomRows.filter(
      async (rowGroupArray, groupkey) => {
        if (groupkey === parentKey) {
          await rowGroupArray.filter(async (rowArray) => {
            rowArray.decorations &&
              (await rowArray.decorations.map(async (decoFilter) => {
                if (decoFilter.locationId === locationID) {
                  decoFilter.artworkID = artwork.id
                  decoFilter.isRequiredForStore = isRequiredForStore
                  decoFilter.supplierDeco = supplierDeco
                  decoFilter.variationUniqID = null
                  if (artwork.design && artwork.design.variation) {
                    if (artwork.design.variation.length) {
                      if (artwork.design.variation.length > 0) {
                        let getSelectedVaria = artwork.design.variation.filter(
                          (val) =>
                            val.design_variation_unique_id ==
                            findProductArtvariationId,
                        )
                        if (getSelectedVaria) {
                          decoFilter.variationUniqID =
                            getSelectedVaria[0].design_variation_unique_id
                        } else {
                          decoFilter.variationUniqID =
                            artwork.design.variation[0].design_variation_unique_id
                        }
                      }

                      await select_variant(
                        artwork.id,
                        parentKey,
                        findProductArtvariationId,
                        locationID,
                        selectDeco,
                      )
                    }
                  }
                }
                return decoFilter
              }))
            return rowArray
          })
        }
        return rowGroupArray
      },
    )
    setCatalogueCustomRows(tempcatalogueCustomRows)
  }

  const select_variant = async (
    artWorkID,
    parentKey,
    findProductArtvariationId,
    locationID,
    selectDeco,
  ) => {
    let artwork_selected = null
    if (artWorkID) {
      artwork_selected =
        artworkListById.length &&
        artworkListById.filter((f) => f.id === artWorkID)[0]

      if (artwork_selected.design && artwork_selected.design.variation) {
        if (artwork_selected.design.variation.length) {
          let artData = {}
          if (artwork_selected.design.variation.length > 0) {
            let getSelectedVaria = artwork_selected.design.variation.filter(
              (val) =>
                val.design_variation_unique_id == findProductArtvariationId,
            )

            if (getSelectedVaria) {
              artData = getSelectedVaria[0]
            } else {
              artData = artwork_selected.design.variation[0]
            }
          }

          await handleVariationselection(
            parentKey,
            locationID,
            artData,
            artwork_selected,
            selectDeco,
          )
        }
      }
    }
  }

  const handleVariationselection = async (
    parentKey,
    locationID,
    artwork,
    artwork_selected,
    selectDeco,
  ) => {
    let dPrice = await getDecoPrice(artwork_selected.id)

    let decoPricedetails = []
    if (dPrice?.length > 0) {
      dPrice.map((priceArray) => {
        if (artwork_selected?.design?.designType === priceArray.decoratorType) {
          decoPricedetails.push(priceArray)
        }
      })
    }

    let tempcatalogueCustomRows = await catalogueCustomRows.filter(
      (rowGroupArray, groupkey) => {
        if (groupkey === parentKey) {
          rowGroupArray.filter((rowArray) => {
            rowArray.decorations &&
              rowArray.decorations.map((decoFilter) => {
                if (decoFilter.locationId === locationID) {
                  if (decoPricedetails) {
                    decoFilter.decoPricedetails = decoPricedetails
                  }
                  decoFilter.variationUniqID =
                    artwork.design_variation_unique_id
                }
                return decoFilter
              })
            return rowArray
          })
        }
        return rowGroupArray
      },
    )
    if (selectDeco) {
      getDisplayStyle(parentKey, 'D')

      let currentProduct = catalogueCustomRows[parentKey][0]
      let productColor = catalogueCustomRows[parentKey][0].color
      let decoData = currentProduct?.decorations?.filter(
        (val) => val.artworkID === artwork_selected.id,
      )

      let decoLocation = decoData?.[0]?.location
      let getIndex = product.LogoInfo.filter(
        (val) => val.color === productColor && val.location === decoLocation,
      )

      if (getIndex.length > 0) {
        animateScroll.scrollToTop()
        setSelectedImage(getIndex[0]?.image)
      } else {
        let getIndexOfExtra_logo = []
        product.LogoInfo.map((val) => {
          if (val.color === productColor) {
            if (
              val.extra_logo.filter((e) => e.location === decoLocation).length >
              0
            ) {
              getIndexOfExtra_logo.push(val)
            }
          }
        })
        if (getIndexOfExtra_logo.length) {
          animateScroll.scrollToTop()
          setSelectedImage(getIndexOfExtra_logo[0]?.image)
        } else {
          let getImgColorIndex = product.LogoInfo.filter(
            (val) => val.color === productColor,
          )

          if (getImgColorIndex.length > 0) {
            animateScroll.scrollToTop()
            setSelectedImage(getImgColorIndex[0]?.image)
          }
        }
      }
      setCatalogueCustomRows(tempcatalogueCustomRows)
    }
  }

  const getDisplayStyle = (key, Type) => {
    if (toggleDivsArray[key]) {
      if (Type === 'D') {
        if (toggleDivsArray[key].decorations) {
          return 'block'
        } else {
          return 'none'
        }
      }
      if (Type === 'A') {
        if (toggleDivsArray[key].attributes) {
          return 'block'
        } else {
          return 'none'
        }
      }
      if (Type === 'P') {
        if (toggleDivsArray[key].personalization) {
          return 'block'
        } else {
          return 'none'
        }
      }
      return 'none'
    } else {
      return 'none'
    }
  }

  const changeColor = async (e, mainKey, callfrom = '', image = '', singleImage = '') => {
    ToggleDivs(mainKey, 'D', 'onChangeColor')
    setSelectedImage('')
    const  artworksFromState =  [...artworksListState, ...artworksProductState]
    let color =
      e && e.target && e.target.value && e.target.value ? e.target.value : e

    setTempSelectedColor(color)
    // setSelectedProductColor(color)
    setSelectedProductMainColor(color)
    setSelectedProductMainImage(image)
    setSelectedImage(singleImage)
    setProductColorTemp(true)
    let getImage = product?.WebstoreProductPartArray.filter(
      (val) => val.color == color && val.isSwapImage === true,
    )

    let tempRows = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      if (groupkey === mainKey) {
        rowGroupArray.filter((rowArray) => {
          rowArray.color = color
          if (product.MediaContent?.length > 0) {
            let defaultImages = product.MediaContent.filter(
              (val) => val.color === rowArray.color && val.primary,
            )
            if (defaultImages.length) {
              rowArray.image = defaultImages[0].url
            } else {
              let secondaryimages = product.MediaContent.filter(
                (val) => val.color === rowArray.color,
              )
              if (secondaryimages.length) {
                rowArray.image = secondaryimages[0].url
              }
            }
          }
          return rowArray
        })
      }
      return rowGroupArray
    })
    tempRows[mainKey][0]['decorations'] = []
    if (getImage.length === 0) {
      if (getSettingStatus('showDecoration') || !getSettingStatus('showDecoration')) {
        setIsAdded(false)
        let findProductArtvariationId = ''
        let locationId = ''
        if( artworksFromState && artworksFromState.length ) {
          const numOfArtwork = artworksFromState.reduce((acc, val) => acc +
              (val.productToDecorationMapping || []).filter(x => x.isRequiredForStore === '1' && x.mapping.filter(m => m.color === color)).length, 0
          )
          let keyMap = 0
          artworksFromState.map(( val, key ) => {
            (val.productToDecorationMapping || []).forEach(( decoMap ) => {
              if( decoMap.isRequiredForStore === '1') {
                locationId = decoMap.location
                decoMap.mapping.map(async( mapVal) => {
                      if( mapVal.color === color ) {
                        handleCheckbox(locationId, mainKey)
                        findProductArtvariationId = mapVal.variationId
                        let isRequiredForStore = decoMap.isRequiredForStore
                        let supplierDeco = decoMap.supplierDeco
                        await handleArtworkselection(
                            val,
                            mainKey,
                            locationId,
                            findProductArtvariationId,
                            keyMap++ ===  (numOfArtwork - 1),
                            isRequiredForStore,
                            supplierDeco
                        )
                      }
                    },
                )
              } else {
                setShowData('yes')
              }
            })
          })
        }
      }
      setIsReplace(true)
    } else {
      setIsReplace(false)
    }

    //change color
    let Quantities = document.getElementsByName('quantity')
    if (!!Quantities) {
      if (!!Quantities.length && Quantities.length > 0) {
        Quantities.forEach((element, key) => {
          if (element.value !== '') {
            element.focus()
          }
        })
      }
    }

    setCatalogueCustomRows(tempRows)
    setTempValue(tempValue + 1)
    if (personalizationAdded) {
      let totalQuantity = catalogueCustomRows[mainKey]?.reduce((acc, val) => acc + Number(val.quantity), 0) ?? 0;
      if (totalQuantity > 0) {
        ToggleDivs(mainKey, 'P', 'onChangeColor')
      }
      addPersonalization(mainKey)
    }
  }

  const fetchPersonalizationCost = async () => {
    try {
      setPersonalizationCost((preVal) => ({
        ...preVal,
        ['matchOrderQty']: 1,
        ['price']: personalizationPerUnitPrice
          ? personalizationPerUnitPrice
          : 0,
        ['setupPrice']: personalizationSetupFee ? personalizationSetupFee : 0,
      }))
      totalPriceData()
    } catch (error) {
      console.log('error', error)
    }
  }

  const totalPriceData = () => {
    let prdt = catalogueCustomRows.filter((rowGroupArray) => {
      let cnt = rowGroupArray.filter((groupArray) => {
        if (!!groupArray?.personalization) {
          return groupArray
        }
      })
      return cnt.length > 0
    })
    let totalSumqw = 0
    catalogueCustomRows.map((itemsRowsabs, keya) => {
      itemsRowsabs &&
        itemsRowsabs.length > 0 &&
        itemsRowsabs.map((sizeObj, index) => {
          if (!!sizeObj?.personalization) {
            totalSumqw =
              totalSumqw + Number(sizeObj?.quantity && sizeObj?.quantity)
          }
        })
    })
    if (
      personalizationCost?.price !== '' &&
      personalizationCost?.price !== 'undefined' &&
      personalizationCost?.price !== undefined &&
      prdt.length > 0
    ) {
      if (personalizationCost.matchOrderQty == '0') {
        toatlPrice =
          Number(toatlPrice) +
          Number(personalizationCost.price) +
          Number(showSetupChargeInCart ? personalizationCost.setupPrice : 0)
      } else if (
        personalizationCost.matchOrderQty == '1' &&
        showPersonalizationCostInCart
      ) {
        toatlPrice =
          Number(toatlPrice) +
          Number(personalizationCost.price) * Number(totalSumqw) +
          Number(showSetupChargeInCart ? personalizationCost.setupPrice : 0) * Number(prdt.length)
      } else if (personalizationCost.matchOrderQty == '1') {
        toatlPrice =
          Number(toatlPrice) +
          Number(showSetupChargeInCart ? personalizationCost.setupPrice : 0) * Number(prdt.length)
      }
    }
    return (
      <>

        { showPrice !== false && (
            <b>
              Total: {
              usePointsAsCurrency ? getPointsFromValue(Number(toatlPrice)) : <>
                { currency.currencySymbol }
                { toatlPrice === 0 || toatlPrice > 0
                    ? productSettings?.priceRoundUp
                        ? roundedPrice(Number(toatlPrice), decimalsToShow)
                        : Number(toatlPrice).toFixed(decimalsToShow)
                    : 0 }
              </>
            }
            </b>
        ) }
      </>
    )
  }

  const addPersonalization = (key) => {
    /*** get personalization cost from advance
    its call one time if personalizationCost less than 0 or 0. ***/

    if (!personalizationCost.price) {
      fetchPersonalizationCost()
    }

    let tempData = catalogueCustomRows.filter((rowGroup, index) => {
      if (index === key || !key) {
        rowGroup.map((rowArray) => {
          if (!rowArray.personalization) {
            rowArray['personalization'] = []
          }
          let persQty = rowArray.quantity
          let tempMainrows = rowArray.personalization.filter(
            (row) => Number(row.sequance) === 1,
          )
          let persRows = []
          if (Number(persQty) > 0) {
            let itemQty = Number(persQty)
            let pushedIds = []
            for (let index = 0; index < itemQty; index++) {
              if (tempMainrows[index]) {
                let tempPersRow = rowArray.personalization.filter(
                  (row) => row.rowQtyNo === tempMainrows[index].rowQtyNo,
                )
                if (pushedIds.indexOf(tempPersRow[0].rowQtyNo) === -1) {
                  pushedIds.push(tempPersRow[0].rowQtyNo)
                  tempPersRow.map((tempRow) => {
                    persRows.push({
                      ...tempRow,
                      itemSize: rowArray.size,
                      itemColor: rowArray.color,
                    })
                  })
                }
              } else {
                if(!!personalizationDropDown && !!requiredLinesPersonalization) {
                  !!dropDownValues && dropDownValues.length > 0 && dropDownValues.map((resData, keysData) => {
                    persRows.push({
                      color: getPersColor(product, productSettings),
                      displayText: '',
                      font: getPersFont(product, productSettings),
                      itemCode: product.itemCode,
                      itemColor: rowArray.color,
                      itemId: product.id,
                      itemNo: product.productId,
                      itemSize: rowArray.size,
                      location: getPersLocation(product, productSettings),
                      notes: getPersNote(product, productSettings),
                      orderId: '',
                      rowId: rowArray.id,
                      rowQtyId: '',
                      rowQtyNo: index + 1,
                      key : key,
                      sequance: 1 + keysData,
                      lineName: resData.personalizationDropDownRename,
                      isDrp: resData.isPersonalizationDropDown,
                      drpValue: resData.personalizationDropDownValue,
                      isEnabled: personalizationDropDown,
                      requiredLine: requiredLinesPersonalization,
                    })
                  })
               } else {
                persRows.push({
                  color: getPersColor(product, productSettings),
                  displayText: '',
                  font: getPersFont(product, productSettings),
                  itemCode: product.itemCode,
                  itemColor: rowArray.color,
                  itemId: product.id,
                  itemNo: product.productId,
                  itemSize: rowArray.size,
                  location: getPersLocation(product, productSettings),
                  notes: getPersNote(product, productSettings),
                  orderId: '',
                  rowId: rowArray.id,
                  rowQtyId: '',
                  rowQtyNo: index + 1,
                  sequance: 1,
                })
               }
              }
            }
          }
          rowArray.personalization = persRows
          return rowArray
        })
      }
      return rowGroup
    })
    setCatalogueCustomRows(tempData)
    updatestate(!statchange)
  }

  const deletePersonalization = (key) => {
    let tempData = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      rowGroupArray = rowGroupArray.filter((groupArray) => {
        if (groupkey === key) {
          ToggleDivs(key, 'P', 'DeleteRow')
          groupArray.personalization = null
          return groupArray
        }
      })
      return rowGroupArray
    })

    let prdt = tempData.filter((rowGroupArray) => {
      let cnt = rowGroupArray.filter((groupArray) => {
        if (!!groupArray?.personalization) {
          return groupArray
        }
      })
      return cnt.length > 0
    })

    setCatalogueCustomRows(tempData)
    if (!(prdt.length > 0)) {
      setPersonalizationAdded(false)
      setPersonalizationCost((preVal) => ({
        ...preVal,
        ['matchOrderQty']: 0,
        ['price']: 0,
        ['setupPrice']: 0,
        ['chargeId']: '',
      }))
      updatestate(!statchange)
    }
  }

  const changeQty = (e, mainArraykey, rowSize, callFrm = 0) => {
    let quantityFromCart = 0;
    if(rowSize && tempSelectedColor) {
      quantityFromCart = getCartItemTotal(product.productId, tempSelectedColor, rowSize);
    }
    if(callFrm === 0) {
      const regularExpression = /^[0-9\b]+$/
      e.target.placeholder = ''
      if (e.target.value !== '' && !regularExpression.test(e.target.value)) {
        return false;
      }
      if (!Number(e.target.value) && e.target.value !== '') {
        return false;
      }
    }
    if (
      showColor &&
      (!tempSelectedColor || tempSelectedColor === `Select ${colorLabel}`)
    ) {
      e.target.value = ''
      return false
    }
    let tempRows = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      if (groupkey === mainArraykey) {
        let checkEditRowexist = rowGroupArray.filter(
          (rowArray) => rowArray.size === rowSize,
        )
        if (checkEditRowexist?.length > 0) {
          rowGroupArray.filter((rowArray) => {
            if (rowArray.size === rowSize) {
              rowArray.quantity = callFrm === 1 ? e : e.target.value
              priceTable.map((priceTableRow) => {
                if (priceTableRow.size === rowArray.size) {
                  let priceOptionsArray = priceTableRow.priceOptions
                  priceOptionsArray.map((priceOption) => {
                    if (
                      priceOption.ColorArray.Color.colorName === rowArray.color
                    ) {
                      let qtStock = getStock(rowArray.size, rowArray.color)
                      let totalToCompare = (qtStock - quantityFromCart) < 0 ? 0 : qtStock - quantityFromCart 
                      if (IsAllowOutOfStockOrder) {
                      } else if (totalToCompare < rowArray.quantity) {
                        rowArray.quantity = totalToCompare;
                      }
                      if (!rowArray.product) {
                        rowArray['product'] = {}
                      }
                      rowArray.product.sku = priceOption.sku
                      if (
                        priceOption.partPrice.PartPriceArray.PartPrice.length >
                        0
                      ) {
                        /*let priceRow =
                          priceOption.partPrice.PartPriceArray.PartPrice[0]
                        const minQuantityRow =
                          priceOption.partPrice.PartPriceArray.PartPrice.filter(
                            (x) =>
                              Number(x.minQuantity) <=
                              Number(rowArray.quantity),
                          )
                        priceRow =
                          minQuantityRow.length > 0
                            ? minQuantityRow.reduce((prev, current) =>
                                Number(prev?.salePrice) <
                                Number(current.salePrice)
                                  ? prev
                                  : current,
                              )
                            : priceRow
                        rowArray.price = priceRow.salePrice
                        rowArray.cost = priceRow.price*/
                        const quantitys = Number(rowArray.quantity);
                        priceOption.partPrice.PartPriceArray.PartPrice.map(
                          (partPrice, k) => {
                            if (
                              quantitys !== '' &&
                              Number(quantitys) > 0 &&
                              Number(quantitys) >= Number(partPrice.minQuantity)
                            ) {
                              rowArray.price = partPrice.salePrice
                              rowArray.cost = partPrice.price
                            } else if (k === 0) {
                              rowArray.price = partPrice.salePrice
                              rowArray.cost = partPrice.price
                            }
                          },
                        )
                      }

                      if (Number(userGroupDiscount > 0) && Number(rowArray.price) > 0) {
                        rowArray.price =
                          Number(rowArray.price) -
                          (Number(rowArray.price) * Number(userGroupDiscount)) /
                            100
                        if (rowArray.price < 0) {
                          rowArray.price = 0
                        }
                      }

                      if (discountType === '1' && showSell && Number(rowArray.price) > 0) {
                        if (offerPrice > 0) {
                          rowArray.price =
                            Number(rowArray.price) - Number(offerPrice)
                          if (rowArray.price < 0) {
                            rowArray.price = 0
                          }
                        }
                      } else if (discountType === '0' && showSell && Number(rowArray.price) > 0) {
                        if (offerPrice > 0) {
                          rowArray.price =
                            Number(rowArray.price) -
                            (Number(rowArray.price) * Number(offerPrice)) / 100

                          if (rowArray.price < 0) {
                            rowArray.price = 0
                          }
                        }
                      }

                      rowArray.totalPrice = rowArray.price * rowArray.quantity
                      rowArray.totalCost = rowArray.cost * rowArray.quantity
                    }
                  })
                }
              })
            }
            return rowArray
          })
        } else {
          if (rowGroupArray[0]) {
            if (rowGroupArray[0].size === '') {
              rowGroupArray[0].size = rowSize
              rowGroupArray[0].quantity = callFrm === 1 ? e : e.target.value
              if (showColor && colors_final.length === 1) {
                rowGroupArray[0].color = colors_final[0]
              }
              let tempGroupArray = { ...rowGroupArray[0] }
              priceTable.map((priceTableRow) => {
                if (priceTableRow.size === tempGroupArray.size) {
                  let priceOptionsArray = priceTableRow.priceOptions
                  priceOptionsArray.map((priceOption) => {
                    if (
                      priceOption.ColorArray.Color.colorName ==
                      tempGroupArray.color
                    ) {
                      if (!tempGroupArray.product) {
                        tempGroupArray['product'] = {}
                      }
                      tempGroupArray.product.sku = priceOption.sku
                      if (
                        priceOption.partPrice.PartPriceArray.PartPrice.length >
                        0
                      ) {
                        priceOption.partPrice.PartPriceArray.PartPrice.map(
                          (p, pKey) => {
                            tempGroupArray.price = p.salePrice
                            tempGroupArray.cost = p.price
                          },
                        )
                      }
                      tempGroupArray.totalPrice =
                        tempGroupArray.price * tempGroupArray.quantity
                      tempGroupArray.totalCost =
                        tempGroupArray.cost * tempGroupArray.quantity
                    }
                  })
                }
              })
              rowGroupArray[0] = tempGroupArray
            } else {
              let newRow = {
                id: uniqIdcounter++,
                size: rowSize,
                quantity: e.target.value,
                image: rowGroupArray[0].image,
                color: rowGroupArray[0].color,
                decorations: rowGroupArray[0].decorations,
                product: { sku: 0 },
                price: 0,
                totalPrice: 0,
                cost: 0,
                totalCost: 0,
                weight: rowGroupArray[0].weight,
                shipFrom: {
                  countryFrom: rowGroupArray[0].shipFromCountry,
                  zipCodeFrom: rowGroupArray[0].shipFromPostalCode,
                },
              }
              priceTable.map((priceTableRow) => {
                if (priceTableRow.size === newRow.size) {
                  let priceOptionsArray = priceTableRow.priceOptions
                  priceOptionsArray.map((priceOption) => {
                    if (
                      priceOption.ColorArray.Color.colorName === newRow.color
                    ) {
                      newRow.product.sku = priceOption.sku
                      if (
                        priceOption.partPrice.PartPriceArray.PartPrice.length >
                        0
                      ) {
                        /*let priceRow =
                          priceOption.partPrice.PartPriceArray.PartPrice[0]
                        const minQuantityRow =
                          priceOption.partPrice.PartPriceArray.PartPrice.filter(
                            (x) =>
                              Number(x.minQuantity) <= Number(newRow.quantity),
                          )
                        priceRow =
                          minQuantityRow.length > 0
                            ? minQuantityRow.reduce((prev, current) =>
                                Number(prev?.salePrice) <
                                Number(current.salePrice)
                                  ? prev
                                  : current,
                              )
                            : priceRow
                        newRow.price = priceRow.salePrice
                        newRow.cost = priceRow.price*/
                        priceOption.partPrice.PartPriceArray.PartPrice.map(
                          (partPrice, k) => {
                            if (
                              newRow.quantity !== '' &&
                              Number(newRow.quantity) > 0 &&
                              Number(newRow.quantity) >= Number(partPrice.minQuantity)
                            ) {
                              newRow.price = partPrice.salePrice
                              newRow.cost = partPrice.price
                            } else if (k === 0) {
                              newRow.price = partPrice.salePrice
                              newRow.cost = partPrice.price
                            }
                          },
                        )
                      }
                      newRow.totalPrice = newRow.price * newRow.quantity
                      newRow.totalCost = newRow.cost * newRow.quantity
                    }
                  })
                }
              })
              rowGroupArray.push(newRow)
            }
          }
        }
      }
      return rowGroupArray
    })
    if (isPriceBreak) {
      let totalSum = 0
      tempRows.map((object, index) => {
        object.map((sizeObj, index) => {
          totalSum = totalSum + Number(sizeObj.quantity)
        })
      })
      if (totalSum > 0) {
        let priceArray = []
        let obj1
        tempRows.map((catItem, index) => {
          let productPartArray =
            product?.WebstoreProductPartArray &&
            product?.WebstoreProductPartArray.length > 0
              ? product?.WebstoreProductPartArray
              : product?.ProductPartArray?.ProductPart
          catItem.map((obj, index) => {
            obj1 = productPartArray.find(
              (productPart) =>
                productPart?.ColorArray?.Color?.colorName === obj.color &&
                productPart?.ApparelSize?.labelSize === obj.size,
            )
          })
          priceArray.push(obj1)
        })
        let minQuantity = []
        priceArray.map((price) => {
          let min = price?.partPrice?.PartPriceArray?.PartPrice.filter(
            (val) => Number(val.minQuantity) <= Number(totalSum),
          )
          if (min && min.length > 0) {
            minQuantity.push(min)
          }
        })
        let allMaxQty = []
        minQuantity.length > 0 &&
          minQuantity.map((qtyMin) => {
            let maxQty =
              qtyMin.length > 0 &&
              qtyMin.reduce((prev, current) =>
                Number(prev?.minQuantity) > Number(current.minQuantity)
                  ? prev
                  : current,
              )
            allMaxQty.push(maxQty)
          })
        let salePrice =
          allMaxQty.length > 0 &&
          allMaxQty.reduce((prev, current) =>
            Number(prev?.salePrice) > Number(current.salePrice)
              ? prev
              : current,
          )
        tempRows.map((cartItem, index) => {
          cartItem.map((order, index) => {
            order.price =
              salePrice?.salePrice && Number(salePrice?.salePrice) !== 0
                ? Number(salePrice?.salePrice)
                : Number(order.price)
            return order
          })
        })
      }
    }

    setCatalogueCustomRows(tempRows)
    if (personalizationAdded) {
      let totalQuantity = tempRows[mainArraykey]?.reduce((acc, val) => acc + Number(val.quantity), 0) ?? 0;
      if (totalQuantity > 0) {
        ToggleDivs(mainArraykey, 'P', 'changeQty')
      } else {
        ToggleDivs(mainArraykey, 'P', 'changeQtyZero')
      }
      addPersonalization(mainArraykey)
    }
  }


  const getDecoPricebyqty = (decorationsArray, qty) => {
    let decosalePrice = 0
    decorationsArray &&
      decorationsArray.map((decoitemArray, index) => {
        if (decoitemArray?.decoPricedetails?.length > 0) {
          let artworkselected = artworksFromState.filter(
            (artorkArray) => artorkArray.id === decoitemArray.artworkID,
          )[0]
          let priceq = 0
          decoitemArray.decoPricedetails.map((decoPriceeArray) => {
            let sCount = artworkselected.design.stitchCount

            if (sCount === '' || !Number(sCount)) {
              sCount = 0
            }

            if (
              Number(sCount) >= Number(decoPriceeArray.stitchesStart) &&
              Number(sCount) <= Number(decoPriceeArray.stitchesUpto)
            ) {
              if (
                Number(qty !== '' ? qty : 1) >=
                  Number(decoPriceeArray.qunatityStart) &&
                Number(qty !== '' ? qty : 1) <=
                  Number(decoPriceeArray.quantityUpto)
              ) {
                priceq = Number(decoPriceeArray.salePrice)
              }
            }
          })

          decosalePrice = decosalePrice + priceq
        }
      })
    return Number(decosalePrice)
  }

  const checkPriceOptionsArray = (
    priceoption,
    itemRowArraycolor,
    pricerowsize,
  ) => {
    let selectDefaultColor =
      showColor === false
        ? priceoption?.ColorArray?.Color?.colorName === itemRowArraycolor
        : true
    if (
      priceoption?.ApparelSize?.labelSize &&
      priceoption.ApparelSize.labelSize === pricerowsize &&
      pricerowsize !== '' &&
      selectDefaultColor
    ) {
      if (
        priceoption?.ColorArray?.Color?.colorName &&
        priceoption.ColorArray.Color.colorName === itemRowArraycolor &&
        itemRowArraycolor !== ''
      ) {
        if (priceoption?.partPrice?.PartPriceArray) {
          return true
        }
      }
    }
    return false
  }

  const getDecoAttach = (color_current, get_image_url, isclicked = 0) => {
    setSelectedDecoration(null)
    setTempValue(tempValue + 1)
    let color_image_url = get_image_url

    if (get_image_url.includes('anterasaas.com')) {
      const url = new URL(get_image_url)
      color_image_url = url.searchParams.get('url')
    }

    let locationName = null
    let getIndex = ''
    for (let i = 0; i < catalogueCustomRows.length; i++) {
      if (
        catalogueCustomRows[i][0].decorations &&
        catalogueCustomRows[i][0].decorations.length > 0
      ) {
        if (catalogueCustomRows[i][0].color === color_current) {
          for (let p = 0; p < product.LogoInfo.length; p++) {
            if (product.LogoInfo[p].color === color_current) {
              if (product.LogoInfo[p].image === color_image_url) {
                let checkLocation = catalogueCustomRows[
                  i
                ][0].decorations?.filter(
                  (val) => val.location === product.LogoInfo[p].location,
                )
                if (checkLocation?.length > 0) {
                  locationName = product.LogoInfo[p].location
                  getIndex = i
                  break
                } else {
                  for (
                    let e = 0;
                    e < product.LogoInfo[p].extra_logo.length;
                    e++
                  ) {
                    if (
                      product.LogoInfo[p].extra_logo[e].color ===
                        color_current &&
                      product.LogoInfo[p].extra_logo[e].image ===
                        color_image_url
                    ) {
                      let checkLocation = catalogueCustomRows[
                        i
                      ][0].decorations?.filter(
                        (val) =>
                          val.location ===
                          product.LogoInfo[p].extra_logo[e].location,
                      )
                      if (checkLocation?.length > 0) {
                        locationName =
                          product.LogoInfo[p].extra_logo[e].location
                        getIndex = i
                        break
                      }
                    }
                  }
                }
                break
              }
            }
          }
          break
        }
      }
      if (productSettings?.isColorSelCarousel === true) {
        if (
          color_current !== '' &&
          isclicked === 1 &&
          i === catalogueCustomRows.length - 1
        ) {
          changeColor(color_current, i, 3)
          locationName = null
        }
      }
    }

    if (locationName !== null && locationName !== 'undefined') {
      let getselectedLocation = catalogueCustomRows[
        getIndex
      ][0].decorations.filter((val) => val.location === locationName)
      setSelectedDecoration(getselectedLocation[0])
      setTempValue(tempValue + 1)
    }
  }

  const photoZoom = (zmImg = '') => {
    let node = document.querySelector(
      '.swiper-slide-active .single-image.lightGal',
    )
    if(isModel) {
      node = document.querySelector(
        '.modalportal .swiper-slide-active .single-image.lightGal',
      )
    }

    let zoomPictureNode = document.getElementsByClassName('lg-img-wrap')
    if (
      !!zoomPictureNode &&
      !!zoomPictureNode?.[0] &&
      !!DefaultProductBackgroundColor &&
      zmImg !== ''
    )
      zoomPictureNode[0].classList.add(zmImg)

    if (node) {
      const clone = node.cloneNode(true)
      zoomPictureNode[0].classList.add('lg-img-zoom')
      if (zoomPictureNode) {
        zoomPictureNode[0].appendChild(clone)
      }
    }

    let getOuterBOx = document.querySelector(
      '.lg-img-wrap .react_lightgallery_item',
    )
    if (!!getOuterBOx) {
      let imgHeight = getSingleImage?.ih

      let viewPortHeight = window.innerHeight
      let reducePer = getReducePer(imgHeight, viewPortHeight)

      imgHeight = (imgHeight * reducePer) / 100

      let positionSetLeft =
        (getOuterBOx.offsetWidth * getSingleImage?.x) / getSingleImage.iw
      let positionSetTop =
        (getOuterBOx.offsetHeight * getSingleImage?.y) / getSingleImage.ih

      let positionSetBoxWidth =
        (getOuterBOx?.offsetWidth * getSingleImage?.w) / getSingleImage.iw
      let positionSetBoxHeight =
        (getOuterBOx?.offsetHeight * getSingleImage?.h) / getSingleImage.ih

      let overlayImag = document.querySelectorAll('.lg-img-wrap .overlayimg')
      const css = '.overlayimg:hover{  transform: scale(1.5); }';
      const style = document.createElement('style');
      if (style.sheet) {
        style.sheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
      for (let i = 0; i < overlayImag.length; i++) {
        overlayImag[i].style.left = positionSetLeft + 'px'
        overlayImag[i].style.top = positionSetTop + 'px'
        overlayImag[i].style.width = positionSetBoxWidth + "px"
        overlayImag[i].style.height = positionSetBoxHeight + "px"
        overlayImag[i].style.transition = 'transform 0.3s';
        overlayImag[i].appendChild(style)
      }


      let overlayImag2 = document.querySelectorAll(
        '.lg-img-wrap .overlayimg .img-fluid',
      )
      for (let i = 0; i < overlayImag2.length; i++) {
        overlayImag2[i].style.maxWidth = positionSetBoxWidth + 'px'
        overlayImag2[i].style.maxHeight = positionSetBoxHeight + 'px'
        overlayImag2[i].style.width = '100%'
        overlayImag2[i].style.height = '100%'
        overlayImag2[i].style.objectFit = 'contain'
      }
    }
    let getOuterBOxCheck2 = document.querySelector('.lg-img-wrap .lightGal')

    let zoomInBtn = document.getElementById('lg-zoom-in')
    let zoomOutBtn = document.getElementById('lg-zoom-out')

    let scaleItem = 1

    zoomInBtn.addEventListener('click', function (event) {
      getOuterBOxCheck2.style.transform = `scale(${
        scaleItem + 0.5
      }) translateX(-50%)`
      scaleItem = scaleItem + 0.5
    })
    zoomOutBtn.addEventListener('click', function (event) {
      getOuterBOxCheck2.style.transform = `scale(${
        scaleItem - 0.5
      }) translateX(-50%)`
      scaleItem = scaleItem - 0.5
    })
  }
  const checkOrderRows = () => {
    let flag = true
    if (catalogueCustomRows.length === 0) {
      orderAction('Please add row', 'error', false)
      flag = false
    }
    catalogueCustomRows.map((rowGroup) => {
      if (flag) {
        if (rowGroup.length === 0) {
          orderAction('Please add row', 'error', false)
          flag = false
        } else {
          let checkQty = true
          rowGroup.map((rowArray) => {
            if (flag) {
              if ((rowArray.color === '' || rowArray.color === `Select ${colorLabel}`) && showColor) {
                orderAction(`Please select ${colorLabel}!`, 'error', false)
                flag = false
              } else {
                if (rowArray.size !== '') {
                  if (rowArray.decorations && rowArray.decorations.length > 0) {
                    rowArray.decorations.map((decoArray) => {
                      if (decoArray.locationId !== '') {
                        if (decoArray.artworkID && decoArray.artworkID !== '') {
                          if (
                            decoArray.variationUniqID &&
                            decoArray.variationUniqID !== '' &&
                            decoArray.variationUniqID !== null
                          ) {
                          } else {
                            orderAction(
                              'Please select variation',
                              'error',
                              false,
                            )
                            flag = false
                          }
                        } else {
                          orderAction('Please select artwork', 'error', false)
                          flag = false
                        }
                      } else {
                        if (decoArray.artworkID !== '') {
                          if (
                            decoArray.variationUniqID === '' ||
                            decoArray.variationUniqID === null
                          ) {
                            orderAction(
                              'Please select variation',
                              'error',
                              false,
                            )
                            flag = false
                          }
                        } else {
                          orderAction('Please select artwork', 'error', false)
                          flag = false
                        }
                      }
                    })
                  }
                }
                if(personalizationAdded && rowArray?.personalization) {
                  let blnktest = rowArray.personalization.filter(x => x.displayText === '')
                  if(blnktest.length > 0) {
                    orderAction('You have added personalization to this product, but you haven’t added your text. Please add your text or delete Personalization to add this product to your cart.', 'error', false)
                    flag = false
                  }
                }
                if (Number(rowArray.quantity) > 0) {
                  checkQty = false
                }
              }
            }
          })
          if (checkQty) {
            orderAction('Please enter quantity', 'error', false)
            flag = false
          }
        }
      }
    })
    if (RequiredDecorationForCheckOutPro === true) {
      let tempRow = []
      catalogueCustomRows.forEach((catalogueCustomRow) => {
        let tempEachRow = []
        catalogueCustomRow.forEach((row) => {
          if (row.quantity && Number(row.quantity) > 0) {
            tempEachRow.push(row)
          }
        })
        tempRow.push(tempEachRow)
      })
      if (
        !!tempRow[0][0]?.decorations &&
        !(tempRow[0][0]?.decorations.length > 0)
      ) {
        orderAction(
          'Please choose a decoration to add this product to your cart',
          'error',
          false,
        )
        flag = false
      }
    }
    return flag
  }

  const goToCart = () => {
    localStorage.setItem('redirectUrl', window.location.pathname)
    if (isDirectToCart) {
      history.push('/cart')
    }
  }

  const addtoCart = () => {
    catalogueCustomRows.map((rowGroup) => {
      rowGroup.map((rowArray) => {
        if(!!rowArray?.decorations && rowArray.decorations.filter(res=>res.artworkID).length === 0){
          rowArray.decorations = []
        }
      })
    })
    if (checkOrderRows()) {
      catalogueCustomRows[0][0].rollDecoChargesToProduct = 0
      catalogueCustomRows[0][0].rollDecoAddonChargesToProduct = 0

      if (catalogueCustomRows[0][0]?.decorations?.length) {
        catalogueCustomRows[0][0].rollDecoChargesToProduct = 1
        catalogueCustomRows[0][0].rollDecoAddonChargesToProduct = 1
      }
      let tempRow = []
      let cnt = 0
      let isPersonalization = false
      catalogueCustomRows.map((itemsRowsabs, keya) => {
          itemsRowsabs && itemsRowsabs.length > 0 && itemsRowsabs.map((sizeObj, index) => {
            if (!!sizeObj?.personalization) {
              isPersonalization = true
            }
          })
      })
      catalogueCustomRows.forEach((catalogueCustomRow) => {
        let tempEachRow = []
        catalogueCustomRow.forEach((row) => {
          if (row.quantity && Number(row.quantity) > 0) {
            cnt = cnt + 1
            if (cnt === 1 && getSettingStatus('showPersonalization') && isPersonalization === true) {
              row.personalizationCost = personalizationCost
            }
            tempEachRow.push(row)
          }
        })
        tempRow.push(tempEachRow)
      })
      tempRow.forEach(async (item, index) => {
        await new Promise(r => setTimeout(r, 500));
        addItemsRowsTocart(product, [item], index === tempRow.length -1 ? addToast: null, isPriceBreak, ipV4, userGroupDiscount);
      })

      goToCart()
      let cartCustomRows = product?.MediaContent.filter((x) => x.primary).map(
        (x) => {
          return {
            id: uniqIdcounter++,
            size: '',
            quantity: '',
            color: '',
            displayGroup: x.displayGroup,
            group: x.group,
            groups: x.groups,
            hex: x.hex,
            hidden: x.hidden,
            index: x.index,
            logoBlockCount: x.logoBlockCount,
            mediaType: x.mediaType,
            partId: x.partId,
            primary: x.primary,
            productId: x.productId,
            secondary: x.secondary,
            select: x.select,
            sku: x.sku,
            url: x.url,
            price: 0,
            totalPrice: 0,
            cost: 0,
            totalCost: 0,
            product: { sku: x.sku },
            weight: product.weight,
            shipFrom: {
              countryFrom: product.shipFromCountry,
              zipCodeFrom: product.shipFromPostalCode,
            },
          }
        },
      )
      cartCustomRows =
        cartCustomRows.length > 0
          ? [cartCustomRows]
          : [
              [
                {
                  id: uniqIdcounter++,
                  size: '',
                  quantity: '',
                  image: product?.MediaContent?.[0]?.url,
                  color: '',
                  product: { sku: 0 },
                  price: 0,
                  totalPrice: 0,
                  cost: 0,
                  totalCost: 0,
                  weight: product.weight,
                  shipFrom: {
                    countryFrom: product.shipFromCountry,
                    zipCodeFrom: product.shipFromPostalCode,
                  },
                },
              ],
            ]

      setPersonalizationCost((preVal) => ({
        ...preVal,
        ['matchOrderQty']:
          cartCustomRows[0][0]?.personalizationCost?.matchOrderQty,
        ['price']: cartCustomRows[0][0]?.personalizationCost?.price,
        ['setupPrice']: cartCustomRows[0][0]?.personalizationCost?.setupPrice,
        ['chargeId']: cartCustomRows[0][0]?.personalizationCost?.chargeId,
      }))
      setCatalogueCustomRows(cartCustomRows)
      updateStateTempNew(statChangeTempNew+1)
      setSelectedProductMainColor(null)
      animateScroll.scrollToTop()
      document.getElementsByName('quantity').forEach((element) => (element.placeholder = '0'))
      setToggleDivs([])
      // if(priceTable.length === 1) { setaddRowQtyFirst(tempRow[0][0].quantity) }
    }
  }

  const verifyShowColor = () => {
    if(colors_final.length === 1) {
      setSelectedProductMainColor(colors_final[0])
      setTempSelectedColor(colors_final[0])
      setProductColorTemp(true)
      setIsReplace(true);
      let tempRows = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
          rowGroupArray.filter((rowArray) => {
            rowArray.color = colors_final[0]
            return rowArray
          })
        return rowGroupArray
      })
      if(tempRows.length > 0) {
        setCatalogueCustomRows(tempRows)
        setTempValue(tempValue + 1)
      }
    }
    if(colors_final.length > 1 && !showColor) {
      setShowColor(true);
    }
    if(priceTable.length > 0 && !showSize) {
      setShowSize(true)
    }
  }

  useEffect(() => {
    verifyShowColor()
  }, [showColor, showSize])

  const checkTaotalQty = () => {
    let totalSumqw = 0
    catalogueCustomRows.map((itemsRowsabs, keya) => {
      itemsRowsabs &&
        itemsRowsabs.length > 0 &&
        itemsRowsabs.map((sizeObj, index) => {
          totalSumqw =
            totalSumqw + Number(sizeObj?.quantity && sizeObj?.quantity)
        })
    })
    return totalSumqw
  }

  const displayDrop = (parentKeyId) => {
    setIsDrpDeco(true)
  }
  let colorLabelTitle = `Choose product ${colorLabel}`
  if (!!productLayoutSettings?.stepperLayout?.colorSelectionText) {
    colorLabelTitle = productLayoutSettings?.stepperLayout?.colorSelectionText
  }
  const getRowHTML = (itemsRows, key) => {
    let itemRowArray = itemsRows[0]
    let rowTotalQty = '0'
    let rowTotal = 0
    let minIsPriceBreak
    let totalSum = 0
    let getImage = product?.WebstoreProductPartArray.filter(
      (val) => val.color === itemRowArray.color && val.isSwapImage === true,
    )
    const artWorkCount = document.querySelectorAll(`artMain`)

    let artwork_selected = null
    if (itemRowArray?.decorations?.[0]?.artworkID && artworkListById?.length) {
      artwork_selected = artworkListById.filter(
        (artorkArray) =>
          artorkArray.id === itemRowArray.decorations[0].artworkID,
      )[0]
    }
    if (itemRowArray?.decorations?.length >= 1) {
      let decoIndex = itemRowArray?.decorations?.length - 1
      let getColorIndex = product.LogoInfo.filter(
        (val) => val.color === tempSelectedColor,
      )

      let getIndex = []
      if (getColorIndex.length > 1) {
        getIndex = product.LogoInfo.filter(
          (val) =>
            val.color === tempSelectedColor && !val?.primary &&
            (val.location === itemRowArray.decorations[decoIndex].location || val.extra_logo.filter(e => e.location === itemRowArray.decorations[decoIndex].location)),
        )
      } else {
        getIndex = getColorIndex
      }

      if (getIndex.length > 0) {
        if (selectedImage === '') {
          setSelectedImage(getIndex[0].image)
        }
      }
    } else {
      if (getImage.length > 0) {
        if (selectedImage === '') {
          setSelectedImage(getImage[0]?.image)
        }
      } else {
        let getIndex = product.MediaContent.filter(
          (val) => val.color === itemRowArray.color && val.primary,
        )
        if (getIndex.length) {
          if (selectedImage === '') {
            setSelectedImage(getIndex[0].url)
          }
        } else {
          if (selectedImage === '') {
            let secondarygetIndex = product.MediaContent.filter(
              (val) => val.color === itemRowArray.color,
            )
           if(secondarygetIndex.length > 0) {
            setSelectedImage(secondarygetIndex[0]?.url) }
          }
        }
      }
    }

    let addDecoPriceFlag = true
    let totalSetupDecoCharges = 0
    let totalDecoCharges = 0
    let totalSetupCharge = 0
    let setupChargeName = 'Setup Charge'
    let setupCharges = []
    if (isPriceBreak) {
      itemsRows &&
        itemsRows.length > 0 &&
        itemsRows.map((sizeObj, index) => {
          totalSum = totalSum + Number(sizeObj?.quantity && sizeObj?.quantity)
        })
    }
    if (showSetupChargeInCart && catalogueCustomRows?.[0]?.[0]?.quantity) {
      let quantity = 0;
      if(catalogueCustomRows?.[0]?.length > 0) {
        catalogueCustomRows[0].forEach(row => {
          quantity += Number(row.quantity)
        })
      }
      if(product?.ChargeArray?.Charge?.length > 0) {
        product.ChargeArray.Charge.forEach(charge => {
          if(setupCharges.findIndex(x => x.chargeCode === charge.chargeCode) === -1) {
            const chargeByQuantity = charge?.ChargePriceArray?.ChargePrice?.filter(x => Number(x.xMinQty) <= quantity)
            if(chargeByQuantity.length > 0) {
              const totalSetupChargeUnit = (chargeByQuantity?.[chargeByQuantity.length - 1]?.salePrice ?? 0) * (charge?.matchOrderQty === "1" ? quantity : 1);
              if (totalSetupChargeUnit > 0) {
                setupChargeName = charge?.chargeName ?? 'Setup Charge';
                totalSetupCharge = Number(totalSetupCharge) + totalSetupChargeUnit
                toatlPrice = Number(toatlPrice) + Number(totalSetupCharge)
                setupCharges.push({ totalSetupCharge, setupChargeName, chargeCode: charge.chargeCode })
              }
            }
          }
        })
      }
    }
    if(getSettingStatus('showDecoration')) {
    itemRowArray.decorations &&
      itemRowArray.decorations
        .filter((de) => de?.artworkID && de?.location && de?.locationId)
        .map((decoArray, index) => {
          let setupChargeSalePrice = 0;
          let salePrice = 0;
          if (isPriceBreak) {
            let decoChargeArray = getDecoChargeArraybyqty([decoArray], totalSum);
            setupChargeSalePrice = Number(decoChargeArray?.[0]?.setupChargeSalePrice || 0);
            salePrice = Number(decoChargeArray?.[0]?.salePrice || 0) * totalSum;
          } else {
            itemsRows.forEach((res, key) => {
              const dtmainArrr = getDecoChargeArraybyqty([decoArray], res.quantity);
              if (key === 0) {
                setupChargeSalePrice = Number(dtmainArrr?.[0]?.setupChargeSalePrice || 0);
              }
              salePrice += Number(dtmainArrr?.[0]?.salePrice || 0) * res.quantity;
            });
          }

          let artwork = null
          if (artworkListById?.length) {
            artwork = artworkListById.find(
              (artworkArray) =>
                artworkArray.id === decoArray.artworkID &&
                artworkArray.artworkLocation === decoArray.location,
            )
            if(!!!artwork && artworksProductState?.length) {
              artwork = artworksProductState.find((artworkArray) =>
                artworkArray?.productToDecorationMapping?.some(
                  (res) => res.locationName === decoArray.location && artworkArray.id === decoArray.artworkID
                )
              );
            }
          }
          const additionalCharges = []
          let totalAdditionalCharges = 0

          if(artwork) {
            let totalItem = 0
            itemsRows && itemsRows.length > 0 && itemsRows.map((sizeObj, index)=>{
              totalItem = totalItem + Number(sizeObj?.quantity && sizeObj?.quantity)
            })
            const additionalChargeData = getAdditionalDecorationCharge(
              artwork || {},
              totalItem,
              decoArray.location,
              product?.id
            );
            totalAdditionalCharges += additionalChargeData.totalAdditionalCharges;
            additionalCharges.push(...additionalChargeData.additionalCharges);
          }



          let itemsQty = 0
          itemsRows &&
            itemsRows.length > 0 &&
            itemsRows.map((sizeObj, index) => {
              itemsQty =
                itemsQty + Number(sizeObj?.quantity ? sizeObj?.quantity : 0)
            })
          if (artwork?.artworkSource == 'aether' && ((getSettingStatus('showDecoration') && customDecorationFee && allowCustomDecoration) || (showSetupChargeInCart && !getSettingStatus('showDecoration')))) {
            if(showSetupChargeInCart) {
              toatlPrice = Number(toatlPrice) + Number(customDecorationSetupFee)
              totalSetupDecoCharges = totalSetupDecoCharges + Number(customDecorationSetupFee)
            }
            totalDecoCharges =
              totalDecoCharges + Number(customDecorationPerUnitPrice) * Number(itemsQty)
           if(showDecorationCostInCart) {
            toatlPrice =
              Number(toatlPrice) +
              Number(customDecorationPerUnitPrice) * Number(itemsQty)
           }
          } else {
            if(showSetupChargeInCart) {
              additionalCharges.forEach(additionalCharge => {
                if (additionalCharge?.totalSetupCharge) {
                  setupCharges.push(additionalCharge)
                }
              })
              totalSetupCharge = totalSetupCharge + totalAdditionalCharges
              toatlPrice =
                Number(toatlPrice) +
                Number(setupChargeSalePrice) +
                Number(totalAdditionalCharges)

              totalSetupDecoCharges =
                Number(totalSetupDecoCharges) +
                Number(setupChargeSalePrice)
            }
            totalDecoCharges =
              Number(totalDecoCharges) +
              Number(salePrice)
         if(showDecorationCostInCart) {
            toatlPrice =
              Number(toatlPrice) +
              Number(salePrice)
          }
          }
        })
      }
    let cnt = 1
    let displayPriceLabel = ''
    let displayDecorationLabel = ''
    if (showPrice !== false || (showSize !== false && (product?.productOrderType == '2' || product?.productOrderType == '1'))) {
      cnt++
    }
    if(showPrice !== false && showSize === false) {
      displayPriceLabel = "Product Price";
    } else if(showPrice === false && (showSize !== false && (product?.productOrderType == '2' || product?.productOrderType == '1'))) {
      displayPriceLabel = `Product ${sizeLabel}`;
    } else {
      displayPriceLabel = `Product Price and Product ${sizeLabel}`;
    }
    if (!!productLayoutSettings?.stepperLayout?.sizePriceSelectionText) {
      displayPriceLabel = productLayoutSettings?.stepperLayout?.sizePriceSelectionText
    }
    if(getSettingStatus('showDecoration') && !getSettingStatus('showPersonalization')) {
      displayDecorationLabel = 'Choose Decoration'
    } else if(!getSettingStatus('showDecoration') && getSettingStatus('showPersonalization')) {
      displayDecorationLabel = `Choose ${ !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
      ? productSettings['showPersonalizationLabel']
      : 'Personalization' }`
    } else {
      displayDecorationLabel = `Choose Decoration and ${ !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
      ? productSettings['showPersonalizationLabel']
      : 'Personalization' }`
    }
    if (!!productLayoutSettings?.stepperLayout?.decoPerSectionText) {
      displayDecorationLabel = productLayoutSettings?.stepperLayout?.decoPerSectionText
    }
    let quantityLabel = 'CHOOSE QUANTITY'
    if (!!productLayoutSettings?.stepperLayout?.quantitySelectionText) {
      quantityLabel = productLayoutSettings?.stepperLayout?.quantitySelectionText
    }
    return (
      <div key={key}>
        {showPrice !== false || (showSize !== false && (product?.productOrderType == '2' || product?.productOrderType == '1')) ? (
          <Grid item xs={12}>
            <h3 className="HaedH3">{cnt}. {displayPriceLabel}</h3>
            <Grid item xs={12}>
              {(product?.productOrderType == '2' || product?.productOrderType == '1') ? (
                <><div className="product-details-content quickview-content">
                {(product && priceTables.length > 0 && !!product?.displayPriceGrid) &&
                  <PriceTable
                    priceTable={priceTables}
                    product={product}
                    currency={currency}
                    decimalsToShow={decimalsToShow}
                    doNotCalculateAtOrder={product?.doNotCalculateAtOrder}
                    userGroupDiscount={userGroupDiscount}
                    showSellPrice={showSellPrice}
                    showSell={showSell}
                    offerPrice={offerPrice}
                    discountType={discountType}
                    layout='condense'
                  />}
               </div>
                <div className="row sizediv">
                  <div className="col-lg sizeContainer">
                    {product?.productOrderType == '2' ? (
                      <label className="inventorylabel" title="Quantity">
                        Quantity
                      </label>
                    ) : (
                      ''
                    )}
                    {product?.productOrderType == '1' && showSize === true ? (
                      <label className="inventorylabel" title={sizeLabel}>
                        {sizeLabel}
                      </label>
                    ) : (
                      ''
                    )}
                    {showPrice !== false && (
                      <label className="bggray" title="Price">
                        Price
                      </label>
                    )}
                  </div>
                  {priceTable.map((pricerow, keyP) => {
                    let rQty = ''
                    let minQuantitydis = '0'
                    let rPrice = '0'
                    let discountPrice = 0;
                    let decosalePrice = '0'
                    let isActive = true
                    let priceRowMoq = []
                    let selRowmain = itemsRows.filter(
                      (row) => row.size === pricerow.size,
                    )
                    let selpriceRowmain = itemsRows.slice(-1)

                    if (pricerow?.priceOptions?.length) {
                      pricerow.priceOptions.map((priceoption) => {
                        let defaultColor =
                          showColor === false
                            ? pricerow?.priceOptions[0]?.ColorArray?.Color
                                ?.colorName
                            : itemRowArray.color
                        if (
                          !itemRowArray.color ||
                          itemRowArray.color === `Select ${colorLabel}`
                        ) {
                          defaultColor =
                            pricerow?.priceOptions[0]?.ColorArray?.Color
                              ?.colorName
                        }
                        if (
                          checkPriceOptionsArray(
                            priceoption,
                            defaultColor,
                            pricerow.size,
                          )
                        ) {
                          if (
                            priceoption.active ||
                            priceoption.active === false ||
                            priceoption.active === 'false'
                          ) {
                            if (
                              priceoption.active === false ||
                              priceoption.active === 'false'
                            ) {
                              isActive = false
                            }
                          }
                          if (selRowmain?.length > 0) {
                            rQty = selRowmain[0].quantity
                            /*rowTotalQty = (
                              Number(rowTotalQty) +
                              Number(selRowmain[0].quantity)
                            ).toString()*/
                          }
                          toatlQty = Number(toatlQty) + Number(rQty)
                          if (isPriceBreak) {
                            priceoption.partPrice.PartPriceArray.PartPrice.map(
                              (partPrice, k) => {
                                if (
                                  Number(partPrice.minQuantity) <= checkTaotalQty()
                                ) {
                                  rPrice = partPrice.salePrice
                                  minQuantitydis = partPrice.minQuantity
                                  minIsPriceBreak = partPrice.minQuantity
                                } else if (k === 0) {
                                  rPrice = partPrice.salePrice
                                  minQuantitydis = partPrice.minQuantity
                                  minIsPriceBreak = partPrice.minQuantity
                                }
                              },
                            )
                          } else {
                            priceoption.partPrice.PartPriceArray.PartPrice.map(
                              (partPrice, k) => {
                                if (
                                  Number(partPrice.minQuantity) <= checkTaotalQty()
                                ) {
                                  rPrice = partPrice.salePrice
                                  minIsPriceBreak = partPrice.minQuantity
                                } else if (k === 0) {
                                  minIsPriceBreak = partPrice.minQuantity
                                  rPrice = partPrice.salePrice
                                }
                              },
                            )
                          }
                          if (addDecoPriceFlag && (Number(rQty) > 0 || (isPriceBreak && Number(totalSum) > 0))) {
                            //addDecoPriceFlag = false;
                           if (artwork_selected?.artworkSource == 'aether' && getSettingStatus("showDecoration") && customDecorationFee && allowCustomDecoration) {
                              itemRowArray && itemRowArray.decorations && itemRowArray.decorations.map((a) => {
                                decosalePrice = decosalePrice + Number(customDecorationPerUnitPrice);
                              });
                           } else {
                            decosalePrice = getDecoPricebyqty(
                                itemRowArray?.decorations,
                                isPriceBreak ? totalSum : rQty,
                            )
                           }
                          } else if (addDecoPriceFlag && Number(rQty) === 0) {
                           if (artwork_selected?.artworkSource == 'aether' && getSettingStatus("showDecoration") && customDecorationFee && allowCustomDecoration) {
                              itemRowArray && itemRowArray.decorations && itemRowArray.decorations.map((a) => {
                                  decosalePrice = decosalePrice + Number(customDecorationPerUnitPrice);
                              });
                           } else {
                            decosalePrice = getDecoPricebyqty(
                                itemRowArray?.decorations,
                                1,
                            )
                           }
                          }

                          let mainDecorationCost = 0
                          let mainPersonalizationCost = 0
                          let mainDecoCost = 0
                          if (!showDecorationCostInCart && getSettingStatus('showDecoration')) {
                            rPrice = Number(rPrice) + Number(decosalePrice)
                            if (product?.doNotCalculateAtOrder == "1" && isRestrictQty) {
                              mainDecorationCost = Number(decosalePrice) * Number(rQty)
                              mainDecoCost =  Number(decosalePrice)
                            }
                          }

                          if (Number(userGroupDiscount > 0) && Number(rPrice) > 0) {
                            rPrice =
                              Number(rPrice) -
                              (Number(rPrice) * Number(userGroupDiscount)) / 100
                            if (Number(rPrice) < 0) {
                              rPrice = 0
                            }
                          }
                          let personalizationCost2 = 0
                          if (
                            getSettingStatus('showPersonalization') &&
                            !showPersonalizationCostInCart &&
                            !!itemRowArray.personalization &&
                            !!personalizationCost?.price
                          ) {
                            personalizationCost2 = Number(personalizationCost.price).toFixed(decimalsToShow)
                            rPrice = Number(rPrice) + Number(personalizationCost2)
                            if (personalizationCost.matchOrderQty == '1' && !getSettingStatus('showPersonalizationCostInCart') && product?.doNotCalculateAtOrder == "1" && isRestrictQty) {
                              mainPersonalizationCost = Number(personalizationCost2) * Number(rQty)
                            }
                          }

                          rWithoutDiscPrice = rPrice
                          if (discountType === '1' && showSell && Number(rPrice) > 0) {
                            if (offerPrice > 0) {
                              discountPrice =  Number(offerPrice)
                              rPrice = Math.max(0,Number(rPrice) - Number(offerPrice));
                              if(!showSellPrice) {
                                rWithoutDiscPrice = Math.max(0,Number(rWithoutDiscPrice) - Number(offerPrice));
                              }
                            }
                          } else if (discountType === '0' && showSell && Number(rPrice) > 0) {
                            if (offerPrice > 0) {
                              discountPrice =  Number((Number(rPrice) * Number(offerPrice)) / 100)
                              rPrice =
                                Number(rPrice) -
                                (Number(rPrice) * Number(offerPrice)) / 100
                              if (Number(rPrice) < 0) {
                                rPrice = 0
                              }
                              if(!showSellPrice) {
                                rWithoutDiscPrice = Math.max(0,Number(rWithoutDiscPrice) - (Number(offerPrice) * Number(offerPrice) / 100));
                              }
                            }
                          }
                          let calPriceIsPrice = Number(rPrice) * Number(rQty)
                          if (product?.doNotCalculateAtOrder == "1" && Number(rQty) > 0 && product?.isRestrictQty) {
                            calPriceIsPrice = Number(rPrice) + (Number(mainPersonalizationCost) - Number(personalizationCost2)) + (Number(mainDecorationCost) - Number(mainDecoCost))
                          }
                          if (selRowmain.length > 0 && isPriceBreak) {
                            rowTotal =
                              Number(rowTotal) +
                              Number(
                                Number(rPrice) +
                                  (!showDecorationCostInCart
                                    ? Number(decosalePrice)
                                    : 0),
                              ) *
                                Number(rQty)
                            toatlPrice =
                              Number(toatlPrice) + calPriceIsPrice
                          } else {
                            rowTotal =
                              Number(rowTotal) + calPriceIsPrice
                            toatlPrice =
                              Number(toatlPrice) + calPriceIsPrice
                          }
                        }
                      })
                    }

                    let color = itemRowArray.color
                    if (!showColor && colors_final.length === 1) {
                      color = colors_final[0]
                    }
                    let inventoryValue = getStock(pricerow.size, color)
                    let inventoryFlag = false
                    if (
                      isProductInventoryEnabled &&
                      !IsAllowOutOfStockOrder &&
                      inventoryValue === 0
                    ) {
                      inventoryFlag = true
                    }
                    totalStock += Number(inventoryValue)
                    let setSelectedColor = pricerow.priceOptions.filter(item => item.ColorArray.Color.colorName == itemRowArray.color )

                    if (isActive) {
                      if(isPriceBreak && priceRowMoq.length > 0 && product?.productOrderType == 2) {
                       return priceRowMoq.map((resData) => (
                            <div className={`col-lg sizeContainer ${itemRowArray?.color ? ((itemRowArray?.color !== `Select ${colorLabel}`) ? (setSelectedColor[0] ? 'active' : 'deactive') : "") : ""}`} key={keyP}>
                              <label className="inventorylabel" title="Quantity">
                                {' '}
                                {resData.minQuantity}{' '}
                              </label>
                              {showPrice !== false && (
                                <>
                                  <label
                                    className={`${
                                      showSellPrice ? 'breakable-price' : ''
                                    } bggray`}
                                    title="Price"
                                  >
                                    {
                                      usePointsAsCurrency ? getPointsValue(((!showDecorationCostInCart && rQty > 0) ? Number(resData.salePrice) + Number(decosalePrice) : resData.salePrice)) : <>
                                        {currency.currencySymbol}
                                        {numberWithCommas2(
                                            ((!showDecorationCostInCart && rQty > 0) ? Number(resData.salePrice) + Number(decosalePrice) : resData.salePrice),
                                            decimalsToShow
                                        )}
                                      </>
                                    }
                                  </label>
                                  {showSellPrice && (
                                    <label className="bggray" title="Price">
                                      {
                                        usePointsAsCurrency ? getPointsValue(selpriceRowmain[0]?.price && isPriceBreak && totalSum >= minIsPriceBreak ?
                                            rPrice : Math.max(0,Number(rWithoutDiscPrice - discountPrice))) : <>
                                            {currency.currencySymbol}
                                            {numberWithCommas2(
                                                selpriceRowmain[0]?.price &&
                                                isPriceBreak &&
                                                totalSum >= minIsPriceBreak
                                                    ? rPrice
                                                    : Math.max(0,Number(rWithoutDiscPrice - discountPrice)),
                                                decimalsToShow
                                            )}
                                        </>
                                      }
                                    </label>
                                  )}
                                </>
                              )}
                            </div>
                          ) 
                        )
                      } else {
                      return (
                        <div className={`col-lg sizeContainer ${itemRowArray?.color ? ((itemRowArray?.color !== `Select ${colorLabel}`) ? (setSelectedColor[0] ? 'active' : 'deactive') : "") : ""}`} key={keyP}>
                          {product?.productOrderType == '2' ? (
                            <label className="inventorylabel" title="Quantity">
                              {' '}
                              {minQuantitydis}{' '}
                            </label>
                          ) : (
                            ''
                          )}
                          {product?.productOrderType == '1' && showSize === true ? (
                            <label className='sizelabelwrap' title={sizeLabel}>
                              {inventoryFlag && showSize && <div className="line"></div>}
                              <b>
                                {' '}
                                {pricerow.size !== 'OSFA'
                                  ? pricerow.size
                                  : ''}{' '}
                              </b>
                            </label>
                          ) : (
                            ''
                          )}
                          {showPrice !== false && (
                            <>
                              <label
                                className={`${
                                  showSellPrice ? 'breakable-price' : ''
                                } bggray`}
                                title="Price"
                              >
                                {
                                  usePointsAsCurrency ?
                                      getPointsValue(selpriceRowmain[0]?.price && isPriceBreak && totalSum >= minIsPriceBreak
                                          ? (showSellPrice ? rWithoutDiscPrice : rPrice) : rWithoutDiscPrice) :
                                      <>
                                        {currency.currencySymbol}
                                        {numberWithCommas2(
                                            selpriceRowmain[0]?.price &&
                                            isPriceBreak &&
                                            totalSum >= minIsPriceBreak
                                                ? (showSellPrice ? rWithoutDiscPrice : rPrice)
                                                : rWithoutDiscPrice,
                                            decimalsToShow
                                        )}
                                      </>
                                }
                              </label>
                              {showSellPrice && (
                                <label className="bggray" title="Price">
                                  {
                                    usePointsAsCurrency ? getPointsValue(  selpriceRowmain[0]?.price && isPriceBreak && totalSum >= minIsPriceBreak
                                            ? rPrice : Math.max(0,Number(rWithoutDiscPrice - discountPrice))) :
                                        <>
                                          {currency.currencySymbol}
                                          {numberWithCommas2(
                                              selpriceRowmain[0]?.price &&
                                              isPriceBreak &&
                                              totalSum >= minIsPriceBreak
                                                  ? rPrice
                                                  : Math.max(0,Number(rWithoutDiscPrice - discountPrice)),
                                              decimalsToShow
                                          )}
                                        </>
                                  }
                                </label>
                              )}
                            </>
                          )}
                        </div>
                      ) 
                     }
                    }
                  })}
                </div>
                </>
              ) : (
              <>
               {showPrice !== false ?
                  <>
                    <div className="product-details-content quickview-content">
                      {(product && priceTables.length > 0 && !!product?.displayPriceGrid) &&
                        <PriceTable
                          priceTable={priceTables}
                          product={product}
                          currency={currency}
                          decimalsToShow={decimalsToShow}
                          doNotCalculateAtOrder={product?.doNotCalculateAtOrder}
                          userGroupDiscount={userGroupDiscount}
                          showSellPrice={showSellPrice}
                          showSell={showSell}
                          offerPrice={offerPrice}
                          discountType={discountType}
                          layout='condense'
                        />}
                    </div>
                <div className="row sizediv product-details-price">
                  <h4>
                    <b>Price</b>
                  </h4>
                  {discountedPrice !== null ? (
                      <>
                        {
                          usePointsAsCurrency? <span>{getPointsValue(showSellPrice ? finalProductDiscPrice : finalProductPrice)}</span> : <>
                            <span>
                              {showCurrencycodeSymbol(currency) +
                                  Number(finalDiscountedPrice)}
                            </span>{' '}
                                  <span className="old">
                              {showCurrencycodeSymbol(currency) +
                                  Number(
                                    showSellPrice 
                                      ? finalProductDiscPrice
                                      : finalProductPrice
                                  ).toFixed(decimalsToShow)}
                            </span>
                          </>
                        }
                      </>
                  ) : (
                    <>
                      <span
                        className={`${
                          showSellPrice ? 'breakable-price' : ''
                        } mr-10 ml-15`}
                      >
                        {
                          usePointsAsCurrency ? getPointsValue(showSellPrice ? finalProductPrice : finalProductDiscPrice) : <>
                            {showCurrencycodeSymbol(currency) +
                                Number(
                                  showSellPrice 
                                    ? finalProductPrice
                                    : finalProductDiscPrice
                                ).toFixed(decimalsToShow)}{' '}
                          </>
                        }
                      </span>
                      <span>
                         {
                             showSellPrice ?
                                 <>
                                   {
                                     usePointsAsCurrency ? getPointsValue(finalProductDiscPrice) :   showCurrencycodeSymbol(currency) +
                                         Number(finalProductDiscPrice).toFixed(decimalsToShow)
                                   }
                                 </> : ''

                         }

                      </span>
                    </>
                  )}
                </div>
                </>
                : ''}
              </>
              )}
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={12}>
          <h3 className={`HaedH3 ${!isProductInventoryEnabled ? 'no-margin-head' : ''}`}>{(cnt = cnt + 1)}. {quantityLabel}</h3>
          <Grid item xs={12}>
            <div className={`${!isProductInventoryEnabled ? 'no-margin' : ''} row sizediv`}>
              <div className="col-lg sizeContainer">
                {isProductInventoryEnabled && isColorValid(itemRowArray?.color) && (
                  <label title="Inventory" className="inventorylabel bggray">
                    {!isSmall || !isMedium ? (
                      <>
                        {inventorySettings?.['inventoryLabel']
                          ? inventorySettings['inventoryLabel']
                          : 'Available'}
                      </>
                    ) : (
                      '-'
                    )}
                  </label>
                )}
                <label title="Quantity" className="inventorylabel">
                  Quantity
                </label>
              </div>
              {priceTable.map((pricerow, keyP) => {
                let rQty = ''
                let isActive = true
                let selRowmain = itemsRows.filter(
                  (row) => row.size === pricerow.size,
                )

                if (pricerow?.priceOptions?.length) {
                  pricerow.priceOptions.map((priceoption) => {
                    let defaultColor =
                      showColor === false
                        ? pricerow?.priceOptions[0]?.ColorArray?.Color
                            ?.colorName
                        : itemRowArray.color
                    if (
                      !itemRowArray.color ||
                      itemRowArray.color === `Select ${colorLabel}`
                    ) {
                      defaultColor =
                        pricerow?.priceOptions[0]?.ColorArray?.Color?.colorName
                    }
                    if (
                      checkPriceOptionsArray(
                        priceoption,
                        defaultColor,
                        pricerow.size,
                      )
                    ) {
                      if (
                        priceoption.active ||
                        priceoption.active === false ||
                        priceoption.active === 'false'
                      ) {
                        if (
                          priceoption.active === false ||
                          priceoption.active === 'false'
                        ) {
                          isActive = false
                        }
                      }
                      if (selRowmain?.length > 0) {
                        rQty = selRowmain[0].quantity
                        rowTotalQty = (
                          Number(rowTotalQty) +
                          Number(selRowmain[0].quantity)
                        ).toString()
                      }
                    }
                  })
                }

                let color = itemRowArray.color
                if (!showColor && colors_final.length === 1) {
                  color = colors_final[0]
                }
                let inventoryValue = getStock(pricerow.size, color)
                let inventoryFlag = false
                if (
                  isProductInventoryEnabled &&
                  !IsAllowOutOfStockOrder &&
                  inventoryValue === 0
                ) {
                  inventoryFlag = true
                }
                totalStock += Number(inventoryValue)

                let setSelectedColor = pricerow.priceOptions.filter(item => item.ColorArray.Color.colorName == itemRowArray.color )

                if (isActive)
                  return (
                    <div className={`col-lg sizeContainer ${itemRowArray?.color ? ((itemRowArray?.color !== `Select ${colorLabel}`) ? (setSelectedColor[0] ? 'active' : 'deactive') : "") : ""}`} key={keyP}>
                      {isProductInventoryEnabled && isColorValid(itemRowArray?.color) && (
                        <label
                          title="Inventory"
                          className="inventorylabel bggray"
                        >
                          {WithCommas(inventoryValue)}
                        </label>
                      )}
                      <label className="inventorylabel inputQty">
                        <input
                          className="quantityIn"
                          type="text"
                          placeholder="0"
                          onClick={(e) => changeQty(e, key, pricerow.size)}
                          onChange={(e) => changeQty(e, key, pricerow.size)}
                          disabled={inventoryFlag ? true : false}
                          value={rQty ? rQty : ''}
                          name="quantity"
                        />
                        <span className="inputQtyTitle StepperTitle">{`${(!!itemRowArray?.color && itemRowArray?.color !== `Select ${colorLabel}`) && !!showColor ? 'Quantity' : (!!showColor ? `Please select ${colorLabel} first` : 'Quantity')}`}</span>
                      </label>
                    </div>
                  )
              })}
            </div>
            {product?.showColorSizeDesc === true && !!product?.colorSizeDesc && (
              <div className="row colorSize">
                <span>
                  {product?.colorSizeDesc
                    ? parse(product.colorSizeDesc)
                    : ''}
                </span>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <>
              {(isSkuMoq || isMoq) && showMoqNote && (
                <p className="moqNote">{moqNote}</p>
              )}
            </>
          </Grid>
        </Grid>
        {getSettingStatus('showDecoration') ||
        getSettingStatus('showPersonalization') ||
        showPrice !== false ? (
          <Grid item xs={12}>
            <h3 className="HaedH3">
              {(cnt = cnt + 1)}. {displayDecorationLabel}
            </h3>
            <Grid item xs={12} container spacing={3}>
              {isReplace && getSettingStatus('showDecoration') && (
                <>
                  {itemRowArray.color !== '' &&
                    itemRowArray.color !== 'undefined' &&
                    itemRowArray.color !== undefined &&
                    itemRowArray.color !== `Select ${colorLabel}` &&
                    (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        onClick={() => ToggleDivs(key, 'D', 'click')}
                      >
                      {(getSettingStatus('showPersonalization') || isDrpDeco === true || (!!artWorkCount && artWorkCount.length > 0 && getSettingStatus('showPersonalization') && isDrpDeco === false) || (!!artWorkCount && artWorkCount.length > 1 && !getSettingStatus('showPersonalization') && isDrpDeco === false)) && 
                        <i
                          className={
                            getDisplayStyle(key, 'D') === 'none'
                              ? 'fa fa-chevron-right pr-1'
                              : 'fa fa-chevron-down pr-1'
                          }
                          aria-hidden="true"
                        ></i>
                      }
                        <label className="toggleLabel">
                          {productSettings?.['showDecorationLable']
                            ? productSettings?.['showDecorationLable']
                            : 'Customize This Item'}
                        </label>
                      </Grid>
                    )}
                </>
              )}
              {getSettingStatus('showPersonalization') && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  onClick={() => {
                    addPersonalization(key)
                    setPersonalizationAdded(true)
                    ToggleDivs(key, 'P')
                  }}
                >
                  <i
                    className={
                      getDisplayStyle(key, 'P') === 'none'
                        ? 'fa fa-chevron-right pr-1'
                        : 'fa fa-chevron-down pr-1'
                    }
                    aria-hidden="true"
                  ></i>
                  <label className="toggleLabel">
                  { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' }
                  </label>
                </Grid>
              )}
            </Grid>
            <Grid>
              {showPrice !== false && rowTotalQty > 0 && (
                <div className="row">
                  <div className="col-md pr-0 mb-1">
                    <div className="chargeTable float-right mr-4">
                      <table>
                        <tbody>
                          {showSetupChargeInCart && Number(totalSetupCharge) > 0 && setupCharges?.length > 0 && setupCharges.map(charge => {
                            return (
                            <>
                              <tr>
                                <td className="deco_per_stup_currency">
                                  {charge.setupChargeName}
                                </td>
                                <td>
                                  <div className="deco_per_stup">
                                    {
                                      usePointsAsCurrency ? getPointsFromValue(charge.totalSetupCharge) : <>
                                        <div className="">{ currency.currencySymbol }</div>
                                        <div>
                                          { Number(charge.totalSetupCharge).toFixed(decimalsToShow) }
                                        </div>
                                      </>
                                    }
                                  </div>
                                </td>
                              </tr>
                            </>
                            )
                          })}
                          {showSetupChargeInCart &&
                            Number(totalSetupDecoCharges) > 0 && (
                              <>
                                <tr>
                                  <td className="deco_per_stup_currency">
                                    Setup Decoration Charge
                                  </td>
                                  <td>
                                    <div className="deco_per_stup">
                                      {
                                        usePointsAsCurrency ? getPointsFromValue(totalSetupDecoCharges) : <>
                                          <div className="">{currency.currencySymbol}</div>
                                          <div>
                                            {Number(totalSetupDecoCharges).toFixed(decimalsToShow)}
                                          </div>
                                        </>
                                      }
                                    </div>
                                  </td>
                                </tr>
                              </>
                            )}

                          {showDecorationCostInCart && totalDecoCharges > 0 && (
                            <tr>
                              <td className="deco_per_stup_currency">
                                Decoration Charge
                              </td>
                              <td>
                                <div className="deco_per_stup">
                                  {
                                    usePointsAsCurrency ? getPointsFromValue(Number(totalDecoCharges)) : <>
                                      <div className="">{ currency.currencySymbol }</div>
                                      <div>
                                        { rowTotalQty > 0
                                            ? (
                                                Number(totalDecoCharges)
                                            ).toFixed(decimalsToShow)
                                            : 0 }
                                      </div>
                                    </>
                                  }
                                </div>
                              </td>
                            </tr>
                          )}

                          {showSetupChargeInCart && getSettingStatus('showPersonalization') &&
                            !!itemRowArray.personalization &&
                            personalizationCost.setupPrice > 0 && (
                              <tr>
                                <td className="deco_per_stup_currency">
                                { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' } setup Charge
                                </td>
                                <td>
                                  <div className="deco_per_stup">
                                    {
                                      usePointsAsCurrency ? getPointsFromValue(personalizationCost.setupPrice) : <>
                                        <div className="">{ currency.currencySymbol }</div>
                                        <div>
                                          { rowTotalQty > 0
                                              ?
                                              Number(personalizationCost.setupPrice)
                                                  .toFixed(decimalsToShow) : 0 }
        
                                        </div>
                                      </>
                                    }
                                  </div>
                                </td>
                              </tr>
                            )}
                          {getSettingStatus('showPersonalization') &&
                            showPersonalizationCostInCart &&
                            !!itemRowArray.personalization &&
                            personalizationCost.price > 0 && (
                              <tr>
                                <td className="deco_per_stup_currency">
                                { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' } Charge
                                </td>
                                <td>
                                  <div className="deco_per_stup">
                                    {
                                      usePointsAsCurrency ? getPointsFromValue(Number(personalizationCost.price) * rowTotalQty) : <>
                                        <div className="">{ currency.currencySymbol }</div>
                                        <div>
                                          { rowTotalQty > 0
                                              ? personalizationCost.matchOrderQty == 1 ? (
                                                  Number(personalizationCost.price) * rowTotalQty
                                              ).toFixed(decimalsToShow) : (
                                                  Number(personalizationCost.price)
                                              ).toFixed(decimalsToShow)
                                              : 0 }
                                        </div>
                                      </>
                                    }
                                  </div>
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
              {isReplace && getSettingStatus('showDecoration') && (
                <div className="row decoBlock pl-2" style={{margin : '0', paddingBottom : '20px'}}
                >
                  <div className="col-md-12" style={{ display: (!!itemRowArray?.decorations && itemRowArray.decorations.length > 0) ? 'flex' : 'none' }}>
                    <div className="row pl-2">
                      {itemRowArray?.decorations?.map((decoitem, index) => {
                        let artworkSelected = null
                        if (decoitem.artworkID && artworksToRead?.length) {
                          artworkSelected = artworksToRead.filter(
                            (artorkArray) =>
                              artorkArray.id === decoitem.artworkID,
                          )[0]
                        }
                        let variations = null
                        if (!artworkSelected) {
                          return ''
                        } else {
                          variations = artworkSelected.design.variation.filter(
                            (variationArray) =>
                              variationArray.design_variation_unique_id ==
                              decoitem.variationUniqID,
                          )

                          let getColorIndex = product.LogoInfo.filter(
                            (val) => val.color === tempSelectedColor,
                          )

                          let getIndex = []
                          if (getColorIndex > 1) {
                            getIndex = product.LogoInfo.filter(
                              (val) =>
                                val.color === tempSelectedColor &&
                                val.location === decoitem.location,
                            )
                          } else {
                            getIndex = getColorIndex
                          }

                          let label = decoTypeList.filter(
                            (val) =>
                              val.code === artworkSelected?.design?.designType,
                          )

                          if (variations)
                            return variations.map((vari, indexi) => {
                              return (
                                <div
                                  className="decorows inlinefexDecorationDiv"
                                  key={'idx' + indexi}
                                  style={{ cursor: 'pointer' }}
                                  onClick={async (e) => {
                                    setSwipeimageFlag(false)
                                    setTempSelectedColor(itemRowArray.color)
                                    getIndex = product.LogoInfo.filter(
                                      (val) =>
                                        val.color === itemRowArray.color &&
                                        val.location === decoitem.location,
                                    )
                                    setSelectedLocation(decoitem.locationId)
                                    setSelectedDecoration(decoitem)
                                    setSingleSelectedDecorations(decoitem)

                                    if (getIndex.length > 0) {
                                      setSwipeimageFlag(true)
                                      setSelectedImage(
                                        getIndex.length > 0
                                          ? getIndex[0].image
                                          : [],
                                      )
                                      animateScroll.scrollToTop()
                                    } else {
                                      getIndex = product.LogoInfo.filter(
                                        (t) =>
                                          t.color === itemRowArray.color &&
                                          t.extra_logo.filter(
                                            (y) =>
                                              y.location === decoitem.location,
                                          ).length > 0,
                                      )
                                      if (getIndex.length > 0) {
                                        setSelectedImage(getIndex[0]?.image)
                                        setSwipeimageFlag(true)
                                      }
                                    }
                                  }}
                                >
                                  <img
                                    alt=""
                                    className="default-img1 pointer"
                                    style={{
                                      maxHeight: '60px',
                                      maxWidth: '60px',
                                    }}
                                    src={
                                      vari?.itemImageThumbnail?.[0] !== ''
                                        ? productImageUrl(
                                            vari.itemImageThumbnail,
                                          )
                                        : productImageUrl(PlaceHolder)
                                    }
                                  />
                                  <div>
                                    <div className="col-md-12">
                                      {!!!hideDecorationName &&
                                        <p>
                                          <b>{artworkSelected?.identity}</b>
                                        </p>
                                      }
                                      {!!!hideLocationName && <p>{decoitem.location}</p>}
                                      {(!decoitem?.isRequiredForStore || decoitem?.isRequiredForStore == "0") &&
                                        <DeleteIcon
                                          className="float-right"
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            showPopupText(key, itemsRows, index)
                                            if (
                                              itemRowArray?.decorations &&
                                              itemRowArray?.decorations
                                                ?.length > 0
                                            ) {
                                              decoitem =
                                                itemRowArray.decorations[0]
                                            }
                                          }}
                                        />
                                      }
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                        }
                      })}
                    </div>
                  </div>
                 {getDisplayStyle(key, 'D') !== 'none' &&
                  <div className="col-md-12">
                    {getSettingStatus('showDecoration') &&
                      getDisplayStyle(key, 'D') !== 'none' &&
                      !notCallDecoHtml &&
                      getDecoHtml(key, itemsRows)}
                      <p className={"ml-3"}>{ loadingArtworks ? 'Loading ...' : ''}</p>
                  </div>
                 }
                </div>
              )}
              {personalizationAdded && (
                <div
                  className="col-md-12"
                  style={{ display: getDisplayStyle(key, 'P') }}
                >
                  <div
                    className="row ml-2"
                    style={{ borderBottom: '1px solid lightgray' }}
                  >
                    <div className="col-md-2">
                      <h3>{ !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' }</h3>
                    </div>
                    {!personalizationRequired && (                      
                      <div className="col-md">
                        <Button
                          variant="contained"
                          onClick={(e) => {
                            deletePersonalization(key)
                          }}
                          color="primary"
                          className="float-right mr-2"
                        >
                          Delete { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                            ? productSettings['showPersonalizationLabel']
                                            : 'Personalization' }
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="row pl-3 decporow">
                    {!personalizationAdded && (
                      <div className="col-md-12 text-center p-2">
                        <Button
                          variant="contained"
                          onClick={() => {
                            addPersonalization(key)
                            setPersonalizationAdded(true)
                          }}
                        >
                          Add { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' }
                        </Button>
                      </div>
                    )}
                  </div>
                  {personalizationAdded && (
                    <div className="row pl-3 decporow" style={!!personalizationDropDown && !!requiredLinesPersonalization && !!dropDownValues && dropDownValues.length > 0 && dropDownValues.filter((res) => !!res?.isPersonalizationDropDown).length > 0 ? {overflowX: 'unset'} : {}}>
                      <div className="col-md-12 text-center p-2">
                        {getPesonalizationTable(itemsRows, key)}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        {toatlQty > 0 && showPrice !== false ? (
          <Grid item xs={12}>
            <div className="HaedHTotal">
              <div className={'dispnon col-lg-4 col-sm-6 p-1 lineflex'}></div>
              <div className="col-md-2 col-sm-6 p-1 dispnon"></div>
              <div className={'col-lg-3 lineflex'}>
                <div className="p-1">
                  <b>Total Qty: {toatlQty}</b>
                </div>
              </div>
              <div className={'col-lg-3 col-sm-6 pl-3 lineflex'}>
                <div className="p-1"> {totalPriceData()}</div>
              </div>
            </div>
          </Grid>
        ) : (
          ''
        )}
      </div>
    )
  }
  const afterAdd = () => {
    getReviews()
    setReviewExist(true)
  }

  return (
    <div
      className={`product-attribute ${spaceTopClass ? spaceTopClass : ''} ${
        spaceBottomClass ? spaceBottomClass : ''
      }`}
    >
      {(loading || isLoading) && <Loader />}
      <Grid container spacing={3}>
        <div className="product-attribute-head">
          <div className="ProductMainDesc">
            <h4>
              <b>Product Name #</b> {product.productName}
            </h4>
            <h4>
              <b>Item #</b>{' '}
              {itemNumber ? product[itemNumber] : product['productId']}
            </h4>
            {productSettingShowDescription && !!product?.description ? (
              <h4>
                <b>Product Description #</b>{' '}
                <p
                  dangerouslySetInnerHTML={{
                    __html: product.description,
                  }}
                ></p>
              </h4>
            ) : (
              ''
            )}
          </div>
          {productSettings?.['showReviews'] ? (
            <div className="reviewscnt">
              <ReactStars
                size={20}
                count={5}
                value={5}
                color="black"
                activeColor="orange"
                edit={false}
                filledIcon={<i className="fa fa-star" />}
              />
              <span>({reviewCount})</span>
            </div>
          ) : (
            ''
          )}
        </div>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <div className="productImageMain">
            <div className="productImage product-large-image-wrapper">
            {swipeimageFlag && (
                <ProductImageGalleryStepper
                product={product}
                setSelectedColor={setTempSelectedColor}
                selectedColor={tempSelectedColor}
                artworkList={artworkList}
                selectedDecorations={!!(catalogueCustomRows?.[0]?.[0]?.decorations) ? catalogueCustomRows?.[0]?.[0]?.decorations : []}
                artworkListById={artworkListById}
                selectedImage={selectedImage}
                singleSelectedDecorations={singleSelectedDecorations}
                setSelectedImage={setSelectedImage}
                isReplace={isReplace}
                setIsReplace={setIsReplace}
                isStepper={true}
              />
              )}
            </div>
          </div>
          <div className="productDesc">
            {product && showAddionalDescription === true && (
              <Accordion
                square
                expanded={expanded === 'DescriptionPanel'}
                onChange={handleChange('DescriptionPanel')}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>
                    {product && product.descriptionLabel
                      ? product.descriptionLabel
                      : 'Description'}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {product && product.showDescription && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: product.descriptionText,
                      }}
                    ></p>
                  )}
                </AccordionDetails>
              </Accordion>
            )}
            {product && product.showAdditionalInformation && (
              <Accordion
                square
                expanded={expanded === 'SpecificationPanel'}
                onChange={handleChange('SpecificationPanel')}
              >
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <Typography>
                    {product && product.additionallnformationLabel
                      ? product.additionallnformationLabel
                      : ' Additional Information'}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {product && product.showAdditionalInformation && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: product.additionallnformationText,
                      }}
                    ></p>
                  )}
                </AccordionDetails>
              </Accordion>
            )}
            {productSettings && productSettings?.['showReviews'] && (
              <Accordion
                square
                expanded={expanded === 'ReviewsPanel'}
                onChange={handleChange('ReviewsPanel')}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography>Reviews</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className={
                      productSettings?.['showReviews'] && !reviewExist
                        ? 'col-lg-7'
                        : 'col-lg-12'
                    }
                  >
                    <DisplayReview
                      productId={product._id}
                      itemId={product.id}
                      reviews={reviews}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={8}>
          {productSettings?.['allowAddReviews'] ? (
            <Grid item xs={12}>
              <p className="WriteReview">
                <a onClick={() => setOpenReview(true)}>Write a Review</a>
              </p>
            </Grid>
          ) : (
            ''
          )}
          <Grid item xs={12}>
            <h3 className="HaedH3">1. {colorLabelTitle}</h3>
            <Grid item xs={12}>
              <div
                id="sw1"
                className={`produc-main product-small-image-wrapper product-slider-img mt-15 ${product?.MediaContent?.length <= 1 ? 'singleImg' : ''}`}
              >
                {isReplace
                  ? isTrue && (
                      <Swiper {...thumbnailSwiperParams}>
                        {sliderData?.filter(
                          (mediaArray) => (((!mediaArray.hidden))),
                        ).map((single, key) => {
                          let getImage =
                          sliderData.filter(
                              (val) =>
                                val.color === single.color &&
                                val.isSwapImage === true,
                            )
                          let mainCls = 'single-image'
                          if (
                            (getImage &&
                              getImage.length > 0 &&
                              !!getImage[0]?.image &&
                              productImageExt(getImage[0]?.image)) ||
                            (!!single?.url && productImageExt(single.url))
                          ) {
                            mainCls = 'single-image product-img-bg'
                          }
                          return (
                            <div
                              key={key}
                              onClick={() =>
                                getDecoAttach(single.color, single.url, 1)
                              }
                            >
                              <label
                                className={mainCls}
                                style={{ maxHeight: '200px' }}
                                htmlFor={`color_${key}`}
                              >
                                <img
                                  src={
                                    getImage && getImage.length > 0
                                      ? productImageUrl(getImage[0]?.image)
                                      : productImageUrl(single.url)
                                  }
                                  className="img-fluid"
                                  alt=""
                                />
                              </label>
                              {!!single.color && (
                                <span className="atrrmain">
                                  {((colors_final.indexOf(single.color) !== -1 && showColor === true && colors_final.length !== 1) || (showColor === true && colors_final.length === 1 && (!!single?.primary || !single?.primary))) && <>
                                  <input type="radio"
                                    id={`color_${key}`}
                                    checked={
                                      selectedProductMainColor === single.color && ((selectedProductMainImage === (getImage && getImage.length > 0 ? productImageUrl(getImage[0]?.image) : productImageUrl(single.url)) || colors_final.length === 1))
                                        ? true
                                        : false
                                    }
                                    value={single.color}
                                    onChange={() => {
                                      changeColor(single.color, 0, 2, (getImage && getImage.length > 0 ? productImageUrl(getImage[0]?.image) : productImageUrl(single.url)), single.url)
                                    }}
                                  /></>}
                                  {single.color}
                                </span>
                              )}
                            </div>
                          )
                        })}
                      </Swiper>
                    )
                  : isTrue && (
                      <Swiper {...thumbnailSwiperParams}>
                        {sliderData?.filter(
                          (ele, ind) =>
                            ind ===
                            product?.WebstoreProductPartArray.findIndex(
                              (elem) => elem.color === ele.color,
                            ),
                        ).map((single, key) => {
                          let getImage =
                            product?.WebstoreProductPartArray.filter(
                              (val) =>
                                val.color === single.color &&
                                val.isSwapImage === true,
                            )
                          let mediaImage = sliderData?.filter(
                            (mediaArray) =>
                              !mediaArray.hidden &&
                              mediaArray.color === single.color,
                          )
                          let mainCls = 'single-image'
                          if (
                            (getImage &&
                              getImage.length > 0 &&
                              !!getImage[0]?.image &&
                              productImageExt(getImage[0]?.image)) ||
                            (!!single?.image &&
                              productImageExt(single.image)) ||
                            productImageExt(mediaImage[0]?.url)
                          ) {
                            mainCls = 'single-image product-img-bg'
                          }
                          return (
                            <div
                              key={key}
                              onClick={() =>
                                getDecoAttach(single.color, single.image, 1)
                              }
                            >
                              <label
                                className={mainCls}
                                style={{ maxHeight: '200px' }}
                                htmlFor={`color_${key}`}
                              >
                                <img
                                  src={
                                    getImage && getImage.length > 0
                                      ? productImageUrl(getImage[0]?.image)
                                      : single.image
                                      ? productImageUrl(single.image)
                                      : productImageUrl(mediaImage[0]?.url)
                                  }
                                  className="img-fluid"
                                  alt=""
                                />
                              </label>
                              {!!single.color && (
                                <span className="atrrmain">
                                  {((colors_final.indexOf(single.color) !== -1 && showColor === true && colors_final.length !== 1) || (showColor === true && colors_final.length === 1 && !!single?.primary)) && <>
                                  <input type="radio"
                                    id={`color_${key}`}
                                    checked={
                                      selectedProductMainColor === single.color && ((selectedProductMainImage === (getImage && getImage.length > 0 ? productImageUrl(getImage[0]?.image) : productImageUrl(single.url)) || colors_final.length === 1))
                                        ? true
                                        : false
                                    }
                                    value={single.color}
                                    onChange={() => {
                                      changeColor(single.color, 0, 1, (getImage && getImage.length > 0 ? productImageUrl(getImage[0]?.image) : productImageUrl(single.url)))
                                    }}
                                  /></>}
                                  {single.color}
                                </span>
                              )}
                            </div>
                          )
                        })}
                      </Swiper>
                    )}
              </div>
            </Grid>
          </Grid>
          {product &&
            catalogueCustomRows?.length > 0 &&
            catalogueCustomRows.map((quoteItemRow, key) => {
              return getRowHTML(quoteItemRow, key)
            })}
          {showBtn &&
            ((showAddToCartOnProd !== false &&
              getSettingStatus('showAddToCart') !== false) ||
              getSettingStatus('showAddToCart') !== false) && (
              <Grid item xs={12}>
                <div className="cartBtn float-right">
                  {(totalStock > 0 || IsAllowOutOfStockOrder) ? (
                    <a
                      onClick={() => {
                        if (checkMinQty(prodId, toatlQty, isRestrictQty, minAllQty, isMoq, isSkuMoq, minQty, null, product?.doNotCalculateAtOrder)) {
                          if (showColor) {
                            if (
                              !selectedProductMainColor ||
                              selectedProductMainColor === `Select ${colorLabel}`
                            ) {
                              orderAction(`You must select a ${colorLabel}`, 'error')
                              return
                            }
                          }
                          addtoCart()
                        }
                      }}
                    >
                      {' '}
                      Add To Cart{' '}
                    </a>
                  ) : (
                    <button disabled>Out of Stock</button>
                  )}
                </div>
              </Grid>
            )}
          {showAddnewart && (
            <AddnewArtDialogue
              productId={productId}
              open={showAddnewart}
              show={showAddnewart}
              title="Add New Artwork"
              showAddnewartId={showAddnewartId}
              onHide={() => handleCancel()}
              handleSubmit={handleAddnewArt}
              cancel={() => handleCancel()}
              catalogueItem={product}
            />
          )}
          {OpenReview && (
            <AddProductReviewModel
              productId={product._id}
              itemId={product.id}
              show={OpenReview}
              setOpenReview={setOpenReview}
              afterAdd={afterAdd}
              cancel={() => handleCancelReview()}
            />
          )}
          {showDecoPopup && (
            <SharedModal 
              indexPopup={indexPopup}
              mainArrayKeyPopup={mainArrayKeyPopup}
              itemsRowsPopup={itemsRowsPopup}
              popupText={popupText}
              removeDeco={removeDeco}
              setShowDecoPopup={setShowDecoPopup}
              showDecoPopup={showDecoPopup}
            />
          )}
        </Grid>
      </Grid>
    </div>
  )
}

ProductAttributeImageDescription.propTypes = {
  cartItems: PropTypes.array,
  compareItems: PropTypes.array,
  currency: PropTypes.object,
  product: PropTypes.object,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  wishlistItems: PropTypes.array,
}

const mapStateToProps = (state) => {
  return {
    currency: state.currencyData,
    cartItems: state.cartData.cartItems,
    artworkList: state.productData.artworkList,
    artworkListById: state.productData.artworkListById,
    wishlistItems: state.wishlistData,
    compareItems: state.compareData,
    decorationLocations: state.productData.decorationLocations,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addItemsRowsTocart: (item, rows, addToast, isPriceBreak, ipV4, userGroupDiscount) => {
      dispatch(addItemsRowsTocart(item, rows, addToast, isPriceBreak, ipV4, userGroupDiscount))
    },
    fetchDecorationLocations: (data) => dispatch(fetchDecorationLocations(data)),
    fetchProductStock: (data) => dispatch(fetchProductStock(data)),
    fetchArtworksById: (data) => dispatch(fetchArtworksById(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductAttributeImageDescription)
