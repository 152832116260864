import React, { useEffect, useState, useContext } from "react";
import Loader from "./common/Loader";
const EmptyComponent = () => {
    return (
        <>
            <Loader/>
        </>
    )
}

export default EmptyComponent;
