import {TextEditorCustomPdfBtn, TextEditorCustomVideoBtn} from "./TextEditorButtons";

export const TextEditorConfig = {
    zIndex: 0,
    readonly: false,
    activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about'],
    toolbarButtonSize: 'middle',
    theme: 'default',
    enableDragAndDropFileToEditor: true,
    saveModeInCookie: false,
    spellcheck: true,
    editorCssClass: false,
    triggerChangeEvent: true,
    height: 220,
    direction: 'ltr',
    language: 'en',
    debugLanguage: false,
    i18n: 'en',
    tabIndex: -1,
    toolbar: true,
    enter: 'P',
    useSplitMode: false,
    colorPickerDefaultTab: 'background',
    imageDefaultWidth: 100,
    extraButtons: [
        TextEditorCustomVideoBtn,
        TextEditorCustomPdfBtn
    ],
    removeButtons: ['source', 'fullsize', 'about', 'outdent', 'indent', 'video', 'print', 'table', 'superscript', 'subscript', 'file', 'cut', 'selectall', 'paragraph'],
    disablePlugins: ['paste', 'stat'],
    events: {},
    textIcons: false,
    uploader: {
        insertImageAsBase64URI: true
    },
    placeholder: '',
    showXPathInStatusbar: false,
    controls: {
        buttons: [
            {
                name: 'insertDate',
                tooltip: 'Insert current Date',
                exec: (editor) => {
                    editor.s.insertHTML(new Date().toDateString());
                }
            }
        ],
        font: {
            list: {
                'Roboto Medium,Arial,sans-serif': 'Roboto',
                '': 'Default',
                'Helvetica,sans-serif': 'Helvetica',
                'Arial,Helvetica,sans-serif': 'Arial',
                'Georgia,serif': 'Georgia',
                'Impact,Charcoal,sans-serif': 'Impact',
                'Tahoma,Geneva,sans-serif': 'Tahoma',
                "'Times New Roman',Times,serif": 'Times New Roman',
                'Verdana,Geneva,sans-serif': 'Verdana',
                'Poppins, sans-serif': 'Poppins',
                'Abril Fatface, cursive': 'Abril',
                'Cormorant Garamond': 'Cormorant Garamond',
                'Great Vibes, cursive': 'Great Vibes, cursive',
                'Lobster, cursive': 'Lobster, cursive',
                'Josefin Sans, sans-serif': 'Josefin Sans, sans-serif',
                'Arial, sans-serif': 'Arial, sans-serif',
                'Arial Black': 'Arial Black',
                'Arial Narrow': 'Arial Narrow',
                'Arimo, sans-serif': 'Arimo',
                'Courier New': 'Courier',
                'Geometria Light': 'Geometria Light',
                'Georgia': 'Georgia',
                'Hurme Bold': 'Hurme Bold',
                'Hurme Regular': 'Hurme Regular',
                'Hurme Thin': 'Hurme Thin',
                'Lato, sans-serif': 'Lato',
                'Lucida Sans': 'Lucida Sans',
                'Montserrat,sans-serif': 'Montserrat',
                'Open Sans,sans-serif': 'Open Sans',
                'Oswald, sans-serif': 'Oswald',
                'PT Sans Narrow,sans-serif': 'PT Sans Narrow',
                'Prompt, sans-serif': 'Prompt',
                'Rajdhani, sans-serif': 'Rajdhani',
                'Roboto, sans-serif': 'Roboto',
                'Roboto Condensed,sans-serif': 'Roboto Condensed',
                'Rockwell': 'Rockwell',
                'Shadows Into Light,cursive': 'Shadows Into Light,cursive',
                'Source Sans Pro, sans-serif': 'Source Sans Pro, sans-serif',
                'Tahoma': 'Tahoma',
                'Times New Roman': 'Times New Roman',
                'Titillium Web,sans-serif': 'Titillium Web',
                'Trebuchet MS': 'Trebuchet MS',
                'Comic Sans MS, cursive, sans-serif': 'Comic Sans',
                'Helvetica, sans-serif': 'Helvetica'
            }
        },
    }
}
export default TextEditorConfig
