import React from 'react';

const ShopProductsNotFound = ({show}) => {
  return (
      <div className="w-100 text-center pt-5 pb-5">{
            show ? <p>No Products Found</p> : <>Loading...</>
      }</div>
  );
};

export default ShopProductsNotFound;