import React, { useContext } from "react"
import { Route, Redirect } from "react-router-dom"
// import { AuthContext } from "./Auth"

function AuthRegRoute ({ RegisterLink: isAllowSelfRegistration, component: Component, ...rest }) {
    // const { isAllowSelfRegistration } = useContext(AuthContext)

    return <Route {...rest} render={props => (!isAllowSelfRegistration ? <Redirect to={process.env.PUBLIC_URL + "/notFound"} /> : <Component {...props} />)} />
}

export default AuthRegRoute