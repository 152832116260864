import React from "react";
import { Stack } from "@mui/material";
import { useAppSelector } from "../../../redux/hooks";
import { selectFulfillments, selectParams, selectSettings } from "../../../redux/selectors/checkoutSelectors";
import PassiveRow from "../PassiveRow";
import { getAddressString, getEtaString } from "../../../helpers/checkout";
import { FulfillmentMethodType } from "../../../models/FulfillmentMethod";

export default function FulfillmentPassiveContent() {
  const params = useAppSelector(selectParams);
  const settings = useAppSelector(selectSettings);
  const fulfillments = useAppSelector((state) => selectFulfillments(state, params));

  const pf = fulfillments?.[0];

  return (
    <>
      {pf && (
        <Stack spacing={2}>
          <PassiveRow field={'Contact'} values={[`${pf.contact.firstName} ${pf.contact.lastName}`, pf.contact.email, pf.contact.phone]} />
          {pf.method.type === FulfillmentMethodType.SHIPPING && pf.address && (
            <PassiveRow field={'Shipping Address'} values={[pf.address?.locationName, getAddressString(pf.address)]} />
          )}
          <PassiveRow field={'Method'} value={pf.method.name} />
          {pf.method.type === FulfillmentMethodType.PICKUP && pf.method.address && (
            <>
              <PassiveRow field={'Pickup Location'} value={getAddressString(pf.method.address)} />
              <PassiveRow field={'Pickup Instructions'} value={pf.method.instructions} />
            </>
          )}
          {settings.showFulfillmentEstimate && (
            <PassiveRow field={pf.method.type === FulfillmentMethodType.SHIPPING ? 'Estimated Delivery Date' : 'Estimated Ready Date'} value={getEtaString(pf)} />
          )}
        </Stack>
      )}
    </>
  );
}