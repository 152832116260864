import { createSelector } from 'reselect'
import { RootState } from '../hooks'
import { checkoutApi } from '../api/checkoutApi'
import {
  CheckoutSettings,
  CheckoutStatus,
  CheckoutStep,
} from '../../models/CheckoutSession'
import { GetOrCreateSessionParams } from '../api/checkoutApi'
import Decimal from 'decimal.js'
import { ItemPaymentRestriction } from '../../models/Payment'
import { AccessType } from '../../models/AccessType'
import { PaymentMethodType } from '../../models/PaymentMethodType'
import { paymentApi } from '../api/paymentApi'
import { PaymentMethodSettings } from '../../models/PaymentMethodSettings'

export const selectParams = (state: RootState) => state.checkout.sessionParams

export const selectHeaderOffset = (state: RootState) =>
  state.checkout.headerOffset

export const selectSession = createSelector(
  [
    (state: RootState) => state,
    (_, params: GetOrCreateSessionParams | null) => params,
  ],
  (state, params) =>
    params != null
      ? checkoutApi.endpoints.getOrCreateCheckoutSession.select(params)(state)
          .data ?? null
      : null,
)

export const selectSettings = (state: RootState): CheckoutSettings =>
  checkoutApi.endpoints.getCheckoutSettings.select()(state).data ?? {}

export const selectPaymentSettings = (
  state: RootState,
): PaymentMethodSettings[] =>
  paymentApi.endpoints.getPaymentSettings.select()(state).data ?? []

export const selectPaymentMethods = createSelector(
  [selectPaymentSettings],
  (paymentSettings) =>
    paymentSettings
      .filter((settings) => settings.enabled)
      .map((settings) => settings.methodType),
)

export const selectOrder = createSelector(
  selectSession,
  (session) => session?.order,
)

export const selectConfirmationHtml = createSelector(
  selectSession,
  (session) => session?.confirmationHtml,
)

export const selectAetherOrderData = createSelector(
  selectOrder,
  (order) => order?.aetherData ?? null,
)

export const selectOrderDecimalsToShow = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => aetherOrderData?.decimalsToShow ?? 2,
)

export const selectSubtotal = createSelector(
  selectOrder,
  (order) => order?.aetherData?.displaySubtotal ?? 0,
)

export const selectTaxAmount = createSelector(
  selectOrder,
  (order) => order?.aetherData?.displayTax ?? 0,
)

export const selectShippingAmount = createSelector(
  selectOrder,
  (order) => order?.aetherData?.displayShipping ?? 0,
)

export const selectTotal = createSelector(
  selectOrder,
  (order) => order?.aetherData?.displayTotal ?? 0,
)

export const selectItems = createSelector(
  selectSession,
  (session) => session?.order.items ?? null,
)

export const selectOrderTotals = createSelector(
  [selectSubtotal, selectShippingAmount, selectTaxAmount, selectTotal],
  (subtotal, shipping, tax, total) => ({ subtotal, shipping, tax, total }),
)

export const selectStatus = createSelector(
  selectSession,
  (session) => session?.status,
)

export const selectPaymentRequests = (state: RootState) =>
  state.checkout.paymentRequests

export const selectActiveStep = createSelector(
  [selectStatus, (state: RootState) => state.checkout.editingStep],
  (status, editingStep) => {
    if (editingStep) {
      return editingStep
    } else {
      switch (status) {
        case CheckoutStatus.PENDING_DETAILS:
          return CheckoutStep.CUSTOMER_DETAILS
        case CheckoutStatus.PENDING_FULFILLMENT_METHOD:
          return CheckoutStep.FULFILLMENT
        case CheckoutStatus.PENDING_PAYMENT:
          return CheckoutStep.PAYMENT
        case CheckoutStatus.PENDING_SUBMISSION:
          return CheckoutStep.PAYMENT
        default:
          return 'CustomerDetails'
      }
    }
  },
)

export const selectQuestions = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => aetherOrderData?.questions ?? null,
)

export const selectFirstName = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => aetherOrderData?.firstName ?? '',
)

export const selectLastName = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => aetherOrderData?.lastName ?? '',
)

export const selectEmail = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => aetherOrderData?.email ?? '',
)

export const selectContactInfo = createSelector(
  [selectFirstName, selectLastName, selectEmail],
  (firstName, lastName, email) => ({ firstName, lastName, email }),
)

export const selectInHandsDate = createSelector(
  [selectOrder],
  (order) => order?.inhandDate,
)

export const selectSessionId = createSelector(
  selectSession,
  (session) => session?._id,
)

export const selectFulfillments = createSelector(
  selectOrder,
  (order) => order?.aetherData?.fulfillments ?? [],
)

export const selectBalanceDue = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => aetherOrderData?.displayBalanceDue ?? 0,
)

export const selectPayments = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => aetherOrderData?.payments ?? [],
)

export const selectCurrency = createSelector(
  (state: RootState) => state,
  (state) => state.currencyData,
)

export const selectCartId = (state: RootState) => state.cartData.id

export const selectDebug = (state: RootState) => state.checkout.debug

export const selectWorkingPaymentRestrictions = createSelector(
  [selectPaymentRequests, selectItems, (_, __, index: number | null) => index],
  (requests, items, index) => {
    let restrictions: ItemPaymentRestriction[] = []
    for (const item of items ?? []) {
      const restriction = item.aetherData.paymentRestriction
      if (restriction) {
        restrictions.push({
          ...restriction,
          itemId: item.id,
          total: new Decimal(item.aetherData.displayTotalPrice)
            .plus(new Decimal(item.aetherData.displayTax))
            .toNumber(),
        })
      }
    }
    const filteredRequests = requests.filter((_, i) => i !== index)
    for (const request of filteredRequests) {
      for (const itemId of request.itemIds) {
        const restriction = restrictions.find((r) => r.itemId === itemId)
        if (restriction) {
          restriction.applied = new Decimal(restriction.applied)
            .plus(request.amount)
            .toNumber()
        }
      }
    }
    console.log(restrictions)
    return restrictions
  },
)

export const selectRemainingBalanceDue = createSelector(
  [
    selectBalanceDue,
    selectPaymentRequests,
    (_, __, index: number | null) => index,
  ],
  (balanceDue, requests, index) =>
    new Decimal(balanceDue)
      .minus(
        requests
          .filter((_, i) => i !== index)
          .reduce(
            (acc, request) => acc.plus(new Decimal(request.amount)),
            new Decimal(0),
          ),
      )
      .toNumber(),
)

export const selectMethodBalanceDueMap = createSelector(
  [
    selectRemainingBalanceDue,
    selectWorkingPaymentRestrictions,
    selectPaymentMethods,
  ],
  (
    remainingBalanceDue,
    workingPaymentRestrictions,
    methodTypes,
  ) => {
    const restrictedBalanceDue = workingPaymentRestrictions.reduce(
      (acc, r) => acc.plus(new Decimal(r.total)).minus(new Decimal(r.applied)),
      new Decimal(0),
    )
    const unrestrictedBalanceDue = new Decimal(remainingBalanceDue).minus(restrictedBalanceDue);
    let methodDataMap: Record<
      string,
      { amount: Decimal; itemIds: string[]; restrictedItemIds: string[] }
    > = {}
    for (const methodType of methodTypes) {
      console.log(unrestrictedBalanceDue)
      methodDataMap[methodType] = {
        amount: new Decimal(unrestrictedBalanceDue),
        itemIds: [],
        restrictedItemIds: [],
      }
    }
    for (const restriction of workingPaymentRestrictions) {
      for (const methodType of methodTypes) {
        if (
          (restriction.type === AccessType.ALLOW_LIST &&
            restriction.methodTypes.includes(methodType)) ||
          (restriction.type === AccessType.BLOCK_LIST &&
            !restriction.methodTypes.includes(methodType))
        ) {
          methodDataMap[methodType].amount = methodDataMap[methodType].amount
            .plus(restriction.total)
            .minus(restriction.applied)
          methodDataMap[methodType].itemIds.push(restriction.itemId)
        } else {
          methodDataMap[methodType].restrictedItemIds.push(restriction.itemId)
        }
      }
    }
    return methodDataMap
  },
)

export const makeSelectMethodPaymentData = () => {
  return createSelector(
    [
      selectMethodBalanceDueMap,
      (_, __, ___, methodType: PaymentMethodType) => methodType,
    ],
    (methodBalanceDueMap, methodType) => methodBalanceDueMap[methodType],
  )
}
