import React from "react";
import { render } from "react-dom";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./styles.css";

/*
 * Custom toolbar component including the custom heart button and dropdowns
 */
const CustomToolbar = () => (
    <div id="toolbar2">
        <button className=" ql-bold" />
        <button className=" ql-italic" />
        <button className=" ql-underline" />
        <button className=" ql-strike" />
        <button className=" ql-blockquote" />
        <select className="ql-font" style={{ display: 'flex' }}>
            <option value="poppins">Poppins, sans-serif</option>
            <option value="abrilFatface">Abril Fatface, cursive</option>
            <option value="cormorantGaramond">Cormorant Garamond</option>
            <option value="greatVibes">"Great Vibes", cursive</option>
            <option value="lobster">"Lobster", cursive</option>
            <option value="josefinSans">Josefin Sans, sans-serif</option>
            <option value="arial" selected> Arial</option>
            <option value="ArialBlack">Arial Black</option>
            <option value="ArialNarrow">Arial Narrow</option>
            <option value="Arimo">Arimo,sans-serif</option>
            <option value="courier-new">Courier New</option>
            <option value="GeometriaLight">Geometria Light</option>
            <option value="georgia">Georgia</option>
            <option value="hurmebold">hurmeBold</option>
            <option value="HurmeRegular">Hurme Regular</option>
            <option value="HurmeThin">Hurme Thin</option>
            <option value="Lato">Lato</option>
            <option value="lucida">Lucida</option>
            <option value="LucidaSans">Lucida Sans</option>
            <option value="Montserrat">Montserrat</option>
            <option value="OpenSans">Open Sans</option>
            <option value="Oswald">Oswald</option>
            <option value="PTSansNarrow">PT Sans Narrow</option>
            <option value="Prompt">Prompt</option>
            <option value="Rajdhani">Rajdhani</option>
            <option value="Roboto">Roboto</option>
            <option value="RobotoCondensed">Roboto Condensed</option>
            <option value="Rockwell">Rockwell</option>
            <option value="ShadowsIntoLight">Shadows Into Light</option>
            <option value="SourceSansPro">Source Sans Pro</option>
            <option value="Tahoma">Tahoma</option>
            <option value="TimesNewRoman">Times New Roman</option>
            <option value="TitilliumWeb">Titillium Web</option>
            <option value="TrebuchetMS">Trebuchet MS</option>
            <option value="Verdana">Verdana</option>
            <option value="comic-sans">Comic Sans</option>
            <option value="helvetica">Helvetica</option>
        </select>
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        <button className="ql-indent" value="-1" />
        <button className="ql-indent" value="+1" />
        <select className="ql-size" style={{ display: 'flex' }}>
            <option value="8">8 px</option>
            <option value="9">9 px</option>
            <option value="10">10 px</option>
            <option value="11">11 px</option>
            <option value="12">12 px</option>
            <option value="14">14 px</option>
            <option value="16">16 px</option>
            <option value="18">18 px</option>
            <option value="20">20 px</option>
            <option value="22">22 px</option>
            <option value="24">24 px</option>
            <option value="26">26 px</option>
            <option value="28">28 px</option>
            <option value="36">36 px</option>
            <option value="48">48 px</option>
            <option value="72">72 px</option>
        </select>
        <select className="ql-align" style={{ display: 'flex' }} />
        <select className="ql-color" style={{ display: 'flex' }} />
        <select className="ql-background" style={{ display: 'flex' }} />
        <button className="ql-clean" />
        <button className=" ql-image" />
        <button className=" ql-video" />

    </div>
);

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["8", "9", "10", "11", "12", "14", "16", "18", "20", "22", "24", "26", "28", "36", "48", "72"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
    "arial",
    "comic-sans",
    "courier-new",
    "georgia",
    "helvetica",
    "lucida",
    "hurmebold",
    "poppins",
    "abrilFatface",
    "cormorantGaramond",
    "greatVibes",
    "lobster",
    "josefinSans",
    "ArialBlack",
    "ArialNarrow",
    "Arimo",
    "GeometriaLight",
    "HurmeRegular",
    "HurmeThin",
    "Lato",
    "LucidaSans",
    "Montserrat",
    "OpenSans",
    "OpenSansArial",
    "Oswald",
    "PTSansNarrow",
    "Prompt",
    "Rajdhani",
    "Roboto",
    "RobotoCondensed",
    "Rockwell",
    "ShadowsIntoLight",
    "SourceSansPro",
    "Tahoma",
    "TimesNewRoman",
    "TitilliumWeb",
    "TrebuchetMS",
    "Verdana"
];
Quill.register(Font, true);

/*
 * Editor component with custom toolbar and content containers
 */
class TextEditor extends React.Component {
    state = { editorHtml: "" };
    constructor (props) {
        super(props);
    }

    handleChange = (content, delta, source, editor) => {
        this.props.onBodyChange(editor.getHTML())
    };

    static modules = {
        toolbar: {
            container: "#toolbar2",
            handlers: {
            }
        }
    };

    static formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "video",
        "background"
    ];

    render () {
        return (
            <div>
                <div className="text-editor">
                    <CustomToolbar />
                    <ReactQuill
                        value={this.props.value}
                        onChange={this.handleChange}
                        placeholder={this.props.placeholder}
                        modules={TextEditor.modules}
                        formats={TextEditor.formats}
                    />
                </div>

            </div>
        );
    }
}

export default TextEditor