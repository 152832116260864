import React, { useContext } from "react"
import { Route, Redirect } from "react-router-dom"
import { AuthContext } from "./Auth"

function AuthRoute ({ component: Component, ...rest }) {
    const { user } = useContext(AuthContext)

    return <Route {...rest} render={props => (user ? <Redirect to={process.env.PUBLIC_URL + "/"} /> : <Component {...props} />)} />
}

export default AuthRoute