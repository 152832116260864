import { Address, AddressContact } from '../../models/Address'
import { CheckoutQuestion } from '../../models/CheckoutQuestion'
import { CheckoutSession, CheckoutSettings } from '../../models/CheckoutSession'
import {
  AddressSource,
  FulfillmentQuote,
  GetFulfillmentQuoteParams,
} from '../../models/FulfillmentQuote'
import { AetherPaymentRequest, AetherPaymentResponse, SubmitPayments, SubmitPaymentsResponse } from '../../models/Payment'
import { aetherApi } from './aetherApi'

export interface GetOrCreateSessionParams {
  cartId: string
  userId: string
}

export interface UpdateCustomerDetails {
  sessionId: string
  firstName: string
  lastName: string
  questions: CheckoutQuestion[]
}

export interface UpdateFulfillmentDetails {
  sessionId: string

  fulfillmentRequests: {
    quoteId: string
    methodId: string
    contact: AddressContact
    addressSource?: AddressSource
    address?: Address
    savedAddressId?: string
  }[]
}

export const checkoutApi = aetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrCreateCheckoutSession: builder.query<
      CheckoutSession,
      GetOrCreateSessionParams
    >({
      query: ({ userId, cartId }) => {
        return {
          url: `api/v2/checkout/session`,

          method: 'POST',
          body: {
            cartId: cartId,
            userId: userId,
          },
        }
      },
      providesTags: ['CheckoutSession'],
    }),
    updateCustomerDetails: builder.mutation<
      CheckoutSession,
      UpdateCustomerDetails
    >({
      query: (body) => ({
        url: `api/v2/checkout/customer-details`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            const { cartId, userId } = data
            dispatch(
              checkoutApi.util.updateQueryData(
                'getOrCreateCheckoutSession',
                { cartId, userId },
                (draft) => {
                  Object.assign(draft, data)
                },
              ),
            )
          }
        } catch {}
      },
    }),
    updateFulfillmentDetails: builder.mutation<
      CheckoutSession,
      UpdateFulfillmentDetails
    >({
      query: (body) => ({
        url: `api/v2/checkout/fulfillment-details`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            const { cartId, userId } = data
            dispatch(
              checkoutApi.util.updateQueryData(
                'getOrCreateCheckoutSession',
                { cartId, userId },
                (draft) => {
                  Object.assign(draft, data)
                },
              ),
            )
          }
        } catch {}
      },
    }),
    submitPayments: builder.mutation<
      SubmitPaymentsResponse,
      SubmitPayments
    >({
      query: (body) => ({
        url: `api/v2/checkout/submit-payments`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            const { session } = data;
            const { cartId, userId } = session
            dispatch(
              checkoutApi.util.updateQueryData(
                'getOrCreateCheckoutSession',
                { cartId, userId },
                (draft) => {
                  Object.assign(draft, session)
                },
              ),
            )
          }
        } catch {}
      },
    }),
    submitOrder: builder.mutation<
      CheckoutSession,
      { sessionId: string }
    >({
      query: (body) => ({
        url: `api/v2/checkout/submit-order`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            const { cartId, userId } = data
            dispatch(
              checkoutApi.util.updateQueryData(
                'getOrCreateCheckoutSession',
                { cartId, userId },
                (draft) => {
                  Object.assign(draft, data)
                },
              ),
            )
          }
        } catch {}
      },
    }),
    createFulfillmentQuote: builder.mutation<
      FulfillmentQuote,
      GetFulfillmentQuoteParams
    >({
      query: (params) => ({
        url: `api/v2/checkout/fulfillment-quote`,
        method: 'POST',
        body: params,
      }),
    }),
    getCheckoutSettings: builder.query<CheckoutSettings, void>({
      query: () => ({
        url: `api/v2/checkout/settings`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetOrCreateCheckoutSessionQuery,
  useUpdateCustomerDetailsMutation,
  useCreateFulfillmentQuoteMutation,
  useUpdateFulfillmentDetailsMutation,
  useSubmitOrderMutation,
  useSubmitPaymentsMutation,
  useGetCheckoutSettingsQuery,
} = checkoutApi
