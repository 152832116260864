import React, { useMemo } from 'react'
import { Button, Grid2, Stack, useMediaQuery, useTheme } from '@mui/material'
import CheckoutTextField from './CheckoutTextField'
import { LoadingButton } from '@mui/lab'

interface AddressContactFormProps {
  formPrefix?: string
  loading?: boolean
  showCancel?: boolean
  onSave: () => void
  onCancel?: () => void
}

export default function AddressContactForm({
  formPrefix,
  loading,
  showCancel,
  onSave,
  onCancel
}: AddressContactFormProps) {
  const theme = useTheme()
  const xsDisplay = useMediaQuery(theme.breakpoints.only('xs'))

  const effectivePrefix = useMemo(() => {
    if (formPrefix !== undefined) {
      return `${formPrefix}.`
    } else {
      return ''
    }
  }, [formPrefix])
  
  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <CheckoutTextField
            name={`${effectivePrefix}contact.firstName`}
            label="First Name"
            rules={{ required: true }}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <CheckoutTextField
            name={`${effectivePrefix}contact.lastName`}
            label="Last Name"
            rules={{ required: true }}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <CheckoutTextField
            name={`${effectivePrefix}contact.email`}
            label="Email"
            rules={{ required: true }}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <CheckoutTextField
            name={`${effectivePrefix}contact.phone`}
            label="Phone"
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <CheckoutTextField
            name={`${effectivePrefix}contact.company`}
            label="Company"
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <CheckoutTextField
            name={`${effectivePrefix}contact.attentionTo`}
            label="Attention To"
          />
        </Grid2>
      </Grid2>
      <Stack direction={'row'} justifyContent={'center'} mt={2} spacing={2}>
        {showCancel && (
          <Button
            variant='outlined'
            onClick={onCancel}
            fullWidth={xsDisplay}
          >
            Cancel
          </Button>
        )}
        <LoadingButton
          variant="contained"
          size="large"
          onClick={onSave}
          loading={loading}
          fullWidth={xsDisplay}
        >
          Submit
        </LoadingButton>
      </Stack>
    </>
  )
}
