import React from 'react'
import { Card, CardContent, Typography } from '@mui/material'
import { useAppSelector } from '../../redux/hooks'
import {
  selectConfirmationHtml,
  selectParams,
} from '../../redux/selectors/checkoutSelectors'
import parse from 'html-react-parser'

export default function ConfirmationCard() {
  const params = useAppSelector(selectParams)
  const confirmationText = useAppSelector((state) =>
    selectConfirmationHtml(state, params),
  )

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {confirmationText
            ? parse(confirmationText)
            : 'Thank you for your order'}
        </Typography>
      </CardContent>
    </Card>
  )
}
