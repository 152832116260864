import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect, useContext } from "react";
import { getDiscountPrice, getFirstPrice, showCurrencycodeSymbol } from "../../../helpers/product";
import { useProductPriceToShow } from "../state/useProductPriceToShow";
import { useThemeSettingsData } from "../../../layouts/state/useThemeSettingsData";
import { AuthContext } from "../../../common/Auth";
import { useSelector } from "react-redux";
import { defaultTo } from "lodash";

const ProductPriceCharges = ({
                                 usePointsAsCurrency,
                                 product,
                                 showPersonalizationCostInCart,
                                 totalSetupDecoCharges,
                                 showSetupChargeInCart,
                                 totalDecoCharges,
                                 showDecorationCostInCart,
                                 personalizationCost,
                                 decimalsToShow,
                                 quantityCount,
                                 setupCharges,
                                 totalSetupCharge
                             })=>{
    const { themeSettingsData } = useContext(AuthContext)
    const {
        productSettings,
    } = useThemeSettingsData({ themeSettingsData })
    
    const {
        getPointsFromValue,
        getPointsValue
    } = useProductPriceToShow({ productSettings })
    
    const currency = useSelector((state) =>
        defaultTo(state.currencyData, '')
    );
    
    
    const getPersonalizationCost = ()=>{
        const quantity = Number(quantityCount.toString().replace(/,/g, ''))
        return quantity > 0 ?
            (personalizationCost.matchOrderQty === 1 ? (Number(personalizationCost.price) * quantity).toFixed(decimalsToShow) :
                Number(personalizationCost.price).toFixed(decimalsToShow)) : 0
    }
    
    return <>
        {showSetupChargeInCart && totalSetupCharge > 0 && setupCharges?.length > 0 && setupCharges.map(charge => {
            return (
                <>
                    <br />
                    <label>
                        <b>{charge.setupChargeName}: </b>
                        {
                            usePointsAsCurrency ? getPointsValue(charge.totalSetupCharge) : <>{showCurrencycodeSymbol(currency)}{Number(charge.totalSetupCharge).toFixed(decimalsToShow)}
                            </>
                        }
                      
                    </label>
                </>
            )
        })}
        { totalSetupDecoCharges > 0 && showSetupChargeInCart && <>
            <br/>
            <label>
                <b>Total setup decoration charge:</b>
                { usePointsAsCurrency ? getPointsValue(totalSetupDecoCharges) : <> { showCurrencycodeSymbol(currency) }{ totalSetupDecoCharges?.toFixed(decimalsToShow) } </> }
            </label>
        </> }
        { (totalDecoCharges > 0 && showDecorationCostInCart) && <>
            <br/>
            <label>
                <b>Total Deco charge:</b>
                { usePointsAsCurrency ? getPointsValue(totalDecoCharges) : <>{ showCurrencycodeSymbol(currency) }{ totalDecoCharges?.toFixed(decimalsToShow) }</> }
            </label>
        </> }
        { (personalizationCost.price > 0) && showPersonalizationCostInCart && <>
            <br/>
            <label>
                <b>{
                    !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                        ? productSettings['showPersonalizationLabel'] : 'Personalization'
                } Charge:
                </b>
                {
                    usePointsAsCurrency ? getPointsValue(getPersonalizationCost()) :
                        <>
                            { showCurrencycodeSymbol(currency) }
                            { getPersonalizationCost() }
                        </>
                }
            </label>
        </> }
        { showSetupChargeInCart && (personalizationCost.setupPrice > 0) && <>
            <br/>
            <label>
                <b>
                    { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel'] ? productSettings['showPersonalizationLabel']
                        : 'Personalization' } setup Charge:</b>
                {
                    usePointsAsCurrency ? getPointsValue(personalizationCost.setupPrice) : <>
                        { showCurrencycodeSymbol(currency) }
                        { Number(personalizationCost.setupPrice)
                            .toFixed(decimalsToShow) }
                    </>
                }
            </label>
        </> }
    </>
};

export default ProductPriceCharges;
