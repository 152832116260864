import PropTypes from "prop-types";
import React, {Fragment, useState, useEffect, useContext} from "react";
import {connect} from "react-redux";
import {useToasts} from "react-toast-notifications";
import {getDiscountPrice, getFirstPrice} from "../../helpers/product";
import ProductDescriptionInfoSlider from "../../components/product/ProductDescriptionInfoSlider";
import ProductDescriptionInfoPromotional from "../../components/product/ProductDescriptionInfoPromotional";
import ProductImageGallerySideThumb from "../../components/product/ProductImageGallerySideThumb";
import ProductImageFixed from "../../components/product/ProductImageFixed";
import {AuthContext} from "../../common/Auth";
import RelatedProductSliderPromotional from "./RelatedProductSliderPromotional";
import Button from "@material-ui/core/Button/Button";
import { useThemeSettingsData } from "../../layouts/state/useThemeSettingsData";
import ProductImageGalleryStepper from "../../components/product/ProductImageGalleryStepper";


const ProductImageDescriptionPromotional = (
    {
        spaceTopClass,
        spaceBottomClass,
        galleryType,
        product,
        currency,
        cartItems,
        wishlistItems,
        artworkList,
        artworkListById,
        compareItems,
        isModel = false,
    }) => {
    const [selectedColor, setSelectedColor] = useState(null);
    const [selectedDecorations, setSelectedDecorations] = useState(null);
    const [selectedThumbImgColor, setSelectedThumbImgColor] = useState(null)
    const [singleSelectedDecorations, setSingleSelectedDecorations] = useState(null);
    const [swipeimageFlag, setSwipeimageFlag] = useState(true)
    const [selectedImage, setSelectedImage] = useState('');
    const [toggle, setToggle] = useState(false);
    const [tempValueCheck, setTempValueCheck] = useState(0)

    const wishlistItem = wishlistItems.filter(
        wishlistItem => wishlistItem.id === product.id
    )[0];
    const compareItem = compareItems.filter(
        compareItem => compareItem.id === product.id
    )[0];
    const {addToast} = useToasts();
    let productPrice = product.price ? product.price : 0;
    let productDiscount = product.discount ? product.discount : 0;
    const discountedPrice = getDiscountPrice(productPrice, productDiscount);
    let finalProductPrice = Number(getFirstPrice(product, decimalsToShow))
    const finalDiscountedPrice = +(
        discountedPrice * currency.currencyRate
    ).toFixed(decimalsToShow);
    const { tabName, themeSettingsData, userGroupDiscount } = useContext(AuthContext)
    const [tempForParent, setTempForParent] = useState(0)
    const [decimalsToShow, setDecimalsToShow] = useState(2);
    const [isReplace, setIsReplace] = useState(true)
    const {
        productSettings,
    } = useThemeSettingsData({ themeSettingsData });
    useEffect(() => {
        if(!!productSettings?.decimalsToShow) {
            setDecimalsToShow(Number(productSettings?.decimalsToShow));
        }
    }, [productSettings])

    let showSell = false;
    let showSellPrice = false
    let offerPrice = 0;
    let discountType = ""

    let todayDate = new Date().toISOString();

    if (todayDate >= product?.startDate && todayDate <= product?.endDate) {
        if (product?.makeProductSale) {
            showSell = true
        }
    } else if (product?.startDate == "" || product?.startDate == undefined) {
        if (product.makeProductSale) {
            showSell = true
        }
    }

    if (product?.showPriceBreak && todayDate >= product?.startDate && todayDate <= product?.endDate) {
        showSellPrice = true
    }
    if (product?.sellingPrice) {
        offerPrice = product.sellingPrice
    }
    if (product?.discountType == "0" || product?.discountType == "1") {
        discountType = product?.discountType
    }

    let finalProductDiscPrice = 0;

    if (Number(userGroupDiscount > 0) && Number(finalProductPrice) > 0) {
        finalProductPrice = Number(finalProductPrice) - (Number(finalProductPrice) * Number(userGroupDiscount) / 100)
        if (Number(finalProductPrice) < 0) {
            finalProductPrice = 0
        }
    }

    if (discountType == "1" && Number(finalProductPrice) > 0) {
        if (offerPrice > 0) {
            finalProductDiscPrice = Number(finalProductPrice) - Number(offerPrice)
            if (Number(finalProductDiscPrice) < 0) {
                finalProductDiscPrice = 0
            }
        }
    } else if (discountType == "0" && Number(finalProductPrice) > 0) {
        if (offerPrice > 0) {
            finalProductDiscPrice = Number(finalProductPrice) - (Number(finalProductPrice) * Number(offerPrice) / 100)
            if (Number(finalProductDiscPrice) < 0) {
                finalProductDiscPrice = 0
            }
        }
    }

    const tempfun = () => {
        setTempForParent(tempForParent + 1)
    }

    return (
        <div
            className={`shop-area ${spaceTopClass ? spaceTopClass : ""} ${spaceBottomClass ? spaceBottomClass : ""
            }`}
        >
            <div className="pl-2 pr-2">
                <div className="row">
                    <div className="col-lg-4 col-md-6">

                        <div className="row">
                            <div className="product-pramotional">
                               <div className="productImageMain">
                                    <div className="productImage product-large-image-wrapper">
                                    {swipeimageFlag && (
                                        <ProductImageGalleryStepper
                                        product={product}
                                        setSelectedColor={setSelectedColor}
                                        selectedColor={selectedColor}
                                        artworkList={artworkList}
                                        selectedDecorations={selectedDecorations}
                                        artworkListById={artworkListById}
                                        selectedImage={selectedImage}
                                        setSelectedDecorations={setSelectedDecorations}
                                        setSingleSelectedDecorations={setSingleSelectedDecorations}
                                        singleSelectedDecorations={singleSelectedDecorations}
                                        setSelectedImage={setSelectedImage}
                                        isReplace={isReplace}
                                        setIsReplace={setIsReplace}
                                        isStepper={true}
                                        isModel={isModel}
                                    />
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div style={{
                                margin: "auto", marginTop:'20px'
                            }}>
                                <p style={{
                                    fontWeight: "bold"
                                }}>{"HELPFUL INFORMATION"}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div style={{
                                margin: "auto"
                            }}>
                                <Button variant="outlined" className="ml-2" style={{
                                    borderRadius: "15px",
                                    backgroundColor: "#eceeed",
                                    border: "none",
                                    width: "300px"
                                }}>
                                    {"Understanding Ink Coverage"}
                                </Button>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div style={{
                                margin: "auto"
                            }}>
                                <Button variant="outlined" className="ml-2" style={{
                                    borderRadius: "15px",
                                    backgroundColor: "#eceeed",
                                    border: "none",
                                    width: "300px"
                                }}>
                                    {"Understanding Dye Sublimation "}
                                </Button>
                            </div>
                        </div>
                        {/* related product slider */}
                        {productSettings && productSettings['showRelatedProducts'] && (
                            <RelatedProductSliderPromotional spaceBottomClass="pb-95" product={product} />
                        )}

                    </div>
                    <div className="col-lg-8 col-md-6">
                        {/* product description info */}
                        <ProductDescriptionInfoPromotional
                            product={product}
                            setSelectedColor={setSelectedColor}
                            selectedColor={selectedColor}
                            setToggle={setToggle}
                            toggle={toggle}
                            discountedPrice={discountedPrice}
                            currency={currency}
                            setSelectedDecorations={setSelectedDecorations}
                            setSingleSelectedDecorations={setSingleSelectedDecorations}
                            singleSelectedDecorations={singleSelectedDecorations}
                            setSelectedImage={setSelectedImage}
                            selectedImage={selectedImage}
                            finalDiscountedPrice={finalDiscountedPrice}
                            finalProductPrice={finalProductPrice}
                            cartItems={cartItems}
                            wishlistItem={wishlistItem}
                            compareItem={compareItem}
                            addToast={addToast}
                            showSell={showSell}
                            showSellPrice={showSellPrice}
                            offerPrice={offerPrice}
                            discountType={discountType}
                            finalProductDiscPrice={finalProductDiscPrice}
                            tempfun={tempfun}
                            setSwipeimageFlag={setSwipeimageFlag}
                            tempValueCheck = {tempValueCheck}
                            setTempValueCheck = {setTempValueCheck}
                            isModel = {isModel}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

ProductImageDescriptionPromotional.propTypes = {
    cartItems: PropTypes.array,
    compareItems: PropTypes.array,
    currency: PropTypes.object,
    galleryType: PropTypes.string,
    product: PropTypes.object,
    spaceBottomClass: PropTypes.string,
    spaceTopClass: PropTypes.string,
    wishlistItems: PropTypes.array
};

const mapStateToProps = state => {
    return {
        currency: state.currencyData,
        cartItems: state.cartData.cartItems,
        wishlistItems: state.wishlistData,
        artworkList: state.productData.artworkList,
        artworkListById: state.productData.artworkListById,
        compareItems: state.compareData
    };
};

export default connect(mapStateToProps)(ProductImageDescriptionPromotional);
