import {
    RECEIVE_FEA_GROUP_PRODUCTS,
    RECEIVE_FEATURED_PRODUCTS,
    RECEIVE_FEATURED_PRODUCTS_REQUEST,
    IS_LOADING,
    FETCH_ERROR
  } from '../actions/featureProductActions'
  
  const initState = {
    featuredProducts: [],
  }
  
  const featureProductReducer = (state = initState, action) => {
    switch (action.type) {
      case RECEIVE_FEATURED_PRODUCTS_REQUEST: {
        return {
          ...state,
          loading: true,
          featuredProducts: "",
        }
      }
      case RECEIVE_FEA_GROUP_PRODUCTS: {
        return {
          ...state,
          loading: false,
          featuredProducts: action.payload
        }
      }
      case RECEIVE_FEATURED_PRODUCTS: {
        return {
          ...state,
          loading: false,
          featuredProducts: action.payload,
          count: action.count,
          meta: action.meta,
        }
      }
      case IS_LOADING: {
        return {
          ...state,
          loading: action.loading,
        }
      }
      case FETCH_ERROR: {
        return {
          ...state,
          fetchError: true,
        }
      }
      default:
        return state
    }
  }
  
  export default featureProductReducer;
  