import PropTypes from "prop-types";
import React from "react";
import ShopSearch from "../../components/product/ShopSearch";
import ShopCategories from "../../components/product/ShopCategories";
import ShopColor from "../../components/product/ShopColor";
import ShopSize from "../../components/product/ShopSize";
import ShopTag from "../../components/product/ShopTag";
const ShopSidebar = (props) => {
  return (
    <div
      className={`sidebar-style ${
        props.sideSpaceClass ? props.sideSpaceClass : ""
      }`}
    >
      {/* shop search */}
      <ShopSearch
          onHandleInput={props.onHandleInput}
          searchQuery={props.searchQuery}
          resetSearchParams={props.resetSearchParams} />
      {/* filter by categories */
        props.sideBarSettings?.showCategory &&
        <ShopCategories
            onSelectCategory={props.onSelectCategory}
            categories={props.categories}
            selectedCategories={props.selectedCategories}
			isExpand={props.sideBarSettings.isExpand}
        />
      }
      {/* filter by color */}
      {props.sideBarSettings?.showColor && <ShopColor {...props} />}
      {/* filter by size */}
      {props.sideBarSettings?.showSize && <ShopSize {...props} />}
      {/* filter by tag */}
      {props.sideBarSettings?.showTag && props.tags.length > 0 && <ShopTag {...props} />}
    </div>
  );
};
ShopSidebar.propTypes = {
  getSortParams: PropTypes.func,
  products: PropTypes.array,
  sideSpaceClass: PropTypes.string,
};
export default ShopSidebar;
