import React, { useState, useEffect } from "react";

export const useShopColorState = (props) => {
    const { selectedColors } = props;
    const {isExpand} = props.sideBarSettings;
    const [defaultExpand, setDefaultExpand] = useState(null)

    useEffect(() => {
        if (isExpand === true) {
            setDefaultExpand(true)
        } else {
            setDefaultExpand(false)
        }
    }, [isExpand])

    useEffect(() => {
        if (selectedColors.length > 0) {
            setDefaultExpand(true)
        } else {
            setDefaultExpand(false)
        }
    }, [selectedColors])

    return {
        defaultExpand,
        selectedColors
    }
}
