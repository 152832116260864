import { createSelector } from "reselect";

export const selectArtworkList = createSelector(
    [
        (state) => state.productData.artworkList,
        (state) => state.productData.artworkListById
    ],
    (artworkList, artworkListById) => {
        return [...artworkList, ...artworkListById];
    }
);
export const isProductDataLoading = state => state.productData.loading;
export const productArtworkLoaded = createSelector(
    [
        (state) => state.productData.artworkProductId,
        (_, productId) => productId
    ],
    (artworkProductId, productId) => {
        return artworkProductId === productId;
    }
);