import React, { useEffect, useState } from "react"
import { Alert, Button, CardContent, Grid2, Stack, Typography } from "@mui/material"
import AddressContactForm from "../AddressContactForm"
import { useAppSelector } from "../../../redux/hooks"
import { selectContactInfo, selectParams, selectSessionId } from "../../../redux/selectors/checkoutSelectors";
import { AddressContact } from "../../../models/Address";
import { useCreateFulfillmentQuoteMutation } from "../../../redux/api/checkoutApi";
import { FulfillmentMethodType } from "../../../models/FulfillmentMethod";
import FulfillmentQuoteMethodList from "./FulfillmentQuoteMethodList";
import ListSkeleton from "../../../common/ListSkeleton";
import { getError } from "../../../helpers/checkout";
import { useFormContext, useWatch } from "react-hook-form-mui";
import { FulfillmentForm } from "./FulfillmentActiveContent";
import { LoadingButton } from "@mui/lab";
import { FulfillmentQuoteMethod } from "../../../models/FulfillmentQuote";
import FulfillmentQuoteContent from "./FulfillmentQuoteContent";
import AddressSelector from "../AddressSelector";

interface PickupMethodContactProps {
  index: number;
  onSubmit: (data: FulfillmentForm) => Promise<void>;
  error?: string;
  submitLoading: boolean;
}

export default function PickupMethodContact({ index, onSubmit, submitLoading, error }: PickupMethodContactProps) {
  const [editingContact, setEditingContact] = useState(false);

  return (
    <>
      <AddressSelector
        label="Pickup"
        formPrefix={`fulfillments.${index}`}
        editingContact={editingContact}
        setEditingContact={setEditingContact}
      />
      <FulfillmentQuoteContent
        index={index}
        hide={editingContact}
        type={FulfillmentMethodType.PICKUP}
        submitLoading={submitLoading}
        error={error}
        onSubmit={onSubmit}
      />
    </>
  )
}
