import {
  IS_LOADING,
  RECEIVE_BLOGS,
  RECEIVE_BLOG,
  FETCH_ERROR,
  RECEIVE_FEATURED_BLOGS,
  RECEIVE_COUNT_BY_CAT
} from "../actions/blogActions";

const initState = {
  blogsList: null,
  featuredBlogs: [],
  blogCountsbyCategories:[]
};

const blogsReducer = (state = initState, action) => {
  switch (action.type) {
    case RECEIVE_BLOG: {
      return {
        ...state,
        loading: false,
        currentBlog: action.payload
      }
    }
    case RECEIVE_BLOGS: {
      return {
        ...state,
        loading: false,
        blogsList: action.payload,
        count: action.count,
        meta: action.meta
      }
    }
    case RECEIVE_COUNT_BY_CAT: {
      return {
        ...state,
        loading: false,
        blogCountsbyCategories: action.payload.data
      }
    }
    case RECEIVE_FEATURED_BLOGS: {
      return {
        ...state,
        loading: false,
        featuredBlogs: action.payload
      }
    }
    case IS_LOADING: {
      return {
        ...state,
        loading: action.loading,
      }
    }
    case FETCH_ERROR: {
      return {
        ...state,
        fetchError: true
      }
    }
    default:
      return state;
  }
};

export default blogsReducer;