import PropTypes from "prop-types";
import React from "react";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {useShopSizesState} from "./state/useShopSizesState";

const ShopSize = (props) => {
	const {onSelectSize,} = props;
	const {isExpand} = props?.sideBarSettings
	const {
		selectedSizes,
		sortSizes,
		defaultExpand,
		dataFilterStyle
	} = useShopSizesState(props)

	return (
		<div className="sidebar-widget mt-20">
			{isExpand !== undefined && <Accordion defaultExpanded={isExpand}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon/>}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<h4 className="pro-sidebar-title">Size </h4>
				</AccordionSummary>
				<AccordionDetails>
					<div className="sidebar-widget-list w-100">
						{sortSizes.length ? (
							<ul className={`${!isExpand ? 'dataFilter' : ''}`} style={dataFilterStyle}>
								{sortSizes.map((size, key) => {
									const isChecked = selectedSizes.findIndex(
										(selectedSize) => selectedSize === size
									) >= 0;
									return (
										<li key={key} className="mb-0">
											<FormControlLabel
												control={
													<Checkbox
														name={size}
														id={size}
														value={size}
														onClick={(e) => onSelectSize(size, e.target.checked)}
														checked={isChecked}
													/>
												}
												label={size}
											/>
										</li>
									);
								})}
							</ul>
						) : (
							"No records"
						)}
					</div>
				</AccordionDetails>
			</Accordion>}
		</div>
	);
};

ShopSize.propTypes = {
	getSortParams: PropTypes.func,
	sizes: PropTypes.array,
	isExpand: PropTypes.bool
};

export default ShopSize;
