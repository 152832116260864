import { useEffect, useState} from "react";

export const useCategoryFormatState = (props) => {
    const [structuredData, setStructuredData] = useState([]);
    const {
        categories,
        sizes
    } = props;

    useEffect(() => {
        if(categories?.length) {
            setStructuredData(getChildrenCategories(categories.map(x => x.category), ""))
        }
    }, [categories])

    const getChildrenCategories = (categories, parentId)  => {
        let children = [];
        categories.forEach( (category) => {
            if (category.parent_id === parentId) {
                children.push({ category, children: getChildrenCategories(categories, category._id)});
            }
        });
        return children;
    }

    const sizesByCategories = (categories) => {
        let filteredSizes = sizes.filter((size) => {
            return categories.includes(size.category);
        });
    }


    return {
        structuredData
    }
}
