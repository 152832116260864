import * as advanceAPIUtil from '../../util/advance/advanceAPIUtil'
import * as userApiUtil from '../../util/user/userAPIUtil'
import { contactBody, paymentBody } from "../../common/AdvanceBodies";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const IS_LOADING = "IS_LOADING";
export const IS_LOADING_CONTACT = "IS_LOADING_CONTACT";
export const FETCH_ERROR = "FETCH_ERROR";
export const STORE_DETAILS = "STORE_DETAILS";
export const CONTACT_DETAILS = "CONTACT_DETAILS";

const loginUserSuccess = users => ({
  type: LOGIN_USER_SUCCESS,
  payload: users
});
const authenticateUserSuccess = loginUser => ({
  type: LOGIN_SUCCESS,
  payload: loginUser
});
const receiveStoreDetails = data => ({
  type: STORE_DETAILS,
  payload: data
});

export const receiveStoreDetailsfromproduct = data => {
  return dispatch => {
    dispatch(receiveStoreDetails(data));
  }
}

const receiveContactData = data => ({
  type: CONTACT_DETAILS,
  payload: data
});

// fetch products
export const fetchUser = users => {
  return dispatch => {
    dispatch(loginUserSuccess(users));
  };
};
export const authenticateUser = data => {

  let loginUser = [];
  data.users.forEach(element => {
    if (element.userName === data.username) {
      loginUser = element;
    }
  });

  return dispatch => {
    dispatch(authenticateUserSuccess(loginUser));
  };
};
export const isLoading = (loading) => ({
  type: IS_LOADING,
  loading
});

export const isLoadingContact = (loadingContact) => ({
  type: IS_LOADING_CONTACT,
  loadingContact
});

export const sendFetchError = () => ({
  type: FETCH_ERROR
});
export const setContactID = (user) => dispatch => {
  dispatch(isLoadingContact(true));
  userApiUtil.getContact(user.userId)
  .then(res => {
    if (res?.data) {
      dispatch(receiveContactData(res.data))
    }
  }).catch(error => {
    console.error(error);
    dispatch(sendFetchError());
  }).finally(() => {    
    dispatch(isLoading(false));
  });
}

export const cleanContactData = () => {
  receiveStoreDetails({})
}

export const getAdvancePaymentMethod = () => dispatch => advanceAPIUtil.customAdvanceCall(paymentBody)
  .then(res => {
    if (res.data) {
      if (res.data.data && res.data.data[0] && res.data.data[0].CODE) {
        if (localStorage.advancePaymentMethod) {
          localStorage.setItem("advancePaymentMethod", res.data.data[0].CODE)
        } else {
          localStorage.setItem("advancePaymentMethod", res.data.data[0].CODE)
          window.location.reload()
        }
      }
    }
  }).catch(error => {
    console.log(error)
  });
