
import React from "react";

const Loader = ({message = ''}) => {
    return (
        <>
          <div className="flone-preloader-wrapper">
            <div className="flone-preloader">
              <span></span>
              <span></span>
              {message && message.length > 0 &&
                <div> {message} </div>
              }
            </div>
          </div>
        </>
    );
};

export default Loader;
