import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./common/Auth";
// import categoryComponent from "./constant/categoryComponent";
import ShopMainProductsView from "./pages/shop/ShopMainProductsView";
import { ShopViewsList, ShopViewsListValues } from "./util/shop.views";

const DynamicCategoryLayout = (props) => {
  const [categoryLayout, setCategoryLayout] = useState(null);
  const { themeSettingsData, categoriesList } = useContext(AuthContext);
  const { url } = props.match;
  const [selectedSorting, setSelectedSorting] = useState({
    label: 'Product Name A-Z',
    value: 'productName_asc',
  });

  useEffect(() => {
   return () => {
     localStorage.removeItem('redux_localstorage_simple')
   }
  }, [])

  useEffect(() => {
    let flag = true;
    if (!!props?.match?.params?.category) {
      for (const category of categoriesList) {
        if (category.ct_slug === props.match.params.category) {
          if (ShopViewsListValues.includes(category?.categoryLayout)) {
            setCategoryLayout(category.categoryLayout);
            flag = false;
          }
        }
      }
    }
    if (themeSettingsData && themeSettingsData.length > 0 && flag) {
      for (const settings of themeSettingsData) {
        if (
          settings.settingsName === "theme" &&
          ShopViewsListValues.includes(settings.values[0]?.categoryLayout)
        ) {
          setCategoryLayout(settings.values[0].categoryLayout);
        }
      }
    }
  }, [themeSettingsData, url]);

  return (
    <>
      { categoryLayout && <ShopMainProductsView themeLayout={ categoryLayout } { ...props } selectedSorting={ selectedSorting }
                              setSelectedSorting={ setSelectedSorting }/> }
    </>
  );
};

export default DynamicCategoryLayout;
