import axios from "axios";
import * as productsAPIUtil from "../../util/products/productsAPIUtil"
export const SET_CURRENCY = "SET_CURRENCY";
export const setCurrency = currencyName => {
  return dispatch => {
    axios
      .get(`https://api.exchangeratesapi.io/latest?base=USD`)
      .then(response => {
        const rates = response.data.rates;
        let currencyRate = 0;
        for (const rate in rates) {
          if (rate === currencyName) {
            currencyRate = rates[rate];
          }
        }
        dispatch({
          type: SET_CURRENCY,
          payload: { currencyName, currencyRate }
        });
      })
      .catch(err => {
        console.log("Error: ", err);
      });
  };
};
const receiveAdvanceCurrency = data => ({
  type: SET_CURRENCY,
  payload: data
});

export const setAdvanceCurrency = () => dispatch => productsAPIUtil.getApittoken()
  .then(res => {
    if (res.data) {
      res.data.map((i) => {
        if (i.page === "General") {
          let currencyData = i.values.storeDefaultCurrency
          if (currencyData && currencyData.currency) {
            dispatch(receiveAdvanceCurrency({
              currencytype: "advanceCurrency",
              symbol: currencyData.symbol,
              currency: currencyData.code,
              currencyRate: 1,
            }));
          }
        }
      })
    }
  }).catch(error => {
    console.log("Error: ", error);
  });