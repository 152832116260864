import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { getProducts } from "../../helpers/product";
import RelatedProductGridSingle from "../../components/product/RelatedProductGridSingle";
import { addToCart } from "../../redux/actions/cartActions";
import { addToWishlist } from "../../redux/actions/wishlistActions";
import { addToCompare } from "../../redux/actions/compareActions";
import { AuthContext } from "../../common/Auth"

const RelatedProductGrid = ({
  currency,
  addToCart,
  addToWishlist,
  addToCompare,
  cartItems,
  wishlistItems,
  compareItems,
  sliderClassName,
  spaceBottomClass,
  ownProps,
  relatedProductsList,
  props
}) => {

  const [toggle, setToggle] = useState(true)

  useEffect(() => {
    setToggle(!toggle)
  }, [])
  let cls = "col-xl-3 col-md-6 col-lg-4 col-sm-6"
  if (relatedProductsList?.length < 5) {
    cls = ""
  }
  return (
    <Fragment>
      {relatedProductsList?.map(product => {
        return (
          <RelatedProductGridSingle
            setToggle={setToggle}
            toggle={toggle}
            colClass={cls}
            sliderClassName={sliderClassName}
            spaceBottomClass={spaceBottomClass}
            product={product}
            currency={currency}
            addToCart={addToCart}
            addToWishlist={addToWishlist}
            addToCompare={addToCompare}
            cartItem={
              cartItems.filter(cartItem => cartItem?.id === product?.id)[0]
            }
            wishlistItem={
              wishlistItems.filter(
                wishlistItem => wishlistItem.id === product.id
              )[0]
            }
            compareItem={
              compareItems.filter(
                compareItem => compareItem.id === product.id
              )[0]
            }
            key={product.id}
          />
        );
      })}
    </Fragment>
  );
};

RelatedProductGrid.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItems: PropTypes.array,
  compareItems: PropTypes.array,
  currency: PropTypes.object,
  product: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  wishlistItems: PropTypes.array
};

const mapStateToProps = (state, ownProps) => {
  return {
    ownProps: ownProps,
    currency: state.currencyData,
    cartItems: state.cartData.cartItems,
    wishlistItems: state.wishlistData,
    compareItems: state.compareData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addToCart: (
      item,
      addToast,
      quantityCount,
      selectedProductColor,
      selectedProductSize,
      selectedProductDecorations,
      personalizationArray
    ) => {
      dispatch(
        addToCart(
          item,
          addToast,
          quantityCount,
          selectedProductColor,
          selectedProductSize,
          selectedProductDecorations,
          personalizationArray
        )
      );
    },
    addToWishlist: (item, addToast) => {
      dispatch(addToWishlist(item, addToast));
    },
    addToCompare: (item, addToast) => {
      dispatch(addToCompare(item, addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RelatedProductGrid);
