import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./common/Auth"
import blogComponent from "./constant/blogComponent"

const DynamicBlogComponent = (props) => {
    const [blogLayout, setBlogLayout] = useState("blog-standard");
    const { themeSettingsData } = useContext(AuthContext)

    useEffect(() => {
        if (themeSettingsData && themeSettingsData.length > 0) {
            themeSettingsData.map((i) => {
                if (i.settingsName === "theme" && i.values[0].blogLayout != "" && blogComponent[i.values[0].blogLayout]) {
                    setBlogLayout(i.values[0].blogLayout);
                }
            })
        }
    }, [themeSettingsData])
    const BlogLayout = blogComponent[blogLayout];
    return (
        <>
            <BlogLayout {...props} />
        </>
    )
}

export default DynamicBlogComponent;