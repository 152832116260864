import { useEffect, useState } from "react"

const useElementSize = (query) => {
  const [size, setSize] = useState([0, 0])
  useEffect(() => {
    const updateSize = () => {
      setSize([document.querySelector(query)?.offsetWidth, document.querySelector(query)?.offsetHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [document.querySelector(query)?.offsetHeight, query])
  return size
}

export default useElementSize