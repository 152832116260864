import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from './common/Auth';
import ThemeComponent from './constant/themeComponent';

const TemplateWrapper = ({ children, hideFooter }) => {
  const [theme, setTheme] = useState('spring');
  const { themeSettingsData } = useContext(AuthContext);

  useEffect(() => {
    if (themeSettingsData && themeSettingsData.length > 0) {
      themeSettingsData.map((i) => {
        if (i.settingsName === 'theme' && i.values[0].themeName != '' && ThemeComponent[i.values[0].themeName]) {
          setTheme(i.values[0].themeName);
        }
      });
    }
    localStorage.removeItem('catTree')
  }, [themeSettingsData]);

  const Template = ThemeComponent[theme];

  return <Template hideFooter={hideFooter} relativePosition={true} >{children}</Template>;
};

export default TemplateWrapper;
