import React from "react";
import ReactStars from "react-rating-stars-component";

const DisplayReview = (props) => {

    const { reviews } = props;
    return (
        <>
            {reviews && reviews.map((i, index) => (
                <div className="review-wrapper" key = {index}>
                    <div className="single-review">
                        <div className="review-content">
                            <div className="review-top-wrap">
                                <div className="review-left">
                                    <div className="review-name">
                                        <h4>{i.userData[0] && i.userData[0].firstName && (i.userData[0].firstName)}</h4>
                                    </div>
                                    <div className="review-rating">
                                        <ReactStars
                                            size={14}
                                            count={5}
                                            value={i.productStart}
                                            color="black"
                                            activeColor="orange"
                                            edit={false}
                                            emptyIcon={<i className="fa fa-star-o" />}
                                            filledIcon={<i className="fa fa-star" />}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="review-bottom">
                                <p>
                                    {i.review && i.review != '' ? i.review : ""}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {!reviews.length && (
                <div className="review-wrapper">
                    <p>No reviews </p>
                </div>
            )}
        </>
    );
};
export default DisplayReview;
