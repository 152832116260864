import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { setActiveLayout } from '../../helpers/product'

const ShopTopAction = ({ 
    getLayout,
    getFilterSortParams,
    productCount,
    sortedProductCount,
    pageLimit,
    pageLimit1,
    selectedSorting,
    setSelectedSorting,
    sortingOrderFilter,
    filterSortType,
}) => {

    const sortingOptions = [
        {
            value: 'productCategorySorting.productSorting_asc',
            label: 'Default Sorting Order',
        },{
            value: 'productName_asc',
            label: 'Product Name A-Z',
        },{
            value: 'productName_desc',
            label: 'Product Name Z-A'
        },{
            value: 'productLowPrice.price_desc',
            label: 'Price: High to Low'
        },{
            value: 'productLowPrice.price_asc',
            label: 'Price: Low to High'
        },{
            value: 'productId_asc',
            label: 'Item number A-Z'
        },{
            value: 'productId_desc',
            label: 'Item number Z-A'
        },{
            value: 'productCreatedDate_desc',
            label: 'Date Added Newest First'
        },{
            value: 'productCreatedDate_asc',
            label: 'Date Added Oldest First'
        },{
            value: 'isFeatured_desc',
            label: 'Featured First'
        },
    ];

    const setSortBy = (value) => {
        getFilterSortParams('filterSort', value)
        setSelectedSorting(sortingOptions.filter(x => x.value === value)[0])
    }

    const getSelected = (value) => {
        if(value === selectedSorting?.value) {
            return 'selected'
        }
        return ''
    }
    
    useEffect(() => {
        if(sortingOrderFilter && filterSortType === 'productCategorySorting.productSorting') {
            setSelectedSorting(sortingOptions[0])
        }
    }, [sortingOrderFilter, filterSortType])

    return (
        <div className="shop-top-bar mb-35">
            <div className="select-shoing-wrap d-flex align-items-center">
                <div className="shop-select d-flex align-items-center">
                    <label className='mb-0 mr-2'>Sort By:</label>
                    <select onChange={(e) => setSortBy( e.target.value)} >
                        {sortingOptions.map((option, key) => {
                            if(option.value !== 'productCategorySorting.productSorting_asc' 
                                    || (option.value === 'productCategorySorting.productSorting_asc' && sortingOrderFilter)) {
                                return <option key={key} value={option.value} selected={getSelected(option.value)}>{option.label}</option>
                            } else {
                                return '';
                            }
                        })}
                    </select>
                </div>
                <p>
                    {/* Showing {sortedProductCount} of {productCount} results */}
                    {productCount} items
                </p>
            </div>

            <div className="shop-tab d-flex align-items-center">
                <button className='d-block d-md-none'
                    onClick={(e) => {
                        getLayout('grid three-column')
                        setActiveLayout(e)
                    }}
                >
                    <i className="fa fa-square" />
                </button>
                <button
                    onClick={(e) => {
                        getLayout('grid two-column')
                        setActiveLayout(e)
                    }}
                >
                    <i className="fa fa-th-large" />
                </button>
                <button className='d-none d-md-block'
                    onClick={(e) => {
                        getLayout('grid three-column')
                        setActiveLayout(e)
                    }}
                >
                    <i className="fa fa-th" />
                </button>
                <button
                    onClick={(e) => {
                        getLayout('list')
                        setActiveLayout(e)
                    }}
                >
                    <i className="fa fa-list-ul" />
                </button>
                <div className="show-select d-flex align-items-center">
                    <div className='mr-2'>Show</div>
                    <select onChange={(e) => pageLimit1(e.target.value)} value={Number(pageLimit)}>
                        <option disabled hidden value=''></option>
                        <option value={9}>9</option>
                        {/* <option value={15}>15</option> */}
                        <option value={18}>18</option>
                        <option value={27}>27</option>
                        <option value={36}>36</option>
                        <option value={45}>45</option>
                    </select>
                </div>
            </div>
        </div>
    )
}

ShopTopAction.propTypes = {
    getFilterSortParams: PropTypes.func,
    getLayout: PropTypes.func,
    productCount: PropTypes.number,
    sortedProductCount: PropTypes.number,
}

export default ShopTopAction
