import React, { useEffect } from 'react'
import {
  Alert,
  CardContent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useAppSelector } from '../../../redux/hooks'
import {
  selectParams,
  selectSessionId,
} from '../../../redux/selectors/checkoutSelectors'
import { Address } from '../../../models/Address'
import { useCreateFulfillmentQuoteMutation } from '../../../redux/api/checkoutApi'
import FulfillmentQuoteMethodList from './FulfillmentQuoteMethodList'
import ListSkeleton from '../../../common/ListSkeleton'
import { getError } from '../../../helpers/checkout'
import { FulfillmentMethodType } from '../../../models/FulfillmentMethod'
import { LoadingButton } from '@mui/lab'
import { AddressSource, FulfillmentQuoteMethod } from '../../../models/FulfillmentQuote'
import { FulfillmentForm } from './FulfillmentActiveContent'
import { useFormContext, useWatch } from 'react-hook-form-mui'

interface FulfillmentQuoteContentProps {
  index: number;
  hide: boolean;
  type: FulfillmentMethodType;
  onSubmit: (data: FulfillmentForm) => Promise<void>;
  error?: string;
  submitLoading: boolean;
}

export default function FulfillmentQuoteContent({
  index,
  hide,
  type,
  onSubmit,
  error,
  submitLoading,
}: FulfillmentQuoteContentProps) {
  const theme = useTheme()
  const xsDisplay = useMediaQuery(theme.breakpoints.only('xs'))
  const params = useAppSelector(selectParams)
  const sessionId = useAppSelector((state) => selectSessionId(state, params))

  const address: Address = useWatch({ name: `fulfillments.${index}.address` })
  const addressSource: AddressSource = useWatch({ name: `fulfillments.${index}.addressSource` })
  const savedAddressId: string = useWatch({ name: `fulfillments.${index}.savedAddressId` })
  const method: FulfillmentQuoteMethod = useWatch({ name: `fulfillments.${index}.method` })

  const [
    createFulfillmentQuote,
    {
      data: fulfillmentQuote,
      isLoading: fulfillmentQuoteLoading,
      error: fulfillmentQuoteError,
    },
  ] = useCreateFulfillmentQuoteMutation()

  const { setValue, handleSubmit } = useFormContext<FulfillmentForm>()

  useEffect(() => {
    const createQuote = async () => {
      if (sessionId && (type === FulfillmentMethodType.PICKUP || addressSource)) {
        try {
          const quote = await createFulfillmentQuote({
            sessionId,
            type,
            addressSource,
            address: addressSource === AddressSource.USER_SUPPLIED ? address : undefined,
            savedAddressId: savedAddressId,
          }).unwrap()
          setValue(`fulfillments.${index}.quoteId`, quote._id)
        } catch (error) {
          console.error(error)
        }
      }
    }
    createQuote()
  }, [sessionId, address, addressSource, savedAddressId])

  const handleSelectMethod = (method: FulfillmentQuoteMethod) => {
    setValue(`fulfillments.${index}.method`, method)
  }

  if (hide) {
    return <></>;
  }

  return (
    <>
      <Typography variant="body1" p={2}>Select Method</Typography>
      {fulfillmentQuote && (
        <FulfillmentQuoteMethodList
          methods={fulfillmentQuote.methods}
          selectedMethodId={method?.methodId}
          onSelectMethod={handleSelectMethod}
        />
      )}
      {fulfillmentQuoteLoading && (
        <ListSkeleton primaryText secondaryAction quantity={3} />
      )}
      {fulfillmentQuoteError && (
        <CardContent>
          <Alert severity="error">
            Unable to load methods: {getError(fulfillmentQuoteError)}
          </Alert>
        </CardContent>
      )}
      <CardContent>
        <Stack direction={"column"} spacing={2}>
          {error && (
            <Alert severity="error">{error}</Alert>
          )}
          <Stack direction={'row'} justifyContent={'center'}>
            <LoadingButton
              variant="contained"
              size="large"
              onClick={handleSubmit(onSubmit)}
              loading={submitLoading}
              fullWidth={xsDisplay}
            >
              Continue
            </LoadingButton>
          </Stack>
        </Stack>
      </CardContent>
    </>
  )
}
