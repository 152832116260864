import React, { Key } from "react";
import { Avatar, AvatarGroup, Box, Paper, Stack, styled, Tooltip, Typography } from "@mui/material";
import { OrderItem } from "../../models/OrderItem";
import { numberWithCommas2 } from "../../helpers/product";
import { getItemColorText, getItemSizesText } from "../../helpers/checkout";
import { useSelector } from "react-redux";
import { selectCurrency } from "../../redux/selectors/checkoutSelectors";
import { green, purple } from "@mui/material/colors";
import { ColorLens, Style } from "@material-ui/icons";

const ItemImage = styled('img')({
  maxWidth: "100%"
});

export function ItemListItem({ item }: { item: OrderItem }) {
  const currency = useSelector(selectCurrency);

  return (
    <Stack direction={"row"} p={2} justifyContent={"space-between"} alignItems={"center"} gap={2}>
      <Stack direction={"row"} gap={2} alignItems={"center"}>
        <Box position={"relative"}>
          <Box width={"3rem"}>
            {(item.rows[0]?.image ?? item.quoteCustomImage[0]) && (
              <ItemImage src={item.rows[0]?.image ?? item.quoteCustomImage[0]} alt={item.product.name} />
            )}
          </Box>
          <Stack direction={"row"} position={"absolute"} bottom={"-12px"} right={0} component={Paper} bgcolor={"primary.main"} color={"white"} pl={"4px"} pr={"4px"}>
            {item.rows[0]?.personalization?.length > 0 &&
              <Tooltip title={"Personalization Added to Item"}>
                <Box fontSize={"1rem"}>
                  <Style fontSize="inherit"/>
                </Box>
              </Tooltip>
            }
            {(item.rows[0]?.decoration?.length ?? 0) > 0 &&          
              <Tooltip title={"Artwork Added to Item"}>
                <Box fontSize={"1rem"}>
                  <ColorLens fontSize="inherit" />
                </Box>
              </Tooltip>
            }
          </Stack>
        </Box>
        <Stack>
          <Typography variant="body2" gutterBottom>{item.product.name}</Typography>
          {item.aetherData.showColor &&
            <Typography variant="lineCaption">Color: {getItemColorText(item)}</Typography>
          }
          {item.aetherData.showSize &&
            <Typography variant="lineCaption">Size: {getItemSizesText(item)}</Typography>
          }
          <Typography variant="lineCaption">Qty: {item.quantity}</Typography>
        </Stack>
      </Stack>
      <Stack>
        {item.aetherPriceDisabled ? <Typography variant="body2"> - </Typography> : <Typography variant="body2">{currency.currencySymbol}{item.aetherData.displayTotalPrice.toFixed(item.aetherData.decimalsToShow)}</Typography>}
      </Stack>
    </Stack>
  );
}