import { Stack } from '@mui/material'
import React from 'react'
import CheckoutTextField from '../CheckoutTextField'

export default function POForm() {

  return (
    <Stack direction={'column'} spacing={2}>
      <CheckoutTextField
        name={'poNumber'}
        label={'PO Number'}
        rules={{ required: true }}
      />
    </Stack>
  )
}
