import React, { lazy } from 'react';
const Spring = lazy(() => import('../pages/home/Spring'));
const Summer = lazy(() => import('../pages/home/Summer'));
const Fall = lazy(() => import('../pages/home/Fall'));
const Winter = lazy(() => import('../pages/home/Winter'));
const Beach = lazy(() => import('../pages/home/Beach'));
const Apparel = lazy(() => import('../pages/home/Apparel'));
const Promotional = lazy(() => import('../pages/home/Promotional'));
const Collegiate = lazy(() => import('../pages/home/Collegiate'));
const Awards = lazy(() => import('../pages/home/Awards'));
const TradeShow = lazy(() => import('../pages/home/TradeShow'));
const Contest = lazy(() => import('../pages/home/Contest'));
const Fancy = lazy(() => import('../pages/home/Fancy'));
const Simple = lazy(() => import('../pages/home/Simple'));
const Complex = lazy(() => import('../pages/home/Complex'));
const HighlightBanner = lazy(() => import('../pages/home/HighlightBanner'));
const BannerSlider = lazy(() => import('../pages/home/BannerSlider'));
const ImageHeavy = lazy(() => import('../pages/home/ImageHeavy'));
const ColorBackground = lazy(() => import('../pages/home/ColorBackground'));
const ProductSlider = lazy(() => import('../pages/home/ProductSlider'));
const Technology = lazy(() => import('../pages/home/Technology'));
const SplitBanner = lazy(() => import('../pages/home/SplitBanner'));
const ProductBased = lazy(() => import('../pages/home/ProductBased'));
const SeparateMenu = lazy(() => import('../pages/home/SeparateMenu'));
const MenuOverlay = lazy(() => import('../pages/home/MenuOverlay'));
const ProductCentered = lazy(() => import('../pages/home/ProductCentered'));
const LargeProducts = lazy(() => import('../pages/home/LargeProducts'));

const DualPictures = lazy(() => import('../pages/home/DualPictures'));
const DailyDeals = lazy(() => import('../pages/home/DailyDeals'));
const PicturesHighlighted = lazy(() => import('../pages/home/PicturesHighlighted'));
const Basic = lazy(() => import('../pages/home/Basic'));
const LatestNews = lazy(() => import('../pages/home/LatestNews'));
const FullWidth = lazy(() => import('../pages/home/FullWidth'));
const Christmas = lazy(() => import('../pages/home/Christmas'));
const Dark = lazy(() => import('../pages/home/Dark'));
const Deadline = lazy(() => import('../pages/home/Deadline'));
const SpecialEvents = lazy(() => import('../pages/home/SpecialEvents'));

const ThemeComponent = {
  spring: Spring,
  summer: Summer,
  fall: Fall,
  winter: Winter,
  beach: Beach,
  apparel: Apparel,
  promotional: Promotional,
  collegiate: Collegiate,
  awards: Awards,
  'trade-show': TradeShow,
  contest: Contest,
  fancy: Fancy,
  simple: Simple,
  complex: Complex,
  'highlight-banner': HighlightBanner,
  'banner-slider': BannerSlider,
  'image-heavy': ImageHeavy,
  'color-background': ColorBackground,
  'product-slider': ProductSlider,
  technology: Technology,
  'split-banner': SplitBanner,
  'product-based': ProductBased,
  'separate-menu': SeparateMenu,
  'menu-overlay': MenuOverlay,
  'product-centered': ProductCentered,
  'large-products': LargeProducts,
  'dual-pictures': DualPictures,
  'daily-deals': DailyDeals,
  'pictures-highlighted': PicturesHighlighted,
  basic: Basic,
  'latest-news': LatestNews,
  'full-width': FullWidth,
  christmas: Christmas,
  dark: Dark,
  deadline: Deadline,
  'special-events': SpecialEvents,
};

export default ThemeComponent;
