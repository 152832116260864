import 'date-fns'
import React, { useEffect, useState } from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { makeStyles } from '@material-ui/core/styles'
import { Modal, Button } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        maxWidth: '450px'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxHeight: '96vh',
        overflowY: 'scroll',
    },
}))

const SharedModal = ({
    showDecoPopup,
    handleClose,
    indexPopup,
    mainArrayKeyPopup,
    itemsRowsPopup,
    popupText,
    removeDeco,
    setShowDecoPopup,
    decoItemData,
}) => {
    const classes = useStyles()
    const defaultHtml = `<p data-renderer-start-pos="182" style="margin: 0.75rem 0px 0px; padding: 0px; line-height: 1.714; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif; white-space: pre-wrap;">Deleting an artwork will clear your artwork selection.</p>
    <p data-renderer-start-pos="239" style="margin: 0.75rem 0px 0px; padding: 0px; line-height: 1.714; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif; white-space: pre-wrap;">If you do not want to clear your selection you can return to a location you have already completed and select a different artwork without deleting one. Your new artwork will replace the existing one.</p>
    <p data-renderer-start-pos="440" style="margin-top: 0.75rem; margin-right: 0px; margin-left: 0px; padding: 0px; line-height: 1.714; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif; white-space: pre-wrap;">Would you like to clear artworks and start over?</p>`
    const confirmPopup = () => {
        removeDeco(mainArrayKeyPopup, itemsRowsPopup, null, decoItemData);
        setShowDecoPopup(false);
    }

    const [text, setText] = useState(defaultHtml);

    useEffect(() => {
        let temp = popupText.replace(new RegExp('<p>', 'g'), '')
            .replace(new RegExp('</p>', 'g'), '')
            .replace(new RegExp('<br>', 'g'), '')
            .replace(new RegExp('<br/>', 'g'), '')
            .replace(new RegExp('&nbsp;', 'g'), '')
        if(!!temp) {
            setText(popupText);
        }
    }, [])
    
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={showDecoPopup}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={showDecoPopup}> 
                    <div className={`${classes.paper} coupons-modal`}>
                        <div dangerouslySetInnerHTML={{ __html: text }} style={{marginBottom: '20px'}}>

                        </div>
                        <Button variant="contained" className="aetherColor mr-3"
                            onClick={() => {
                                setShowDecoPopup(false)
                            }}
                            style={{backgroundColor: 'red', color: 'white'}}
                        >
                            No
                        </Button>
                        <Button onClick={() => confirmPopup()} variant="contained" style={{backgroundColor: 'green', color: 'white'}}>
                            Yes
                        </Button>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}

export default SharedModal
