import React, { lazy } from "react";
const BlogStandard = lazy(() => import("../pages/blog/BlogStandard"));
const BlogNoSidebar = lazy(() => import("../pages/blog/BlogNoSidebar"));
const BlogRightSidebar = lazy(() => import("../pages/blog/BlogRightSidebar"));

const blogComponent = {
    "blog-standard": BlogStandard,
    "blog-no-sidebar": BlogNoSidebar,
    "blog-right-sidebar": BlogRightSidebar,
};

export default blogComponent