const Validate = (inputs) => {
    const errors = {};

    // if (!inputs.image1 && !inputs.image_file) {
    //     errors.image1 = "Please upload image";
    // }

    if (inputs.hasOwnProperty('deadLineDate') && !inputs.deadLineDate.length) {
        errors.deadLineDate = "Please ender dealLine date";
    }

    return errors;
}

export default Validate;