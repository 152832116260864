import { CREATE_ARTWORK_URL, CUSTOM_ADVANCE_URL, X_TENANT_ID } from "../../common/ActionUrl";
import axios from 'axios';

const requestOptions = {
    headers: { 'X-TENANT-ID': X_TENANT_ID },
}

export const customAdvanceCall = async (data) => {
    return await axios.post(CUSTOM_ADVANCE_URL, data, requestOptions);
}

export const createArtwork = async (data) => {
    return await axios.post(CREATE_ARTWORK_URL, data, requestOptions);
}
