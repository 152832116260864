import {useContext, useEffect, useState} from "react";

export const useShopCategoryDescriptionState = (props) => {
    const [categoryDescription, setCategoryDescription] = useState("")
    const [categoryBottomDescription, setCategoryBottomDescription] = useState("")
    const {
        selectedCategories,
        generalDescription
    } = props;


    const getTreeCategories = (node) => {
        if(node.category.parent_id) {
            const index = selectedCategories.findIndex(x => x.category._id === node.category.parent_id);
            if(index >= 0) {
                const child = selectedCategories[index].children || []
                const childIndex = child?.length ? child.findIndex(x => x.category._id === node.category._id) : -1
                if(childIndex < 0) {
                    child.push(node)
                }
                selectedCategories[index].children = child
            }
        }
    }

    const isChildCategory = (parentId, node) => {
        if(node.category.parent_id === parentId) {
            return true;
        }
        if(node.category.parent_id) {
            const index = selectedCategories.findIndex(x => x.category._id === node.category.parent_id)
            if(index >= 0){
                return isChildCategory(parentId, selectedCategories[index])
            }
        }
        return false
    }

    useEffect(() => {
        let parentsCategories = [];
        let childrenCategories = [];
        if (selectedCategories.length > 0) {
            selectedCategories.forEach(x => {
                getTreeCategories(x)
            })
            parentsCategories = selectedCategories.filter(x => !x.category?.parent_id)
            if (parentsCategories.length === 0) {
                parentsCategories = selectedCategories.filter(x => x.children)
            }
            if (parentsCategories.length === 1) {
                childrenCategories = selectedCategories.filter(x => x.category?.parent_id === parentsCategories[0].category._id)
                    .filter((value, index, self) => {
                        return self.indexOf(value) === index;
                    })

                const nonChildren = selectedCategories.filter(x => x.category?.parent_id !== parentsCategories[0].category._id &&
                    x.category._id !== parentsCategories[0].category._id).filter(x => !isChildCategory(parentsCategories[0].category._id, x))
                if ((childrenCategories.length === 0 || childrenCategories.length === parentsCategories[0].children.length) && (!nonChildren.length)) {
                    setCategoryDescription(parentsCategories[0].category.categoryDescription ?
                        parentsCategories[0].category.categoryDescription : generalDescription);
                    setCategoryBottomDescription(parentsCategories[0].category.categoryDescriptionBottom ?
                        parentsCategories[0].category.categoryDescriptionBottom : "");
                } else if (nonChildren.length === 0 && childrenCategories.length === 1) {
                    setCategoryDescription(childrenCategories[0].category.categoryDescription ?
                        childrenCategories[0].category.categoryDescription : generalDescription);
                    setCategoryBottomDescription(childrenCategories[0].category.categoryDescriptionBottom ?
                        childrenCategories[0].category.categoryDescriptionBottom : "");
                } else {
                    setCategoryDescription(generalDescription);
                    setCategoryBottomDescription("");
                }
            } else if (selectedCategories.length === 1) {
                setCategoryDescription(selectedCategories[0].category.categoryDescription ?
                    selectedCategories[0].category.categoryDescription : "")
                setCategoryBottomDescription(selectedCategories[0].category.categoryDescriptionBottom ?
                    selectedCategories[0].category.categoryDescriptionBottom : "");
            } else {
                setCategoryDescription(generalDescription)
                setCategoryBottomDescription("");
            }
        }
    }, [selectedCategories])

    return {
        categoryDescription,
        categoryBottomDescription
    }
}
