import React from "react";
import { useShopCategoryDescriptionState } from "../state/useShopCategoryDescriptionState";

const ShopGridNoSidebar = ({
  ShopTopbarComponent,
  PaginatorComponent,
  ShopProductsComponent,
  selectedCategories,
  generalDescription
}) => {
  const  {
    categoryDescription,
    categoryBottomDescription
} = useShopCategoryDescriptionState({
    generalDescription,
    selectedCategories
})
  return (
    <div className="shop-area pt-30 pb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* shop topbar default */}
            <div className="row d-flex mb-12 mt-3">
              <div className="col-md-12 col-12">
                { (categoryDescription !== "" &&
                    <h6 dangerouslySetInnerHTML = {{__html: `${categoryDescription}`}}/>
                )
                }
              </div>
            </div>
            {ShopTopbarComponent()}

            {/* shop page content default */}
            {ShopProductsComponent()}

            {/* shop product pagination */}
            <div className="pro-pagination-style text-center mt-30">
              {PaginatorComponent()}
            </div>
            <div className="row d-flex mb-12 mt-4">
              <div className="col-md-12 col-12">
                { (categoryBottomDescription !== "" &&
                    <h6 dangerouslySetInnerHTML = {{__html: `${categoryBottomDescription}`}}/>
                )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopGridNoSidebar;
