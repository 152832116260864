export const ShopViewsObject = {
  SHOP_GRID_STANDARD: "shop-grid-standard",
  SHOP_GRID_TWO_COLUMN: "shop-grid-two-column",
  SHOP_GRID_FULL_WIDTH: "shop-grid-full-width",
  SHOP_GRID_FILTER: "shop-grid-filter",
  SHOP_GRID_NO_SIDEBAR: "shop-grid-no-sidebar",
  SHOP_LIST_STANDARD: "shop-list-standard",
  SHOP_GRID_RIGHT_SIDEBAR: "shop-grid-right-sidebar",
  SHOP_LIST_FULL_WIDTH: "shop-list-full-width",
  SHOP_LIST_TWO_COLUMN: "shop-list-two-column",
};


export const ShopViewsList = Object.keys(ShopViewsObject);
export const ShopViewsListValues = Object.values(ShopViewsObject);