import React, { useEffect } from "react";
import ClearCache from 'react-clear-cache';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from "@material-ui/core";

const CacheBuster = props => {
  return (<ClearCache duration={80000} > 
    {({ isLatestVersion, emptyCacheStorage }) => (<div>
      {!isLatestVersion && (
        <Dialog open={true} aria-labelledby="form-dialog-title">
          <DialogContent>
            <div style={{ marginBottom: "11px" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={e => {
                  e.preventDefault();
                  emptyCacheStorage();
                }}
              >
                Refresh
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>)}
  </ClearCache >)
};

export default (CacheBuster);
