import { LOGIN_USER_SUCCESS, LOGIN_SUCCESS, FETCH_ERROR, IS_LOADING, STORE_DETAILS, CONTACT_DETAILS } from "../actions/loginActions";

const initState = {
  users: [],
  user: [],
  contactDetails: null,
  storeDetails: {}
};

const loginReducer = (state = initState, action) => {
  switch (action.type) {
    case STORE_DETAILS:
      return {
        ...state,
        storeDetails: action.payload
      }
    case LOGIN_USER_SUCCESS: {
      return {
        ...state,
        users: action.payload
      };
    }

    case LOGIN_SUCCESS: {
      return {
        ...state,
        user: action.payload
      };
    }
    case IS_LOADING: {
      return {
        ...state,
        loading: action.loading,
      }
    }
    case FETCH_ERROR: {
      return {
        ...state,
        fetchError: true
      }
    }
    case CONTACT_DETAILS:
      return {
        ...state,
        contactDetails: action.payload
      }
    default:
      return state;
  }
};

export default loginReducer;
