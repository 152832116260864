import React, { useContext, useEffect, useRef } from 'react'
import {
  Box,
  Button,
  Card,
  Container,
  Grid2,
  Skeleton,
  Stack,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import axios from 'axios'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import ItemsCard from './ItemsCard'
import { AuthContext } from '../../common/Auth'
import {
  useGetCheckoutSettingsQuery,
  useGetOrCreateCheckoutSessionQuery,
} from '../../redux/api/checkoutApi'
import { newTheme } from '../../components/theme/theme'
import { useThemeSettings } from '../../layouts/state/useThemeSettings'
import CustomerDetailsCard from './customerDetails/CustomerDetailsCard'
import {
  setDebug,
  updateSessionParams,
} from '../../redux/reducers/checkoutReducer'
import {
  selectActiveStep,
  selectCartId,
  selectDebug,
  selectHeaderOffset,
  selectParams,
  selectStatus,
} from '../../redux/selectors/checkoutSelectors'
import FulfillmentCard from './fulfillment/FulfillmentCard'
import { useLocation } from 'react-router-dom'
import DebugCard from './DebugCard'
import PaymentCard from './payment/PaymentCard'
import { CheckoutStatus, CheckoutStep } from '../../models/CheckoutSession'
import ConfirmationCard from './ConfirmationCard'

export default function NewCheckout() {
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const { user, settingData } = useContext(AuthContext)
  const { themedefaultColor, themedefaultTextColor } = useThemeSettings({
    settingData,
  })
  const cartId = useAppSelector(selectCartId)
  const debug = useAppSelector(selectDebug)
  const params = useAppSelector(selectParams)
  const offset = useAppSelector(selectHeaderOffset)
  const status = useAppSelector((state) => selectStatus(state, params))
  const activeStep = useAppSelector((state) => selectActiveStep(state, params))
  const { isSuccess: sessionSuccess, isLoading: sessionLoading } =
    useGetOrCreateCheckoutSessionQuery(
      { cartId, userId: user.userId ?? localStorage.getItem('-') },
      { skip: !cartId },
    )
  const { isSuccess: settingsSuccess, isLoading: settingsLoading } =
    useGetCheckoutSettingsQuery()

  const theme = useTheme()
  const xsDisplay = useMediaQuery(theme.breakpoints.only('xs'))

  const fulfillmentRef = useRef<HTMLDivElement>(null)
  const paymentRef = useRef<HTMLDivElement>(null)

  // Can be replaced with a selector once user ID is in Redux
  useEffect(() => {
    if (cartId) {
      dispatch(
        updateSessionParams({
          userId: user.userId ?? localStorage.getItem('-'),
          cartId,
        }),
      )
    }
  }, [user, cartId])

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search)
      if (params.get('debug')) {
        dispatch(setDebug(params.get('debug') === 'true'))
      }
    }
  }, [search])

  useEffect(() => {
    if (!sessionSuccess || !settingsSuccess) {
      return;
    }
    let element = null
    if (activeStep === CheckoutStep.FULFILLMENT) {
      element = fulfillmentRef.current
    }
    if (activeStep === CheckoutStep.PAYMENT) {
      element = paymentRef.current
    }
    if (element) {
      const y = (element.getBoundingClientRect().top ?? 0) - offset + window.scrollY - 16;
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }, [activeStep, sessionSuccess, settingsSuccess, offset])

  const isLoading = sessionLoading || settingsLoading
  const isSuccess = sessionSuccess && settingsSuccess

  return (
    <ThemeProvider
      theme={newTheme({ themedefaultColor, themedefaultTextColor })}
    >
      <Container
        maxWidth={'lg'}
        sx={{
          pt: 2,
          pb: 2,
          flexGrow: 1,
          pl: xsDisplay ? '4px' : '16px',
          pr: xsDisplay ? '4px' : '16px',
        }}
      >
        <Stack direction={'column'} alignItems={'stretch'} height={'100%'}>
          <Typography variant={'h4'} gutterBottom>
            Checkout
          </Typography>
          <Grid2 container flexGrow={1} spacing={2} direction={'row-reverse'}>
            {status === CheckoutStatus.COMPLETED && [
              <Grid2 key={'confirm'} size={12}>
                <ConfirmationCard />
              </Grid2>,
              <Grid2 key={'debug'} size={12}>
                {debug && <DebugCard />}
              </Grid2>,
            ]}
            {status !== CheckoutStatus.COMPLETED && [
              <Grid2 key={'itemscard'} size={{ sm: 12, md: 4 }}>
                <Box sx={{ position: 'sticky', top: offset + 16 }}>
                  {isLoading && <Skeleton variant="rounded" height={'100%'} />}
                  {isSuccess && <ItemsCard />}
                </Box>
              </Grid2>,
              <Grid2 key={'maincontent'} size={{ sm: 12, md: 8 }}>
                <Stack height={'100%'} spacing={2}>
                  {isLoading && <Skeleton variant="rounded" height={'34%'} />}
                  {isLoading && <Skeleton variant="rounded" height={'33%'} />}
                  {isLoading && <Skeleton variant="rounded" height={'33%'} />}
                  {debug && <DebugCard />}
                  {isSuccess && <CustomerDetailsCard />}
                  {isSuccess && <FulfillmentCard ref={fulfillmentRef} />}
                  {isSuccess && <PaymentCard ref={paymentRef} />}
                </Stack>
              </Grid2>,
            ]}
          </Grid2>
        </Stack>
      </Container>
    </ThemeProvider>
  )
}
