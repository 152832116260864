import axios from 'axios';
import {
    X_TENANT_ID,
    CATEGORY_BY_IDS, NEWCOLOR
} from '../../common/ActionUrl';

const requestOptions = {
    headers: {
        "Content-Type": "application/json",
        'X-TENANT-ID': X_TENANT_ID,
    },
}

export const fetchCategoryList = async (data) => {
    return  axios.post(CATEGORY_BY_IDS, data, requestOptions);
};

export const fetchColors = async () => {
    return  axios.post(NEWCOLOR, {}, requestOptions);
};

