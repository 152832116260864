import { createSelector } from "reselect";

export const selectKitItems = createSelector(
    [
        (_, kitId) => kitId,
        (state) => state.cartData.pendingCartItems,
        (state) => state.cartData.cartItems,
    ],
    (kitId, pendingCartItems, cartItems) => [
        ...cartItems.filter((item) => item?.product?.kitId === kitId),
        ...pendingCartItems.filter((item) => item?.product?.kitId === kitId)
    ]
);

export const selectKitItemsLength = createSelector(
    [selectKitItems],
    (kitItems) => kitItems.length
);

export const selectKitEditingIndex = createSelector(
    [selectKitItems],
    (kitItems) => kitItems.findIndex((item) => item.editing)
);

export const selectCartItems = createSelector(
    [(state) => state.cartData.cartItems],
    (cartItems) => (cartItems || []).filter((item) => !item.pending)
);
