import { Grid2 } from "@mui/material";
import React from "react";
import Skeleton from "react-loading-skeleton";

export default function GridSkeleton({ size, quantity }: { size: number, quantity: number }) {
  return (
    <Grid2 container spacing={2}>
      {Array.from({ length: quantity }, Number.call, (i: number) => i + 1).map(
        (_, idx) => (
          <Grid2 key={idx} size={{ xs: 12, sm: size }}>
            <Skeleton width={"100%"} height={"40px"} />
          </Grid2>
        ),
      )}
    </Grid2>
  );
}