import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../common/ActionUrl';
import { CheckoutSession } from '../../models/CheckoutSession';
import { CheckoutQuestion } from '../../models/CheckoutQuestion';
import { Question, QuestionResponse } from '../../models/Question';
import { AddressContact } from '../../models/Address';

export const aetherApi = createApi({
    reducerPath: 'aetherApi',
    tagTypes: ['CheckoutSession', 'Questions'],
    baseQuery: fetchBaseQuery({
      baseUrl: API_URL,
      prepareHeaders: (headers) => {
        const token = localStorage.getItem('accessToken');
        if (token) {
          headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
      }
    }),
    endpoints: (builder) => ({
        getCheckoutQuestions: builder.query<Question[], void>({
            query: () => `api/v1/question`,
            providesTags: ['Questions'],
            transformResponse: (response: QuestionResponse) => response.results.map((question: Question) => question)
        }),
    }),
});

export const { useGetCheckoutQuestionsQuery } = aetherApi;