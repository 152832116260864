import {createArtwork, customAdvanceCall} from '../../../util/advance/advanceAPIUtil'
import {useContext} from "react";
import {AuthContext} from "../../../common/Auth";
import {createArtworkBody} from "../../../common/AdvanceBodies";


export const useProductArtwork = ({ productSettings, decoratorsVendorsList, productId }) => {
    const { storeDetails, user } = useContext(AuthContext)

    const saveArtwork = (selectedFile, artName, notes, designArray, decoLocation, decoratorsType, callingLayout = 0, isCustomAetherArtwork = 0) => {
        const requestBody = {...createArtworkBody}
        requestBody.data = {
            productId: productId,
            customerId: storeDetails && storeDetails.accountId,
            accountId: storeDetails && storeDetails.accountId,
            statusId: '5',
            identity: !!productSettings?.prefixOnCustomArtwork ? `${productSettings?.prefixOnCustomArtwork}- ${artName}` : artName,
            image: '',
            notes: notes,
            featureImage: selectedFile,
            artworkLocation: decoLocation,
            royaltyWholesaleAmountType: '',
            artworkSource: 'aether',
            inactivateAfterOrderComplete: "1",
            isCustomAetherArtwork,
            singleUseOnly: "1",
            artworkType: callingLayout === 1 ? decoratorsType : designArray?.name,
            design: {
                name: (callingLayout === 0 ? (!!productSettings?.prefixOnCustomArtwork ? `${productSettings?.prefixOnCustomArtwork}- ${artName}` : artName) : 'Test 1'),
                description: '',
                color: '',
                stitchCount: 10000,
                size: '',
                url: '',
                customerId: storeDetails && storeDetails.accountId,
                customerName: storeDetails && storeDetails.accountName,
                decoVendorId: decoratorsVendorsList
                    ? decoratorsVendorsList?.[0]?.id
                    : '',
                variation: [
                    {
                        itemImage: [selectedFile],
                        itemImageThumbnail: [selectedFile],
                        design_variation_product: '',
                        design_variation_color: '',
                        design_variation_location: '',
                        design_note: '',
                        design_color_thread_pms: [],
                    },
                ],
            },
            thumbnail: selectedFile,
        };
        return new Promise((resolve, reject) => {
            Promise.resolve(customAdvanceCall(requestBody))
                .then((res) => {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data)
                            if (user && user.userId) {
                                let requestData = {
                                    designId: res.data.data.designId,
                                    userId: user?.userId
                                }
                                createArtwork(requestData).catch((err) => {
                                    reject(err)
                                })
                            }
                        }
                    }
                }).catch((error) => {
                    reject(error)
                })
        })
    }

    return {
        saveArtwork
    }

}
