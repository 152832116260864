import React from 'react'
import { Card, Divider, Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useAppSelector } from '../../redux/hooks'
import {
  selectCurrency,
  selectItems,
  selectOrderDecimalsToShow,
  selectOrderTotals,
  selectParams,
  selectPaymentRequests,
  selectRemainingBalanceDue,
  selectStatus,
} from '../../redux/selectors/checkoutSelectors'
import { ItemListItem } from './ItemListItem'
import { CheckoutStatus } from '../../models/CheckoutSession'
import { PAYMENT_NAMES } from '../../models/PaymentMethodType'

export default function ItemsCard() {
  const params = useAppSelector(selectParams)
  const orderDecimalsToShow = useAppSelector((state) => selectOrderDecimalsToShow(state, params))
  const status = useAppSelector((state) => selectStatus(state, params))
  const items = useAppSelector((state) => selectItems(state, params))
  const paymentRequests = useAppSelector(selectPaymentRequests)
  const remainingBalanceDue = useAppSelector((state) => selectRemainingBalanceDue(state, params, null))
  const currency = useSelector(selectCurrency)
  const { subtotal, shipping, tax, total } = useAppSelector((state) =>
    selectOrderTotals(state, params),
  )

  return (
    <Card variant="outlined" data-testid="itemcard">
      <Stack divider={<Divider flexItem />}>
        <>
          <Typography variant="h6" align="center" mt={1}>
            Items
          </Typography>
          {items?.map((item, idx) => [
            <ItemListItem key={idx} item={item} data-testid={'listItem' + idx} />,
          ])}
        </>
        <Stack direction={"column"} p={2}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body2">Shipping</Typography>
            <Typography variant="body2">
              {status === CheckoutStatus.PENDING_PAYMENT
                ? `${currency.currencySymbol}${shipping.toFixed(orderDecimalsToShow)}`
                : '-'}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body2">Subtotal</Typography>
            <Typography variant="body2">
              {currency.currencySymbol}
              {subtotal.toFixed(orderDecimalsToShow)}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body2">Tax</Typography>
            <Typography variant="body2">
              {status === CheckoutStatus.PENDING_PAYMENT
                ? `${currency.currencySymbol}${tax.toFixed(orderDecimalsToShow)}`
                : '-'}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'} p={2}>
          <Typography variant="body1">Total</Typography>
          <Typography variant="body1">
            {currency.currencySymbol}
            {total.toFixed(orderDecimalsToShow)}
          </Typography>
        </Stack>
        {paymentRequests.length > 0 && (
          <>
            <Stack direction={"column"} p={2}>
              {paymentRequests.map((request, idx) => (
                <Stack key={idx} direction={"row"} justifyContent={"space-between"}>
                  <Typography variant="body2">{PAYMENT_NAMES[request.methodType]}</Typography>
                  <Typography variant="body2">- {currency.currencySymbol}{request.amount.toFixed(orderDecimalsToShow)}</Typography>
                </Stack>
              ))}
            </Stack>
          </>
        )}
        {status === CheckoutStatus.PENDING_PAYMENT && (
          <>
            <Stack direction={'row'} justifyContent={'space-between'} p={2}>
              <Typography variant="body1">Balance Remaining</Typography>
              <Typography variant="body1">
                {currency.currencySymbol}
                {remainingBalanceDue.toFixed(orderDecimalsToShow)}
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
    </Card>
  )
}
