import React from 'react'
import { Stack } from '@mui/system'
import { useGetFulfillmentTypesQuery } from '../../../redux/api/fulfillmentMethodApi'
import {
  Card,
  CardActionArea,
  CardContent,
  Radio,
  Skeleton,
  Typography,
} from '@mui/material'
import { FulfillmentMethodType } from '../../../models/FulfillmentMethod'
import { LocationCity } from '@material-ui/icons'
import { LocalShipping } from '@mui/icons-material'

interface FulfillmentTypeCardProps {
  icon: React.ReactNode
  name: string
  onSelect: () => void
  selected: boolean
  children?: React.ReactNode
}

export default function FulfillmentTypeCard({
  icon,
  name,
  onSelect,
  selected,
  children,
}: FulfillmentTypeCardProps) {
  return (
    <Card>
      <CardActionArea onClick={onSelect} disabled={selected}>
        <CardContent>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Stack direction={'row'} spacing={2}>
              {icon}
              <Typography variant="body1">{name}</Typography>
            </Stack>
            <Radio checked={selected} />
          </Stack>
        </CardContent>
      </CardActionArea>
      {selected && children}
    </Card>
  )
}
