import React, { Fragment, useState, useEffect, useContext } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Button, IconButton, useMediaQuery } from "@material-ui/core";
import PlaceHolder from "../../assets/img/placeholder-image.png";
import { numberWithCommas } from "../../helpers/product";
import DeleteIcon from '@material-ui/icons/Delete';
import Copyrowicon from "../../assets/img/copyrow.png";
import { AuthContext } from "../../common/Auth";
import { useProductPriceToShow } from "../../components/product/state/useProductPriceToShow";

const QuoteSeprateList = ({
  key,
  getDecoHtml,
  catalogueCustomRows,
  getStock,
  productSettings,
  getSettingStatus,
  getDisplayStyle,
  removeDeco,
  ToggleDivs,
  addFirstRow,
  copyRow,
  deleteRow,
  setPersonalizationAdded,
  personalizationAdded,
  getPesonalizationTable,
  setToggleValue,
  toggleValue,
  deletePersonalization,
  addPersonalization,
  artworkList,
  setCatalogueCustomRows,
  isAllowoutofStockOrder,
  showLogoblock,
  priceTable,
  colors_final,
  setPrice,
  currency,
  saveRowchanges,
  product,
  props,
  inventorySettings,
  tempSelectedColor,
  setSelectedImage,
  selectedImage,
  isDisableProductClone
}) => {
  const isSmall = useMediaQuery('(max-width: 600px)')
  const isMedium = useMediaQuery('(max-width: 900px)')
  const {
    usePointsAsCurrency
  } = useContext(AuthContext)

  const {
    getPointsFromValue,
    getPointsValue
  } = useProductPriceToShow({ productSettings, product  })

  const getSizeDropdown = (itemRow) => {
    return (
      <select
        className="selectBox"
        value={itemRow.size}
        onChange={(e) => changeSize(e, itemRow)}
      >
        {priceTable.map((rowArray, key) => {
          return (
            <option key={key} value={rowArray.size}>
              {rowArray.size}
            </option>
          )
        })}
      </select>
    )
  }
  const changeColor = (e, quoteItemRow) => {
    if (quoteItemRow?.size == '' && priceTable?.[0]?.size) {
      changeSize(null, quoteItemRow, priceTable?.[0]?.size)
    }
    catalogueCustomRows = catalogueCustomRows.map((rowGroup, groupKey) => {
      rowGroup.map((itemRow, key) => {
        if (itemRow.id == quoteItemRow.id) {
          itemRow.color = e.target.value
          if (priceTable?.length == 1 && itemRow.size == '') {
            itemRow.size = priceTable?.[0] ? priceTable[0].size : ''
          }
          priceTable.map((priceTabel) => {
            if (priceTabel.size == itemRow.size) {
              let priceTableOptions = priceTabel.priceOptions
              priceTableOptions.map((priceOption) => {
                if (priceOption.ColorArray.Color.colorName == itemRow.color) {
                  itemRow.product.sku = priceOption.sku
                  itemRow.totalPrice = itemRow.price * itemRow.quantity
                  product.MediaContent.forEach((m) => {
                    if (m.color === itemRow.color) {
                      if (m.url && m.url != '') {
                        itemRow.image = m.url
                      }
                    }
                  })
                  if (
                    priceOption.partPrice.PartPriceArray.PartPrice.length == 1
                  ) {
                    itemRow.price =
                      priceOption.partPrice.PartPriceArray.PartPrice[0].salePrice
                  } else {
                    priceOption.partPrice.PartPriceArray.PartPrice.map(
                      (p, pKey) => {
                        if (Number(p.minQuantity) <= Number(itemRow.quantity)) {
                          itemRow.price = p.salePrice
                        } else if (pKey == 0) {
                          itemRow.price = p.salePrice
                        }
                      },
                    )
                  }
                  itemRow.totalPrice = itemRow.price * itemRow.quantity
                }
              })
            }
          })
        }
        return itemRow
      })
      return rowGroup
    })
    setCatalogueCustomRows(catalogueCustomRows)
  }
  const changeSize = (e, quoteItemRow, defaultSize = '') => {
    catalogueCustomRows = catalogueCustomRows.map((rowGroup, groupKey) => {
      rowGroup.map((itemRow, key) => {
        if (itemRow.id == quoteItemRow.id) {
          itemRow.size = e?.target?.value ? e.target.value : defaultSize
          priceTable.map((priceTabel) => {
            if (priceTabel.size == itemRow.size) {
              let priceTableOptions = priceTabel.priceOptions
              priceTableOptions.map((priceOption) => {
                if (priceOption.ColorArray.Color.colorName == itemRow.color) {
                  itemRow.product.sku = priceOption.sku
                  itemRow.totalPrice = itemRow.price * itemRow.quantity
                  product.MediaContent.forEach((m) => {
                    if (m.color === itemRow.color) {
                      if (m.url && m.url != '') {
                        itemRow.image = m.url
                      }
                    }
                  })
                  if (
                    priceOption.partPrice.PartPriceArray.PartPrice.length == 1
                  ) {
                    itemRow.price =
                      priceOption.partPrice.PartPriceArray.PartPrice[0].salePrice
                  } else {
                    priceOption.partPrice.PartPriceArray.PartPrice.map(
                      (p, pKey) => {
                        if (Number(p.minQuantity) <= Number(itemRow.quantity)) {
                          itemRow.price = p.salePrice
                        } else if (pKey == 0) {
                          itemRow.price = p.salePrice
                        }
                      },
                    )
                  }
                  itemRow.totalPrice = itemRow.price * itemRow.quantity
                }
              })
            }
          })
        }
        return itemRow
      })
      return rowGroup
    })
    setCatalogueCustomRows(catalogueCustomRows)
  }
  const changeQty = (e, quoteItemRow) => {
    const regularExpression = /^[0-9\b]+$/
    if (e.target.value != '' && !regularExpression.test(e.target.value)) {
      return false
    }
    if (!Number(e.target.value) && e.target.value != '') {
      return false
    }
    catalogueCustomRows = catalogueCustomRows.map((rowGroup, groupKey) => {
      rowGroup.map((itemRow, key) => {
        if (itemRow.id == quoteItemRow.id) {
          itemRow.quantity = e.target.value
        }
        priceTable.map((priceTableRow) => {
          if (priceTableRow.size === itemRow.size) {
            let priceOptionsArray = priceTableRow.priceOptions
            priceTable.map((priceTabel) => {
              if (priceTabel.size == itemRow.size) {
                let priceTableOptions = priceTabel.priceOptions
                priceTableOptions.map((priceOption) => {
                  if (priceOption.ColorArray.Color.colorName == itemRow.color) {
                    let qtStock = getStock(itemRow.size, itemRow.color)
                    if (isAllowoutofStockOrder) {
                    } else if (qtStock < itemRow.quantity) {
                      itemRow.quantity = qtStock
                    }
                    itemRow.product.sku = priceOption.sku
                    itemRow.totalPrice = itemRow.price * itemRow.quantity
                    product.MediaContent.forEach((m) => {
                      if (m.color === itemRow.color) {
                        if (m.url && m.url != '') {
                          itemRow.image = m.url
                        }
                      }
                    })
                    if (
                      priceOption.partPrice.PartPriceArray.PartPrice.length == 1
                    ) {
                      itemRow.price =
                        priceOption.partPrice.PartPriceArray.PartPrice[0].salePrice
                    } else {
                      priceOption.partPrice.PartPriceArray.PartPrice.map(
                        (p, pKey) => {
                          if (
                            Number(p.minQuantity) <= Number(itemRow.quantity)
                          ) {
                            itemRow.price = p.salePrice
                          } else if (pKey == 0) {
                            itemRow.price = p.salePrice
                          }
                        },
                      )
                    }
                    itemRow.totalPrice = itemRow.price * itemRow.quantity
                  }
                })
              }
            })
          }
        })
        return itemRow
      })
      return rowGroup
    })
    setCatalogueCustomRows(catalogueCustomRows)
  }
  const colorselectbox = (quoteItemRow, selected) => {
    return (
      <select
        className="seprateSelectBox"
        onChange={(e) => changeColor(e, quoteItemRow)}
        value={selected}
      >
        <option key={'colorkey'} value="">
          Select color
        </option>
        {colors_final.map((color, key) => {
          return (
            <option key={key} value={color}>
              {color}
            </option>
          )
        })}
      </select>
    )
  }
  const getDecoChargeArraybyqty = (decorationsArray, qty) => {
    let decosalePrice = []
    decorationsArray &&
      decorationsArray.map((decoitemArray, index) => {
        if (decoitemArray?.decoPricedetails?.length > 0) {
          let artworkselected = artworkList.filter(
            (artorkArray) => artorkArray.id === decoitemArray.artworkID,
          )[0]
          let priceq = 0

          decoitemArray.decoPricedetails.map((decoPriceeArray) => {
            let sCount = artworkselected.design.stitchCount
            if (sCount == '' || !Number(sCount)) {
              sCount = 0
            }
            if (
              Number(sCount) >= Number(decoPriceeArray.stitchesStart) &&
              Number(sCount) <= Number(decoPriceeArray.stitchesUpto)
          ) {
            if (
                Number(qty !== '' ? qty : 1) >=
                Number(decoPriceeArray.qunatityStart) &&
                Number(qty !== '' ? qty : 1) <=
                Number(decoPriceeArray.quantityUpto)
            ) {
                priceq = decoPriceeArray
              }
            }
          })
          decosalePrice.push(priceq)
        }
      })
    return decosalePrice
  }
  let toatlQty = 0
  let toatlPrice = 0
  useEffect(() => {
    let flag = false
    catalogueCustomRows = catalogueCustomRows?.map((rowGroup, groupKey) => {
      rowGroup.map((itemRow, key) => {
        if (itemRow.color == '') {
          flag = true
          itemRow.color = colors_final[0]
        }
        if (itemRow.size == '') {
          flag = true
          itemRow.size = priceTable?.[0] ? priceTable[0].size : ''
        }
        priceTable.map((priceTableRow) => {
          if (priceTableRow.size === itemRow.size) {
            let priceOptionsArray = priceTableRow.priceOptions
            priceTable.map((priceTabel) => {
              if (priceTabel.size == itemRow.size) {
                let priceTableOptions = priceTabel.priceOptions
                priceTableOptions.map((priceOption) => {
                  if (priceOption.ColorArray.Color.colorName == itemRow.color) {
                    itemRow.product.sku = priceOption.sku
                    itemRow.totalPrice = itemRow.price * itemRow.quantity
                    product.MediaContent.forEach((m) => {
                      if (m.color === itemRow.color) {
                        if (m.url && m.url != '') {
                          itemRow.image = m.url
                        }
                      }
                    })
                    if (
                      priceOption.partPrice.PartPriceArray.PartPrice.length == 1
                    ) {
                      itemRow.price =
                        priceOption.partPrice.PartPriceArray.PartPrice[0].salePrice
                    } else {
                      priceOption.partPrice.PartPriceArray.PartPrice.map(
                        (p, pKey) => {
                          if (
                            Number(p.minQuantity) <= Number(itemRow.quantity)
                          ) {
                            itemRow.price = p.salePrice
                          } else if (pKey == 0) {
                            itemRow.price = p.salePrice
                          }
                        },
                      )
                    }
                    itemRow.totalPrice = itemRow.price * itemRow.quantity
                  }
                })
              }
            })
          }
        })
        return itemRow
      })
      return rowGroup
    })
    if (flag) {
      setCatalogueCustomRows(catalogueCustomRows)
      setToggleValue(!toggleValue)
    }
  }, [product, catalogueCustomRows])

  const getRowHTML = (itemRowArray, key) => {
    let rowTotalQty = '0'
    let rowTotal = 0
    let showDecoCharges = false
    let artwork_selected = null
    if (itemRowArray?.decorations?.[0]?.artworkID && artworkList?.length) {
      artwork_selected = artworkList.filter(
        (artorkArray) =>
          artorkArray.id === itemRowArray.decorations[0].artworkID,
      )[0]
    }
    if (itemRowArray?.decorations?.length >= 1) {
      let decoIndex = itemRowArray?.decorations?.length - 1
      let getColorIndex = product.LogoInfo.filter(
        (val) => val.color == tempSelectedColor,
      )

      let getIndex = []
      if (getColorIndex > 1) {
        getIndex = product.LogoInfo.filter(
          (val) =>
            val.color == tempSelectedColor &&
            val.location == itemRowArray.decorations[decoIndex].location,
        )
      } else {
        getIndex = getColorIndex
      }

      if (getIndex.length) {
        if (selectedImage == '') {
          setSelectedImage(getIndex[0].image)
        }
      }
    }

    let rowVariation = null
    if (artwork_selected?.design?.variation?.length > 0) {
      rowVariation = artwork_selected.design.variation[0]
    }

    if (productSettings && productSettings['showDecorationCostInCart']) {
      if (product?.showDecorationCostInCart) {
        showDecoCharges = true
      }
    } else {
      if (product?.showDecorationCostInCart) {
        showDecoCharges = true
      }
    }

    let addDecoPriceFlag = true
    let totalSetupDecoCharges = 0
    let totalDecoCharges = 0
    itemRowArray.decorations &&
      itemRowArray.decorations.map((decoArray, index) => {
        let decoChargeArray = getDecoChargeArraybyqty(
          [decoArray],
          itemRowArray?.[0]?.quantity,
        )
        if (decoChargeArray.length > 0) {
          toatlPrice =
            Number(toatlPrice) +
            Number(decoChargeArray?.[0]?.setupChargeSalePrice)
          totalSetupDecoCharges =
            totalSetupDecoCharges +
            Number(decoChargeArray?.[0]?.setupChargeSalePrice)

          totalDecoCharges =
            totalDecoCharges + Number(decoChargeArray?.[0]?.salePrice)
        }
      })
    let priceBreakArray = null
    if (product?.WebstoreProductPartArray?.length) {
      product.WebstoreProductPartArray.forEach((part) => {
        if (part?.ApparelSize?.labelSize == itemRowArray.size) {
          if (part?.ColorArray?.Color?.colorName == itemRowArray.color) {
            if (part?.partPrice?.PartPriceArray?.PartPrice)
              priceBreakArray = part.partPrice.PartPriceArray.PartPrice
          }
        }
      })
    } else if (product?.ProductPartArray?.ProductPart?.length) {
      product.ProductPartArray.ProductPart.forEach((part) => {
        if (part?.ApparelSize?.labelSize == itemRowArray.size) {
          if (part?.ColorArray?.Color?.colorName == itemRowArray.color) {
            if (part?.partPrice?.PartPriceArray?.PartPrice)
              priceBreakArray = part.partPrice.PartPriceArray.PartPrice
          }
        }
      })
    }
    let deco = null
    return (
      <div className="col-md-12 lineitemsrow mb-15 pr-3" key={key}>
        <div className="row pt-2 pl-2">
          <div
            className="product-img col-md-2 pt-2"
            onClick={() => showLogoblock(itemRowArray, deco)}
          >
            {itemRowArray.image && (
              <img
                className="default-img1"
                src={itemRowArray.image}
                alt="thumbnail"
                style={{ maxHeight: '100px', maxWidth: '100px' }}
              />
            )}
            {!itemRowArray.image && !itemRowArray.image && (
              <img
                className="default-img1"
                style={{ maxHeight: '70px', maxWidth: '70px' }}
                src={PlaceHolder}
                alt="No Image"
              />
            )}
          </div>
          <div className="col-md-2">
            <p>
              <b>Color:</b>
            </p>{' '}
            {colorselectbox(itemRowArray, itemRowArray.color)}
          </div>
          <div className="col-md-5 sepDecoCol">
            <div className="col-md-12">
              <div className="row justify-content-center">
                {inventorySettings?.['showInventory'] && (
                  <>
                    {inventorySettings?.['inventoryLabel']
                      ? inventorySettings['inventoryLabel']
                      : 'Available'}{' '}
                    {getStock(itemRowArray.size, itemRowArray.color)}
                  </>
                )}
              </div>
              <div className="row sizediv">
                {priceBreakArray?.map((priceArray, keyP) => {
                  return (
                    <div className="col-md-2 sizeContainer" key={keyP}>
                      <label className="bggray" title="Quantity">
                        {priceArray.minQuantity}
                      </label>
                      <label title="Price">
                        {
                          usePointsAsCurrency ? getPointsValue(priceArray.salePrice) : numberWithCommas(Number(priceArray.salePrice))
                        }
                      </label>
                    </div>
                  )
                })}
              </div>
              <div className="row">
                {getSettingStatus('showDecoration') && (
                  <div
                    className="col-md-6"
                    onClick={() => ToggleDivs(key, 'D')}
                  >
                    <i
                      className={
                        getDisplayStyle(key, 'D') == 'none'
                          ? 'fa fa-chevron-right pr-1'
                          : 'fa fa-chevron-down pr-1'
                      }
                      aria-hidden="true"
                    ></i>
                    <label className="toggleLabel">
                      {productSettings?.['showDecorationLable']
                        ? productSettings['showDecorationLable']
                        : 'Customize This Item'}
                    </label>
                  </div>
                )}
                {getSettingStatus('showPersonalization') && (
                  <div
                    className="col-md-6"
                    onClick={() => {
                      addPersonalization()
                      setPersonalizationAdded(true)
                      ToggleDivs(key, 'P')
                    }}
                  >
                    <i
                      className={
                        getDisplayStyle(key, 'P') == 'none'
                          ? 'fa fa-chevron-right pr-1'
                          : 'fa fa-chevron-down pr-1'
                      }
                      aria-hidden="true"
                    ></i>
                    <label className="toggleLabel">
                      {' '}
                      {productSettings?.['showPersonalizationLabel']
                        ? productSettings['showPersonalizationLabel']
                        : 'Personalization'}
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md">
            <p>
              <b>Qty:</b>
            </p>
            <input
              type="text"
              onChange={(e) => changeQty(e, itemRowArray)}
              style={{
                maxWidth: '70px',
                textAlign: 'center',
                paddingLeft: '0px',
              }}
              value={itemRowArray.quantity}
            />
          </div>
          <div className="col-md">
            <div className="row pt-2" style={{ alignItems: 'center' }}>
              <div className="pl-2">
                {itemRowArray.id && (
                  <IconButton
                    aria-label="delete"
                    className="pt-2 p-1 removeeffect iconDisplaystyle"
                    title="Delete line"
                    onClick={() => deleteRow(key)}
                  >
                    <DeleteIcon style={{ fontSize: 30, marginBottom: '1px' }} />
                    <span className="iconSpan">Delete</span>
                  </IconButton>
                )}
                {itemRowArray.id && isDisableProductClone && (
                  <IconButton
                    aria-label="copy"
                    title="Copy line"
                    className="pt-2 p-1 removeeffect iconDisplaystyle"
                    onClick={(e) => {
                      copyRow(key)
                    }}
                  >
                    <img
                      src={Copyrowicon}
                      height="30px"
                      width="30px"
                      style={{
                        marginBottom: '1px',
                        border: '1px solid lightgray',
                        padding: '2px',
                      }}
                    />
                    <span className="iconSpan">Clone</span>
                  </IconButton>
                )}
              </div>
            </div>
            <p>
              <b>Total:</b>
            </p>
            <label>
              {/*Number(decosalePrice)*/}
              {numberWithCommas(
                Number(itemRowArray.price) * Number(itemRowArray.quantity),
              )}
            </label>
          </div>
        </div>
        {!isSmall && (
          <div
            className="row decoBlock pl-2 mt-2"
            style={{ display: getDisplayStyle(key, 'D') }}
          >
            <div className="col-md-12" style={{ display: 'flex' }}>
              <div className="col-md-12" style={{ display: 'flex' }}>
                {itemRowArray.decorations &&
                  itemRowArray.decorations.map((decoitem, index) => {
                    let artworkSelected = null
                    let rowVariationArray = null
                    if (
                      decoitem.artworkID &&
                      artworkList &&
                      artworkList.length
                    ) {
                      artworkSelected = artworkList.filter(
                        (artorkArray) => artorkArray.id === decoitem.artworkID,
                      )[0]
                    }
                    let variations = null
                    if (!artworkSelected) {
                      return ''
                    } else {
                      variations = artworkSelected.design.variation.filter(
                        (variationArray) =>
                          variationArray.design_variation_unique_id ===
                          decoitem.variationUniqID,
                      )
                      if (variations)
                        return variations.map((vari, indexi) => {
                          return (
                            <div
                              className="decorows inlinefexDecorationDiv"
                              key={'idx' + indexi}
                            >
                              <img
                                className="default-img1"
                                style={{
                                  maxHeight: '60px',
                                  maxWidth: '60px',
                                }}
                                src={
                                  vari.itemImageThumbnail &&
                                  vari.itemImageThumbnail[0] != ''
                                    ? vari.itemImageThumbnail
                                    : PlaceHolder
                                }
                              />
                              <div>
                                <label
                                  style={{
                                    fontSize: '12px',
                                    margin: '1px',
                                  }}
                                >
                                  <b>
                                    {artworkSelected.design &&
                                      artworkSelected.design.designType}
                                  </b>
                                </label>
                                <p style={{ fontSize: '12px' }}>
                                  {decoitem.location}
                                </p>
                                <DeleteIcon
                                  className="float-right"
                                  onClick={() =>
                                    removeDeco(key, [itemRowArray], index)
                                  }
                                />
                              </div>
                            </div>
                          )
                        })
                    }
                  })}
              </div>
              <div className="row pl-2">
                {itemRowArray.decoration &&
                  itemRowArray.decoration.map((decoitem, index) => {
                    let artworkSelected = null
                    let rowVariationArray = null
                    if (decoitem.artworkID && artworkList.length) {
                      artworkSelected = artworkList.filter(
                        (artorkArray) => artorkArray.id === decoitem.artworkID,
                      )[0]
                    }
                    let variations = null
                    if (!artworkSelected) {
                      return ''
                    } else {
                      variations = artworkSelected.design.variation.filter(
                        (variationArray) =>
                          variationArray.design_variation_unique_id ===
                          decoitem.variationUniqID,
                      )
                      if (variations)
                        return variations.map((vari, indexi) => {
                          return (
                            <div
                              className="decorows inlinefexDecorationDiv"
                              key={'idx' + indexi}
                            >
                              <img
                                className="default-img1"
                                style={{
                                  maxHeight: '60px',
                                  maxWidth: '60px',
                                }}
                                src={
                                  vari.itemImageThumbnail &&
                                  vari.itemImageThumbnail[0] != ''
                                    ? vari.itemImageThumbnail
                                    : PlaceHolder
                                }
                              />
                              <div>
                                <div className="col-md-12">
                                  <p>
                                    <b>
                                      {artworkSelected.design &&
                                        artworkSelected.design.designType}
                                    </b>
                                  </p>
                                  <p>{decoitem.location}</p>
                                  <DeleteIcon
                                    className="float-right"
                                    onClick={() =>
                                      removeDeco(key, [itemRowArray], index)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        })
                    }
                  })}
              </div>
            </div>
            {getDisplayStyle(key, 'D') != 'none' && (
              <div className="col-md-12">
                {getDecoHtml(key, [itemRowArray])}
              </div>
            )}
          </div>
        )}
        {!isSmall && (
          <div
            className="col-md-12"
            style={{ display: getDisplayStyle(key, 'P') }}
          >
            <div
              className="row ml-2"
              style={{ borderBottom: '1px solid lightgray' }}
            >
              <div className="col-md-2">
                <h3>Personalization</h3>
              </div>
              <div className="col-md">
                <Button
                  variant="contained"
                  onClick={(e) => {
                    ToggleDivs(key, 'P')
                    deletePersonalization()
                  }}
                  color="primary"
                  className="float-right mr-2"
                >
                  Delete Personalization
                </Button>
              </div>
            </div>
            <div className="row pl-3 decporow">
              {!personalizationAdded && (
                <div className="col-md-12 text-center p-2">
                  <Button
                    variant="contained"
                    onClick={() => {
                      addPersonalization()
                      setPersonalizationAdded(true)
                    }}
                  >
                    Add Personalization
                  </Button>
                </div>
              )}
            </div>
            {personalizationAdded && (
              <div className="row pl-3 decporow">
                <div className="col-md-12 text-center p-2">
                  {getPesonalizationTable([itemRowArray])}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
  return (
    <div className="mb-4" key={key}>
      <div className="row p-2">
        <div className="col-md-2">
          <h2>Items</h2>
        </div>
      </div>
      <div className="row ">
        {catalogueCustomRows &&
          catalogueCustomRows.map((rowGroup, key) => {
            return getRowHTML(rowGroup[0], key)
          })}
        {catalogueCustomRows && catalogueCustomRows.length == 0 && (
          <div className="col-md-12 text-center lineitemsrow mb-4">
            <h4 className="p-3">No row added!</h4>
          </div>
        )}
        <div className="col-md-12 lineitemsrow">
          <div className="row" style={{ alignItems: 'center' }}>
            <div className="col-md-9 pr-3">
              <div className="col-md-10 pr-0">
                <button
                  className="btn add-row"
                  onClick={() => addFirstRow()}
                >
                  Add Row
                </button>
              </div>
            </div>
            <div className="col-md pr-3">
              <p>Total Qty:</p>
              <p>
                <b>{toatlQty}</b>
              </p>
            </div>
            <div className="col-md">
              <p>Total Price:</p>
              <p>
                <b> {numberWithCommas(Number(toatlPrice))}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
    return {
        artworkList: state.productData.artworkList,
    };
};
export default connect(mapStateToProps, null)(withRouter(QuoteSeprateList));
