import React, {useState, useEffect, useContext} from "react"
import {AuthContext} from "../../../common/Auth";

export const useShopTagState = (props) => {
    const { selectedTags } = props;
    const {themeSettingsData} = useContext(AuthContext);
    const [tagSettings, setTagSettings] = useState({});
    const [defaultExpand, setDefaultExpand] = useState(null)

    useEffect(() => {
        if (themeSettingsData && themeSettingsData.length > 0) {
            const settings = themeSettingsData.find(x => x.settingsName === 'tagSettings');
            setTagSettings(settings?.values[0] || {});
        }
    }, themeSettingsData)

    useEffect(() => {
        if (selectedTags.length > 0) {
            setDefaultExpand(true)
        } else {
            setDefaultExpand(false)
        }
    }, [selectedTags])

    return {
        defaultExpand,
        tagSettings,
        selectedTags
    }
}
