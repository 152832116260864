import React, { useContext } from "react"
import {
    getDiscountPrice,
    getFirstPrice,
    numberWithCommas,
    numberWithCommas2,
    roundedPrice
} from "../../../helpers/product";
import { AuthContext } from "../../../common/Auth";

export const useProductPriceToShow = (props) => {

    const product = props.product
    const productSettings = props.productSettings
    const showCurrencyAsPoints = typeof props.showCurrencyAsPoints === 'boolean' ? props.showCurrencyAsPoints : true;
    const decimalsToShow = props.decimalsToShow
    const currency = props.currency

    const { pointValue, usePointsAsCurrency, showDecimalPoints, pointLabel, pointLabelUnit } = useContext(AuthContext)




    const getPointsValue = (value, onlyValue = false) => {
        let resValue = (value / ((!isNaN(pointValue) && Number(pointValue) > 0) ? Number(pointValue) : 1)).toFixed(2)
        if ( !showDecimalPoints ) {
            resValue = Math.ceil(Number(resValue))
        }
        if(onlyValue) {
            return resValue
        }
        return <>
            {
                resValue
            }
            <span className={"mx-1"} style={{fontSize: "inherit"}}>{getPointsLabelBasedOnValue(resValue)}</span>
        </>
    }
    const getPointsFromValue = (value) => {
       return <p className="pt-1 mb-0">{getPointsValue(value) }</p>
    }
    const getPoints = (value) => {
        return getPointsValue(value, true)
    }
    
    const getPriceToShowAsString =(price, decimalsToShowProduct) => {
        if(typeof price === 'string') {
            price = price.replace(/,/g, '')
        }
        const points = getPointsValue(price, true)
        return usePointsAsCurrency ? `${points} ${getPointsLabelBasedOnValue(points)}` : `${currency?.currencySymbol}${numberWithCommas2(Number(price), decimalsToShowProduct ?? decimalsToShow)}`
    }
    
    const getPriceToShow = (price, decimalsToShowProduct) => {
        if(typeof price === 'string') {
            price = price.replace(/,/g, '')
        }
        return usePointsAsCurrency && showCurrencyAsPoints ? getPointsValue(price) : <>
            {currency?.currencySymbol}
            {numberWithCommas2(Number(price), decimalsToShowProduct ?? decimalsToShow)}
        </>
    }

    const getPointsLabelBasedOnValue = (value) => {
        return value > 1 ? pointLabel : pointLabelUnit
    }

    const getPointsLabel = () => {
        return pointLabel
    }

    return {
        getPointsValue,
        getPointsFromValue,
        getPoints,
        getPriceToShow,
        getPriceToShowAsString,
        getPointsLabel,
        getPointsLabelBasedOnValue
    }
}
